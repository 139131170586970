.refer-page .main-heading {
  color: #4f2582 !important;
}

.refer-page .refer-body {
  background-color: #4f2582;
}

.refer-page .refer-step {
  flex: 1 1 33%;
}

.refer-page .icon-wrapper {
  aspect-ratio: 1/1;
  background-color: #f6f4f9;
  border-radius: 100px;
  overflow: hidden;
}