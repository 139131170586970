.feature_404_wrapper {
  display: flex;
}

.feature_card {
  border-radius: 2rem !important;
  color: #fff;
  background: linear-gradient(to left top, #e35d5b, #d33139);
  border: none;
  outline: none;
}

.image_wrapper {
  margin: auto;
}

.feature_404_wrapper a {
  text-decoration: none;
}

.feature_404_wrapper .ui-card {
  height: max-content;
}