.style-wrapper {
  display: flex;
}

.bagde-flag-wrap {
  position: absolute;
  right: -12px;
}

.bagde-flag-wrap::before {
  content: "";
  position: absolute;
  top: 27px;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 12px 12px 0px;
  transform: rotate(46deg);
  left: 45px;
  border-color: transparent #ecb41c transparent transparent;
}

.bagde-flag {
  text-transform: capitalize;
  color: #ffffff;
  background: #ecb41c;
  letter-spacing: 0;
  font-size: 14px;
  line-height: 15px;
  font-weight: 600;
  padding: 10px 15px;
  display: block;
  text-decoration: none;
  border-top-right-radius: 4px;
  /* border-top-left-radius: 4px;
  border-bottom-left-radius: 4px; */
  border-bottom-right-radius: 3px;
}

/* Badge area 2 style */
.badge-area-2 {
  width: 500px;
  height: 500px;
  margin: 0 auto;
  background: #666;
  position: relative;
}

.badge-product-sale {
  display: table;
  position: absolute;
  text-align: center;
  top: 55px;
  right: -20px;
  z-index: 2;
  min-width: 100px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.badge-product-sale span {
  position: relative;
  background: #bc8246;
  color: #fff;
  float: left;
  font-size: 11px;
  font-weight: 400;
  height: 28px;
  line-height: 28px;
  padding: 0 10px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.5px;
  min-width: 50px;
  border-radius: 0;
  min-height: auto;
  border: 0;
}

.badge-product-sale span.percent {
  background: #201f1f;
}

.badge-product-sale span::before {
  border: 5px solid #bc8246;
  border-color: #bc8246 transparent transparent #bc8246;
  border-width: 9px 5px;
  position: absolute;
  right: -9px;
  top: 0;
  content: "";
  z-index: 1;
}

.badge-product-sale span::after {
  border: 5px solid #bc8246;
  border-color: transparent transparent #bc8246 #bc8246;
  border-width: 10px 5px;
  position: absolute;
  right: -9px;
  bottom: 0;
  content: "";
  z-index: 1;
}

.badge-product-sale span.percent::before {
  border: 5px solid #201f1f;
  border-color: #201f1f transparent transparent #201f1f;
  border-width: 9px 5px;
}

.badge-product-sale span.percent::after {
  border: 5px solid #201f1f;
  border-color: transparent transparent #201f1f #201f1f;
  border-width: 10px 5px;
}
