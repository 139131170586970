.Subscribe {
  height: 100vh;
  width: 100%;
  /* background-color: rgb(182, 182, 182); */
  display: flex;
  justify-content: center;
  align-items: center;
}
.Subscribe .stp {
  width: 60%;
  margin: auto;
  text-align: center;
  /* margin-top: 250px; */
  background-color: rgb(255 255 255);
  padding: 40px 30px;
  box-shadow: rgb(0 0 0 / 29%) 5px 1rem 2rem -0.075rem;
  border-radius: 20px;
  /* height: 250px; */
  text-align: start;
}
.Subscribe .stp *::placeholder {
  color: black;
}
.Subscribe button.back-btn{
  position: relative;
  top: -10px;
  left: -20px;
}
.Subscribe button.s-btn {
  width: 100%;
  margin-top: 50px;
  background-color: #6264a7;
  border: #6264a7;
  cursor: pointer;
  outline-width: 0px;
  font: 400 20px Arial;
  /* font-size: 16px !important; */
  color: #ffffff;
  text-decoration: none;
  padding: 12px 20px;
  font-size: 1.1em;
  font-weight: 600;
  border-radius: 20px;
  text-transform: uppercase;
  letter-spacing: 4px;
  display: inline-block;
  height: 45px;
  box-shadow: rgb(0 0 0 / 10%) 0px 0.2rem 0.4rem -0.075rem;
  /* vertical-align: middle; */
  transition: all 100ms ease 0s;
  /* position: relative;
    -webkit-box-pack: center;
    justify-content: center;
    max-width: 17.5rem;
    max-width: 17.5rem;
    overflow: visible;
    -webkit-appearance: button; */
  line-height: 1.15;
  /* margin: 0; */
}

.Subscribe .h2 {
  color: rgba(0, 0, 0, 0.8) !important;
  font-size: 1.8em;
  margin: 0 0 40px 0;
  display: flex;
}

@media only screen and (min-width: 767.99px) {
  .Subscribe .stp {
    width: 540px;
  }
}

@media only screen and (max-width: 767px) {
  .Subscribe .stp {
    width: 90%;
  }
}
