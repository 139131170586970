@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:400,400i,600,700,800&display=swap);
.Landing,
.btsp {
  :root {
    $blue: #008aff;
    $indigo: #6e00ff;
    $purple: #510fa8;
    $pink: #f074ad;
    $red: #d33139;
    $orange: #ffbe3d;
    $yellow: #ffc431;
    $green: #5cc9a7;
    $teal: #50b5ff;
    $cyan: #4bd6e5;
    $white: #fff;
    $gray: #718096;
    $gray-dark: #2d3748;
    $primary: #d33139;
    $secondary: #eaecf3;
    $success: #5cc9a7;
    $info: #50b5ff;
    $warning: #ffbe3d;
    $danger: #d33139;
    $light: #eaecf3;
    $dark: #171347;
    $neutral: #fff;
    $white: #fff;
    $breakpoint-xs: 0;
    $breakpoint-sm: 576px;
    $breakpoint-md: 768px;
    $breakpoint-lg: 992px;
    $breakpoint-xl: 1200px;
    $font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
    $font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
      "Liberation Mono", "Courier New", monospace;
  }
  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }
  html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(31, 45, 61, 0);
  }
  article,
  aside,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  nav,
  section {
    display: block;
  }
  body {
    margin: 0;
    font-family: "Nunito Sans", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.7;
    color: #718096;
    text-align: left;
    background-color: #fff;
  }
  [tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important;
  }
  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 0.66667rem;
  }
  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  abbr[data-original-title],
  abbr[title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
  }
  address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
  }
  dl,
  ol,
  ul {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-bottom: 0;
  }
  dt {
    font-weight: 600;
  }
  dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
  }
  blockquote {
    margin: 0 0 1rem;
  }
  b,
  strong {
    font-weight: 700;
  }
  small {
    font-size: 80%;
  }
  sub,
  sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
  }
  sub {
    bottom: -0.25em;
  }
  sup {
    top: -0.5em;
  }
  a {
    color: #008aff;
    text-decoration: none;
    background-color: transparent;
  }
  a:hover {
    color: #0061b3;
    text-decoration: none;
  }
  a:not([href]):not([class]) {
    color: inherit;
    text-decoration: none;
  }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
  }
  code,
  kbd,
  pre,
  samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
      "Courier New", monospace;
    font-size: 1em;
  }
  pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar;
  }
  figure {
    margin: 0 0 1rem;
  }
  img {
    vertical-align: middle;
    border-style: none;
  }
  svg {
    overflow: hidden;
    vertical-align: middle;
  }
  table {
    border-collapse: collapse;
  }
  caption {
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: #718096;
    text-align: left;
    caption-side: bottom;
  }
  th {
    text-align: inherit;
    text-align: -webkit-match-parent;
  }
  label {
    display: inline-block;
    margin-bottom: 0.5rem;
  }
  button {
    border-radius: 0;
  }
  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  button,
  input {
    overflow: visible;
  }
  button,
  select {
    text-transform: none;
  }
  [role="button"] {
    cursor: pointer;
  }
  select {
    word-wrap: normal;
  }
  [type="button"],
  [type="reset"],
  [type="submit"],
  button {
    -webkit-appearance: button;
  }
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled),
  button:not(:disabled) {
    cursor: pointer;
  }
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner,
  button::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }
  input[type="checkbox"],
  input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
  }
  textarea {
    overflow: auto;
    resize: vertical;
  }
  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }
  legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
  }
  @media (max-width: 1200px) {
    legend {
      font-size: calc(1.275rem + 0.3vw);
    }
  }
  progress {
    vertical-align: baseline;
  }
  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }
  [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
  }
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
  }
  output {
    display: inline-block;
  }
  summary {
    display: list-item;
    cursor: pointer;
  }
  template {
    display: none;
  }
  [hidden] {
    display: none !important;
  }
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0.66667rem;
    font-family: inherit;
    font-weight: 600;
    line-height: 1.5;
    color: #121212;
  }
  .h1,
  h1 {
    font-size: 2.5rem;
  }
  @media (max-width: 1200px) {
    .h1,
    h1 {
      font-size: calc(1.375rem + 1.5vw);
    }
  }
  .h2,
  h2 {
    font-size: 2rem;
  }
  @media (max-width: 1200px) {
    .h2,
    h2 {
      font-size: calc(1.325rem + 0.9vw);
    }
  }
  .h3,
  h3 {
    font-size: 1.75rem;
  }
  @media (max-width: 1200px) {
    .h3,
    h3 {
      font-size: calc(1.3rem + 0.6vw);
    }
  }
  .h4,
  h4 {
    font-size: 1.5rem;
  }
  @media (max-width: 1200px) {
    .h4,
    h4 {
      font-size: calc(1.275rem + 0.3vw);
    }
  }
  .h5,
  h5 {
    font-size: 1.25rem;
  }
  .h6,
  h6 {
    font-size: 1rem;
  }
  .f16 {
    font-size: 16px;
  }
  .lead {
    font-size: 1.125rem;
    font-weight: 300;
  }
  .display-1 {
    font-size: 6rem;
    font-weight: 700;
    line-height: 1.3;
  }
  @media (max-width: 1200px) {
    .display-1 {
      font-size: calc(1.725rem + 5.7vw);
    }
  }
  .display-2 {
    font-size: 5rem;
    font-weight: 700;
    line-height: 1.3;
  }
  @media (max-width: 1200px) {
    .display-2 {
      font-size: calc(1.625rem + 4.5vw);
    }
  }
  .display-3 {
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.3;
  }
  @media (max-width: 1200px) {
    .display-3 {
      font-size: calc(1.525rem + 3.3vw);
    }
  }
  .display-4 {
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.3;
  }
  @media (max-width: 1200px) {
    .display-4 {
      font-size: calc(1.425rem + 2.1vw);
    }
  }
  hr {
    margin-top: 2rem;
    margin-bottom: 2rem;
    border: 0;
    border-top: 1px solid #e2e8f0;
  }
  .small,
  small {
    font-size: 80%;
    font-weight: 400;
  }
  .mark,
  mark {
    padding: 0.2em;
    background-color: #fcf8e3;
  }
  .list-unstyled {
    padding-left: 0;
    list-style: none;
  }
  .list-inline {
    padding-left: 0;
    list-style: none;
  }
  .list-inline-item {
    display: inline-block;
  }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
  }
  .initialism {
    font-size: 90%;
    text-transform: uppercase;
  }
  .blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem;
  }
  .blockquote-footer {
    display: block;
    font-size: 80%;
    color: #718096;
  }
  .blockquote-footer::before {
    content: "\2014\00A0";
  }
  .img-fluid {
    max-width: 100%;
  }
  .img-fluid.fImg {
    // min-width: 450px;
  }
  .img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #e2e8f0;
    border-radius: 0.375rem;
    box-shadow: 0 1px 2px rgba(31, 45, 61, 0.075);
    max-width: 100%;
    height: auto;
  }
  .figure {
    display: inline-block;
  }
  .figure-img {
    margin-bottom: 0.5rem;
    line-height: 1;
  }
  .figure-caption {
    font-size: 90%;
    color: #718096;
  }
  code {
    font-size: 87.5%;
    color: #f074ad;
    word-wrap: break-word;
  }
  a > code {
    color: inherit;
  }
  kbd {
    padding: 0.2rem 0.4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #273444;
    border-radius: 0.2rem;
    box-shadow: inset 0 -0.1rem 0 rgba(31, 45, 61, 0.25);
  }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 600;
    box-shadow: none;
  }
  pre {
    display: block;
    font-size: 87.5%;
    color: #273444;
  }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
  }
  .pre-scrollable {
    max-height: 340px;
    overflow-y: scroll;
  }
  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  @media (min-width: 576px) {
    .container,
    .container-sm {
      max-width: 540px;
    }
  }
  @media (min-width: 768px) {
    .container,
    .container-md,
    .container-sm {
      max-width: 720px;
    }
  }
  @media (min-width: 992px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm {
      max-width: 960px;
    }
  }
  @media (min-width: 1200px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
      max-width: 1140px;
    }
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .no-gutters {
    margin-right: 0;
    margin-left: 0;
  }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-first {
    order: -1;
  }
  .order-last {
    order: 13;
  }
  .order-0 {
    order: 0;
  }
  .order-1 {
    order: 1;
  }
  .order-2 {
    order: 2;
  }
  .order-3 {
    order: 3;
  }
  .order-4 {
    order: 4;
  }
  .order-5 {
    order: 5;
  }
  .order-6 {
    order: 6;
  }
  .order-7 {
    order: 7;
  }
  .order-8 {
    order: 8;
  }
  .order-9 {
    order: 9;
  }
  .order-10 {
    order: 10;
  }
  .order-11 {
    order: 11;
  }
  .order-12 {
    order: 12;
  }
  .offset-1 {
    margin-left: 8.33333%;
  }
  .offset-2 {
    margin-left: 16.66667%;
  }
  .offset-3 {
    margin-left: 25%;
  }
  .offset-4 {
    margin-left: 33.33333%;
  }
  .offset-5 {
    margin-left: 41.66667%;
  }
  .offset-6 {
    margin-left: 50%;
  }
  .offset-7 {
    margin-left: 58.33333%;
  }
  .offset-8 {
    margin-left: 66.66667%;
  }
  .offset-9 {
    margin-left: 75%;
  }
  .offset-10 {
    margin-left: 83.33333%;
  }
  .offset-11 {
    margin-left: 91.66667%;
  }
  @media (min-width: 576px) {
    .col-sm {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .row-cols-sm-1 > * {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .row-cols-sm-2 > * {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .row-cols-sm-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .row-cols-sm-4 > * {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .row-cols-sm-5 > * {
      flex: 0 0 20%;
      max-width: 20%;
    }
    .row-cols-sm-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-sm-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }
    .col-sm-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
    }
    .col-sm-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-sm-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-sm-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .col-sm-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }
    .col-sm-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-sm-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }
    .col-sm-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }
    .col-sm-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-sm-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
    }
    .col-sm-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
    }
    .col-sm-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-sm-first {
      order: -1;
    }
    .order-sm-last {
      order: 13;
    }
    .order-sm-0 {
      order: 0;
    }
    .order-sm-1 {
      order: 1;
    }
    .order-sm-2 {
      order: 2;
    }
    .order-sm-3 {
      order: 3;
    }
    .order-sm-4 {
      order: 4;
    }
    .order-sm-5 {
      order: 5;
    }
    .order-sm-6 {
      order: 6;
    }
    .order-sm-7 {
      order: 7;
    }
    .order-sm-8 {
      order: 8;
    }
    .order-sm-9 {
      order: 9;
    }
    .order-sm-10 {
      order: 10;
    }
    .order-sm-11 {
      order: 11;
    }
    .order-sm-12 {
      order: 12;
    }
    .offset-sm-0 {
      margin-left: 0;
    }
    .offset-sm-1 {
      margin-left: 8.33333%;
    }
    .offset-sm-2 {
      margin-left: 16.66667%;
    }
    .offset-sm-3 {
      margin-left: 25%;
    }
    .offset-sm-4 {
      margin-left: 33.33333%;
    }
    .offset-sm-5 {
      margin-left: 41.66667%;
    }
    .offset-sm-6 {
      margin-left: 50%;
    }
    .offset-sm-7 {
      margin-left: 58.33333%;
    }
    .offset-sm-8 {
      margin-left: 66.66667%;
    }
    .offset-sm-9 {
      margin-left: 75%;
    }
    .offset-sm-10 {
      margin-left: 83.33333%;
    }
    .offset-sm-11 {
      margin-left: 91.66667%;
    }
  }
  @media (min-width: 768px) {
    .col-md {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .row-cols-md-1 > * {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .row-cols-md-2 > * {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .row-cols-md-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .row-cols-md-4 > * {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .row-cols-md-5 > * {
      flex: 0 0 20%;
      max-width: 20%;
    }
    .row-cols-md-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-md-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }
    .col-md-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
    }
    .col-md-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-md-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-md-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .col-md-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }
    .col-md-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-md-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }
    .col-md-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }
    .col-md-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-md-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
    }
    .col-md-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
    }
    .col-md-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-md-first {
      order: -1;
    }
    .order-md-last {
      order: 13;
    }
    .order-md-0 {
      order: 0;
    }
    .order-md-1 {
      order: 1;
    }
    .order-md-2 {
      order: 2;
    }
    .order-md-3 {
      order: 3;
    }
    .order-md-4 {
      order: 4;
    }
    .order-md-5 {
      order: 5;
    }
    .order-md-6 {
      order: 6;
    }
    .order-md-7 {
      order: 7;
    }
    .order-md-8 {
      order: 8;
    }
    .order-md-9 {
      order: 9;
    }
    .order-md-10 {
      order: 10;
    }
    .order-md-11 {
      order: 11;
    }
    .order-md-12 {
      order: 12;
    }
    .offset-md-0 {
      margin-left: 0;
    }
    .offset-md-1 {
      margin-left: 8.33333%;
    }
    .offset-md-2 {
      margin-left: 16.66667%;
    }
    .offset-md-3 {
      margin-left: 25%;
    }
    .offset-md-4 {
      margin-left: 33.33333%;
    }
    .offset-md-5 {
      margin-left: 41.66667%;
    }
    .offset-md-6 {
      margin-left: 50%;
    }
    .offset-md-7 {
      margin-left: 58.33333%;
    }
    .offset-md-8 {
      margin-left: 66.66667%;
    }
    .offset-md-9 {
      margin-left: 75%;
    }
    .offset-md-10 {
      margin-left: 83.33333%;
    }
    .offset-md-11 {
      margin-left: 91.66667%;
    }
  }
  @media (min-width: 992px) {
    .col-lg {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .row-cols-lg-1 > * {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .row-cols-lg-2 > * {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .row-cols-lg-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .row-cols-lg-4 > * {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .row-cols-lg-5 > * {
      flex: 0 0 20%;
      max-width: 20%;
    }
    .row-cols-lg-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-lg-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }
    .col-lg-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
    }
    .col-lg-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-lg-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-lg-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .col-lg-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }
    .col-lg-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-lg-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }
    .col-lg-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }
    .col-lg-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-lg-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
    }
    .col-lg-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
    }
    .col-lg-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-lg-first {
      order: -1;
    }
    .order-lg-last {
      order: 13;
    }
    .order-lg-0 {
      order: 0;
    }
    .order-lg-1 {
      order: 1;
    }
    .order-lg-2 {
      order: 2;
    }
    .order-lg-3 {
      order: 3;
    }
    .order-lg-4 {
      order: 4;
    }
    .order-lg-5 {
      order: 5;
    }
    .order-lg-6 {
      order: 6;
    }
    .order-lg-7 {
      order: 7;
    }
    .order-lg-8 {
      order: 8;
    }
    .order-lg-9 {
      order: 9;
    }
    .order-lg-10 {
      order: 10;
    }
    .order-lg-11 {
      order: 11;
    }
    .order-lg-12 {
      order: 12;
    }
    .offset-lg-0 {
      margin-left: 0;
    }
    .offset-lg-1 {
      margin-left: 8.33333%;
    }
    .offset-lg-2 {
      margin-left: 16.66667%;
    }
    .offset-lg-3 {
      margin-left: 25%;
    }
    .offset-lg-4 {
      margin-left: 33.33333%;
    }
    .offset-lg-5 {
      margin-left: 41.66667%;
    }
    .offset-lg-6 {
      margin-left: 50%;
    }
    .offset-lg-7 {
      margin-left: 58.33333%;
    }
    .offset-lg-8 {
      margin-left: 66.66667%;
    }
    .offset-lg-9 {
      margin-left: 75%;
    }
    .offset-lg-10 {
      margin-left: 83.33333%;
    }
    .offset-lg-11 {
      margin-left: 91.66667%;
    }
  }
  @media (min-width: 1200px) {
    .col-xl {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .row-cols-xl-1 > * {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .row-cols-xl-2 > * {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .row-cols-xl-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .row-cols-xl-4 > * {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .row-cols-xl-5 > * {
      flex: 0 0 20%;
      max-width: 20%;
    }
    .row-cols-xl-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-xl-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }
    .col-xl-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
    }
    .col-xl-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .col-xl-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-xl-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .col-xl-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }
    .col-xl-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-xl-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }
    .col-xl-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }
    .col-xl-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-xl-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
    }
    .col-xl-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
    }
    .col-xl-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-xl-first {
      order: -1;
    }
    .order-xl-last {
      order: 13;
    }
    .order-xl-0 {
      order: 0;
    }
    .order-xl-1 {
      order: 1;
    }
    .order-xl-2 {
      order: 2;
    }
    .order-xl-3 {
      order: 3;
    }
    .order-xl-4 {
      order: 4;
    }
    .order-xl-5 {
      order: 5;
    }
    .order-xl-6 {
      order: 6;
    }
    .order-xl-7 {
      order: 7;
    }
    .order-xl-8 {
      order: 8;
    }
    .order-xl-9 {
      order: 9;
    }
    .order-xl-10 {
      order: 10;
    }
    .order-xl-11 {
      order: 11;
    }
    .order-xl-12 {
      order: 12;
    }
    .offset-xl-0 {
      margin-left: 0;
    }
    .offset-xl-1 {
      margin-left: 8.33333%;
    }
    .offset-xl-2 {
      margin-left: 16.66667%;
    }
    .offset-xl-3 {
      margin-left: 25%;
    }
    .offset-xl-4 {
      margin-left: 33.33333%;
    }
    .offset-xl-5 {
      margin-left: 41.66667%;
    }
    .offset-xl-6 {
      margin-left: 50%;
    }
    .offset-xl-7 {
      margin-left: 58.33333%;
    }
    .offset-xl-8 {
      margin-left: 66.66667%;
    }
    .offset-xl-9 {
      margin-left: 75%;
    }
    .offset-xl-10 {
      margin-left: 83.33333%;
    }
    .offset-xl-11 {
      margin-left: 91.66667%;
    }
  }
  .table {
    width: 100%;
    margin-bottom: 1rem;
    color: #718096;
    background-color: transparent;
  }
  .table td,
  .table th {
    padding: 1rem;
    vertical-align: top;
    border-top: 1px solid #eaecf3;
  }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #eaecf3;
  }
  .table tbody + tbody {
    border-top: 2px solid #eaecf3;
  }
  .table-sm td,
  .table-sm th {
    padding: 0.5rem;
  }
  .table-bordered {
    border: 1px solid #eaecf3;
  }
  .table-bordered td,
  .table-bordered th {
    border: 1px solid #eaecf3;
  }
  .table-bordered thead td,
  .table-bordered thead th {
    border-bottom-width: 2px;
  }
  .table-borderless tbody + tbody,
  .table-borderless td,
  .table-borderless th,
  .table-borderless thead th {
    border: 0;
  }
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(250, 251, 254, 0.3);
  }
  .table-hover tbody tr:hover {
    color: #718096;
    background-color: #fafbfe;
  }
  .table-primary,
  .table-primary > td,
  .table-primary > th {
    background-color: #b8deff;
  }
  .table-primary tbody + tbody,
  .table-primary td,
  .table-primary th,
  .table-primary thead th {
    border-color: #7ac2ff;
  }
  .table-hover .table-primary:hover {
    background-color: #9fd2ff;
  }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fd2ff;
  }
  .table-secondary,
  .table-secondary > td,
  .table-secondary > th {
    background-color: #f9fafc;
  }
  .table-secondary tbody + tbody,
  .table-secondary td,
  .table-secondary th,
  .table-secondary thead th {
    border-color: #f4f5f9;
  }
  .table-hover .table-secondary:hover {
    background-color: #e8ecf4;
  }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #e8ecf4;
  }
  .table-success,
  .table-success > td,
  .table-success > th {
    background-color: #d1f0e6;
  }
  .table-success tbody + tbody,
  .table-success td,
  .table-success th,
  .table-success thead th {
    border-color: #aae3d1;
  }
  .table-hover .table-success:hover {
    background-color: #beeadc;
  }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #beeadc;
  }
  .table-info,
  .table-info > td,
  .table-info > th {
    background-color: #ceeaff;
  }
  .table-info tbody + tbody,
  .table-info td,
  .table-info th,
  .table-info thead th {
    border-color: #a4d9ff;
  }
  .table-hover .table-info:hover {
    background-color: #b5dfff;
  }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #b5dfff;
  }
  .table-warning,
  .table-warning > td,
  .table-warning > th {
    background-color: #ffedc9;
  }
  .table-warning tbody + tbody,
  .table-warning td,
  .table-warning th,
  .table-warning thead th {
    border-color: #ffdd9a;
  }
  .table-hover .table-warning:hover {
    background-color: #ffe5b0;
  }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe5b0;
  }
  .table-danger,
  .table-danger > td,
  .table-danger > th {
    background-color: #fbd0d4;
  }
  .table-danger tbody + tbody,
  .table-danger td,
  .table-danger th,
  .table-danger thead th {
    border-color: #f8a8b0;
  }
  .table-hover .table-danger:hover {
    background-color: #f9b9bf;
  }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f9b9bf;
  }
  .table-light,
  .table-light > td,
  .table-light > th {
    background-color: #f9fafc;
  }
  .table-light tbody + tbody,
  .table-light td,
  .table-light th,
  .table-light thead th {
    border-color: #f4f5f9;
  }
  .table-hover .table-light:hover {
    background-color: #e8ecf4;
  }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #e8ecf4;
  }
  .table-dark,
  .table-dark > td,
  .table-dark > th {
    background-color: #bebdcb;
  }
  .table-dark tbody + tbody,
  .table-dark td,
  .table-dark th,
  .table-dark thead th {
    border-color: #86849f;
  }
  .table-hover .table-dark:hover {
    background-color: #b0afc0;
  }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b0afc0;
  }
  .table-neutral,
  .table-neutral > td,
  .table-neutral > th {
    background-color: #fff;
  }
  .table-neutral tbody + tbody,
  .table-neutral td,
  .table-neutral th,
  .table-neutral thead th {
    border-color: #fff;
  }
  .table-hover .table-neutral:hover {
    background-color: #f2f2f2;
  }
  .table-hover .table-neutral:hover > td,
  .table-hover .table-neutral:hover > th {
    background-color: #f2f2f2;
  }
  .table-white,
  .table-white > td,
  .table-white > th {
    background-color: #fff;
  }
  .table-white tbody + tbody,
  .table-white td,
  .table-white th,
  .table-white thead th {
    border-color: #fff;
  }
  .table-hover .table-white:hover {
    background-color: #f2f2f2;
  }
  .table-hover .table-white:hover > td,
  .table-hover .table-white:hover > th {
    background-color: #f2f2f2;
  }
  .table-active,
  .table-active > td,
  .table-active > th {
    background-color: #fafbfe;
  }
  .table-hover .table-active:hover {
    background-color: #e5eafa;
  }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: #e5eafa;
  }
  .table .thead-dark th {
    color: #fff;
    background-color: #171347;
    border-color: #201b63;
  }
  .table .thead-light th {
    color: #4a5568;
    background-color: transparent;
    border-color: #eaecf3;
  }
  .table-dark {
    color: #fff;
    background-color: #171347;
  }
  .table-dark td,
  .table-dark th,
  .table-dark thead th {
    border-color: #201b63;
  }
  .table-dark.table-bordered {
    border: 0;
  }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
  }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075);
  }
  @media (max-width: 575.98px) {
    .table-responsive-sm {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    .table-responsive-sm > .table-bordered {
      border: 0;
    }
  }
  @media (max-width: 767.98px) {
    .table-responsive-md {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    .table-responsive-md > .table-bordered {
      border: 0;
    }
  }
  @media (max-width: 991.98px) {
    .table-responsive-lg {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    .table-responsive-lg > .table-bordered {
      border: 0;
    }
  }
  @media (max-width: 1199.98px) {
    .table-responsive-xl {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    .table-responsive-xl > .table-bordered {
      border: 0;
    }
  }
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive > .table-bordered {
    border: 0;
  }
  .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.5rem + 2px);
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #4a5568;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e2e8f0;
    border-radius: 0.375rem;
    box-shadow: inset 0 1px 1px rgba(31, 45, 61, 0.075);
    transition: all 0.2s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none;
    }
  }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0;
  }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #4a5568;
  }
  .form-control:focus {
    color: #4a5568;
    background-color: #fff;
    border-color: rgba(0, 138, 255, 0.5);
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(31, 45, 61, 0.075),
      0 0 20px rgba(0, 138, 255, 0.1);
  }
  .form-control:-ms-input-placeholder {
    color: #a0aec0;
    opacity: 1;
  }
  .form-control::-ms-input-placeholder {
    color: #a0aec0;
    opacity: 1;
  }
  .form-control::placeholder {
    color: #a0aec0;
    opacity: 1;
  }
  .form-control:disabled,
  .form-control[readonly] {
    background-color: #eaecf3;
    opacity: 1;
  }
  input[type="date"].form-control,
  input[type="datetime-local"].form-control,
  input[type="month"].form-control,
  input[type="time"].form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  select.form-control:focus::-ms-value {
    color: #4a5568;
    background-color: #fff;
  }
  .form-control-file,
  .form-control-range {
    display: block;
    width: 100%;
  }
  .col-form-label {
    padding-top: calc(0.75rem + 1px);
    padding-bottom: calc(0.75rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
  }
  .col-form-label-lg {
    padding-top: calc(1rem + 1px);
    padding-bottom: calc(1rem + 1px);
    font-size: 1rem;
    line-height: 1.5;
  }
  .col-form-label-sm {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    font-size: 0.875rem;
    line-height: 1.5;
  }
  .form-control-plaintext {
    display: block;
    width: 100%;
    padding: 0.75rem 0;
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 1.5;
    color: #718096;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
  }
  .form-control-plaintext.form-control-lg,
  .form-control-plaintext.form-control-sm {
    padding-right: 0;
    padding-left: 0;
  }
  .form-control-sm {
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1.25rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
  .form-control-lg {
    height: calc(1.5em + 2rem + 2px);
    padding: 1rem 1.875rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.5rem;
  }
  select.form-control[multiple],
  select.form-control[size] {
    height: auto;
  }
  textarea.form-control {
    height: auto;
  }
  .form-group {
    margin-bottom: 1rem;
  }
  .form-text {
    display: block;
    margin-top: 0.25rem;
  }
  .form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
  }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
  .form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
  }
  .form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem;
  }
  .form-check-input:disabled ~ .form-check-label,
  .form-check-input[disabled] ~ .form-check-label {
    color: #718096;
  }
  .form-check-label {
    margin-bottom: 0;
  }
  .form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem;
  }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
  }
  .valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #5cc9a7;
  }
  .valid-tooltip {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    line-height: 1.7;
    color: #fff;
    background-color: rgba(92, 201, 167, 0.9);
    border-radius: 0.375rem;
  }
  .is-valid ~ .valid-feedback,
  .is-valid ~ .valid-tooltip,
  .was-validated :valid ~ .valid-feedback,
  .was-validated :valid ~ .valid-tooltip {
    display: block;
  }
  .form-control.is-valid,
  .was-validated .form-control:valid {
    border-color: #5cc9a7;
  }
  .form-control.is-valid:focus,
  .was-validated .form-control:valid:focus {
    border-color: #5cc9a7;
    box-shadow: 0 0 0 0 rgba(92, 201, 167, 0.25);
  }
  .custom-select.is-valid,
  .was-validated .custom-select:valid {
    border-color: #5cc9a7;
  }
  .custom-select.is-valid:focus,
  .was-validated .custom-select:valid:focus {
    border-color: #5cc9a7;
    box-shadow: 0 0 0 0 rgba(92, 201, 167, 0.25);
  }
  .form-check-input.is-valid ~ .form-check-label,
  .was-validated .form-check-input:valid ~ .form-check-label {
    color: #5cc9a7;
  }
  .form-check-input.is-valid ~ .valid-feedback,
  .form-check-input.is-valid ~ .valid-tooltip,
  .was-validated .form-check-input:valid ~ .valid-feedback,
  .was-validated .form-check-input:valid ~ .valid-tooltip {
    display: block;
  }
  .custom-control-input.is-valid ~ .custom-control-label,
  .was-validated .custom-control-input:valid ~ .custom-control-label {
    color: #5cc9a7;
  }
  .custom-control-input.is-valid ~ .custom-control-label::before,
  .was-validated .custom-control-input:valid ~ .custom-control-label::before {
    border-color: #5cc9a7;
  }
  .custom-control-input.is-valid:checked ~ .custom-control-label::before,
  .was-validated
    .custom-control-input:valid:checked
    ~ .custom-control-label::before {
    border-color: #82d6bc;
    background-color: #82d6bc;
  }
  .custom-control-input.is-valid:focus ~ .custom-control-label::before,
  .was-validated
    .custom-control-input:valid:focus
    ~ .custom-control-label::before {
    box-shadow: 0 0 0 0 rgba(92, 201, 167, 0.25);
  }
  .custom-control-input.is-valid:focus:not(:checked)
    ~ .custom-control-label::before,
  .was-validated
    .custom-control-input:valid:focus:not(:checked)
    ~ .custom-control-label::before {
    border-color: #5cc9a7;
  }
  .custom-file-input.is-valid ~ .custom-file-label,
  .was-validated .custom-file-input:valid ~ .custom-file-label {
    border-color: #5cc9a7;
  }
  .custom-file-input.is-valid:focus ~ .custom-file-label,
  .was-validated .custom-file-input:valid:focus ~ .custom-file-label {
    border-color: #5cc9a7;
    box-shadow: 0 0 0 0 rgba(92, 201, 167, 0.25);
  }
  .invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #d33139;
  }
  .invalid-tooltip {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    line-height: 1.7;
    color: #fff;
    background-color: rgba(242, 87, 103, 0.9);
    border-radius: 0.375rem;
  }
  .is-invalid ~ .invalid-feedback,
  .is-invalid ~ .invalid-tooltip,
  .was-validated :invalid ~ .invalid-feedback,
  .was-validated :invalid ~ .invalid-tooltip {
    display: block;
  }
  .form-control.is-invalid,
  .was-validated .form-control:invalid {
    border-color: #d33139;
  }
  .form-control.is-invalid:focus,
  .was-validated .form-control:invalid:focus {
    border-color: #d33139;
    box-shadow: 0 0 0 0 rgba(242, 87, 103, 0.25);
  }
  .custom-select.is-invalid,
  .was-validated .custom-select:invalid {
    border-color: #d33139;
  }
  .custom-select.is-invalid:focus,
  .was-validated .custom-select:invalid:focus {
    border-color: #d33139;
    box-shadow: 0 0 0 0 rgba(242, 87, 103, 0.25);
  }
  .form-check-input.is-invalid ~ .form-check-label,
  .was-validated .form-check-input:invalid ~ .form-check-label {
    color: #d33139;
  }
  .form-check-input.is-invalid ~ .invalid-feedback,
  .form-check-input.is-invalid ~ .invalid-tooltip,
  .was-validated .form-check-input:invalid ~ .invalid-feedback,
  .was-validated .form-check-input:invalid ~ .invalid-tooltip {
    display: block;
  }
  .custom-control-input.is-invalid ~ .custom-control-label,
  .was-validated .custom-control-input:invalid ~ .custom-control-label {
    color: #d33139;
  }
  .custom-control-input.is-invalid ~ .custom-control-label::before,
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before {
    border-color: #d33139;
  }
  .custom-control-input.is-invalid:checked ~ .custom-control-label::before,
  .was-validated
    .custom-control-input:invalid:checked
    ~ .custom-control-label::before {
    border-color: #f68692;
    background-color: #f68692;
  }
  .custom-control-input.is-invalid:focus ~ .custom-control-label::before,
  .was-validated
    .custom-control-input:invalid:focus
    ~ .custom-control-label::before {
    box-shadow: 0 0 0 0 rgba(242, 87, 103, 0.25);
  }
  .custom-control-input.is-invalid:focus:not(:checked)
    ~ .custom-control-label::before,
  .was-validated
    .custom-control-input:invalid:focus:not(:checked)
    ~ .custom-control-label::before {
    border-color: #d33139;
  }
  .custom-file-input.is-invalid ~ .custom-file-label,
  .was-validated .custom-file-input:invalid ~ .custom-file-label {
    border-color: #d33139;
  }
  .custom-file-input.is-invalid:focus ~ .custom-file-label,
  .was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
    border-color: #d33139;
    box-shadow: 0 0 0 0 rgba(242, 87, 103, 0.25);
  }
  .form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }
  .form-inline .form-check {
    width: 100%;
  }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
    }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0;
    }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle;
    }
    .form-inline .form-control-plaintext {
      display: inline-block;
    }
    .form-inline .custom-select,
    .form-inline .input-group {
      width: auto;
    }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0;
    }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0;
    }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center;
    }
    .form-inline .custom-control-label {
      margin-bottom: 0;
    }
  }
  .btn {
    display: inline-block;
    font-weight: 600;
    color: #718096;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.75rem 1.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.375rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none;
    }
  }
  .btn:hover {
    color: #718096;
    text-decoration: none;
  }
  .btn.focus,
  .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 rgba(0, 124, 230, 0.25);
  }
  .btn.disabled,
  .btn:disabled {
    opacity: 0.65;
    box-shadow: none;
  }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  .btn:not(:disabled):not(.disabled).active,
  .btn:not(:disabled):not(.disabled):active {
    box-shadow: none;
  }
  .btn:not(:disabled):not(.disabled).active:focus,
  .btn:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 rgba(0, 124, 230, 0.25);
  }
  a.btn.disabled,
  fieldset:disabled a.btn {
    pointer-events: none;
  }
  .btn-primary {
    color: #fff;
    background-color: #d33139;
    border-color: #d33139;
    box-shadow: none;
  }
  .btn-primary:hover {
    color: #fff;
    background-color: #b62d34;
    border-color: #b62d34;
  }
  .btn-primary.focus,
  .btn-primary:focus {
    box-shadow: none, 0 0 0 rgba(0, 138, 255, 0.35);
  }
  .btn-primary.disabled,
  .btn-primary:disabled {
    color: #fff;
    background-color: #d33139;
    border-color: #d33139;
  }
  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #b62d34;
    border-color: #b62d34;
  }
  .btn-primary:not(:disabled):not(.disabled).active:focus,
  .btn-primary:not(:disabled):not(.disabled):active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 rgba(0, 138, 255, 0.35);
  }
  .btn-secondary {
    color: #273444;
    background-color: #eaecf3;
    border-color: #eaecf3;
    box-shadow: none;
  }
  .btn-secondary:hover {
    color: #273444;
    background-color: #d2d6e5;
    border-color: #cacfe0;
  }
  .btn-secondary.focus,
  .btn-secondary:focus {
    box-shadow: none, 0 0 0 rgba(234, 236, 243, 0.35);
  }
  .btn-secondary.disabled,
  .btn-secondary:disabled {
    color: #273444;
    background-color: #eaecf3;
    border-color: #eaecf3;
  }
  .btn-secondary:not(:disabled):not(.disabled).active,
  .btn-secondary:not(:disabled):not(.disabled):active,
  .show > .btn-secondary.dropdown-toggle {
    color: #273444;
    background-color: #cacfe0;
    border-color: #c1c7dc;
  }
  .btn-secondary:not(:disabled):not(.disabled).active:focus,
  .btn-secondary:not(:disabled):not(.disabled):active:focus,
  .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 rgba(234, 236, 243, 0.35);
  }
  .btn-success {
    color: #fff;
    background-color: #5cc9a7;
    border-color: #5cc9a7;
    box-shadow: none;
  }
  .btn-success:hover {
    color: #fff;
    background-color: #3fbf97;
    border-color: #3cb690;
  }
  .btn-success.focus,
  .btn-success:focus {
    box-shadow: none, 0 0 0 rgba(92, 201, 167, 0.35);
  }
  .btn-success.disabled,
  .btn-success:disabled {
    color: #fff;
    background-color: #5cc9a7;
    border-color: #5cc9a7;
  }
  .btn-success:not(:disabled):not(.disabled).active,
  .btn-success:not(:disabled):not(.disabled):active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3cb690;
    border-color: #39ac88;
  }
  .btn-success:not(:disabled):not(.disabled).active:focus,
  .btn-success:not(:disabled):not(.disabled):active:focus,
  .show > .btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 rgba(92, 201, 167, 0.35);
  }
  .btn-info {
    color: #fff;
    background-color: #50b5ff;
    border-color: #50b5ff;
    box-shadow: none;
  }
  .btn-info:hover {
    color: #fff;
    background-color: #2aa5ff;
    border-color: #1d9fff;
  }
  .btn-info.focus,
  .btn-info:focus {
    box-shadow: none, 0 0 0 rgba(80, 181, 255, 0.35);
  }
  .btn-info.disabled,
  .btn-info:disabled {
    color: #fff;
    background-color: #50b5ff;
    border-color: #50b5ff;
  }
  .btn-info:not(:disabled):not(.disabled).active,
  .btn-info:not(:disabled):not(.disabled):active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #1d9fff;
    border-color: #109aff;
  }
  .btn-info:not(:disabled):not(.disabled).active:focus,
  .btn-info:not(:disabled):not(.disabled):active:focus,
  .show > .btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 rgba(80, 181, 255, 0.35);
  }
  .btn-warning {
    color: #fff;
    background-color: #ffbe3d;
    border-color: #ffbe3d;
    box-shadow: none;
  }
  .btn-warning:hover {
    color: #fff;
    background-color: #ffb117;
    border-color: #ffad0a;
  }
  .btn-warning.focus,
  .btn-warning:focus {
    box-shadow: none, 0 0 0 rgba(255, 190, 61, 0.35);
  }
  .btn-warning.disabled,
  .btn-warning:disabled {
    color: #fff;
    background-color: #ffbe3d;
    border-color: #ffbe3d;
  }
  .btn-warning:not(:disabled):not(.disabled).active,
  .btn-warning:not(:disabled):not(.disabled):active,
  .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #ffad0a;
    border-color: #fca800;
  }
  .btn-warning:not(:disabled):not(.disabled).active:focus,
  .btn-warning:not(:disabled):not(.disabled):active:focus,
  .show > .btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 rgba(255, 190, 61, 0.35);
  }
  .btn-danger {
    color: #fff;
    background-color: #d33139;
    border-color: #d33139;
    box-shadow: none;
  }
  .btn-danger:hover {
    color: #fff;
    background-color: #b62d34;
    border-color: #b62d34;
  }
  .btn-danger.focus,
  .btn-danger:focus {
    box-shadow: none, 0 0 0 rgba(242, 87, 103, 0.35);
  }
  .btn-danger.disabled,
  .btn-danger:disabled {
    color: #fff;
    background-color: #d33139;
    border-color: #d33139;
  }
  .btn-danger:not(:disabled):not(.disabled).active,
  .btn-danger:not(:disabled):not(.disabled):active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #b62d34;
    border-color: #b62d34;
  }
  .btn-danger:not(:disabled):not(.disabled).active:focus,
  .btn-danger:not(:disabled):not(.disabled):active:focus,
  .show > .btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 rgba(242, 87, 103, 0.35);
  }
  .btn-light {
    color: #273444;
    background-color: #eaecf3;
    border-color: #eaecf3;
    box-shadow: none;
  }
  .btn-light:hover {
    color: #273444;
    background-color: #d2d6e5;
    border-color: #cacfe0;
  }
  .btn-light.focus,
  .btn-light:focus {
    box-shadow: none, 0 0 0 rgba(234, 236, 243, 0.35);
  }
  .btn-light.disabled,
  .btn-light:disabled {
    color: #273444;
    background-color: #eaecf3;
    border-color: #eaecf3;
  }
  .btn-light:not(:disabled):not(.disabled).active,
  .btn-light:not(:disabled):not(.disabled):active,
  .show > .btn-light.dropdown-toggle {
    color: #273444;
    background-color: #cacfe0;
    border-color: #c1c7dc;
  }
  .btn-light:not(:disabled):not(.disabled).active:focus,
  .btn-light:not(:disabled):not(.disabled):active:focus,
  .show > .btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 rgba(234, 236, 243, 0.35);
  }
  .btn-dark {
    color: #fff;
    background-color: #171347;
    border-color: #171347;
    box-shadow: none;
  }
  .btn-dark:hover {
    color: #fff;
    background-color: #0d0b29;
    border-color: #0a081f;
  }
  .btn-dark.focus,
  .btn-dark:focus {
    box-shadow: none, 0 0 0 rgba(23, 19, 71, 0.35);
  }
  .btn-dark.disabled,
  .btn-dark:disabled {
    color: #fff;
    background-color: #171347;
    border-color: #171347;
  }
  .btn-dark:not(:disabled):not(.disabled).active,
  .btn-dark:not(:disabled):not(.disabled):active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #0a081f;
    border-color: #070615;
  }
  .btn-dark:not(:disabled):not(.disabled).active:focus,
  .btn-dark:not(:disabled):not(.disabled):active:focus,
  .show > .btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 rgba(23, 19, 71, 0.35);
  }
  .btn-neutral {
    color: #273444;
    background-color: #fff;
    border-color: #fff;
    box-shadow: none;
  }
  .btn-neutral:hover {
    color: #273444;
    background-color: #ececec;
    border-color: #e6e6e6;
  }
  .btn-neutral.focus,
  .btn-neutral:focus {
    box-shadow: none, 0 0 0 rgba(255, 255, 255, 0.35);
  }
  .btn-neutral.disabled,
  .btn-neutral:disabled {
    color: #273444;
    background-color: #fff;
    border-color: #fff;
  }
  .btn-neutral:not(:disabled):not(.disabled).active,
  .btn-neutral:not(:disabled):not(.disabled):active,
  .show > .btn-neutral.dropdown-toggle {
    color: #273444;
    background-color: #e6e6e6;
    border-color: #dfdfdf;
  }
  .btn-neutral:not(:disabled):not(.disabled).active:focus,
  .btn-neutral:not(:disabled):not(.disabled):active:focus,
  .show > .btn-neutral.dropdown-toggle:focus {
    box-shadow: 0 0 0 rgba(255, 255, 255, 0.35);
  }
  .btn-white {
    color: #273444;
    background-color: #fff;
    border-color: #fff;
    box-shadow: none;
  }
  .btn-white:hover {
    color: #273444;
    background-color: #ececec;
    border-color: #e6e6e6;
  }
  .btn-white.focus,
  .btn-white:focus {
    box-shadow: none, 0 0 0 rgba(255, 255, 255, 0.35);
  }
  .btn-white.disabled,
  .btn-white:disabled {
    color: #273444;
    background-color: #fff;
    border-color: #fff;
  }
  .btn-white:not(:disabled):not(.disabled).active,
  .btn-white:not(:disabled):not(.disabled):active,
  .show > .btn-white.dropdown-toggle {
    color: #273444;
    background-color: #e6e6e6;
    border-color: #dfdfdf;
  }
  .btn-white:not(:disabled):not(.disabled).active:focus,
  .btn-white:not(:disabled):not(.disabled):active:focus,
  .show > .btn-white.dropdown-toggle:focus {
    box-shadow: 0 0 0 rgba(255, 255, 255, 0.35);
  }
  .btn-outline-primary {
    color: #d33139;
    border-color: #d33139;
  }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #d33139;
    border-color: #d33139;
  }
  .btn-outline-primary.focus,
  .btn-outline-primary:focus {
    box-shadow: none, 0 0 0 rgba(0, 138, 255, 0.35);
  }
  .btn-outline-primary.disabled,
  .btn-outline-primary:disabled {
    color: #d33139;
    background-color: transparent;
  }
  .btn-outline-primary:not(:disabled):not(.disabled).active,
  .btn-outline-primary:not(:disabled):not(.disabled):active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #d33139;
    border-color: #d33139;
  }
  .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
  .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
  .show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 rgba(0, 138, 255, 0.35);
  }
  .btn-outline-secondary {
    color: #eaecf3;
    border-color: #eaecf3;
  }
  .btn-outline-secondary:hover {
    color: #273444;
    background-color: #eaecf3;
    border-color: #eaecf3;
  }
  .btn-outline-secondary.focus,
  .btn-outline-secondary:focus {
    box-shadow: none, 0 0 0 rgba(234, 236, 243, 0.35);
  }
  .btn-outline-secondary.disabled,
  .btn-outline-secondary:disabled {
    color: #eaecf3;
    background-color: transparent;
  }
  .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .btn-outline-secondary:not(:disabled):not(.disabled):active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #273444;
    background-color: #eaecf3;
    border-color: #eaecf3;
  }
  .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
  .btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
  .show > .btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 rgba(234, 236, 243, 0.35);
  }
  .btn-outline-success {
    color: #5cc9a7;
    border-color: #5cc9a7;
  }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #5cc9a7;
    border-color: #5cc9a7;
  }
  .btn-outline-success.focus,
  .btn-outline-success:focus {
    box-shadow: none, 0 0 0 rgba(92, 201, 167, 0.35);
  }
  .btn-outline-success.disabled,
  .btn-outline-success:disabled {
    color: #5cc9a7;
    background-color: transparent;
  }
  .btn-outline-success:not(:disabled):not(.disabled).active,
  .btn-outline-success:not(:disabled):not(.disabled):active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #5cc9a7;
    border-color: #5cc9a7;
  }
  .btn-outline-success:not(:disabled):not(.disabled).active:focus,
  .btn-outline-success:not(:disabled):not(.disabled):active:focus,
  .show > .btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 rgba(92, 201, 167, 0.35);
  }
  .btn-outline-info {
    color: #50b5ff;
    border-color: #50b5ff;
  }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #50b5ff;
    border-color: #50b5ff;
  }
  .btn-outline-info.focus,
  .btn-outline-info:focus {
    box-shadow: none, 0 0 0 rgba(80, 181, 255, 0.35);
  }
  .btn-outline-info.disabled,
  .btn-outline-info:disabled {
    color: #50b5ff;
    background-color: transparent;
  }
  .btn-outline-info:not(:disabled):not(.disabled).active,
  .btn-outline-info:not(:disabled):not(.disabled):active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #50b5ff;
    border-color: #50b5ff;
  }
  .btn-outline-info:not(:disabled):not(.disabled).active:focus,
  .btn-outline-info:not(:disabled):not(.disabled):active:focus,
  .show > .btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 rgba(80, 181, 255, 0.35);
  }
  .btn-outline-warning {
    color: #ffbe3d;
    border-color: #ffbe3d;
  }
  .btn-outline-warning:hover {
    color: #fff;
    background-color: #ffbe3d;
    border-color: #ffbe3d;
  }
  .btn-outline-warning.focus,
  .btn-outline-warning:focus {
    box-shadow: none, 0 0 0 rgba(255, 190, 61, 0.35);
  }
  .btn-outline-warning.disabled,
  .btn-outline-warning:disabled {
    color: #ffbe3d;
    background-color: transparent;
  }
  .btn-outline-warning:not(:disabled):not(.disabled).active,
  .btn-outline-warning:not(:disabled):not(.disabled):active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #ffbe3d;
    border-color: #ffbe3d;
  }
  .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
  .btn-outline-warning:not(:disabled):not(.disabled):active:focus,
  .show > .btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 rgba(255, 190, 61, 0.35);
  }
  .btn-outline-danger {
    color: #d33139;
    border-color: #d33139;
  }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #d33139;
    border-color: #d33139;
  }
  .btn-outline-danger.focus,
  .btn-outline-danger:focus {
    box-shadow: none, 0 0 0 rgba(242, 87, 103, 0.35);
  }
  .btn-outline-danger.disabled,
  .btn-outline-danger:disabled {
    color: #d33139;
    background-color: transparent;
  }
  .btn-outline-danger:not(:disabled):not(.disabled).active,
  .btn-outline-danger:not(:disabled):not(.disabled):active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #d33139;
    border-color: #d33139;
  }
  .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
  .btn-outline-danger:not(:disabled):not(.disabled):active:focus,
  .show > .btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 rgba(242, 87, 103, 0.35);
  }
  .btn-outline-light {
    color: #eaecf3;
    border-color: #eaecf3;
  }
  .btn-outline-light:hover {
    color: #273444;
    background-color: #eaecf3;
    border-color: #eaecf3;
  }
  .btn-outline-light.focus,
  .btn-outline-light:focus {
    box-shadow: none, 0 0 0 rgba(234, 236, 243, 0.35);
  }
  .btn-outline-light.disabled,
  .btn-outline-light:disabled {
    color: #eaecf3;
    background-color: transparent;
  }
  .btn-outline-light:not(:disabled):not(.disabled).active,
  .btn-outline-light:not(:disabled):not(.disabled):active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #273444;
    background-color: #eaecf3;
    border-color: #eaecf3;
  }
  .btn-outline-light:not(:disabled):not(.disabled).active:focus,
  .btn-outline-light:not(:disabled):not(.disabled):active:focus,
  .show > .btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 rgba(234, 236, 243, 0.35);
  }
  .btn-outline-dark {
    color: #171347;
    border-color: #171347;
  }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #171347;
    border-color: #171347;
  }
  .btn-outline-dark.focus,
  .btn-outline-dark:focus {
    box-shadow: none, 0 0 0 rgba(23, 19, 71, 0.35);
  }
  .btn-outline-dark.disabled,
  .btn-outline-dark:disabled {
    color: #171347;
    background-color: transparent;
  }
  .btn-outline-dark:not(:disabled):not(.disabled).active,
  .btn-outline-dark:not(:disabled):not(.disabled):active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #171347;
    border-color: #171347;
  }
  .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
  .btn-outline-dark:not(:disabled):not(.disabled):active:focus,
  .show > .btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 rgba(23, 19, 71, 0.35);
  }
  .btn-outline-neutral {
    color: #fff;
    border-color: #fff;
  }
  .btn-outline-neutral:hover {
    color: #273444;
    background-color: #fff;
    border-color: #fff;
  }
  .btn-outline-neutral.focus,
  .btn-outline-neutral:focus {
    box-shadow: none, 0 0 0 rgba(255, 255, 255, 0.35);
  }
  .btn-outline-neutral.disabled,
  .btn-outline-neutral:disabled {
    color: #fff;
    background-color: transparent;
  }
  .btn-outline-neutral:not(:disabled):not(.disabled).active,
  .btn-outline-neutral:not(:disabled):not(.disabled):active,
  .show > .btn-outline-neutral.dropdown-toggle {
    color: #273444;
    background-color: #fff;
    border-color: #fff;
  }
  .btn-outline-neutral:not(:disabled):not(.disabled).active:focus,
  .btn-outline-neutral:not(:disabled):not(.disabled):active:focus,
  .show > .btn-outline-neutral.dropdown-toggle:focus {
    box-shadow: 0 0 0 rgba(255, 255, 255, 0.35);
  }
  .btn-outline-white {
    color: #fff;
    border-color: #fff;
  }
  .btn-outline-white:hover {
    color: #273444;
    background-color: #fff;
    border-color: #fff;
  }
  .btn-outline-white.focus,
  .btn-outline-white:focus {
    box-shadow: none, 0 0 0 rgba(255, 255, 255, 0.35);
  }
  .btn-outline-white.disabled,
  .btn-outline-white:disabled {
    color: #fff;
    background-color: transparent;
  }
  .btn-outline-white:not(:disabled):not(.disabled).active,
  .btn-outline-white:not(:disabled):not(.disabled):active,
  .show > .btn-outline-white.dropdown-toggle {
    color: #273444;
    background-color: #fff;
    border-color: #fff;
  }
  .btn-outline-white:not(:disabled):not(.disabled).active:focus,
  .btn-outline-white:not(:disabled):not(.disabled):active:focus,
  .show > .btn-outline-white.dropdown-toggle:focus {
    box-shadow: 0 0 0 rgba(255, 255, 255, 0.35);
  }
  .btn-link {
    font-weight: 400;
    color: #d33139;
    text-decoration: none;
  }
  .btn-link:hover {
    color: #0061b3;
    text-decoration: none;
  }
  .btn-link.focus,
  .btn-link:focus {
    text-decoration: none;
  }
  .btn-link.disabled,
  .btn-link:disabled {
    color: #718096;
    pointer-events: none;
  }
  .btn-group-lg > .btn,
  .btn-lg {
    padding: 1rem 1.875rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.5rem;
  }
  .btn-group-sm > .btn,
  .btn-sm {
    padding: 0.5rem 1.25rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.375rem;
  }
  .btn-block {
    display: block;
    width: 100%;
  }
  .btn-block + .btn-block {
    margin-top: 0.5rem;
  }
  input[type="button"].btn-block,
  input[type="reset"].btn-block,
  input[type="submit"].btn-block {
    width: 100%;
  }
  .fade {
    transition: opacity 0.2s linear;
  }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none;
    }
  }
  .fade:not(.show) {
    opacity: 0;
  }
  .collapse:not(.show) {
    display: none;
  }
  .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.2s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none;
    }
  }
  .dropdown,
  .dropleft,
  .dropright,
  .dropup {
    position: relative;
  }
  .dropdown-toggle {
    white-space: nowrap;
  }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.5rem;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Free";
    font-weight: 700;
    font-size: 12px;
    content: "\f107";
  }
  .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 12rem;
    padding: 0.35rem 0;
    margin: 0.125rem 0 0;
    font-size: 0.875rem;
    color: #718096;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #eaecf3;
    border-radius: 0.5rem;
    box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.05);
  }
  .dropdown-menu-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  @media (min-width: 576px) {
    .dropdown-menu-sm-left {
      right: auto;
      left: 0;
    }
    .dropdown-menu-sm-right {
      right: 0;
      left: auto;
    }
  }
  @media (min-width: 768px) {
    .dropdown-menu-md-left {
      right: auto;
      left: 0;
    }
    .dropdown-menu-md-right {
      right: 0;
      left: auto;
    }
  }
  @media (min-width: 992px) {
    .dropdown-menu-lg-left {
      right: auto;
      left: 0;
    }
    .dropdown-menu-lg-right {
      right: 0;
      left: auto;
    }
  }
  @media (min-width: 1200px) {
    .dropdown-menu-xl-left {
      right: auto;
      left: 0;
    }
    .dropdown-menu-xl-right {
      right: 0;
      left: auto;
    }
  }
  .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.125rem;
  }
  .dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.5rem;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Free";
    font-weight: 700;
    font-size: 12px;
    content: "\f106";
  }
  .dropup .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  .dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.125rem;
  }
  .dropright .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.5rem;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Free";
    font-weight: 700;
    font-size: 12px;
    content: "\f105";
  }
  .dropright .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  .dropright .dropdown-toggle::after {
    vertical-align: 0;
  }
  .dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem;
  }
  .dropleft .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.5rem;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Free";
    font-weight: 700;
    font-size: 12px;
  }
  .dropleft .dropdown-toggle::after {
    display: none;
  }
  .dropleft .dropdown-toggle::before {
    font-family: "Font Awesome 5 Free";
    font-weight: 700;
    display: inline-block;
    margin-right: 0.5rem;
    content: "\f104";
  }
  .dropleft .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  .dropleft .dropdown-toggle::before {
    vertical-align: 0;
  }
  .dropdown-menu[x-placement^="bottom"],
  .dropdown-menu[x-placement^="left"],
  .dropdown-menu[x-placement^="right"],
  .dropdown-menu[x-placement^="top"] {
    right: auto;
    bottom: auto;
  }
  .dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #eaecf3;
  }
  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1rem;
    clear: both;
    font-weight: 400;
    color: #4a5568;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }
  .dropdown-item:focus,
  .dropdown-item:hover {
    color: #d33139;
    text-decoration: none;
    background-color: transparent;
  }
  .dropdown-item.active,
  .dropdown-item:active {
    color: #d33139;
    text-decoration: none;
    background-color: transparent;
  }
  .dropdown-item.disabled,
  .dropdown-item:disabled {
    color: #718096;
    pointer-events: none;
    background-color: transparent;
  }
  .dropdown-menu.show {
    display: block;
  }
  .dropdown-header {
    display: block;
    padding: 0.35rem 1rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: #718096;
    white-space: nowrap;
  }
  .dropdown-item-text {
    display: block;
    padding: 0.25rem 1rem;
    color: #4a5568;
  }
  .btn-group,
  .btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
  }
  .btn-group-vertical > .btn,
  .btn-group > .btn {
    position: relative;
    flex: 1 1 auto;
  }
  .btn-group-vertical > .btn:hover,
  .btn-group > .btn:hover {
    z-index: 1;
  }
  .btn-group-vertical > .btn.active,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn:focus,
  .btn-group > .btn.active,
  .btn-group > .btn:active,
  .btn-group > .btn:focus {
    z-index: 1;
  }
  .btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .btn-toolbar .input-group {
    width: auto;
  }
  .btn-group > .btn-group:not(:first-child),
  .btn-group > .btn:not(:first-child) {
    margin-left: -1px;
  }
  .btn-group > .btn-group:not(:last-child) > .btn,
  .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .btn-group > .btn-group:not(:first-child) > .btn,
  .btn-group > .btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .dropdown-toggle-split {
    padding-right: 1.3125rem;
    padding-left: 1.3125rem;
  }
  .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after {
    margin-left: 0;
  }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0;
  }
  .btn-group-sm > .btn + .dropdown-toggle-split,
  .btn-sm + .dropdown-toggle-split {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
  .btn-group-lg > .btn + .dropdown-toggle-split,
  .btn-lg + .dropdown-toggle-split {
    padding-right: 1.40625rem;
    padding-left: 1.40625rem;
  }
  .btn-group.show .dropdown-toggle {
    box-shadow: none;
  }
  .btn-group.show .dropdown-toggle.btn-link {
    box-shadow: none;
  }
  .btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%;
  }
  .btn-group-vertical > .btn-group:not(:first-child),
  .btn-group-vertical > .btn:not(:first-child) {
    margin-top: -1px;
  }
  .btn-group-vertical > .btn-group:not(:last-child) > .btn,
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .btn-group-vertical > .btn-group:not(:first-child) > .btn,
  .btn-group-vertical > .btn:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .btn-group-toggle > .btn,
  .btn-group-toggle > .btn-group > .btn {
    margin-bottom: 0;
  }
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
  }
  .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
  }
  .input-group > .custom-file,
  .input-group > .custom-select,
  .input-group > .form-control,
  .input-group > .form-control-plaintext {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
  }
  .input-group > .custom-file + .custom-file,
  .input-group > .custom-file + .custom-select,
  .input-group > .custom-file + .form-control,
  .input-group > .custom-select + .custom-file,
  .input-group > .custom-select + .custom-select,
  .input-group > .custom-select + .form-control,
  .input-group > .form-control + .custom-file,
  .input-group > .form-control + .custom-select,
  .input-group > .form-control + .form-control,
  .input-group > .form-control-plaintext + .custom-file,
  .input-group > .form-control-plaintext + .custom-select,
  .input-group > .form-control-plaintext + .form-control {
    margin-left: -1px;
  }
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label,
  .input-group > .custom-select:focus,
  .input-group > .form-control:focus {
    z-index: 3;
  }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4;
  }
  .input-group > .custom-select:not(:last-child),
  .input-group > .form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input-group > .custom-select:not(:first-child),
  .input-group > .form-control:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group > .custom-file {
    display: flex;
    align-items: center;
  }
  .input-group > .custom-file:not(:last-child) .custom-file-label,
  .input-group > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input-group > .custom-file:not(:first-child) .custom-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group-append,
  .input-group-prepend {
    display: flex;
  }
  .input-group-append .btn,
  .input-group-prepend .btn {
    position: relative;
    z-index: 2;
  }
  .input-group-append .btn:focus,
  .input-group-prepend .btn:focus {
    z-index: 3;
  }
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .btn,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-prepend .input-group-text + .input-group-text {
    margin-left: -1px;
  }
  .input-group-prepend {
    margin-right: -1px;
  }
  .input-group-append {
    margin-left: -1px;
  }
  .input-group-text {
    display: flex;
    align-items: center;
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #a0aec0;
    text-align: center;
    white-space: nowrap;
    background-color: #fafbfe;
    border: 1px solid #e2e8f0;
    border-radius: 0.375rem;
  }
  .input-group-text input[type="checkbox"],
  .input-group-text input[type="radio"] {
    margin-top: 0;
  }
  .input-group-lg > .custom-select,
  .input-group-lg > .form-control:not(textarea) {
    height: calc(1.5em + 2rem + 2px);
  }
  .input-group-lg > .custom-select,
  .input-group-lg > .form-control,
  .input-group-lg > .input-group-append > .btn,
  .input-group-lg > .input-group-append > .input-group-text,
  .input-group-lg > .input-group-prepend > .btn,
  .input-group-lg > .input-group-prepend > .input-group-text {
    padding: 1rem 1.875rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.5rem;
  }
  .input-group-sm > .custom-select,
  .input-group-sm > .form-control:not(textarea) {
    height: calc(1.5em + 1rem + 2px);
  }
  .input-group-sm > .custom-select,
  .input-group-sm > .form-control,
  .input-group-sm > .input-group-append > .btn,
  .input-group-sm > .input-group-append > .input-group-text,
  .input-group-sm > .input-group-prepend > .btn,
  .input-group-sm > .input-group-prepend > .input-group-text {
    padding: 0.5rem 1.25rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
  .input-group-lg > .custom-select,
  .input-group-sm > .custom-select {
    padding-right: 2.25rem;
  }
  .input-group
    > .input-group-append:last-child
    > .btn:not(:last-child):not(.dropdown-toggle),
  .input-group
    > .input-group-append:last-child
    > .input-group-text:not(:last-child),
  .input-group > .input-group-append:not(:last-child) > .btn,
  .input-group > .input-group-append:not(:last-child) > .input-group-text,
  .input-group > .input-group-prepend > .btn,
  .input-group > .input-group-prepend > .input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input-group > .input-group-append > .btn,
  .input-group > .input-group-append > .input-group-text,
  .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
  .input-group
    > .input-group-prepend:first-child
    > .input-group-text:not(:first-child),
  .input-group > .input-group-prepend:not(:first-child) > .btn,
  .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .custom-control {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.7rem;
    padding-left: 1.75rem;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
  .custom-control-inline {
    display: inline-flex;
    margin-right: 1rem;
  }
  .custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.35rem;
    opacity: 0;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007ce6;
    background-color: #007ce6;
    box-shadow: none;
  }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: inset 0 1px 1px rgba(31, 45, 61, 0.075),
      0 0 20px rgba(0, 138, 255, 0.1);
  }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: rgba(0, 138, 255, 0.5);
  }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #99d0ff;
    border-color: #99d0ff;
    box-shadow: none;
  }
  .custom-control-input:disabled ~ .custom-control-label,
  .custom-control-input[disabled] ~ .custom-control-label {
    color: #718096;
  }
  .custom-control-input:disabled ~ .custom-control-label::before,
  .custom-control-input[disabled] ~ .custom-control-label::before {
    background-color: #eaecf3;
  }
  .custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
  }
  .custom-control-label::before {
    position: absolute;
    top: 0.35rem;
    left: -1.75rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #e2e8f0;
    border: #eaecf3 solid 0;
    box-shadow: none;
  }
  .custom-control-label::after {
    position: absolute;
    top: 0.35rem;
    left: -1.75rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50%/50% 50%;
  }
  .custom-checkbox .custom-control-label::before {
    border-radius: 0.375rem;
  }
  .custom-checkbox
    .custom-control-input:checked
    ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23FFF' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
  }
  .custom-checkbox
    .custom-control-input:indeterminate
    ~ .custom-control-label::before {
    border-color: #007ce6;
    background-color: #007ce6;
    box-shadow: none;
  }
  .custom-checkbox
    .custom-control-input:indeterminate
    ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23FFF' d='M0 2h4'/%3e%3c/svg%3e");
  }
  .custom-checkbox
    .custom-control-input:disabled:checked
    ~ .custom-control-label::before {
    background-color: rgba(0, 138, 255, 0.5);
  }
  .custom-checkbox
    .custom-control-input:disabled:indeterminate
    ~ .custom-control-label::before {
    background-color: rgba(0, 138, 255, 0.5);
  }
  .custom-radio .custom-control-label::before {
    border-radius: 50%;
  }
  .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFF'/%3e%3c/svg%3e");
  }
  .custom-radio
    .custom-control-input:disabled:checked
    ~ .custom-control-label::before {
    background-color: rgba(0, 138, 255, 0.5);
  }
  .custom-switch {
    padding-left: 3.75rem;
  }
  .custom-switch .custom-control-label::before {
    left: -3.75rem;
    width: 3rem;
    pointer-events: all;
    border-radius: 0.5625rem;
  }
  .custom-switch .custom-control-label::after {
    top: 0.35rem;
    left: -3.75rem;
    width: 1.125rem;
    height: 1.125rem;
    background-color: #eaecf3;
    border-radius: 0.5625rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .custom-switch .custom-control-label::after {
      transition: none;
    }
  }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #e2e8f0;
    transform: translateX(2rem);
  }
  .custom-switch
    .custom-control-input:disabled:checked
    ~ .custom-control-label::before {
    background-color: rgba(0, 138, 255, 0.5);
  }
  .custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 1.5rem + 2px);
    padding: 0.75rem 2.25rem 0.75rem 1.25rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #4a5568;
    vertical-align: middle;
    background: #fff
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%232D3748' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 1.25rem center/8px 10px;
    border: 1px solid #e2e8f0;
    border-radius: 0.375rem;
    box-shadow: inset 0 1px 2px rgba(31, 45, 61, 0.075);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .custom-select:focus {
    border-color: rgba(0, 138, 255, 0.5);
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(31, 45, 61, 0.075),
      0 0 20px rgba(0, 138, 255, 0.1);
  }
  .custom-select:focus::-ms-value {
    color: #4a5568;
    background-color: #fff;
  }
  .custom-select[multiple],
  .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 1.25rem;
    background-image: none;
  }
  .custom-select:disabled {
    color: #718096;
    background-color: #eaecf3;
  }
  .custom-select::-ms-expand {
    display: none;
  }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #4a5568;
  }
  .custom-select-sm {
    height: calc(1.5em + 1rem + 2px);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1.25rem;
    font-size: 0.875rem;
  }
  .custom-select-lg {
    height: calc(1.5em + 2rem + 2px);
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.875rem;
    font-size: 1rem;
  }
  .custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 1.5rem + 2px);
    margin-bottom: 0;
  }
  .custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em + 1.5rem + 2px);
    margin: 0;
    opacity: 0;
  }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: rgba(0, 138, 255, 0.5);
    box-shadow: 0 0 20px rgba(0, 138, 255, 0.1);
  }
  .custom-file-input:disabled ~ .custom-file-label,
  .custom-file-input[disabled] ~ .custom-file-label {
    background-color: #eaecf3;
  }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse";
  }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse);
  }
  .custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.5em + 1.5rem + 2px);
    padding: 0.75rem 1.25rem;
    font-weight: 400;
    line-height: 1.5;
    color: #4a5568;
    background-color: #fff;
    border: 1px solid #e2e8f0;
    border-radius: 0.375rem;
    box-shadow: inset 0 1px 1px rgba(31, 45, 61, 0.075);
  }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 1.5rem);
    padding: 0.75rem 1.25rem;
    line-height: 1.5;
    color: #4a5568;
    content: "Browse";
    background-color: #fafbfe;
    border-left: inherit;
    border-radius: 0 0.375rem 0.375rem 0;
  }
  .custom-range {
    width: 100%;
    height: 1rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .custom-range:focus {
    outline: 0;
  }
  .custom-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 20px rgba(0, 138, 255, 0.1);
  }
  .custom-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 20px rgba(0, 138, 255, 0.1);
  }
  .custom-range:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 20px rgba(0, 138, 255, 0.1);
  }
  .custom-range::-moz-focus-outer {
    border: 0;
  }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007ce6;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(31, 45, 61, 0.1);
    transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
  }
  @media (prefers-reduced-motion: reduce) {
    .custom-range::-webkit-slider-thumb {
      transition: none;
    }
  }
  .custom-range::-webkit-slider-thumb:active {
    background-color: #99d0ff;
  }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #e2e8f0;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(31, 45, 61, 0.1);
  }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007ce6;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(31, 45, 61, 0.1);
    transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
    appearance: none;
  }
  @media (prefers-reduced-motion: reduce) {
    .custom-range::-moz-range-thumb {
      transition: none;
    }
  }
  .custom-range::-moz-range-thumb:active {
    background-color: #99d0ff;
  }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #e2e8f0;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(31, 45, 61, 0.1);
  }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
    background-color: #007ce6;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(31, 45, 61, 0.1);
    transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
  }
  @media (prefers-reduced-motion: reduce) {
    .custom-range::-ms-thumb {
      transition: none;
    }
  }
  .custom-range::-ms-thumb:active {
    background-color: #99d0ff;
  }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(31, 45, 61, 0.1);
  }
  .custom-range::-ms-fill-lower {
    background-color: #e2e8f0;
    border-radius: 1rem;
  }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #e2e8f0;
    border-radius: 1rem;
  }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #a0aec0;
  }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default;
  }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #a0aec0;
  }
  .custom-range:disabled::-moz-range-track {
    cursor: default;
  }
  .custom-range:disabled::-ms-thumb {
    background-color: #a0aec0;
  }
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none;
    }
  }
  .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  .nav-link {
    display: block;
    padding: 0.25rem 1rem;
  }
  .nav-link:focus,
  .nav-link:hover {
    text-decoration: none;
  }
  .nav-link.disabled {
    color: #718096;
    pointer-events: none;
    cursor: default;
  }
  .nav-tabs {
    border-bottom: 1px solid #e2e8f0;
  }
  .nav-tabs .nav-item {
    margin-bottom: -1px;
  }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    border-color: transparent transparent transparent;
  }
  .nav-tabs .nav-link.disabled {
    color: #718096;
    background-color: transparent;
    border-color: transparent;
  }
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: #718096;
    background-color: transparent;
    border-color: transparent transparent #d33139;
  }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .nav-pills .nav-link {
    border-radius: 0.375rem;
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #007ce6;
  }
  .nav-fill .nav-item,
  .nav-fill > .nav-link {
    flex: 1 1 auto;
    text-align: center;
  }
  .nav-justified .nav-item,
  .nav-justified > .nav-link {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }
  .tab-content > .tab-pane {
    display: none;
  }
  .tab-content > .active {
    display: block;
  }
  .navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 1rem;
    border-bottom: 1px solid #f0f0f0;
  }
  .navbar .container,
  .navbar .container-fluid,
  .navbar .container-lg,
  .navbar .container-md,
  .navbar .container-sm,
  .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  .navbar-brand {
    display: inline-block;
    padding-top: 0.0375rem;
    padding-bottom: 0.0375rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
  }
  .navbar-brand:focus,
  .navbar-brand:hover {
    text-decoration: none;
  }
  .navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }
  .navbar-text {
    display: inline-block;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
  }
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.375rem;
  }
  .navbar-toggler:focus,
  .navbar-toggler:hover {
    text-decoration: none;
  }
  .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
  }
  @media (max-width: 575.98px) {
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid,
    .navbar-expand-sm > .container-lg,
    .navbar-expand-sm > .container-md,
    .navbar-expand-sm > .container-sm,
    .navbar-expand-sm > .container-xl {
      padding-right: 0;
      padding-left: 0;
    }
  }
  @media (min-width: 576px) {
    .navbar-expand-sm {
      flex-flow: row nowrap;
      justify-content: flex-start;
    }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row;
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-sm .navbar-nav .nav-link {
      padding-right: 1rem;
      padding-left: 1rem;
    }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid,
    .navbar-expand-sm > .container-lg,
    .navbar-expand-sm > .container-md,
    .navbar-expand-sm > .container-sm,
    .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap;
    }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-expand-sm .navbar-toggler {
      display: none;
    }
  }
  @media (max-width: 767.98px) {
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid,
    .navbar-expand-md > .container-lg,
    .navbar-expand-md > .container-md,
    .navbar-expand-md > .container-sm,
    .navbar-expand-md > .container-xl {
      padding-right: 0;
      padding-left: 0;
    }
  }
  @media (min-width: 768px) {
    .navbar-expand-md {
      flex-flow: row nowrap;
      justify-content: flex-start;
    }
    .navbar-expand-md .navbar-nav {
      flex-direction: row;
    }
    .navbar-expand-md .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-md .navbar-nav .nav-link {
      padding-right: 1rem;
      padding-left: 1rem;
    }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid,
    .navbar-expand-md > .container-lg,
    .navbar-expand-md > .container-md,
    .navbar-expand-md > .container-sm,
    .navbar-expand-md > .container-xl {
      flex-wrap: nowrap;
    }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-expand-md .navbar-toggler {
      display: none;
    }
  }
  @media (max-width: 991.98px) {
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid,
    .navbar-expand-lg > .container-lg,
    .navbar-expand-lg > .container-md,
    .navbar-expand-lg > .container-sm,
    .navbar-expand-lg > .container-xl {
      padding-right: 0;
      padding-left: 0;
    }
  }
  @media (min-width: 992px) {
    .navbar-expand-lg {
      flex-flow: row nowrap;
      justify-content: flex-start;
    }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: 1rem;
      padding-left: 1rem;
    }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid,
    .navbar-expand-lg > .container-lg,
    .navbar-expand-lg > .container-md,
    .navbar-expand-lg > .container-sm,
    .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap;
    }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-expand-lg .navbar-toggler {
      display: none;
    }
  }
  @media (max-width: 1199.98px) {
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid,
    .navbar-expand-xl > .container-lg,
    .navbar-expand-xl > .container-md,
    .navbar-expand-xl > .container-sm,
    .navbar-expand-xl > .container-xl {
      padding-right: 0;
      padding-left: 0;
    }
  }
  @media (min-width: 1200px) {
    .navbar-expand-xl {
      flex-flow: row nowrap;
      justify-content: flex-start;
    }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row;
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-xl .navbar-nav .nav-link {
      padding-right: 1rem;
      padding-left: 1rem;
    }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid,
    .navbar-expand-xl > .container-lg,
    .navbar-expand-xl > .container-md,
    .navbar-expand-xl > .container-sm,
    .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap;
    }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-expand-xl .navbar-toggler {
      display: none;
    }
  }
  .navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid,
  .navbar-expand > .container-lg,
  .navbar-expand > .container-md,
  .navbar-expand > .container-sm,
  .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid,
  .navbar-expand > .container-lg,
  .navbar-expand > .container-md,
  .navbar-expand > .container-sm,
  .navbar-expand > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand .navbar-toggler {
    display: none;
  }
  .navbar-light .navbar-brand {
    color: rgb(182, 45, 52);
  }
  .navbar-light .navbar-brand:focus,
  .navbar-light .navbar-brand:hover {
    color: rgb(182, 45, 52);
  }
  .navbar-light .navbar-nav .nav-link {
    color: rgba(31, 45, 61, 0.6);
  }
  .navbar-light .navbar-nav .nav-link:focus,
  .navbar-light .navbar-nav .nav-link:hover {
    color: rgb(182, 45, 52);
  }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(31, 45, 61, 0.3);
  }
  .navbar-light .navbar-nav .active > .nav-link,
  .navbar-light .navbar-nav .nav-link.active,
  .navbar-light .navbar-nav .nav-link.show,
  .navbar-light .navbar-nav .show > .nav-link {
    color: rgb(182, 45, 52);
  }
  .navbar-light .navbar-toggler {
    color: rgba(31, 45, 61, 0.6);
    border-color: transparent;
  }
  .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%2831, 45, 61, 0.6%29' stroke-width='2' strokeLinecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }
  .navbar-light .navbar-text {
    color: rgba(31, 45, 61, 0.6);
  }
  .navbar-light .navbar-text a {
    color: rgb(182, 45, 52);
  }
  .navbar-light .navbar-text a:focus,
  .navbar-light .navbar-text a:hover {
    color: rgb(182, 45, 52);
  }
  .navbar-dark .navbar-brand {
    color: #fff;
  }
  .navbar-dark .navbar-brand:focus,
  .navbar-dark .navbar-brand:hover {
    color: #fff;
  }
  .navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.85);
  }
  .navbar-dark .navbar-nav .nav-link:focus,
  .navbar-dark .navbar-nav .nav-link:hover {
    color: #fff;
  }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25);
  }
  .navbar-dark .navbar-nav .active > .nav-link,
  .navbar-dark .navbar-nav .nav-link.active,
  .navbar-dark .navbar-nav .nav-link.show,
  .navbar-dark .navbar-nav .show > .nav-link {
    color: #fff;
  }
  .navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.85);
    border-color: transparent;
  }
  .navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 0.85%29' stroke-width='2' strokeLinecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }
  .navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.85);
  }
  .navbar-dark .navbar-text a {
    color: #fff;
  }
  .navbar-dark .navbar-text a:focus,
  .navbar-dark .navbar-text a:hover {
    color: #fff;
  }
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #eaecf3;
    border-radius: 0.75rem;
  }
  .card > hr {
    margin-right: 0;
    margin-left: 0;
  }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit;
  }
  .card > .list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: calc(0.75rem - 1px);
    border-top-right-radius: calc(0.75rem - 1px);
  }
  .card > .list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: calc(0.75rem - 1px);
    border-bottom-left-radius: calc(0.75rem - 1px);
  }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0;
  }
  .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.75rem;
    color: #718096;
  }
  .card-title {
    margin-bottom: 1.25rem;
  }
  .card-subtitle {
    margin-top: -0.625rem;
    margin-bottom: 0;
  }
  .card-text:last-child {
    margin-bottom: 0;
  }
  .card-link:hover {
    text-decoration: none;
  }
  .card-link + .card-link {
    margin-left: 1.75rem;
  }
  .card-header {
    padding: 1.25rem 1.75rem;
    margin-bottom: 0;
    background-color: transparent;
    border-bottom: 1px solid #eaecf3;
  }
  .card-header:first-child {
    border-radius: calc(0.75rem - 1px) calc(0.75rem - 1px) 0 0;
  }
  .card-footer {
    padding: 1.25rem 1.75rem;
    background-color: transparent;
    border-top: 1px solid #eaecf3;
  }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.75rem - 1px) calc(0.75rem - 1px);
  }
  .card-header-tabs {
    margin-right: -0.875rem;
    margin-bottom: -1.25rem;
    margin-left: -0.875rem;
    border-bottom: 0;
  }
  .card-header-pills {
    margin-right: -0.875rem;
    margin-left: -0.875rem;
  }
  .card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
    border-radius: calc(0.75rem - 1px);
  }
  .card-img,
  .card-img-bottom,
  .card-img-top {
    flex-shrink: 0;
    width: 100%;
  }
  .card-img,
  .card-img-top {
    border-top-left-radius: calc(0.75rem - 1px);
    border-top-right-radius: calc(0.75rem - 1px);
  }
  .card-img,
  .card-img-bottom {
    border-bottom-right-radius: calc(0.75rem - 1px);
    border-bottom-left-radius: calc(0.75rem - 1px);
  }
  .card-deck .card {
    margin-bottom: 15px;
  }
  @media (min-width: 576px) {
    .card-deck {
      display: flex;
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px;
    }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px;
    }
  }
  .card-group > .card {
    margin-bottom: 15px;
  }
  @media (min-width: 576px) {
    .card-group {
      display: flex;
      flex-flow: row wrap;
    }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0;
    }
    .card-group > .card + .card {
      margin-left: 0;
      border-left: 0;
    }
    .card-group > .card:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .card-group > .card:not(:last-child) .card-header,
    .card-group > .card:not(:last-child) .card-img-top {
      border-top-right-radius: 0;
    }
    .card-group > .card:not(:last-child) .card-footer,
    .card-group > .card:not(:last-child) .card-img-bottom {
      border-bottom-right-radius: 0;
    }
    .card-group > .card:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .card-group > .card:not(:first-child) .card-header,
    .card-group > .card:not(:first-child) .card-img-top {
      border-top-left-radius: 0;
    }
    .card-group > .card:not(:first-child) .card-footer,
    .card-group > .card:not(:first-child) .card-img-bottom {
      border-bottom-left-radius: 0;
    }
  }
  .card-columns .card {
    margin-bottom: 1.25rem;
  }
  @media (min-width: 576px) {
    .card-columns {
      column-count: 3;
      column-gap: 1.25rem;
      orphans: 1;
      widows: 1;
    }
    .card-columns .card {
      display: inline-block;
      width: 100%;
    }
  }
  .accordion {
    overflow-anchor: none;
  }
  .accordion > .card {
    overflow: hidden;
  }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px;
  }
  .breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 0;
    list-style: none;
    background-color: transparent;
    border-radius: 0.375rem;
  }
  .breadcrumb-item {
    display: flex;
  }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #cbd5e0;
    content: "/";
  }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline;
  }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none;
  }
  .breadcrumb-item.active {
    color: #718096;
  }
  .pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.375rem;
  }
  .page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #718096;
    background-color: #fff;
    border: 1px solid #e2e8f0;
  }
  .page-link:hover {
    z-index: 2;
    color: #718096;
    text-decoration: none;
    background-color: #e2e8f0;
    border-color: #e2e8f0;
  }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 rgba(0, 124, 230, 0.25);
  }
  .page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .page-item:last-child .page-link {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }
  .page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #007ce6;
    border-color: #007ce6;
  }
  .page-item.disabled .page-link {
    color: #718096;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #e2e8f0;
  }
  .pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5;
  }
  .pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
  }
  .pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
  }
  .pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
  }
  .badge {
    display: inline-block;
    padding: 0.35rem 0.5rem;
    font-size: 80%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.375rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none;
    }
  }
  a.badge:focus,
  a.badge:hover {
    text-decoration: none;
  }
  .badge:empty {
    display: none;
  }
  .btn .badge {
    position: relative;
    top: -1px;
  }
  .badge-pill {
    padding-right: 0.875em;
    padding-left: 0.875em;
    border-radius: 50rem;
  }
  .badge-primary {
    color: #fff;
    background-color: #d33139;
  }
  a.badge-primary:focus,
  a.badge-primary:hover {
    color: #fff;
    background-color: #b62d34;
  }
  a.badge-primary.focus,
  a.badge-primary:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(0, 138, 255, 0.5);
  }
  .badge-secondary {
    color: #273444;
    background-color: #eaecf3;
  }
  a.badge-secondary:focus,
  a.badge-secondary:hover {
    color: #273444;
    background-color: #cacfe0;
  }
  a.badge-secondary.focus,
  a.badge-secondary:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(234, 236, 243, 0.5);
  }
  .badge-success {
    color: #fff;
    background-color: #5cc9a7;
  }
  a.badge-success:focus,
  a.badge-success:hover {
    color: #fff;
    background-color: #3cb690;
  }
  a.badge-success.focus,
  a.badge-success:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(92, 201, 167, 0.5);
  }
  .badge-info {
    color: #fff;
    background-color: #50b5ff;
  }
  a.badge-info:focus,
  a.badge-info:hover {
    color: #fff;
    background-color: #1d9fff;
  }
  a.badge-info.focus,
  a.badge-info:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(80, 181, 255, 0.5);
  }
  .badge-warning {
    color: #fff;
    background-color: #ffbe3d;
  }
  a.badge-warning:focus,
  a.badge-warning:hover {
    color: #fff;
    background-color: #ffad0a;
  }
  a.badge-warning.focus,
  a.badge-warning:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(255, 190, 61, 0.5);
  }
  .badge-danger {
    color: #fff;
    background-color: #d33139;
  }
  a.badge-danger:focus,
  a.badge-danger:hover {
    color: #fff;
    background-color: #b62d34;
  }
  a.badge-danger.focus,
  a.badge-danger:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(242, 87, 103, 0.5);
  }
  .badge-light {
    color: #273444;
    background-color: #eaecf3;
  }
  a.badge-light:focus,
  a.badge-light:hover {
    color: #273444;
    background-color: #cacfe0;
  }
  a.badge-light.focus,
  a.badge-light:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(234, 236, 243, 0.5);
  }
  .badge-dark {
    color: #fff;
    background-color: #171347;
  }
  a.badge-dark:focus,
  a.badge-dark:hover {
    color: #fff;
    background-color: #0a081f;
  }
  a.badge-dark.focus,
  a.badge-dark:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(23, 19, 71, 0.5);
  }
  .badge-neutral {
    color: #273444;
    background-color: #fff;
  }
  a.badge-neutral:focus,
  a.badge-neutral:hover {
    color: #273444;
    background-color: #e6e6e6;
  }
  a.badge-neutral.focus,
  a.badge-neutral:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  }
  .badge-white {
    color: #273444;
    background-color: #fff;
  }
  a.badge-white:focus,
  a.badge-white:hover {
    color: #273444;
    background-color: #e6e6e6;
  }
  a.badge-white.focus,
  a.badge-white:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  }
  .jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #fff;
    border-radius: 0.5rem;
  }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem;
    }
  }
  .jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0;
  }
  .alert {
    position: relative;
    padding: 0.875rem 0.875rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.375rem;
  }
  .alert-heading {
    color: inherit;
  }
  .alert-link {
    font-weight: 600;
  }
  .alert-dismissible {
    padding-right: 3rem;
  }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.875rem 0.875rem;
    color: inherit;
  }
  .alert-primary {
    color: #fff;
    background-color: #d33139;
    border-color: #d33139;
  }
  .alert-primary hr {
    border-top-color: #007ce6;
  }
  .alert-primary .alert-link {
    color: #fff;
    font-weight: 600;
    border-bottom: 1px dashed #fff;
  }
  .alert-primary .close > span:not(.sr-only) {
    color: #fff;
  }
  .alert-secondary {
    color: #273444;
    background-color: #eaecf3;
    border-color: #eaecf3;
  }
  .alert-secondary hr {
    border-top-color: #daddea;
  }
  .alert-secondary .alert-link {
    color: #273444;
    font-weight: 600;
    border-bottom: 1px dashed #273444;
  }
  .alert-secondary .close > span:not(.sr-only) {
    color: #273444;
  }
  .alert-success {
    color: #fff;
    background-color: #4CAF50;
    border-color:#4CAF50;
  }
  .alert-success hr {
    border-top-color: #49c39d;
  }
  .alert-success .alert-link {
    color: #fff;
    font-weight: 600;
    border-bottom: 1px dashed #fff;
  }
  .alert-success .close > span:not(.sr-only) {
    color: #fff;
  }
  .alert-info {
    color: #fff;
    background-color: #50b5ff;
    border-color: #50b5ff;
  }
  .alert-info hr {
    border-top-color: #37aaff;
  }
  .alert-info .alert-link {
    color: #fff;
    font-weight: 600;
    border-bottom: 1px dashed #fff;
  }
  .alert-info .close > span:not(.sr-only) {
    color: #fff;
  }
  .alert-warning {
    color: #fff;
    background-color: #ffbe3d;
    border-color: #ffbe3d;
  }
  .alert-warning hr {
    border-top-color: #ffb524;
  }
  .alert-warning .alert-link {
    color: #fff;
    font-weight: 600;
    border-bottom: 1px dashed #fff;
  }
  .alert-warning .close > span:not(.sr-only) {
    color: #fff;
  }
  .alert-danger {
    color: #fff;
    background-color: #d33139;
    border-color: #d33139;
  }
  .alert-danger hr {
    border-top-color: #f03f52;
  }
  .alert-danger .alert-link {
    color: #fff;
    font-weight: 600;
    border-bottom: 1px dashed #fff;
  }
  .alert-danger .close > span:not(.sr-only) {
    color: #fff;
  }
  .alert-light {
    color: #273444;
    background-color: #eaecf3;
    border-color: #eaecf3;
  }
  .alert-light hr {
    border-top-color: #daddea;
  }
  .alert-light .alert-link {
    color: #273444;
    font-weight: 600;
    border-bottom: 1px dashed #273444;
  }
  .alert-light .close > span:not(.sr-only) {
    color: #273444;
  }
  .alert-dark {
    color: #fff;
    background-color: #171347;
    border-color: #171347;
  }
  .alert-dark hr {
    border-top-color: #100e33;
  }
  .alert-dark .alert-link {
    color: #fff;
    font-weight: 600;
    border-bottom: 1px dashed #fff;
  }
  .alert-dark .close > span:not(.sr-only) {
    color: #fff;
  }
  .alert-neutral {
    color: #273444;
    background-color: #fff;
    border-color: #fff;
  }
  .alert-neutral hr {
    border-top-color: #f2f2f2;
  }
  .alert-neutral .alert-link {
    color: #273444;
    font-weight: 600;
    border-bottom: 1px dashed #273444;
  }
  .alert-neutral .close > span:not(.sr-only) {
    color: #273444;
  }
  .alert-white {
    color: #273444;
    background-color: #fff;
    border-color: #fff;
  }
  .alert-white hr {
    border-top-color: #f2f2f2;
  }
  .alert-white .alert-link {
    color: #273444;
    font-weight: 600;
    border-bottom: 1px dashed #273444;
  }
  .alert-white .close > span:not(.sr-only) {
    color: #273444;
  }
  @keyframes progress-bar-stripes {
    from {
      background-position: 0.5rem 0;
    }
    to {
      background-position: 0 0;
    }
  }
  .progress {
    display: flex;
    height: 0.5rem;
    overflow: hidden;
    line-height: 0;
    font-size: 0.75rem;
    background-color: #eaecf3;
    border-radius: 50rem;
    box-shadow: inset 0 0.1rem 0.1rem rgba(31, 45, 61, 0.1);
  }
  .progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #008aff;
    transition: width 0.6s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none;
    }
  }
  .progress-bar-striped {
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-size: 0.5rem 0.5rem;
  }
  .progress-bar-animated {
    animation: progress-bar-stripes 1s linear infinite;
  }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none;
    }
  }
  .media {
    display: flex;
    align-items: flex-start;
  }
  .media-body {
    flex: 1;
  }
  .list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: 0.375rem;
  }
  .list-group-item-action {
    width: 100%;
    color: #4a5568;
    text-align: inherit;
  }
  .list-group-item-action:focus,
  .list-group-item-action:hover {
    z-index: 1;
    color: #4a5568;
    text-decoration: none;
    background-color: #fafbfe;
  }
  .list-group-item-action:active {
    color: #4a5568;
    background-color: #eaecf3;
  }
  .list-group-item {
    position: relative;
    display: block;
    padding: 1rem 1.5rem;
    background-color: #fff;
    border: 1px solid #eaecf3;
  }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
  }
  .list-group-item.disabled,
  .list-group-item:disabled {
    color: #718096;
    pointer-events: none;
    background-color: #fff;
  }
  .list-group-item.active {
    z-index: 2;
    color: #4a5568;
    background-color: #fafbfe;
    border-color: #eaecf3;
  }
  .list-group-item + .list-group-item {
    border-top-width: 0;
  }
  .list-group-item + .list-group-item.active {
    margin-top: -1px;
    border-top-width: 1px;
  }
  .list-group-horizontal {
    flex-direction: row;
  }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
  @media (min-width: 576px) {
    .list-group-horizontal-sm {
      flex-direction: row;
    }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.375rem;
      border-top-right-radius: 0;
    }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.375rem;
      border-bottom-left-radius: 0;
    }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0;
    }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0;
    }
    .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px;
    }
  }
  @media (min-width: 768px) {
    .list-group-horizontal-md {
      flex-direction: row;
    }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.375rem;
      border-top-right-radius: 0;
    }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.375rem;
      border-bottom-left-radius: 0;
    }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0;
    }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0;
    }
    .list-group-horizontal-md > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px;
    }
  }
  @media (min-width: 992px) {
    .list-group-horizontal-lg {
      flex-direction: row;
    }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.375rem;
      border-top-right-radius: 0;
    }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.375rem;
      border-bottom-left-radius: 0;
    }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0;
    }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0;
    }
    .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px;
    }
  }
  @media (min-width: 1200px) {
    .list-group-horizontal-xl {
      flex-direction: row;
    }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.375rem;
      border-top-right-radius: 0;
    }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.375rem;
      border-bottom-left-radius: 0;
    }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0;
    }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0;
    }
    .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px;
    }
  }
  .list-group-flush {
    border-radius: 0;
  }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px;
  }
  .list-group-flush > .list-group-item:last-child {
    border-bottom-width: 0;
  }
  .list-group-item-primary {
    color: #0f5da2;
    background-color: #b8deff;
  }
  .list-group-item-primary.list-group-item-action:focus,
  .list-group-item-primary.list-group-item-action:hover {
    color: #0f5da2;
    background-color: #9fd2ff;
  }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #0f5da2;
    border-color: #0f5da2;
  }
  .list-group-item-secondary {
    color: #89909c;
    background-color: #f9fafc;
  }
  .list-group-item-secondary.list-group-item-action:focus,
  .list-group-item-secondary.list-group-item-action:hover {
    color: #89909c;
    background-color: #e8ecf4;
  }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #89909c;
    border-color: #89909c;
  }
  .list-group-item-success {
    color: #3f7e74;
    background-color: #d1f0e6;
  }
  .list-group-item-success.list-group-item-action:focus,
  .list-group-item-success.list-group-item-action:hover {
    color: #3f7e74;
    background-color: #beeadc;
  }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #3f7e74;
    border-color: #3f7e74;
  }
  .list-group-item-info {
    color: #3874a2;
    background-color: #ceeaff;
  }
  .list-group-item-info.list-group-item-action:focus,
  .list-group-item-info.list-group-item-action:hover {
    color: #3874a2;
    background-color: #b5dfff;
  }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #3874a2;
    border-color: #3874a2;
  }
  .list-group-item-warning {
    color: #93783d;
    background-color: #ffedc9;
  }
  .list-group-item-warning.list-group-item-action:focus,
  .list-group-item-warning.list-group-item-action:hover {
    color: #93783d;
    background-color: #ffe5b0;
  }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #93783d;
    border-color: #93783d;
  }
  .list-group-item-danger {
    color: #8d4353;
    background-color: #fbd0d4;
  }
  .list-group-item-danger.list-group-item-action:focus,
  .list-group-item-danger.list-group-item-action:hover {
    color: #8d4353;
    background-color: #f9b9bf;
  }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #8d4353;
    border-color: #8d4353;
  }
  .list-group-item-light {
    color: #89909c;
    background-color: #f9fafc;
  }
  .list-group-item-light.list-group-item-action:focus,
  .list-group-item-light.list-group-item-action:hover {
    color: #89909c;
    background-color: #e8ecf4;
  }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #89909c;
    border-color: #89909c;
  }
  .list-group-item-dark {
    color: #1b1f42;
    background-color: #bebdcb;
  }
  .list-group-item-dark.list-group-item-action:focus,
  .list-group-item-dark.list-group-item-action:hover {
    color: #1b1f42;
    background-color: #b0afc0;
  }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1f42;
    border-color: #1b1f42;
  }
  .list-group-item-neutral {
    color: #939aa2;
    background-color: #fff;
  }
  .list-group-item-neutral.list-group-item-action:focus,
  .list-group-item-neutral.list-group-item-action:hover {
    color: #939aa2;
    background-color: #f2f2f2;
  }
  .list-group-item-neutral.list-group-item-action.active {
    color: #fff;
    background-color: #939aa2;
    border-color: #939aa2;
  }
  .list-group-item-white {
    color: #939aa2;
    background-color: #fff;
  }
  .list-group-item-white.list-group-item-action:focus,
  .list-group-item-white.list-group-item-action:hover {
    color: #939aa2;
    background-color: #f2f2f2;
  }
  .list-group-item-white.list-group-item-action.active {
    color: #fff;
    background-color: #939aa2;
    border-color: #939aa2;
  }
  .close {
    float: right;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1;
    color: rgba(255, 255, 255, 0.6);
    text-shadow: none;
    opacity: 0.5;
  }
  .close:hover {
    color: rgba(255, 255, 255, 0.6);
    text-decoration: none;
  }
  .close:not(:disabled):not(.disabled):focus,
  .close:not(:disabled):not(.disabled):hover {
    opacity: 0.75;
  }
  button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
  }
  a.close.disabled {
    pointer-events: none;
  }
  .toast {
    flex-basis: 350px;
    max-width: 350px;
    font-size: 0.875rem;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.25rem 0.75rem rgba(31, 45, 61, 0.1);
    opacity: 0;
    border-radius: 0.25rem;
  }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem;
  }
  .toast.showing {
    opacity: 1;
  }
  .toast.show {
    display: block;
    opacity: 1;
  }
  .toast.hide {
    display: none;
  }
  .toast-header {
    display: flex;
    align-items: center;
    padding: 0.25rem 0.75rem;
    color: #718096;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
  }
  .toast-body {
    padding: 0.75rem;
  }
  .modal-open {
    overflow: hidden;
  }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
  }
  .modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
  }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
  }
  @media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
      transition: none;
    }
  }
  .modal.show .modal-dialog {
    transform: none;
  }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02);
  }
  .modal-dialog-scrollable {
    display: flex;
    max-height: calc(100% - 1rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden;
  }
  .modal-dialog-scrollable .modal-footer,
  .modal-dialog-scrollable .modal-header {
    flex-shrink: 0;
  }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto;
  }
  .modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
  }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: -webkit-min-content;
    height: min-content;
    content: "";
  }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  .modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-height: none;
  }
  .modal-dialog-centered.modal-dialog-scrollable::before {
    content: none;
  }
  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(31, 45, 61, 0.2);
    border-radius: 0.75rem;
    box-shadow: 0 0.25rem 0.5rem rgba(31, 45, 61, 0.3);
    outline: 0;
  }
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #1f2d3d;
  }
  .modal-backdrop.fade {
    opacity: 0;
  }
  .modal-backdrop.show {
    opacity: 0.4;
  }
  .modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1.25rem;
    border-bottom: 1px solid #eaecf3;
    border-top-left-radius: calc(0.75rem - 1px);
    border-top-right-radius: calc(0.75rem - 1px);
  }
  .modal-header .close {
    padding: 1.25rem;
    margin: -1rem -1rem -1rem auto;
  }
  .modal-title {
    margin-bottom: 0;
    line-height: 1.7;
  }
  .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1.5rem;
  }
  .modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 1.25rem;
    border-top: 1px solid #eaecf3;
    border-bottom-right-radius: calc(0.75rem - 1px);
    border-bottom-left-radius: calc(0.75rem - 1px);
  }
  .modal-footer > * {
    margin: 0.25rem;
  }
  .modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
  }
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 500px;
      margin: 1.75rem auto;
    }
    .modal-dialog-scrollable {
      max-height: calc(100% - 3.5rem);
    }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem);
    }
    .modal-dialog-centered {
      min-height: calc(100% - 3.5rem);
    }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: -webkit-min-content;
      height: min-content;
    }
    .modal-content {
      box-shadow: 0 0.5rem 1rem rgba(31, 45, 61, 0.3);
    }
    .modal-sm {
      max-width: 300px;
    }
  }
  @media (min-width: 992px) {
    .modal-lg,
    .modal-xl {
      max-width: 800px;
    }
  }
  @media (min-width: 1200px) {
    .modal-xl {
      max-width: 1140px;
    }
  }
  .tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.7;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    opacity: 0;
  }
  .tooltip.show {
    opacity: 0.9;
  }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
  }
  .tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
  }
  .bs-tooltip-auto[x-placement^="top"],
  .bs-tooltip-top {
    padding: 0.4rem 0;
  }
  .bs-tooltip-auto[x-placement^="top"] .arrow,
  .bs-tooltip-top .arrow {
    bottom: 0;
  }
  .bs-tooltip-auto[x-placement^="top"] .arrow::before,
  .bs-tooltip-top .arrow::before {
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #1f2d3d;
  }
  .bs-tooltip-auto[x-placement^="right"],
  .bs-tooltip-right {
    padding: 0 0.4rem;
  }
  .bs-tooltip-auto[x-placement^="right"] .arrow,
  .bs-tooltip-right .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem;
  }
  .bs-tooltip-auto[x-placement^="right"] .arrow::before,
  .bs-tooltip-right .arrow::before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #1f2d3d;
  }
  .bs-tooltip-auto[x-placement^="bottom"],
  .bs-tooltip-bottom {
    padding: 0.4rem 0;
  }
  .bs-tooltip-auto[x-placement^="bottom"] .arrow,
  .bs-tooltip-bottom .arrow {
    top: 0;
  }
  .bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
  .bs-tooltip-bottom .arrow::before {
    bottom: 0;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #1f2d3d;
  }
  .bs-tooltip-auto[x-placement^="left"],
  .bs-tooltip-left {
    padding: 0 0.4rem;
  }
  .bs-tooltip-auto[x-placement^="left"] .arrow,
  .bs-tooltip-left .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem;
  }
  .bs-tooltip-auto[x-placement^="left"] .arrow::before,
  .bs-tooltip-left .arrow::before {
    left: 0;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #1f2d3d;
  }
  .tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #1f2d3d;
    border-radius: 0.375rem;
  }
  .popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.7;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(31, 45, 61, 0.05);
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 2rem 0 rgba(31, 45, 61, 0.2);
  }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.5rem;
  }
  .popover .arrow::after,
  .popover .arrow::before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
  }
  .bs-popover-auto[x-placement^="top"],
  .bs-popover-top {
    margin-bottom: 0.5rem;
  }
  .bs-popover-auto[x-placement^="top"] > .arrow,
  .bs-popover-top > .arrow {
    bottom: calc(-0.5rem - 1px);
  }
  .bs-popover-auto[x-placement^="top"] > .arrow::before,
  .bs-popover-top > .arrow::before {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: transparent;
  }
  .bs-popover-auto[x-placement^="top"] > .arrow::after,
  .bs-popover-top > .arrow::after {
    bottom: 1px;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #fff;
  }
  .bs-popover-auto[x-placement^="right"],
  .bs-popover-right {
    margin-left: 0.5rem;
  }
  .bs-popover-auto[x-placement^="right"] > .arrow,
  .bs-popover-right > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.5rem 0;
  }
  .bs-popover-auto[x-placement^="right"] > .arrow::before,
  .bs-popover-right > .arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: transparent;
  }
  .bs-popover-auto[x-placement^="right"] > .arrow::after,
  .bs-popover-right > .arrow::after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #fff;
  }
  .bs-popover-auto[x-placement^="bottom"],
  .bs-popover-bottom {
    margin-top: 0.5rem;
  }
  .bs-popover-auto[x-placement^="bottom"] > .arrow,
  .bs-popover-bottom > .arrow {
    top: calc(-0.5rem - 1px);
  }
  .bs-popover-auto[x-placement^="bottom"] > .arrow::before,
  .bs-popover-bottom > .arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: transparent;
  }
  .bs-popover-auto[x-placement^="bottom"] > .arrow::after,
  .bs-popover-bottom > .arrow::after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #fff;
  }
  .bs-popover-auto[x-placement^="bottom"] .popover-header::before,
  .bs-popover-bottom .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #fff;
  }
  .bs-popover-auto[x-placement^="left"],
  .bs-popover-left {
    margin-right: 0.5rem;
  }
  .bs-popover-auto[x-placement^="left"] > .arrow,
  .bs-popover-left > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.5rem 0;
  }
  .bs-popover-auto[x-placement^="left"] > .arrow::before,
  .bs-popover-left > .arrow::before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: transparent;
  }
  .bs-popover-auto[x-placement^="left"] > .arrow::after,
  .bs-popover-left > .arrow::after {
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #fff;
  }
  .popover-header {
    padding: 0.75rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    color: #121212;
    background-color: #fff;
    border-bottom: 1px solid #f2f2f2;
    border-top-left-radius: calc(0.5rem - 1px);
    border-top-right-radius: calc(0.5rem - 1px);
  }
  .popover-header:empty {
    display: none;
  }
  .popover-body {
    padding: 0.75rem 0.75rem;
    color: #718096;
  }
  .carousel {
    position: relative;
  }
  .carousel.pointer-event {
    touch-action: pan-y;
  }
  .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: "";
  }
  .carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform 0.6s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none;
    }
  }
  .carousel-item-next,
  .carousel-item-prev,
  .carousel-item.active {
    display: block;
  }
  .active.carousel-item-right,
  .carousel-item-next:not(.carousel-item-left) {
    transform: translateX(100%);
  }
  .active.carousel-item-left,
  .carousel-item-prev:not(.carousel-item-right) {
    transform: translateX(-100%);
  }
  .carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none;
  }
  .carousel-fade .carousel-item-next.carousel-item-left,
  .carousel-fade .carousel-item-prev.carousel-item-right,
  .carousel-fade .carousel-item.active {
    z-index: 1;
    opacity: 1;
  }
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s 0.6s;
  }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none;
    }
  }
  .carousel-control-next,
  .carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 0.5;
    transition: opacity 0.15s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-next,
    .carousel-control-prev {
      transition: none;
    }
  }
  .carousel-control-next:focus,
  .carousel-control-next:hover,
  .carousel-control-prev:focus,
  .carousel-control-prev:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
  }
  .carousel-control-prev {
    left: 0;
  }
  .carousel-control-next {
    right: 0;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: no-repeat 50%/100% 100%;
  }
  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFF' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
  }
  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFF' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
  }
  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
  }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .carousel-indicators li {
      transition: none;
    }
  }
  .carousel-indicators .active {
    opacity: 1;
  }
  .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
  }
  @keyframes spinner-border {
    to {
      transform: rotate(360deg);
    }
  }
  .spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border 0.75s linear infinite;
  }
  .spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
  }
  @keyframes spinner-grow {
    0% {
      transform: scale(0);
    }
    50% {
      opacity: 1;
      transform: none;
    }
  }
  .spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    animation: spinner-grow 0.75s linear infinite;
  }
  .spinner-grow-sm {
    width: 1rem;
    height: 1rem;
  }
  .align-baseline {
    vertical-align: baseline !important;
  }
  .align-top {
    vertical-align: top !important;
  }
  .align-middle {
    vertical-align: middle !important;
  }
  .align-bottom {
    vertical-align: bottom !important;
  }
  .align-text-bottom {
    vertical-align: text-bottom !important;
  }
  .align-text-top {
    vertical-align: text-top !important;
  }
  .bg-primary {
    background-color: #008aff !important;
  }
  a.bg-primary:focus,
  a.bg-primary:hover,
  button.bg-primary:focus,
  button.bg-primary:hover {
    background-color: #b62d34 !important;
  }
  .bg-secondary {
    background-color: #eaecf3 !important;
  }
  a.bg-secondary:focus,
  a.bg-secondary:hover,
  button.bg-secondary:focus,
  button.bg-secondary:hover {
    background-color: #cacfe0 !important;
  }
  .bg-success {
    background-color: #5cc9a7 !important;
  }
  a.bg-success:focus,
  a.bg-success:hover,
  button.bg-success:focus,
  button.bg-success:hover {
    background-color: #3cb690 !important;
  }
  .bg-info {
    background-color: #50b5ff !important;
  }
  a.bg-info:focus,
  a.bg-info:hover,
  button.bg-info:focus,
  button.bg-info:hover {
    background-color: #1d9fff !important;
  }
  .bg-warning {
    background-color: #ffbe3d !important;
  }
  a.bg-warning:focus,
  a.bg-warning:hover,
  button.bg-warning:focus,
  button.bg-warning:hover {
    background-color: #ffad0a !important;
  }
  .bg-danger {
    background-color: #d33139 !important;
  }
  a.bg-danger:focus,
  a.bg-danger:hover,
  button.bg-danger:focus,
  button.bg-danger:hover {
    background-color: #b62d34 !important;
  }
  .bg-light {
    background-color: #eaecf3 !important;
  }
  a.bg-light:focus,
  a.bg-light:hover,
  button.bg-light:focus,
  button.bg-light:hover {
    background-color: #cacfe0 !important;
  }
  .bg-dark {
    background-color: #121212 !important;
  }
  a.bg-dark:focus,
  a.bg-dark:hover,
  button.bg-dark:focus,
  button.bg-dark:hover {
    background-color: #0a081f !important;
  }
  .bg-neutral {
    background-color: #fff !important;
  }
  a.bg-neutral:focus,
  a.bg-neutral:hover,
  button.bg-neutral:focus,
  button.bg-neutral:hover {
    background-color: #e6e6e6 !important;
  }
  .bg-white {
    background-color: #fff !important;
  }
  a.bg-white:focus,
  a.bg-white:hover,
  button.bg-white:focus,
  button.bg-white:hover {
    background-color: #e6e6e6 !important;
  }
  .bg-white {
    background-color: #fff !important;
  }
  .bg-transparent {
    background-color: transparent !important;
  }
  .border {
    border: 1px solid #e2e8f0 !important;
  }
  .border-top {
    border-top: 1px solid #e2e8f0 !important;
  }
  .border-right {
    border-right: 1px solid #e2e8f0 !important;
  }
  .border-bottom {
    border-bottom: 1px solid #e2e8f0 !important;
  }
  .border-left {
    border-left: 1px solid #e2e8f0 !important;
  }
  .border-0 {
    border: 0 !important;
  }
  .border-top-0 {
    border-top: 0 !important;
  }
  .border-right-0 {
    border-right: 0 !important;
  }
  .border-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-left-0 {
    border-left: 0 !important;
  }
  .border-primary {
    border-color: #008aff !important;
  }
  .border-secondary {
    border-color: #eaecf3 !important;
  }
  .border-success {
    border-color: #5cc9a7 !important;
  }
  .border-info {
    border-color: #50b5ff !important;
  }
  .border-warning {
    border-color: #ffbe3d !important;
  }
  .border-danger {
    border-color: #d33139 !important;
  }
  .border-light {
    border-color: #eaecf3 !important;
  }
  .border-dark {
    border-color: #171347 !important;
  }
  .border-neutral {
    border-color: #fff !important;
  }
  .border-white {
    border-color: #fff !important;
  }
  .border-white {
    border-color: #fff !important;
  }
  .rounded-sm {
    border-radius: 0.2rem !important;
  }
  .rounded {
    border-radius: 0.375rem !important;
  }
  .rounded-top {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }
  .rounded-right {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .rounded-bottom {
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }
  .rounded-left {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }
  .rounded-lg {
    border-radius: 0.5rem !important;
  }
  .rounded-circle {
    border-radius: 10px !important;
  }
  .rounded-pill {
    border-radius: 50rem !important;
  }
  .rounded-0 {
    border-radius: 0 !important;
  }
  .clearfix::after {
    display: block;
    clear: both;
    content: "";
  }
  .d-none {
    display: none !important;
  }
  .d-inline {
    display: inline !important;
  }
  .d-inline-block {
    display: inline-block !important;
  }
  .d-block {
    display: block !important;
  }
  .d-table {
    display: table !important;
  }
  .d-table-row {
    display: table-row !important;
  }
  .d-table-cell {
    display: table-cell !important;
  }
  .d-flex {
    display: flex !important;
  }
  .d-inline-flex {
    display: inline-flex !important;
  }
  @media (min-width: 576px) {
    .d-sm-none {
      display: none !important;
    }
    .d-sm-inline {
      display: inline !important;
    }
    .d-sm-inline-block {
      display: inline-block !important;
    }
    .d-sm-block {
      display: block !important;
    }
    .d-sm-table {
      display: table !important;
    }
    .d-sm-table-row {
      display: table-row !important;
    }
    .d-sm-table-cell {
      display: table-cell !important;
    }
    .d-sm-flex {
      display: flex !important;
    }
    .d-sm-inline-flex {
      display: inline-flex !important;
    }
  }
  @media (min-width: 768px) {
    .d-md-none {
      display: none !important;
    }
    .d-md-inline {
      display: inline !important;
    }
    .d-md-inline-block {
      display: inline-block !important;
    }
    .d-md-block {
      display: block !important;
    }
    .d-md-table {
      display: table !important;
    }
    .d-md-table-row {
      display: table-row !important;
    }
    .d-md-table-cell {
      display: table-cell !important;
    }
    .d-md-flex {
      display: flex !important;
    }
    .d-md-inline-flex {
      display: inline-flex !important;
    }
  }
  @media (min-width: 992px) {
    .d-lg-none {
      display: none !important;
    }
    .d-lg-inline {
      display: inline !important;
    }
    .d-lg-inline-block {
      display: inline-block !important;
    }
    .d-lg-block {
      display: block !important;
    }
    .d-lg-table {
      display: table !important;
    }
    .d-lg-table-row {
      display: table-row !important;
    }
    .d-lg-table-cell {
      display: table-cell !important;
    }
    .d-lg-flex {
      display: flex !important;
    }
    .d-lg-inline-flex {
      display: inline-flex !important;
    }
  }
  @media (min-width: 1200px) {
    .d-xl-none {
      display: none !important;
    }
    .d-xl-inline {
      display: inline !important;
    }
    .d-xl-inline-block {
      display: inline-block !important;
    }
    .d-xl-block {
      display: block !important;
    }
    .d-xl-table {
      display: table !important;
    }
    .d-xl-table-row {
      display: table-row !important;
    }
    .d-xl-table-cell {
      display: table-cell !important;
    }
    .d-xl-flex {
      display: flex !important;
    }
    .d-xl-inline-flex {
      display: inline-flex !important;
    }
  }
  @media print {
    .d-print-none {
      display: none !important;
    }
    .d-print-inline {
      display: inline !important;
    }
    .d-print-inline-block {
      display: inline-block !important;
    }
    .d-print-block {
      display: block !important;
    }
    .d-print-table {
      display: table !important;
    }
    .d-print-table-row {
      display: table-row !important;
    }
    .d-print-table-cell {
      display: table-cell !important;
    }
    .d-print-flex {
      display: flex !important;
    }
    .d-print-inline-flex {
      display: inline-flex !important;
    }
  }
  .embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
  }
  .embed-responsive::before {
    display: block;
    content: "";
  }
  .embed-responsive .embed-responsive-item,
  .embed-responsive embed,
  .embed-responsive iframe,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  .embed-responsive-21by9::before {
    padding-top: 42.85714%;
  }
  .embed-responsive-16by9::before {
    padding-top: 56.25%;
  }
  .embed-responsive-4by3::before {
    padding-top: 75%;
  }
  .embed-responsive-1by1::before {
    padding-top: 100%;
  }
  .flex-row {
    flex-direction: row !important;
  }
  .flex-column {
    flex-direction: column !important;
  }
  .flex-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-wrap {
    flex-wrap: wrap !important;
  }
  .flex-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-fill {
    flex: 1 1 auto !important;
  }
  .flex-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-start {
    justify-content: flex-start !important;
  }
  .justify-content-end {
    justify-content: flex-end !important;
  }
  .justify-content-center {
    justify-content: center !important;
  }
  .justify-content-between {
    justify-content: space-between !important;
  }
  .justify-content-around {
    justify-content: space-around !important;
  }
  .align-items-start {
    align-items: flex-start !important;
  }
  .align-items-end {
    align-items: flex-end !important;
  }
  .align-items-center {
    align-items: center !important;
  }
  .align-items-baseline {
    align-items: baseline !important;
  }
  .align-items-stretch {
    align-items: stretch !important;
  }
  .align-content-start {
    align-content: flex-start !important;
  }
  .align-content-end {
    align-content: flex-end !important;
  }
  .align-content-center {
    align-content: center !important;
  }
  .align-content-between {
    align-content: space-between !important;
  }
  .align-content-around {
    align-content: space-around !important;
  }
  .align-content-stretch {
    align-content: stretch !important;
  }
  .align-self-auto {
    align-self: auto !important;
  }
  .align-self-start {
    align-self: flex-start !important;
  }
  .align-self-end {
    align-self: flex-end !important;
  }
  .align-self-center {
    align-self: center !important;
  }
  .align-self-baseline {
    align-self: baseline !important;
  }
  .align-self-stretch {
    align-self: stretch !important;
  }
  @media (min-width: 576px) {
    .flex-sm-row {
      flex-direction: row !important;
    }
    .flex-sm-column {
      flex-direction: column !important;
    }
    .flex-sm-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-sm-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-sm-wrap {
      flex-wrap: wrap !important;
    }
    .flex-sm-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-sm-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .flex-sm-fill {
      flex: 1 1 auto !important;
    }
    .flex-sm-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-sm-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-sm-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-sm-shrink-1 {
      flex-shrink: 1 !important;
    }
    .justify-content-sm-start {
      justify-content: flex-start !important;
    }
    .justify-content-sm-end {
      justify-content: flex-end !important;
    }
    .justify-content-sm-center {
      justify-content: center !important;
    }
    .justify-content-sm-between {
      justify-content: space-between !important;
    }
    .justify-content-sm-around {
      justify-content: space-around !important;
    }
    .align-items-sm-start {
      align-items: flex-start !important;
    }
    .align-items-sm-end {
      align-items: flex-end !important;
    }
    .align-items-sm-center {
      align-items: center !important;
    }
    .align-items-sm-baseline {
      align-items: baseline !important;
    }
    .align-items-sm-stretch {
      align-items: stretch !important;
    }
    .align-content-sm-start {
      align-content: flex-start !important;
    }
    .align-content-sm-end {
      align-content: flex-end !important;
    }
    .align-content-sm-center {
      align-content: center !important;
    }
    .align-content-sm-between {
      align-content: space-between !important;
    }
    .align-content-sm-around {
      align-content: space-around !important;
    }
    .align-content-sm-stretch {
      align-content: stretch !important;
    }
    .align-self-sm-auto {
      align-self: auto !important;
    }
    .align-self-sm-start {
      align-self: flex-start !important;
    }
    .align-self-sm-end {
      align-self: flex-end !important;
    }
    .align-self-sm-center {
      align-self: center !important;
    }
    .align-self-sm-baseline {
      align-self: baseline !important;
    }
    .align-self-sm-stretch {
      align-self: stretch !important;
    }
  }
  @media (min-width: 768px) {
    .flex-md-row {
      flex-direction: row !important;
    }
    .flex-md-column {
      flex-direction: column !important;
    }
    .flex-md-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-md-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-md-wrap {
      flex-wrap: wrap !important;
    }
    .flex-md-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-md-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .flex-md-fill {
      flex: 1 1 auto !important;
    }
    .flex-md-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-md-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-md-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-md-shrink-1 {
      flex-shrink: 1 !important;
    }
    .justify-content-md-start {
      justify-content: flex-start !important;
    }
    .justify-content-md-end {
      justify-content: flex-end !important;
    }
    .justify-content-md-center {
      justify-content: center !important;
    }
    .justify-content-md-between {
      justify-content: space-between !important;
    }
    .justify-content-md-around {
      justify-content: space-around !important;
    }
    .align-items-md-start {
      align-items: flex-start !important;
    }
    .align-items-md-end {
      align-items: flex-end !important;
    }
    .align-items-md-center {
      align-items: center !important;
    }
    .align-items-md-baseline {
      align-items: baseline !important;
    }
    .align-items-md-stretch {
      align-items: stretch !important;
    }
    .align-content-md-start {
      align-content: flex-start !important;
    }
    .align-content-md-end {
      align-content: flex-end !important;
    }
    .align-content-md-center {
      align-content: center !important;
    }
    .align-content-md-between {
      align-content: space-between !important;
    }
    .align-content-md-around {
      align-content: space-around !important;
    }
    .align-content-md-stretch {
      align-content: stretch !important;
    }
    .align-self-md-auto {
      align-self: auto !important;
    }
    .align-self-md-start {
      align-self: flex-start !important;
    }
    .align-self-md-end {
      align-self: flex-end !important;
    }
    .align-self-md-center {
      align-self: center !important;
    }
    .align-self-md-baseline {
      align-self: baseline !important;
    }
    .align-self-md-stretch {
      align-self: stretch !important;
    }
  }
  @media (min-width: 992px) {
    .flex-lg-row {
      flex-direction: row !important;
    }
    .flex-lg-column {
      flex-direction: column !important;
    }
    .flex-lg-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-lg-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-lg-wrap {
      flex-wrap: wrap !important;
    }
    .flex-lg-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-lg-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .flex-lg-fill {
      flex: 1 1 auto !important;
    }
    .flex-lg-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-lg-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-lg-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-lg-shrink-1 {
      flex-shrink: 1 !important;
    }
    .justify-content-lg-start {
      justify-content: flex-start !important;
    }
    .justify-content-lg-end {
      justify-content: flex-end !important;
    }
    .justify-content-lg-center {
      justify-content: center !important;
    }
    .justify-content-lg-between {
      justify-content: space-between !important;
    }
    .justify-content-lg-around {
      justify-content: space-around !important;
    }
    .align-items-lg-start {
      align-items: flex-start !important;
    }
    .align-items-lg-end {
      align-items: flex-end !important;
    }
    .align-items-lg-center {
      align-items: center !important;
    }
    .align-items-lg-baseline {
      align-items: baseline !important;
    }
    .align-items-lg-stretch {
      align-items: stretch !important;
    }
    .align-content-lg-start {
      align-content: flex-start !important;
    }
    .align-content-lg-end {
      align-content: flex-end !important;
    }
    .align-content-lg-center {
      align-content: center !important;
    }
    .align-content-lg-between {
      align-content: space-between !important;
    }
    .align-content-lg-around {
      align-content: space-around !important;
    }
    .align-content-lg-stretch {
      align-content: stretch !important;
    }
    .align-self-lg-auto {
      align-self: auto !important;
    }
    .align-self-lg-start {
      align-self: flex-start !important;
    }
    .align-self-lg-end {
      align-self: flex-end !important;
    }
    .align-self-lg-center {
      align-self: center !important;
    }
    .align-self-lg-baseline {
      align-self: baseline !important;
    }
    .align-self-lg-stretch {
      align-self: stretch !important;
    }
  }
  @media (min-width: 1200px) {
    .flex-xl-row {
      flex-direction: row !important;
    }
    .flex-xl-column {
      flex-direction: column !important;
    }
    .flex-xl-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-xl-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-xl-wrap {
      flex-wrap: wrap !important;
    }
    .flex-xl-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-xl-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .flex-xl-fill {
      flex: 1 1 auto !important;
    }
    .flex-xl-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-xl-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-xl-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-xl-shrink-1 {
      flex-shrink: 1 !important;
    }
    .justify-content-xl-start {
      justify-content: flex-start !important;
    }
    .justify-content-xl-end {
      justify-content: flex-end !important;
    }
    .justify-content-xl-center {
      justify-content: center !important;
    }
    .justify-content-xl-between {
      justify-content: space-between !important;
    }
    .justify-content-xl-around {
      justify-content: space-around !important;
    }
    .align-items-xl-start {
      align-items: flex-start !important;
    }
    .align-items-xl-end {
      align-items: flex-end !important;
    }
    .align-items-xl-center {
      align-items: center !important;
    }
    .align-items-xl-baseline {
      align-items: baseline !important;
    }
    .align-items-xl-stretch {
      align-items: stretch !important;
    }
    .align-content-xl-start {
      align-content: flex-start !important;
    }
    .align-content-xl-end {
      align-content: flex-end !important;
    }
    .align-content-xl-center {
      align-content: center !important;
    }
    .align-content-xl-between {
      align-content: space-between !important;
    }
    .align-content-xl-around {
      align-content: space-around !important;
    }
    .align-content-xl-stretch {
      align-content: stretch !important;
    }
    .align-self-xl-auto {
      align-self: auto !important;
    }
    .align-self-xl-start {
      align-self: flex-start !important;
    }
    .align-self-xl-end {
      align-self: flex-end !important;
    }
    .align-self-xl-center {
      align-self: center !important;
    }
    .align-self-xl-baseline {
      align-self: baseline !important;
    }
    .align-self-xl-stretch {
      align-self: stretch !important;
    }
  }
  .float-left {
    float: left !important;
  }
  .float-right {
    float: right !important;
  }
  .float-none {
    float: none !important;
  }
  @media (min-width: 576px) {
    .float-sm-left {
      float: left !important;
    }
    .float-sm-right {
      float: right !important;
    }
    .float-sm-none {
      float: none !important;
    }
  }
  @media (min-width: 768px) {
    .float-md-left {
      float: left !important;
    }
    .float-md-right {
      float: right !important;
    }
    .float-md-none {
      float: none !important;
    }
  }
  @media (min-width: 992px) {
    .float-lg-left {
      float: left !important;
    }
    .float-lg-right {
      float: right !important;
    }
    .float-lg-none {
      float: none !important;
    }
  }
  @media (min-width: 1200px) {
    .float-xl-left {
      float: left !important;
    }
    .float-xl-right {
      float: right !important;
    }
    .float-xl-none {
      float: none !important;
    }
  }
  .user-select-all {
    -webkit-user-select: all !important;
    -moz-user-select: all !important;
    -ms-user-select: all !important;
    user-select: all !important;
  }
  .user-select-auto {
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    -ms-user-select: auto !important;
    user-select: auto !important;
  }
  .user-select-none {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }
  .overflow-auto {
    overflow: auto !important;
  }
  .overflow-hidden {
    overflow: hidden !important;
  }
  .position-static {
    position: static !important;
  }
  .position-relative {
    position: relative !important;
  }
  .position-absolute {
    position: absolute !important;
  }
  .position-fixed {
    position: fixed !important;
  }
  .position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
  .fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  .fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
  }
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .sticky-top {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1020;
    }
  }
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
  .sr-only-focusable:active,
  .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(31, 45, 61, 0.08) !important;
  }
  .shadow {
    box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.05) !important;
  }
  .shadow-lg {
    box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125) !important;
  }
  .shadow-none {
    box-shadow: none !important;
  }
  .w-25 {
    width: 25% !important;
  }
  .w-50 {
    width: 50% !important;
  }
  .w-75 {
    width: 75% !important;
  }
  .w-100 {
    width: 100% !important;
  }
  .w-auto {
    width: auto !important;
  }
  .w-10 {
    width: 10% !important;
  }
  .w-15 {
    width: 15% !important;
  }
  .w-80 {
    width: 80% !important;
  }
  .w-90 {
    width: 90% !important;
  }
  .w-110 {
    width: 110% !important;
  }
  .w-120 {
    width: 120% !important;
  }
  .w-130 {
    width: 130% !important;
  }
  .h-25 {
    height: 25% !important;
  }
  .h-50 {
    height: 50% !important;
  }
  .h-75 {
    height: 75% !important;
  }
  .h-100 {
    height: 100% !important;
  }
  .h-auto {
    height: auto !important;
  }
  .h-10 {
    height: 10% !important;
  }
  .h-15 {
    height: 15% !important;
  }
  .h-80 {
    height: 80% !important;
  }
  .h-90 {
    height: 90% !important;
  }
  .h-110 {
    height: 110% !important;
  }
  .h-120 {
    height: 120% !important;
  }
  .h-130 {
    height: 130% !important;
  }
  .mw-100 {
    max-width: 100% !important;
  }
  .mh-100 {
    max-height: 100% !important;
  }
  .min-vw-100 {
    min-width: 100vw !important;
  }
  .min-vh-100 {
    min-height: 100vh !important;
  }
  .vw-100 {
    width: 100vw !important;
  }
  .vh-100 {
    height: 100vh !important;
  }
  .m-0 {
    margin: 0 !important;
  }
  .mt-0,
  .my-0 {
    margin-top: 0 !important;
  }
  .mr-0,
  .mx-0 {
    margin-right: 0 !important;
  }
  .mb-0,
  .my-0 {
    margin-bottom: 0 !important;
  }
  .ml-0,
  .mx-0 {
    margin-left: 0 !important;
  }
  .m-1 {
    margin: 0.25rem !important;
  }
  .mt-1,
  .my-1 {
    margin-top: 0.25rem !important;
  }
  .mr-1,
  .mx-1 {
    margin-right: 0.25rem !important;
  }
  .mb-1,
  .my-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-1,
  .mx-1 {
    margin-left: 0.25rem !important;
  }
  .m-2 {
    margin: 0.5rem !important;
  }
  .mt-2,
  .my-2 {
    margin-top: 0.5rem !important;
  }
  .mr-2,
  .mx-2 {
    margin-right: 0.5rem !important;
  }
  .mb-2,
  .my-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-2,
  .mx-2 {
    margin-left: 0.5rem !important;
  }
  .m-3 {
    margin: 1rem !important;
  }
  .mt-3,
  .my-3 {
    margin-top: 1rem !important;
  }
  .mr-3,
  .mx-3 {
    margin-right: 1rem !important;
  }
  .mb-3,
  .my-3 {
    margin-bottom: 1rem !important;
  }
  .ml-3,
  .mx-3 {
    margin-left: 1rem !important;
  }
  .m-4 {
    margin: 1.5rem !important;
  }
  .mt-4,
  .my-4 {
    margin-top: 1.5rem !important;
  }
  .mr-4,
  .mx-4 {
    margin-right: 1.5rem !important;
  }
  .mb-4,
  .my-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-4,
  .mx-4 {
    margin-left: 1.5rem !important;
  }
  .m-5 {
    margin: 3rem !important;
  }
  .mt-5,
  .my-5 {
    margin-top: 3rem !important;
  }
  .mr-5,
  .mx-5 {
    margin-right: 3rem !important;
  }
  .mb-5,
  .my-5 {
    margin-bottom: 3rem !important;
  }
  .ml-5,
  .mx-5 {
    margin-left: 3rem !important;
  }
  .m-6 {
    margin: 4.5rem !important;
  }
  .mt-6,
  .my-6 {
    margin-top: 4.5rem !important;
  }
  .mr-6,
  .mx-6 {
    margin-right: 4.5rem !important;
  }
  .mb-6,
  .my-6 {
    margin-bottom: 4.5rem !important;
  }
  .ml-6,
  .mx-6 {
    margin-left: 4.5rem !important;
  }
  .m-7 {
    margin: 6rem !important;
  }
  .mt-7,
  .my-7 {
    margin-top: 6rem !important;
  }
  .mr-7,
  .mx-7 {
    margin-right: 6rem !important;
  }
  .mb-7,
  .my-7 {
    margin-bottom: 6rem !important;
  }
  .ml-7,
  .mx-7 {
    margin-left: 6rem !important;
  }
  .m-8 {
    margin: 7.5rem !important;
  }
  .mt-8,
  .my-8 {
    margin-top: 7.5rem !important;
  }
  .mr-8,
  .mx-8 {
    margin-right: 7.5rem !important;
  }
  .mb-8,
  .my-8 {
    margin-bottom: 7.5rem !important;
  }
  .ml-8,
  .mx-8 {
    margin-left: 7.5rem !important;
  }
  .m-9 {
    margin: 9rem !important;
  }
  .mt-9,
  .my-9 {
    margin-top: 9rem !important;
  }
  .mr-9,
  .mx-9 {
    margin-right: 9rem !important;
  }
  .mb-9,
  .my-9 {
    margin-bottom: 9rem !important;
  }
  .ml-9,
  .mx-9 {
    margin-left: 9rem !important;
  }
  .m-10 {
    margin: 10.5rem !important;
  }
  .mt-10,
  .my-10 {
    margin-top: 10.5rem !important;
  }
  .mr-10,
  .mx-10 {
    margin-right: 10.5rem !important;
  }
  .mb-10,
  .my-10 {
    margin-bottom: 10.5rem !important;
  }
  .ml-10,
  .mx-10 {
    margin-left: 10.5rem !important;
  }
  .m-17 {
    margin: 21rem !important;
  }
  .mt-17,
  .my-17 {
    margin-top: 21rem !important;
  }
  .mr-17,
  .mx-17 {
    margin-right: 21rem !important;
  }
  .mb-17,
  .my-17 {
    margin-bottom: 21rem !important;
  }
  .ml-17,
  .mx-17 {
    margin-left: 21rem !important;
  }
  .p-0 {
    padding: 0 !important;
  }
  .pt-0,
  .py-0 {
    padding-top: 0 !important;
  }
  .pr-0,
  .px-0 {
    padding-right: 0 !important;
  }
  .pb-0,
  .py-0 {
    padding-bottom: 0 !important;
  }
  .pl-0,
  .px-0 {
    padding-left: 0 !important;
  }
  .p-1 {
    padding: 0.25rem !important;
  }
  .pt-1,
  .py-1 {
    padding-top: 0.25rem !important;
  }
  .pr-1,
  .px-1 {
    padding-right: 0.25rem !important;
  }
  .pb-1,
  .py-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-1,
  .px-1 {
    padding-left: 0.25rem !important;
  }
  .p-2 {
    padding: 0.5rem !important;
  }
  .pt-2,
  .py-2 {
    padding-top: 0.5rem !important;
  }
  .pr-2,
  .px-2 {
    padding-right: 0.5rem !important;
  }
  .pb-2,
  .py-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-2,
  .px-2 {
    padding-left: 0.5rem !important;
  }
  .p-3 {
    padding: 1rem !important;
  }
  .pt-3,
  .py-3 {
    padding-top: 1rem !important;
  }
  .pr-3,
  .px-3 {
    padding-right: 1rem !important;
  }
  .pb-3,
  .py-3 {
    padding-bottom: 1rem !important;
  }
  .pl-3,
  .px-3 {
    padding-left: 1rem !important;
  }
  .p-4 {
    padding: 1.5rem !important;
  }
  .pt-4,
  .py-4 {
    padding-top: 1.5rem !important;
  }
  .pr-4,
  .px-4 {
    padding-right: 1.5rem !important;
  }
  .pb-4,
  .py-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-4,
  .px-4 {
    padding-left: 1.5rem !important;
  }
  .p-5 {
    padding: 3rem !important;
  }
  .pt-5,
  .py-5 {
    padding-top: 3rem !important;
  }
  .pr-5,
  .px-5 {
    padding-right: 3rem !important;
  }
  .pb-5,
  .py-5 {
    padding-bottom: 3rem !important;
  }
  .pl-5,
  .px-5 {
    padding-left: 3rem !important;
  }
  .p-6 {
    padding: 4.5rem !important;
  }
  .pt-6,
  .py-6 {
    padding-top: 4.5rem !important;
  }
  .pr-6,
  .px-6 {
    padding-right: 4.5rem !important;
  }
  .pb-6,
  .py-6 {
    padding-bottom: 4.5rem !important;
  }
  .pl-6,
  .px-6 {
    padding-left: 4.5rem !important;
  }
  .p-7 {
    padding: 6rem !important;
  }
  .pt-7,
  .py-7 {
    padding-top: 6rem !important;
  }
  .pr-7,
  .px-7 {
    padding-right: 6rem !important;
  }
  .pb-7,
  .py-7 {
    padding-bottom: 6rem !important;
  }
  .pl-7,
  .px-7 {
    padding-left: 6rem !important;
  }
  .p-8 {
    padding: 7.5rem !important;
  }
  .pt-8,
  .py-8 {
    padding-top: 7.5rem !important;
  }
  .pr-8,
  .px-8 {
    padding-right: 7.5rem !important;
  }
  .pb-8,
  .py-8 {
    padding-bottom: 7.5rem !important;
  }
  .pl-8,
  .px-8 {
    padding-left: 7.5rem !important;
  }
  .p-9 {
    padding: 9rem !important;
  }
  .pt-9,
  .py-9 {
    padding-top: 9rem !important;
  }
  .pr-9,
  .px-9 {
    padding-right: 9rem !important;
  }
  .pb-9,
  .py-9 {
    padding-bottom: 9rem !important;
  }
  .pl-9,
  .px-9 {
    padding-left: 9rem !important;
  }
  .p-10 {
    padding: 10.5rem !important;
  }
  .pt-10,
  .py-10 {
    padding-top: 10.5rem !important;
  }
  .pr-10,
  .px-10 {
    padding-right: 10.5rem !important;
  }
  .pb-10,
  .py-10 {
    padding-bottom: 10.5rem !important;
  }
  .pl-10,
  .px-10 {
    padding-left: 10.5rem !important;
  }
  .p-17 {
    padding: 21rem !important;
  }
  .pt-17,
  .py-17 {
    padding-top: 21rem !important;
  }
  .pr-17,
  .px-17 {
    padding-right: 21rem !important;
  }
  .pb-17,
  .py-17 {
    padding-bottom: 21rem !important;
  }
  .pl-17,
  .px-17 {
    padding-left: 21rem !important;
  }
  .m-n1 {
    margin: -0.25rem !important;
  }
  .mt-n1,
  .my-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-n1,
  .mx-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-n1,
  .my-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-n1,
  .mx-n1 {
    margin-left: -0.25rem !important;
  }
  .m-n2 {
    margin: -0.5rem !important;
  }
  .mt-n2,
  .my-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-n2,
  .mx-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-n2,
  .my-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-n2,
  .mx-n2 {
    margin-left: -0.5rem !important;
  }
  .m-n3 {
    margin: -1rem !important;
  }
  .mt-n3,
  .my-n3 {
    margin-top: -1rem !important;
  }
  .mr-n3,
  .mx-n3 {
    margin-right: -1rem !important;
  }
  .mb-n3,
  .my-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-n3,
  .mx-n3 {
    margin-left: -1rem !important;
  }
  .m-n4 {
    margin: -1.5rem !important;
  }
  .mt-n4,
  .my-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-n4,
  .mx-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-n4,
  .my-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-n4,
  .mx-n4 {
    margin-left: -1.5rem !important;
  }
  .m-n5 {
    margin: -3rem !important;
  }
  .mt-n5,
  .my-n5 {
    margin-top: -3rem !important;
  }
  .mr-n5,
  .mx-n5 {
    margin-right: -3rem !important;
  }
  .mb-n5,
  .my-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-n5,
  .mx-n5 {
    margin-left: -3rem !important;
  }
  .m-n6 {
    margin: -4.5rem !important;
  }
  .mt-n6,
  .my-n6 {
    margin-top: -4.5rem !important;
  }
  .mr-n6,
  .mx-n6 {
    margin-right: -4.5rem !important;
  }
  .mb-n6,
  .my-n6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-n6,
  .mx-n6 {
    margin-left: -4.5rem !important;
  }
  .m-n7 {
    margin: -6rem !important;
  }
  .mt-n7,
  .my-n7 {
    margin-top: -6rem !important;
  }
  .mr-n7,
  .mx-n7 {
    margin-right: -6rem !important;
  }
  .mb-n7,
  .my-n7 {
    margin-bottom: -6rem !important;
  }
  .ml-n7,
  .mx-n7 {
    margin-left: -6rem !important;
  }
  .m-n8 {
    margin: -7.5rem !important;
  }
  .mt-n8,
  .my-n8 {
    margin-top: -7.5rem !important;
  }
  .mr-n8,
  .mx-n8 {
    margin-right: -7.5rem !important;
  }
  .mb-n8,
  .my-n8 {
    margin-bottom: -7.5rem !important;
  }
  .ml-n8,
  .mx-n8 {
    margin-left: -7.5rem !important;
  }
  .m-n9 {
    margin: -9rem !important;
  }
  .mt-n9,
  .my-n9 {
    margin-top: -9rem !important;
  }
  .mr-n9,
  .mx-n9 {
    margin-right: -9rem !important;
  }
  .mb-n9,
  .my-n9 {
    margin-bottom: -9rem !important;
  }
  .ml-n9,
  .mx-n9 {
    margin-left: -9rem !important;
  }
  .m-n10 {
    margin: -10.5rem !important;
  }
  .mt-n10,
  .my-n10 {
    margin-top: -10.5rem !important;
  }
  .mr-n10,
  .mx-n10 {
    margin-right: -10.5rem !important;
  }
  .mb-n10,
  .my-n10 {
    margin-bottom: -10.5rem !important;
  }
  .ml-n10,
  .mx-n10 {
    margin-left: -10.5rem !important;
  }
  .m-n17 {
    margin: -21rem !important;
  }
  .mt-n17,
  .my-n17 {
    margin-top: -21rem !important;
  }
  .mr-n17,
  .mx-n17 {
    margin-right: -21rem !important;
  }
  .mb-n17,
  .my-n17 {
    margin-bottom: -21rem !important;
  }
  .ml-n17,
  .mx-n17 {
    margin-left: -21rem !important;
  }
  .m-auto {
    margin: auto !important;
  }
  .mt-auto,
  .my-auto {
    margin-top: auto !important;
  }
  .mr-auto,
  .mx-auto {
    margin-right: auto !important;
  }
  .mb-auto,
  .my-auto {
    margin-bottom: auto !important;
  }
  .ml-auto,
  .mx-auto {
    margin-left: auto !important;
  }
  @media (min-width: 576px) {
    .m-sm-0 {
      margin: 0 !important;
    }
    .mt-sm-0,
    .my-sm-0 {
      margin-top: 0 !important;
    }
    .mr-sm-0,
    .mx-sm-0 {
      margin-right: 0 !important;
    }
    .mb-sm-0,
    .my-sm-0 {
      margin-bottom: 0 !important;
    }
    .ml-sm-0,
    .mx-sm-0 {
      margin-left: 0 !important;
    }
    .m-sm-1 {
      margin: 0.25rem !important;
    }
    .mt-sm-1,
    .my-sm-1 {
      margin-top: 0.25rem !important;
    }
    .mr-sm-1,
    .mx-sm-1 {
      margin-right: 0.25rem !important;
    }
    .mb-sm-1,
    .my-sm-1 {
      margin-bottom: 0.25rem !important;
    }
    .ml-sm-1,
    .mx-sm-1 {
      margin-left: 0.25rem !important;
    }
    .m-sm-2 {
      margin: 0.5rem !important;
    }
    .mt-sm-2,
    .my-sm-2 {
      margin-top: 0.5rem !important;
    }
    .mr-sm-2,
    .mx-sm-2 {
      margin-right: 0.5rem !important;
    }
    .mb-sm-2,
    .my-sm-2 {
      margin-bottom: 0.5rem !important;
    }
    .ml-sm-2,
    .mx-sm-2 {
      margin-left: 0.5rem !important;
    }
    .m-sm-3 {
      margin: 1rem !important;
    }
    .mt-sm-3,
    .my-sm-3 {
      margin-top: 1rem !important;
    }
    .mr-sm-3,
    .mx-sm-3 {
      margin-right: 1rem !important;
    }
    .mb-sm-3,
    .my-sm-3 {
      margin-bottom: 1rem !important;
    }
    .ml-sm-3,
    .mx-sm-3 {
      margin-left: 1rem !important;
    }
    .m-sm-4 {
      margin: 1.5rem !important;
    }
    .mt-sm-4,
    .my-sm-4 {
      margin-top: 1.5rem !important;
    }
    .mr-sm-4,
    .mx-sm-4 {
      margin-right: 1.5rem !important;
    }
    .mb-sm-4,
    .my-sm-4 {
      margin-bottom: 1.5rem !important;
    }
    .ml-sm-4,
    .mx-sm-4 {
      margin-left: 1.5rem !important;
    }
    .m-sm-5 {
      margin: 3rem !important;
    }
    .mt-sm-5,
    .my-sm-5 {
      margin-top: 3rem !important;
    }
    .mr-sm-5,
    .mx-sm-5 {
      margin-right: 3rem !important;
    }
    .mb-sm-5,
    .my-sm-5 {
      margin-bottom: 3rem !important;
    }
    .ml-sm-5,
    .mx-sm-5 {
      margin-left: 3rem !important;
    }
    .m-sm-6 {
      margin: 4.5rem !important;
    }
    .mt-sm-6,
    .my-sm-6 {
      margin-top: 4.5rem !important;
    }
    .mr-sm-6,
    .mx-sm-6 {
      margin-right: 4.5rem !important;
    }
    .mb-sm-6,
    .my-sm-6 {
      margin-bottom: 4.5rem !important;
    }
    .ml-sm-6,
    .mx-sm-6 {
      margin-left: 4.5rem !important;
    }
    .m-sm-7 {
      margin: 6rem !important;
    }
    .mt-sm-7,
    .my-sm-7 {
      margin-top: 6rem !important;
    }
    .mr-sm-7,
    .mx-sm-7 {
      margin-right: 6rem !important;
    }
    .mb-sm-7,
    .my-sm-7 {
      margin-bottom: 6rem !important;
    }
    .ml-sm-7,
    .mx-sm-7 {
      margin-left: 6rem !important;
    }
    .m-sm-8 {
      margin: 7.5rem !important;
    }
    .mt-sm-8,
    .my-sm-8 {
      margin-top: 7.5rem !important;
    }
    .mr-sm-8,
    .mx-sm-8 {
      margin-right: 7.5rem !important;
    }
    .mb-sm-8,
    .my-sm-8 {
      margin-bottom: 7.5rem !important;
    }
    .ml-sm-8,
    .mx-sm-8 {
      margin-left: 7.5rem !important;
    }
    .m-sm-9 {
      margin: 9rem !important;
    }
    .mt-sm-9,
    .my-sm-9 {
      margin-top: 9rem !important;
    }
    .mr-sm-9,
    .mx-sm-9 {
      margin-right: 9rem !important;
    }
    .mb-sm-9,
    .my-sm-9 {
      margin-bottom: 9rem !important;
    }
    .ml-sm-9,
    .mx-sm-9 {
      margin-left: 9rem !important;
    }
    .m-sm-10 {
      margin: 10.5rem !important;
    }
    .mt-sm-10,
    .my-sm-10 {
      margin-top: 10.5rem !important;
    }
    .mr-sm-10,
    .mx-sm-10 {
      margin-right: 10.5rem !important;
    }
    .mb-sm-10,
    .my-sm-10 {
      margin-bottom: 10.5rem !important;
    }
    .ml-sm-10,
    .mx-sm-10 {
      margin-left: 10.5rem !important;
    }
    .m-sm-17 {
      margin: 21rem !important;
    }
    .mt-sm-17,
    .my-sm-17 {
      margin-top: 21rem !important;
    }
    .mr-sm-17,
    .mx-sm-17 {
      margin-right: 21rem !important;
    }
    .mb-sm-17,
    .my-sm-17 {
      margin-bottom: 21rem !important;
    }
    .ml-sm-17,
    .mx-sm-17 {
      margin-left: 21rem !important;
    }
    .p-sm-0 {
      padding: 0 !important;
    }
    .pt-sm-0,
    .py-sm-0 {
      padding-top: 0 !important;
    }
    .pr-sm-0,
    .px-sm-0 {
      padding-right: 0 !important;
    }
    .pb-sm-0,
    .py-sm-0 {
      padding-bottom: 0 !important;
    }
    .pl-sm-0,
    .px-sm-0 {
      padding-left: 0 !important;
    }
    .p-sm-1 {
      padding: 0.25rem !important;
    }
    .pt-sm-1,
    .py-sm-1 {
      padding-top: 0.25rem !important;
    }
    .pr-sm-1,
    .px-sm-1 {
      padding-right: 0.25rem !important;
    }
    .pb-sm-1,
    .py-sm-1 {
      padding-bottom: 0.25rem !important;
    }
    .pl-sm-1,
    .px-sm-1 {
      padding-left: 0.25rem !important;
    }
    .p-sm-2 {
      padding: 0.5rem !important;
    }
    .pt-sm-2,
    .py-sm-2 {
      padding-top: 0.5rem !important;
    }
    .pr-sm-2,
    .px-sm-2 {
      padding-right: 0.5rem !important;
    }
    .pb-sm-2,
    .py-sm-2 {
      padding-bottom: 0.5rem !important;
    }
    .pl-sm-2,
    .px-sm-2 {
      padding-left: 0.5rem !important;
    }
    .p-sm-3 {
      padding: 1rem !important;
    }
    .pt-sm-3,
    .py-sm-3 {
      padding-top: 1rem !important;
    }
    .pr-sm-3,
    .px-sm-3 {
      padding-right: 1rem !important;
    }
    .pb-sm-3,
    .py-sm-3 {
      padding-bottom: 1rem !important;
    }
    .pl-sm-3,
    .px-sm-3 {
      padding-left: 1rem !important;
    }
    .p-sm-4 {
      padding: 1.5rem !important;
    }
    .pt-sm-4,
    .py-sm-4 {
      padding-top: 1.5rem !important;
    }
    .pr-sm-4,
    .px-sm-4 {
      padding-right: 1.5rem !important;
    }
    .pb-sm-4,
    .py-sm-4 {
      padding-bottom: 1.5rem !important;
    }
    .pl-sm-4,
    .px-sm-4 {
      padding-left: 1.5rem !important;
    }
    .p-sm-5 {
      padding: 3rem !important;
    }
    .pt-sm-5,
    .py-sm-5 {
      padding-top: 3rem !important;
    }
    .pr-sm-5,
    .px-sm-5 {
      padding-right: 3rem !important;
    }
    .pb-sm-5,
    .py-sm-5 {
      padding-bottom: 3rem !important;
    }
    .pl-sm-5,
    .px-sm-5 {
      padding-left: 3rem !important;
    }
    .p-sm-6 {
      padding: 4.5rem !important;
    }
    .pt-sm-6,
    .py-sm-6 {
      padding-top: 4.5rem !important;
    }
    .pr-sm-6,
    .px-sm-6 {
      padding-right: 4.5rem !important;
    }
    .pb-sm-6,
    .py-sm-6 {
      padding-bottom: 4.5rem !important;
    }
    .pl-sm-6,
    .px-sm-6 {
      padding-left: 4.5rem !important;
    }
    .p-sm-7 {
      padding: 6rem !important;
    }
    .pt-sm-7,
    .py-sm-7 {
      padding-top: 6rem !important;
    }
    .pr-sm-7,
    .px-sm-7 {
      padding-right: 6rem !important;
    }
    .pb-sm-7,
    .py-sm-7 {
      padding-bottom: 6rem !important;
    }
    .pl-sm-7,
    .px-sm-7 {
      padding-left: 6rem !important;
    }
    .p-sm-8 {
      padding: 7.5rem !important;
    }
    .pt-sm-8,
    .py-sm-8 {
      padding-top: 7.5rem !important;
    }
    .pr-sm-8,
    .px-sm-8 {
      padding-right: 7.5rem !important;
    }
    .pb-sm-8,
    .py-sm-8 {
      padding-bottom: 7.5rem !important;
    }
    .pl-sm-8,
    .px-sm-8 {
      padding-left: 7.5rem !important;
    }
    .p-sm-9 {
      padding: 9rem !important;
    }
    .pt-sm-9,
    .py-sm-9 {
      padding-top: 9rem !important;
    }
    .pr-sm-9,
    .px-sm-9 {
      padding-right: 9rem !important;
    }
    .pb-sm-9,
    .py-sm-9 {
      padding-bottom: 9rem !important;
    }
    .pl-sm-9,
    .px-sm-9 {
      padding-left: 9rem !important;
    }
    .p-sm-10 {
      padding: 10.5rem !important;
    }
    .pt-sm-10,
    .py-sm-10 {
      padding-top: 10.5rem !important;
    }
    .pr-sm-10,
    .px-sm-10 {
      padding-right: 10.5rem !important;
    }
    .pb-sm-10,
    .py-sm-10 {
      padding-bottom: 10.5rem !important;
    }
    .pl-sm-10,
    .px-sm-10 {
      padding-left: 10.5rem !important;
    }
    .p-sm-17 {
      padding: 21rem !important;
    }
    .pt-sm-17,
    .py-sm-17 {
      padding-top: 21rem !important;
    }
    .pr-sm-17,
    .px-sm-17 {
      padding-right: 21rem !important;
    }
    .pb-sm-17,
    .py-sm-17 {
      padding-bottom: 21rem !important;
    }
    .pl-sm-17,
    .px-sm-17 {
      padding-left: 21rem !important;
    }
    .m-sm-n1 {
      margin: -0.25rem !important;
    }
    .mt-sm-n1,
    .my-sm-n1 {
      margin-top: -0.25rem !important;
    }
    .mr-sm-n1,
    .mx-sm-n1 {
      margin-right: -0.25rem !important;
    }
    .mb-sm-n1,
    .my-sm-n1 {
      margin-bottom: -0.25rem !important;
    }
    .ml-sm-n1,
    .mx-sm-n1 {
      margin-left: -0.25rem !important;
    }
    .m-sm-n2 {
      margin: -0.5rem !important;
    }
    .mt-sm-n2,
    .my-sm-n2 {
      margin-top: -0.5rem !important;
    }
    .mr-sm-n2,
    .mx-sm-n2 {
      margin-right: -0.5rem !important;
    }
    .mb-sm-n2,
    .my-sm-n2 {
      margin-bottom: -0.5rem !important;
    }
    .ml-sm-n2,
    .mx-sm-n2 {
      margin-left: -0.5rem !important;
    }
    .m-sm-n3 {
      margin: -1rem !important;
    }
    .mt-sm-n3,
    .my-sm-n3 {
      margin-top: -1rem !important;
    }
    .mr-sm-n3,
    .mx-sm-n3 {
      margin-right: -1rem !important;
    }
    .mb-sm-n3,
    .my-sm-n3 {
      margin-bottom: -1rem !important;
    }
    .ml-sm-n3,
    .mx-sm-n3 {
      margin-left: -1rem !important;
    }
    .m-sm-n4 {
      margin: -1.5rem !important;
    }
    .mt-sm-n4,
    .my-sm-n4 {
      margin-top: -1.5rem !important;
    }
    .mr-sm-n4,
    .mx-sm-n4 {
      margin-right: -1.5rem !important;
    }
    .mb-sm-n4,
    .my-sm-n4 {
      margin-bottom: -1.5rem !important;
    }
    .ml-sm-n4,
    .mx-sm-n4 {
      margin-left: -1.5rem !important;
    }
    .m-sm-n5 {
      margin: -3rem !important;
    }
    .mt-sm-n5,
    .my-sm-n5 {
      margin-top: -3rem !important;
    }
    .mr-sm-n5,
    .mx-sm-n5 {
      margin-right: -3rem !important;
    }
    .mb-sm-n5,
    .my-sm-n5 {
      margin-bottom: -3rem !important;
    }
    .ml-sm-n5,
    .mx-sm-n5 {
      margin-left: -3rem !important;
    }
    .m-sm-n6 {
      margin: -4.5rem !important;
    }
    .mt-sm-n6,
    .my-sm-n6 {
      margin-top: -4.5rem !important;
    }
    .mr-sm-n6,
    .mx-sm-n6 {
      margin-right: -4.5rem !important;
    }
    .mb-sm-n6,
    .my-sm-n6 {
      margin-bottom: -4.5rem !important;
    }
    .ml-sm-n6,
    .mx-sm-n6 {
      margin-left: -4.5rem !important;
    }
    .m-sm-n7 {
      margin: -6rem !important;
    }
    .mt-sm-n7,
    .my-sm-n7 {
      margin-top: -6rem !important;
    }
    .mr-sm-n7,
    .mx-sm-n7 {
      margin-right: -6rem !important;
    }
    .mb-sm-n7,
    .my-sm-n7 {
      margin-bottom: -6rem !important;
    }
    .ml-sm-n7,
    .mx-sm-n7 {
      margin-left: -6rem !important;
    }
    .m-sm-n8 {
      margin: -7.5rem !important;
    }
    .mt-sm-n8,
    .my-sm-n8 {
      margin-top: -7.5rem !important;
    }
    .mr-sm-n8,
    .mx-sm-n8 {
      margin-right: -7.5rem !important;
    }
    .mb-sm-n8,
    .my-sm-n8 {
      margin-bottom: -7.5rem !important;
    }
    .ml-sm-n8,
    .mx-sm-n8 {
      margin-left: -7.5rem !important;
    }
    .m-sm-n9 {
      margin: -9rem !important;
    }
    .mt-sm-n9,
    .my-sm-n9 {
      margin-top: -9rem !important;
    }
    .mr-sm-n9,
    .mx-sm-n9 {
      margin-right: -9rem !important;
    }
    .mb-sm-n9,
    .my-sm-n9 {
      margin-bottom: -9rem !important;
    }
    .ml-sm-n9,
    .mx-sm-n9 {
      margin-left: -9rem !important;
    }
    .m-sm-n10 {
      margin: -10.5rem !important;
    }
    .mt-sm-n10,
    .my-sm-n10 {
      margin-top: -10.5rem !important;
    }
    .mr-sm-n10,
    .mx-sm-n10 {
      margin-right: -10.5rem !important;
    }
    .mb-sm-n10,
    .my-sm-n10 {
      margin-bottom: -10.5rem !important;
    }
    .ml-sm-n10,
    .mx-sm-n10 {
      margin-left: -10.5rem !important;
    }
    .m-sm-n17 {
      margin: -21rem !important;
    }
    .mt-sm-n17,
    .my-sm-n17 {
      margin-top: -21rem !important;
    }
    .mr-sm-n17,
    .mx-sm-n17 {
      margin-right: -21rem !important;
    }
    .mb-sm-n17,
    .my-sm-n17 {
      margin-bottom: -21rem !important;
    }
    .ml-sm-n17,
    .mx-sm-n17 {
      margin-left: -21rem !important;
    }
    .m-sm-auto {
      margin: auto !important;
    }
    .mt-sm-auto,
    .my-sm-auto {
      margin-top: auto !important;
    }
    .mr-sm-auto,
    .mx-sm-auto {
      margin-right: auto !important;
    }
    .mb-sm-auto,
    .my-sm-auto {
      margin-bottom: auto !important;
    }
    .ml-sm-auto,
    .mx-sm-auto {
      margin-left: auto !important;
    }
  }
  @media (min-width: 768px) {
    .m-md-0 {
      margin: 0 !important;
    }
    .mt-md-0,
    .my-md-0 {
      margin-top: 0 !important;
    }
    .mr-md-0,
    .mx-md-0 {
      margin-right: 0 !important;
    }
    .mb-md-0,
    .my-md-0 {
      margin-bottom: 0 !important;
    }
    .ml-md-0,
    .mx-md-0 {
      margin-left: 0 !important;
    }
    .m-md-1 {
      margin: 0.25rem !important;
    }
    .mt-md-1,
    .my-md-1 {
      margin-top: 0.25rem !important;
    }
    .mr-md-1,
    .mx-md-1 {
      margin-right: 0.25rem !important;
    }
    .mb-md-1,
    .my-md-1 {
      margin-bottom: 0.25rem !important;
    }
    .ml-md-1,
    .mx-md-1 {
      margin-left: 0.25rem !important;
    }
    .m-md-2 {
      margin: 0.5rem !important;
    }
    .mt-md-2,
    .my-md-2 {
      margin-top: 0.5rem !important;
    }
    .mr-md-2,
    .mx-md-2 {
      margin-right: 0.5rem !important;
    }
    .mb-md-2,
    .my-md-2 {
      margin-bottom: 0.5rem !important;
    }
    .ml-md-2,
    .mx-md-2 {
      margin-left: 0.5rem !important;
    }
    .m-md-3 {
      margin: 1rem !important;
    }
    .mt-md-3,
    .my-md-3 {
      margin-top: 1rem !important;
    }
    .mr-md-3,
    .mx-md-3 {
      margin-right: 1rem !important;
    }
    .mb-md-3,
    .my-md-3 {
      margin-bottom: 1rem !important;
    }
    .ml-md-3,
    .mx-md-3 {
      margin-left: 1rem !important;
    }
    .m-md-4 {
      margin: 1.5rem !important;
    }
    .mt-md-4,
    .my-md-4 {
      margin-top: 1.5rem !important;
    }
    .mr-md-4,
    .mx-md-4 {
      margin-right: 1.5rem !important;
    }
    .mb-md-4,
    .my-md-4 {
      margin-bottom: 1.5rem !important;
    }
    .ml-md-4,
    .mx-md-4 {
      margin-left: 1.5rem !important;
    }
    .m-md-5 {
      margin: 3rem !important;
    }
    .mt-md-5,
    .my-md-5 {
      margin-top: 3rem !important;
    }
    .mr-md-5,
    .mx-md-5 {
      margin-right: 3rem !important;
    }
    .mb-md-5,
    .my-md-5 {
      margin-bottom: 3rem !important;
    }
    .ml-md-5,
    .mx-md-5 {
      margin-left: 3rem !important;
    }
    .m-md-6 {
      margin: 4.5rem !important;
    }
    .mt-md-6,
    .my-md-6 {
      margin-top: 4.5rem !important;
    }
    .mr-md-6,
    .mx-md-6 {
      margin-right: 4.5rem !important;
    }
    .mb-md-6,
    .my-md-6 {
      margin-bottom: 4.5rem !important;
    }
    .ml-md-6,
    .mx-md-6 {
      margin-left: 4.5rem !important;
    }
    .m-md-7 {
      margin: 6rem !important;
    }
    .mt-md-7,
    .my-md-7 {
      margin-top: 6rem !important;
    }
    .mr-md-7,
    .mx-md-7 {
      margin-right: 6rem !important;
    }
    .mb-md-7,
    .my-md-7 {
      margin-bottom: 6rem !important;
    }
    .ml-md-7,
    .mx-md-7 {
      margin-left: 6rem !important;
    }
    .m-md-8 {
      margin: 7.5rem !important;
    }
    .mt-md-8,
    .my-md-8 {
      margin-top: 7.5rem !important;
    }
    .mr-md-8,
    .mx-md-8 {
      margin-right: 7.5rem !important;
    }
    .mb-md-8,
    .my-md-8 {
      margin-bottom: 7.5rem !important;
    }
    .ml-md-8,
    .mx-md-8 {
      margin-left: 7.5rem !important;
    }
    .m-md-9 {
      margin: 9rem !important;
    }
    .mt-md-9,
    .my-md-9 {
      margin-top: 9rem !important;
    }
    .mr-md-9,
    .mx-md-9 {
      margin-right: 9rem !important;
    }
    .mb-md-9,
    .my-md-9 {
      margin-bottom: 9rem !important;
    }
    .ml-md-9,
    .mx-md-9 {
      margin-left: 9rem !important;
    }
    .m-md-10 {
      margin: 10.5rem !important;
    }
    .mt-md-10,
    .my-md-10 {
      margin-top: 10.5rem !important;
    }
    .mr-md-10,
    .mx-md-10 {
      margin-right: 10.5rem !important;
    }
    .mb-md-10,
    .my-md-10 {
      margin-bottom: 10.5rem !important;
    }
    .ml-md-10,
    .mx-md-10 {
      margin-left: 10.5rem !important;
    }
    .m-md-17 {
      margin: 21rem !important;
    }
    .mt-md-17,
    .my-md-17 {
      margin-top: 21rem !important;
    }
    .mr-md-17,
    .mx-md-17 {
      margin-right: 21rem !important;
    }
    .mb-md-17,
    .my-md-17 {
      margin-bottom: 21rem !important;
    }
    .ml-md-17,
    .mx-md-17 {
      margin-left: 21rem !important;
    }
    .p-md-0 {
      padding: 0 !important;
    }
    .pt-md-0,
    .py-md-0 {
      padding-top: 0 !important;
    }
    .pr-md-0,
    .px-md-0 {
      padding-right: 0 !important;
    }
    .pb-md-0,
    .py-md-0 {
      padding-bottom: 0 !important;
    }
    .pl-md-0,
    .px-md-0 {
      padding-left: 0 !important;
    }
    .p-md-1 {
      padding: 0.25rem !important;
    }
    .pt-md-1,
    .py-md-1 {
      padding-top: 0.25rem !important;
    }
    .pr-md-1,
    .px-md-1 {
      padding-right: 0.25rem !important;
    }
    .pb-md-1,
    .py-md-1 {
      padding-bottom: 0.25rem !important;
    }
    .pl-md-1,
    .px-md-1 {
      padding-left: 0.25rem !important;
    }
    .p-md-2 {
      padding: 0.5rem !important;
    }
    .pt-md-2,
    .py-md-2 {
      padding-top: 0.5rem !important;
    }
    .pr-md-2,
    .px-md-2 {
      padding-right: 0.5rem !important;
    }
    .pb-md-2,
    .py-md-2 {
      padding-bottom: 0.5rem !important;
    }
    .pl-md-2,
    .px-md-2 {
      padding-left: 0.5rem !important;
    }
    .p-md-3 {
      padding: 1rem !important;
    }
    .pt-md-3,
    .py-md-3 {
      padding-top: 1rem !important;
    }
    .pr-md-3,
    .px-md-3 {
      padding-right: 1rem !important;
    }
    .pb-md-3,
    .py-md-3 {
      padding-bottom: 1rem !important;
    }
    .pl-md-3,
    .px-md-3 {
      padding-left: 1rem !important;
    }
    .p-md-4 {
      padding: 1.5rem !important;
    }
    .pt-md-4,
    .py-md-4 {
      padding-top: 1.5rem !important;
    }
    .pr-md-4,
    .px-md-4 {
      padding-right: 1.5rem !important;
    }
    .pb-md-4,
    .py-md-4 {
      padding-bottom: 1.5rem !important;
    }
    .pl-md-4,
    .px-md-4 {
      padding-left: 1.5rem !important;
    }
    .p-md-5 {
      padding: 3rem !important;
    }
    .pt-md-5,
    .py-md-5 {
      padding-top: 3rem !important;
    }
    .pr-md-5,
    .px-md-5 {
      padding-right: 3rem !important;
    }
    .pb-md-5,
    .py-md-5 {
      padding-bottom: 3rem !important;
    }
    .pl-md-5,
    .px-md-5 {
      padding-left: 3rem !important;
    }
    .p-md-6 {
      padding: 4.5rem !important;
    }
    .pt-md-6,
    .py-md-6 {
      padding-top: 4.5rem !important;
    }
    .pr-md-6,
    .px-md-6 {
      padding-right: 4.5rem !important;
    }
    .pb-md-6,
    .py-md-6 {
      padding-bottom: 4.5rem !important;
    }
    .pl-md-6,
    .px-md-6 {
      padding-left: 4.5rem !important;
    }
    .p-md-7 {
      padding: 6rem !important;
    }
    .pt-md-7,
    .py-md-7 {
      padding-top: 6rem !important;
    }
    .pr-md-7,
    .px-md-7 {
      padding-right: 6rem !important;
    }
    .pb-md-7,
    .py-md-7 {
      padding-bottom: 6rem !important;
    }
    .pl-md-7,
    .px-md-7 {
      padding-left: 6rem !important;
    }
    .p-md-8 {
      padding: 7.5rem !important;
    }
    .pt-md-8,
    .py-md-8 {
      padding-top: 7.5rem !important;
    }
    .pr-md-8,
    .px-md-8 {
      padding-right: 7.5rem !important;
    }
    .pb-md-8,
    .py-md-8 {
      padding-bottom: 7.5rem !important;
    }
    .pl-md-8,
    .px-md-8 {
      padding-left: 7.5rem !important;
    }
    .p-md-9 {
      padding: 9rem !important;
    }
    .pt-md-9,
    .py-md-9 {
      padding-top: 9rem !important;
    }
    .pr-md-9,
    .px-md-9 {
      padding-right: 9rem !important;
    }
    .pb-md-9,
    .py-md-9 {
      padding-bottom: 9rem !important;
    }
    .pl-md-9,
    .px-md-9 {
      padding-left: 9rem !important;
    }
    .p-md-10 {
      padding: 10.5rem !important;
    }
    .pt-md-10,
    .py-md-10 {
      padding-top: 10.5rem !important;
    }
    .pr-md-10,
    .px-md-10 {
      padding-right: 10.5rem !important;
    }
    .pb-md-10,
    .py-md-10 {
      padding-bottom: 10.5rem !important;
    }
    .pl-md-10,
    .px-md-10 {
      padding-left: 10.5rem !important;
    }
    .p-md-17 {
      padding: 21rem !important;
    }
    .pt-md-17,
    .py-md-17 {
      padding-top: 21rem !important;
    }
    .pr-md-17,
    .px-md-17 {
      padding-right: 21rem !important;
    }
    .pb-md-17,
    .py-md-17 {
      padding-bottom: 21rem !important;
    }
    .pl-md-17,
    .px-md-17 {
      padding-left: 21rem !important;
    }
    .m-md-n1 {
      margin: -0.25rem !important;
    }
    .mt-md-n1,
    .my-md-n1 {
      margin-top: -0.25rem !important;
    }
    .mr-md-n1,
    .mx-md-n1 {
      margin-right: -0.25rem !important;
    }
    .mb-md-n1,
    .my-md-n1 {
      margin-bottom: -0.25rem !important;
    }
    .ml-md-n1,
    .mx-md-n1 {
      margin-left: -0.25rem !important;
    }
    .m-md-n2 {
      margin: -0.5rem !important;
    }
    .mt-md-n2,
    .my-md-n2 {
      margin-top: -0.5rem !important;
    }
    .mr-md-n2,
    .mx-md-n2 {
      margin-right: -0.5rem !important;
    }
    .mb-md-n2,
    .my-md-n2 {
      margin-bottom: -0.5rem !important;
    }
    .ml-md-n2,
    .mx-md-n2 {
      margin-left: -0.5rem !important;
    }
    .m-md-n3 {
      margin: -1rem !important;
    }
    .mt-md-n3,
    .my-md-n3 {
      margin-top: -1rem !important;
    }
    .mr-md-n3,
    .mx-md-n3 {
      margin-right: -1rem !important;
    }
    .mb-md-n3,
    .my-md-n3 {
      margin-bottom: -1rem !important;
    }
    .ml-md-n3,
    .mx-md-n3 {
      margin-left: -1rem !important;
    }
    .m-md-n4 {
      margin: -1.5rem !important;
    }
    .mt-md-n4,
    .my-md-n4 {
      margin-top: -1.5rem !important;
    }
    .mr-md-n4,
    .mx-md-n4 {
      margin-right: -1.5rem !important;
    }
    .mb-md-n4,
    .my-md-n4 {
      margin-bottom: -1.5rem !important;
    }
    .ml-md-n4,
    .mx-md-n4 {
      margin-left: -1.5rem !important;
    }
    .m-md-n5 {
      margin: -3rem !important;
    }
    .mt-md-n5,
    .my-md-n5 {
      margin-top: -3rem !important;
    }
    .mr-md-n5,
    .mx-md-n5 {
      margin-right: -3rem !important;
    }
    .mb-md-n5,
    .my-md-n5 {
      margin-bottom: -3rem !important;
    }
    .ml-md-n5,
    .mx-md-n5 {
      margin-left: -3rem !important;
    }
    .m-md-n6 {
      margin: -4.5rem !important;
    }
    .mt-md-n6,
    .my-md-n6 {
      margin-top: -4.5rem !important;
    }
    .mr-md-n6,
    .mx-md-n6 {
      margin-right: -4.5rem !important;
    }
    .mb-md-n6,
    .my-md-n6 {
      margin-bottom: -4.5rem !important;
    }
    .ml-md-n6,
    .mx-md-n6 {
      margin-left: -4.5rem !important;
    }
    .m-md-n7 {
      margin: -6rem !important;
    }
    .mt-md-n7,
    .my-md-n7 {
      margin-top: -6rem !important;
    }
    .mr-md-n7,
    .mx-md-n7 {
      margin-right: -6rem !important;
    }
    .mb-md-n7,
    .my-md-n7 {
      margin-bottom: -6rem !important;
    }
    .ml-md-n7,
    .mx-md-n7 {
      margin-left: -6rem !important;
    }
    .m-md-n8 {
      margin: -7.5rem !important;
    }
    .mt-md-n8,
    .my-md-n8 {
      margin-top: -7.5rem !important;
    }
    .mr-md-n8,
    .mx-md-n8 {
      margin-right: -7.5rem !important;
    }
    .mb-md-n8,
    .my-md-n8 {
      margin-bottom: -7.5rem !important;
    }
    .ml-md-n8,
    .mx-md-n8 {
      margin-left: -7.5rem !important;
    }
    .m-md-n9 {
      margin: -9rem !important;
    }
    .mt-md-n9,
    .my-md-n9 {
      margin-top: -9rem !important;
    }
    .mr-md-n9,
    .mx-md-n9 {
      margin-right: -9rem !important;
    }
    .mb-md-n9,
    .my-md-n9 {
      margin-bottom: -9rem !important;
    }
    .ml-md-n9,
    .mx-md-n9 {
      margin-left: -9rem !important;
    }
    .m-md-n10 {
      margin: -10.5rem !important;
    }
    .mt-md-n10,
    .my-md-n10 {
      margin-top: -10.5rem !important;
    }
    .mr-md-n10,
    .mx-md-n10 {
      margin-right: -10.5rem !important;
    }
    .mb-md-n10,
    .my-md-n10 {
      margin-bottom: -10.5rem !important;
    }
    .ml-md-n10,
    .mx-md-n10 {
      margin-left: -10.5rem !important;
    }
    .m-md-n17 {
      margin: -21rem !important;
    }
    .mt-md-n17,
    .my-md-n17 {
      margin-top: -21rem !important;
    }
    .mr-md-n17,
    .mx-md-n17 {
      margin-right: -21rem !important;
    }
    .mb-md-n17,
    .my-md-n17 {
      margin-bottom: -21rem !important;
    }
    .ml-md-n17,
    .mx-md-n17 {
      margin-left: -21rem !important;
    }
    .m-md-auto {
      margin: auto !important;
    }
    .mt-md-auto,
    .my-md-auto {
      margin-top: auto !important;
    }
    .mr-md-auto,
    .mx-md-auto {
      margin-right: auto !important;
    }
    .mb-md-auto,
    .my-md-auto {
      margin-bottom: auto !important;
    }
    .ml-md-auto,
    .mx-md-auto {
      margin-left: auto !important;
    }
  }
  @media (min-width: 992px) {
    .m-lg-0 {
      margin: 0 !important;
    }
    .mt-lg-0,
    .my-lg-0 {
      margin-top: 0 !important;
    }
    .mr-lg-0,
    .mx-lg-0 {
      margin-right: 0 !important;
    }
    .mb-lg-0,
    .my-lg-0 {
      margin-bottom: 0 !important;
    }
    .ml-lg-0,
    .mx-lg-0 {
      margin-left: 0 !important;
    }
    .m-lg-1 {
      margin: 0.25rem !important;
    }
    .mt-lg-1,
    .my-lg-1 {
      margin-top: 0.25rem !important;
    }
    .mr-lg-1,
    .mx-lg-1 {
      margin-right: 0.25rem !important;
    }
    .mb-lg-1,
    .my-lg-1 {
      margin-bottom: 0.25rem !important;
    }
    .ml-lg-1,
    .mx-lg-1 {
      margin-left: 0.25rem !important;
    }
    .m-lg-2 {
      margin: 0.5rem !important;
    }
    .mt-lg-2,
    .my-lg-2 {
      margin-top: 0.5rem !important;
    }
    .mr-lg-2,
    .mx-lg-2 {
      margin-right: 0.5rem !important;
    }
    .mb-lg-2,
    .my-lg-2 {
      margin-bottom: 0.5rem !important;
    }
    .ml-lg-2,
    .mx-lg-2 {
      margin-left: 0.5rem !important;
    }
    .m-lg-3 {
      margin: 1rem !important;
    }
    .mt-lg-3,
    .my-lg-3 {
      margin-top: 1rem !important;
    }
    .mr-lg-3,
    .mx-lg-3 {
      margin-right: 1rem !important;
    }
    .mb-lg-3,
    .my-lg-3 {
      margin-bottom: 1rem !important;
    }
    .ml-lg-3,
    .mx-lg-3 {
      margin-left: 1rem !important;
    }
    .m-lg-4 {
      margin: 1.5rem !important;
    }
    .mt-lg-4,
    .my-lg-4 {
      margin-top: 1.5rem !important;
    }
    .mr-lg-4,
    .mx-lg-4 {
      margin-right: 1.5rem !important;
    }
    .mb-lg-4,
    .my-lg-4 {
      margin-bottom: 1.5rem !important;
    }
    .ml-lg-4,
    .mx-lg-4 {
      margin-left: 1.5rem !important;
    }
    .m-lg-5 {
      margin: 3rem !important;
    }
    .mt-lg-5,
    .my-lg-5 {
      margin-top: 3rem !important;
    }
    .mr-lg-5,
    .mx-lg-5 {
      margin-right: 3rem !important;
    }
    .mb-lg-5,
    .my-lg-5 {
      margin-bottom: 3rem !important;
    }
    .ml-lg-5,
    .mx-lg-5 {
      margin-left: 3rem !important;
    }
    .m-lg-6 {
      margin: 4.5rem !important;
    }
    .mt-lg-6,
    .my-lg-6 {
      margin-top: 4.5rem !important;
    }
    .mr-lg-6,
    .mx-lg-6 {
      margin-right: 4.5rem !important;
    }
    .mb-lg-6,
    .my-lg-6 {
      margin-bottom: 4.5rem !important;
    }
    .ml-lg-6,
    .mx-lg-6 {
      margin-left: 4.5rem !important;
    }
    .m-lg-7 {
      margin: 6rem !important;
    }
    .mt-lg-7,
    .my-lg-7 {
      margin-top: 6rem !important;
    }
    .mr-lg-7,
    .mx-lg-7 {
      margin-right: 6rem !important;
    }
    .mb-lg-7,
    .my-lg-7 {
      margin-bottom: 6rem !important;
    }
    .ml-lg-7,
    .mx-lg-7 {
      margin-left: 6rem !important;
    }
    .m-lg-8 {
      margin: 7.5rem !important;
    }
    .mt-lg-8,
    .my-lg-8 {
      margin-top: 7.5rem !important;
    }
    .mr-lg-8,
    .mx-lg-8 {
      margin-right: 7.5rem !important;
    }
    .mb-lg-8,
    .my-lg-8 {
      margin-bottom: 7.5rem !important;
    }
    .ml-lg-8,
    .mx-lg-8 {
      margin-left: 7.5rem !important;
    }
    .m-lg-9 {
      margin: 9rem !important;
    }
    .mt-lg-9,
    .my-lg-9 {
      margin-top: 9rem !important;
    }
    .mr-lg-9,
    .mx-lg-9 {
      margin-right: 9rem !important;
    }
    .mb-lg-9,
    .my-lg-9 {
      margin-bottom: 9rem !important;
    }
    .ml-lg-9,
    .mx-lg-9 {
      margin-left: 9rem !important;
    }
    .m-lg-10 {
      margin: 10.5rem !important;
    }
    .mt-lg-10,
    .my-lg-10 {
      margin-top: 10.5rem !important;
    }
    .mr-lg-10,
    .mx-lg-10 {
      margin-right: 10.5rem !important;
    }
    .mb-lg-10,
    .my-lg-10 {
      margin-bottom: 10.5rem !important;
    }
    .ml-lg-10,
    .mx-lg-10 {
      margin-left: 10.5rem !important;
    }
    .m-lg-17 {
      margin: 21rem !important;
    }
    .mt-lg-17,
    .my-lg-17 {
      margin-top: 21rem !important;
    }
    .mr-lg-17,
    .mx-lg-17 {
      margin-right: 21rem !important;
    }
    .mb-lg-17,
    .my-lg-17 {
      margin-bottom: 21rem !important;
    }
    .ml-lg-17,
    .mx-lg-17 {
      margin-left: 21rem !important;
    }
    .p-lg-0 {
      padding: 0 !important;
    }
    .pt-lg-0,
    .py-lg-0 {
      padding-top: 0 !important;
    }
    .pr-lg-0,
    .px-lg-0 {
      padding-right: 0 !important;
    }
    .pb-lg-0,
    .py-lg-0 {
      padding-bottom: 0 !important;
    }
    .pl-lg-0,
    .px-lg-0 {
      padding-left: 0 !important;
    }
    .p-lg-1 {
      padding: 0.25rem !important;
    }
    .pt-lg-1,
    .py-lg-1 {
      padding-top: 0.25rem !important;
    }
    .pr-lg-1,
    .px-lg-1 {
      padding-right: 0.25rem !important;
    }
    .pb-lg-1,
    .py-lg-1 {
      padding-bottom: 0.25rem !important;
    }
    .pl-lg-1,
    .px-lg-1 {
      padding-left: 0.25rem !important;
    }
    .p-lg-2 {
      padding: 0.5rem !important;
    }
    .pt-lg-2,
    .py-lg-2 {
      padding-top: 0.5rem !important;
    }
    .pr-lg-2,
    .px-lg-2 {
      padding-right: 0.5rem !important;
    }
    .pb-lg-2,
    .py-lg-2 {
      padding-bottom: 0.5rem !important;
    }
    .pl-lg-2,
    .px-lg-2 {
      padding-left: 0.5rem !important;
    }
    .p-lg-3 {
      padding: 1rem !important;
    }
    .pt-lg-3,
    .py-lg-3 {
      padding-top: 1rem !important;
    }
    .pr-lg-3,
    .px-lg-3 {
      padding-right: 1rem !important;
    }
    .pb-lg-3,
    .py-lg-3 {
      padding-bottom: 1rem !important;
    }
    .pl-lg-3,
    .px-lg-3 {
      padding-left: 1rem !important;
    }
    .p-lg-4 {
      padding: 1.5rem !important;
    }
    .pt-lg-4,
    .py-lg-4 {
      padding-top: 1.5rem !important;
    }
    .pr-lg-4,
    .px-lg-4 {
      padding-right: 1.5rem !important;
    }
    .pb-lg-4,
    .py-lg-4 {
      padding-bottom: 1.5rem !important;
    }
    .pl-lg-4,
    .px-lg-4 {
      padding-left: 1.5rem !important;
    }
    .p-lg-5 {
      padding: 3rem !important;
    }
    .pt-lg-5,
    .py-lg-5 {
      padding-top: 3rem !important;
    }
    .pr-lg-5,
    .px-lg-5 {
      padding-right: 3rem !important;
    }
    .pb-lg-5,
    .py-lg-5 {
      padding-bottom: 3rem !important;
    }
    .pl-lg-5,
    .px-lg-5 {
      padding-left: 3rem !important;
    }
    .p-lg-6 {
      padding: 4.5rem !important;
    }
    .pt-lg-6,
    .py-lg-6 {
      padding-top: 4.5rem !important;
    }
    .pr-lg-6,
    .px-lg-6 {
      padding-right: 4.5rem !important;
    }
    .pb-lg-6,
    .py-lg-6 {
      padding-bottom: 4.5rem !important;
    }
    .pl-lg-6,
    .px-lg-6 {
      padding-left: 4.5rem !important;
    }
    .p-lg-7 {
      padding: 6rem !important;
    }
    .pt-lg-7,
    .py-lg-7 {
      padding-top: 6rem !important;
    }
    .pr-lg-7,
    .px-lg-7 {
      padding-right: 6rem !important;
    }
    .pb-lg-7,
    .py-lg-7 {
      padding-bottom: 6rem !important;
    }
    .pl-lg-7,
    .px-lg-7 {
      padding-left: 6rem !important;
    }
    .p-lg-8 {
      padding: 7.5rem !important;
    }
    .pt-lg-8,
    .py-lg-8 {
      padding-top: 7.5rem !important;
    }
    .pr-lg-8,
    .px-lg-8 {
      padding-right: 7.5rem !important;
    }
    .pb-lg-8,
    .py-lg-8 {
      padding-bottom: 7.5rem !important;
    }
    .pl-lg-8,
    .px-lg-8 {
      padding-left: 7.5rem !important;
    }
    .p-lg-9 {
      padding: 9rem !important;
    }
    .pt-lg-9,
    .py-lg-9 {
      padding-top: 9rem !important;
    }
    .pr-lg-9,
    .px-lg-9 {
      padding-right: 9rem !important;
    }
    .pb-lg-9,
    .py-lg-9 {
      padding-bottom: 9rem !important;
    }
    .pl-lg-9,
    .px-lg-9 {
      padding-left: 9rem !important;
    }
    .p-lg-10 {
      padding: 10.5rem !important;
    }
    .pt-lg-10,
    .py-lg-10 {
      padding-top: 10.5rem !important;
    }
    .pr-lg-10,
    .px-lg-10 {
      padding-right: 10.5rem !important;
    }
    .pb-lg-10,
    .py-lg-10 {
      padding-bottom: 10.5rem !important;
    }
    .pl-lg-10,
    .px-lg-10 {
      padding-left: 10.5rem !important;
    }
    .p-lg-17 {
      padding: 21rem !important;
    }
    .pt-lg-17,
    .py-lg-17 {
      padding-top: 21rem !important;
    }
    .pr-lg-17,
    .px-lg-17 {
      padding-right: 21rem !important;
    }
    .pb-lg-17,
    .py-lg-17 {
      padding-bottom: 21rem !important;
    }
    .pl-lg-17,
    .px-lg-17 {
      padding-left: 21rem !important;
    }
    .m-lg-n1 {
      margin: -0.25rem !important;
    }
    .mt-lg-n1,
    .my-lg-n1 {
      margin-top: -0.25rem !important;
    }
    .mr-lg-n1,
    .mx-lg-n1 {
      margin-right: -0.25rem !important;
    }
    .mb-lg-n1,
    .my-lg-n1 {
      margin-bottom: -0.25rem !important;
    }
    .ml-lg-n1,
    .mx-lg-n1 {
      margin-left: -0.25rem !important;
    }
    .m-lg-n2 {
      margin: -0.5rem !important;
    }
    .mt-lg-n2,
    .my-lg-n2 {
      margin-top: -0.5rem !important;
    }
    .mr-lg-n2,
    .mx-lg-n2 {
      margin-right: -0.5rem !important;
    }
    .mb-lg-n2,
    .my-lg-n2 {
      margin-bottom: -0.5rem !important;
    }
    .ml-lg-n2,
    .mx-lg-n2 {
      margin-left: -0.5rem !important;
    }
    .m-lg-n3 {
      margin: -1rem !important;
    }
    .mt-lg-n3,
    .my-lg-n3 {
      margin-top: -1rem !important;
    }
    .mr-lg-n3,
    .mx-lg-n3 {
      margin-right: -1rem !important;
    }
    .mb-lg-n3,
    .my-lg-n3 {
      margin-bottom: -1rem !important;
    }
    .ml-lg-n3,
    .mx-lg-n3 {
      margin-left: -1rem !important;
    }
    .m-lg-n4 {
      margin: -1.5rem !important;
    }
    .mt-lg-n4,
    .my-lg-n4 {
      margin-top: -1.5rem !important;
    }
    .mr-lg-n4,
    .mx-lg-n4 {
      margin-right: -1.5rem !important;
    }
    .mb-lg-n4,
    .my-lg-n4 {
      margin-bottom: -1.5rem !important;
    }
    .ml-lg-n4,
    .mx-lg-n4 {
      margin-left: -1.5rem !important;
    }
    .m-lg-n5 {
      margin: -3rem !important;
    }
    .mt-lg-n5,
    .my-lg-n5 {
      margin-top: -3rem !important;
    }
    .mr-lg-n5,
    .mx-lg-n5 {
      margin-right: -3rem !important;
    }
    .mb-lg-n5,
    .my-lg-n5 {
      margin-bottom: -3rem !important;
    }
    .ml-lg-n5,
    .mx-lg-n5 {
      margin-left: -3rem !important;
    }
    .m-lg-n6 {
      margin: -4.5rem !important;
    }
    .mt-lg-n6,
    .my-lg-n6 {
      margin-top: -4.5rem !important;
    }
    .mr-lg-n6,
    .mx-lg-n6 {
      margin-right: -4.5rem !important;
    }
    .mb-lg-n6,
    .my-lg-n6 {
      margin-bottom: -4.5rem !important;
    }
    .ml-lg-n6,
    .mx-lg-n6 {
      margin-left: -4.5rem !important;
    }
    .m-lg-n7 {
      margin: -6rem !important;
    }
    .mt-lg-n7,
    .my-lg-n7 {
      margin-top: -6rem !important;
    }
    .mr-lg-n7,
    .mx-lg-n7 {
      margin-right: -6rem !important;
    }
    .mb-lg-n7,
    .my-lg-n7 {
      margin-bottom: -6rem !important;
    }
    .ml-lg-n7,
    .mx-lg-n7 {
      margin-left: -6rem !important;
    }
    .m-lg-n8 {
      margin: -7.5rem !important;
    }
    .mt-lg-n8,
    .my-lg-n8 {
      margin-top: -7.5rem !important;
    }
    .mr-lg-n8,
    .mx-lg-n8 {
      margin-right: -7.5rem !important;
    }
    .mb-lg-n8,
    .my-lg-n8 {
      margin-bottom: -7.5rem !important;
    }
    .ml-lg-n8,
    .mx-lg-n8 {
      margin-left: -7.5rem !important;
    }
    .m-lg-n9 {
      margin: -9rem !important;
    }
    .mt-lg-n9,
    .my-lg-n9 {
      margin-top: -9rem !important;
    }
    .mr-lg-n9,
    .mx-lg-n9 {
      margin-right: -9rem !important;
    }
    .mb-lg-n9,
    .my-lg-n9 {
      margin-bottom: -9rem !important;
    }
    .ml-lg-n9,
    .mx-lg-n9 {
      margin-left: -9rem !important;
    }
    .m-lg-n10 {
      margin: -10.5rem !important;
    }
    .mt-lg-n10,
    .my-lg-n10 {
      margin-top: -10.5rem !important;
    }
    .mr-lg-n10,
    .mx-lg-n10 {
      margin-right: -10.5rem !important;
    }
    .mb-lg-n10,
    .my-lg-n10 {
      margin-bottom: -10.5rem !important;
    }
    .ml-lg-n10,
    .mx-lg-n10 {
      margin-left: -10.5rem !important;
    }
    .m-lg-n17 {
      margin: -21rem !important;
    }
    .mt-lg-n17,
    .my-lg-n17 {
      margin-top: -21rem !important;
    }
    .mr-lg-n17,
    .mx-lg-n17 {
      margin-right: -21rem !important;
    }
    .mb-lg-n17,
    .my-lg-n17 {
      margin-bottom: -21rem !important;
    }
    .ml-lg-n17,
    .mx-lg-n17 {
      margin-left: -21rem !important;
    }
    .m-lg-auto {
      margin: auto !important;
    }
    .mt-lg-auto,
    .my-lg-auto {
      margin-top: auto !important;
    }
    .mr-lg-auto,
    .mx-lg-auto {
      margin-right: auto !important;
    }
    .mb-lg-auto,
    .my-lg-auto {
      margin-bottom: auto !important;
    }
    .ml-lg-auto,
    .mx-lg-auto {
      margin-left: auto !important;
    }
  }
  @media (min-width: 1200px) {
    .m-xl-0 {
      margin: 0 !important;
    }
    .mt-xl-0,
    .my-xl-0 {
      margin-top: 0 !important;
    }
    .mr-xl-0,
    .mx-xl-0 {
      margin-right: 0 !important;
    }
    .mb-xl-0,
    .my-xl-0 {
      margin-bottom: 0 !important;
    }
    .ml-xl-0,
    .mx-xl-0 {
      margin-left: 0 !important;
    }
    .m-xl-1 {
      margin: 0.25rem !important;
    }
    .mt-xl-1,
    .my-xl-1 {
      margin-top: 0.25rem !important;
    }
    .mr-xl-1,
    .mx-xl-1 {
      margin-right: 0.25rem !important;
    }
    .mb-xl-1,
    .my-xl-1 {
      margin-bottom: 0.25rem !important;
    }
    .ml-xl-1,
    .mx-xl-1 {
      margin-left: 0.25rem !important;
    }
    .m-xl-2 {
      margin: 0.5rem !important;
    }
    .mt-xl-2,
    .my-xl-2 {
      margin-top: 0.5rem !important;
    }
    .mr-xl-2,
    .mx-xl-2 {
      margin-right: 0.5rem !important;
    }
    .mb-xl-2,
    .my-xl-2 {
      margin-bottom: 0.5rem !important;
    }
    .ml-xl-2,
    .mx-xl-2 {
      margin-left: 0.5rem !important;
    }
    .m-xl-3 {
      margin: 1rem !important;
    }
    .mt-xl-3,
    .my-xl-3 {
      margin-top: 1rem !important;
    }
    .mr-xl-3,
    .mx-xl-3 {
      margin-right: 1rem !important;
    }
    .mb-xl-3,
    .my-xl-3 {
      margin-bottom: 1rem !important;
    }
    .ml-xl-3,
    .mx-xl-3 {
      margin-left: 1rem !important;
    }
    .m-xl-4 {
      margin: 1.5rem !important;
    }
    .mt-xl-4,
    .my-xl-4 {
      margin-top: 1.5rem !important;
    }
    .mr-xl-4,
    .mx-xl-4 {
      margin-right: 1.5rem !important;
    }
    .mb-xl-4,
    .my-xl-4 {
      margin-bottom: 1.5rem !important;
    }
    .ml-xl-4,
    .mx-xl-4 {
      margin-left: 1.5rem !important;
    }
    .m-xl-5 {
      margin: 3rem !important;
    }
    .mt-xl-5,
    .my-xl-5 {
      margin-top: 3rem !important;
    }
    .mr-xl-5,
    .mx-xl-5 {
      margin-right: 3rem !important;
    }
    .mb-xl-5,
    .my-xl-5 {
      margin-bottom: 3rem !important;
    }
    .ml-xl-5,
    .mx-xl-5 {
      margin-left: 3rem !important;
    }
    .m-xl-6 {
      margin: 4.5rem !important;
    }
    .mt-xl-6,
    .my-xl-6 {
      margin-top: 4.5rem !important;
    }
    .mr-xl-6,
    .mx-xl-6 {
      margin-right: 4.5rem !important;
    }
    .mb-xl-6,
    .my-xl-6 {
      margin-bottom: 4.5rem !important;
    }
    .ml-xl-6,
    .mx-xl-6 {
      margin-left: 4.5rem !important;
    }
    .m-xl-7 {
      margin: 6rem !important;
    }
    .mt-xl-7,
    .my-xl-7 {
      margin-top: 6rem !important;
    }
    .mr-xl-7,
    .mx-xl-7 {
      margin-right: 6rem !important;
    }
    .mb-xl-7,
    .my-xl-7 {
      margin-bottom: 6rem !important;
    }
    .ml-xl-7,
    .mx-xl-7 {
      margin-left: 6rem !important;
    }
    .m-xl-8 {
      margin: 7.5rem !important;
    }
    .mt-xl-8,
    .my-xl-8 {
      margin-top: 7.5rem !important;
    }
    .mr-xl-8,
    .mx-xl-8 {
      margin-right: 7.5rem !important;
    }
    .mb-xl-8,
    .my-xl-8 {
      margin-bottom: 7.5rem !important;
    }
    .ml-xl-8,
    .mx-xl-8 {
      margin-left: 7.5rem !important;
    }
    .m-xl-9 {
      margin: 9rem !important;
    }
    .mt-xl-9,
    .my-xl-9 {
      margin-top: 9rem !important;
    }
    .mr-xl-9,
    .mx-xl-9 {
      margin-right: 9rem !important;
    }
    .mb-xl-9,
    .my-xl-9 {
      margin-bottom: 9rem !important;
    }
    .ml-xl-9,
    .mx-xl-9 {
      margin-left: 9rem !important;
    }
    .m-xl-10 {
      margin: 10.5rem !important;
    }
    .mt-xl-10,
    .my-xl-10 {
      margin-top: 10.5rem !important;
    }
    .mr-xl-10,
    .mx-xl-10 {
      margin-right: 10.5rem !important;
    }
    .mb-xl-10,
    .my-xl-10 {
      margin-bottom: 10.5rem !important;
    }
    .ml-xl-10,
    .mx-xl-10 {
      margin-left: 10.5rem !important;
    }
    .m-xl-17 {
      margin: 21rem !important;
    }
    .mt-xl-17,
    .my-xl-17 {
      margin-top: 21rem !important;
    }
    .mr-xl-17,
    .mx-xl-17 {
      margin-right: 21rem !important;
    }
    .mb-xl-17,
    .my-xl-17 {
      margin-bottom: 21rem !important;
    }
    .ml-xl-17,
    .mx-xl-17 {
      margin-left: 21rem !important;
    }
    .p-xl-0 {
      padding: 0 !important;
    }
    .pt-xl-0,
    .py-xl-0 {
      padding-top: 0 !important;
    }
    .pr-xl-0,
    .px-xl-0 {
      padding-right: 0 !important;
    }
    .pb-xl-0,
    .py-xl-0 {
      padding-bottom: 0 !important;
    }
    .pl-xl-0,
    .px-xl-0 {
      padding-left: 0 !important;
    }
    .p-xl-1 {
      padding: 0.25rem !important;
    }
    .pt-xl-1,
    .py-xl-1 {
      padding-top: 0.25rem !important;
    }
    .pr-xl-1,
    .px-xl-1 {
      padding-right: 0.25rem !important;
    }
    .pb-xl-1,
    .py-xl-1 {
      padding-bottom: 0.25rem !important;
    }
    .pl-xl-1,
    .px-xl-1 {
      padding-left: 0.25rem !important;
    }
    .p-xl-2 {
      padding: 0.5rem !important;
    }
    .pt-xl-2,
    .py-xl-2 {
      padding-top: 0.5rem !important;
    }
    .pr-xl-2,
    .px-xl-2 {
      padding-right: 0.5rem !important;
    }
    .pb-xl-2,
    .py-xl-2 {
      padding-bottom: 0.5rem !important;
    }
    .pl-xl-2,
    .px-xl-2 {
      padding-left: 0.5rem !important;
    }
    .p-xl-3 {
      padding: 1rem !important;
    }
    .pt-xl-3,
    .py-xl-3 {
      padding-top: 1rem !important;
    }
    .pr-xl-3,
    .px-xl-3 {
      padding-right: 1rem !important;
    }
    .pb-xl-3,
    .py-xl-3 {
      padding-bottom: 1rem !important;
    }
    .pl-xl-3,
    .px-xl-3 {
      padding-left: 1rem !important;
    }
    .p-xl-4 {
      padding: 1.5rem !important;
    }
    .pt-xl-4,
    .py-xl-4 {
      padding-top: 1.5rem !important;
    }
    .pr-xl-4,
    .px-xl-4 {
      padding-right: 1.5rem !important;
    }
    .pb-xl-4,
    .py-xl-4 {
      padding-bottom: 1.5rem !important;
    }
    .pl-xl-4,
    .px-xl-4 {
      padding-left: 1.5rem !important;
    }
    .p-xl-5 {
      padding: 3rem !important;
    }
    .pt-xl-5,
    .py-xl-5 {
      padding-top: 3rem !important;
    }
    .pr-xl-5,
    .px-xl-5 {
      padding-right: 3rem !important;
    }
    .pb-xl-5,
    .py-xl-5 {
      padding-bottom: 3rem !important;
    }
    .pl-xl-5,
    .px-xl-5 {
      padding-left: 3rem !important;
    }
    .p-xl-6 {
      padding: 4.5rem !important;
    }
    .pt-xl-6,
    .py-xl-6 {
      padding-top: 4.5rem !important;
    }
    .pr-xl-6,
    .px-xl-6 {
      padding-right: 4.5rem !important;
    }
    .pb-xl-6,
    .py-xl-6 {
      padding-bottom: 4.5rem !important;
    }
    .pl-xl-6,
    .px-xl-6 {
      padding-left: 4.5rem !important;
    }
    .p-xl-7 {
      padding: 6rem !important;
    }
    .pt-xl-7,
    .py-xl-7 {
      padding-top: 6rem !important;
    }
    .pr-xl-7,
    .px-xl-7 {
      padding-right: 6rem !important;
    }
    .pb-xl-7,
    .py-xl-7 {
      padding-bottom: 6rem !important;
    }
    .pl-xl-7,
    .px-xl-7 {
      padding-left: 6rem !important;
    }
    .p-xl-8 {
      padding: 7.5rem !important;
    }
    .pt-xl-8,
    .py-xl-8 {
      padding-top: 7.5rem !important;
    }
    .pr-xl-8,
    .px-xl-8 {
      padding-right: 7.5rem !important;
    }
    .pb-xl-8,
    .py-xl-8 {
      padding-bottom: 7.5rem !important;
    }
    .pl-xl-8,
    .px-xl-8 {
      padding-left: 7.5rem !important;
    }
    .p-xl-9 {
      padding: 9rem !important;
    }
    .pt-xl-9,
    .py-xl-9 {
      padding-top: 9rem !important;
    }
    .pr-xl-9,
    .px-xl-9 {
      padding-right: 9rem !important;
    }
    .pb-xl-9,
    .py-xl-9 {
      padding-bottom: 9rem !important;
    }
    .pl-xl-9,
    .px-xl-9 {
      padding-left: 9rem !important;
    }
    .p-xl-10 {
      padding: 10.5rem !important;
    }
    .pt-xl-10,
    .py-xl-10 {
      padding-top: 10.5rem !important;
    }
    .pr-xl-10,
    .px-xl-10 {
      padding-right: 10.5rem !important;
    }
    .pb-xl-10,
    .py-xl-10 {
      padding-bottom: 10.5rem !important;
    }
    .pl-xl-10,
    .px-xl-10 {
      padding-left: 10.5rem !important;
    }
    .p-xl-17 {
      padding: 21rem !important;
    }
    .pt-xl-17,
    .py-xl-17 {
      padding-top: 21rem !important;
    }
    .pr-xl-17,
    .px-xl-17 {
      padding-right: 21rem !important;
    }
    .pb-xl-17,
    .py-xl-17 {
      padding-bottom: 21rem !important;
    }
    .pl-xl-17,
    .px-xl-17 {
      padding-left: 21rem !important;
    }
    .m-xl-n1 {
      margin: -0.25rem !important;
    }
    .mt-xl-n1,
    .my-xl-n1 {
      margin-top: -0.25rem !important;
    }
    .mr-xl-n1,
    .mx-xl-n1 {
      margin-right: -0.25rem !important;
    }
    .mb-xl-n1,
    .my-xl-n1 {
      margin-bottom: -0.25rem !important;
    }
    .ml-xl-n1,
    .mx-xl-n1 {
      margin-left: -0.25rem !important;
    }
    .m-xl-n2 {
      margin: -0.5rem !important;
    }
    .mt-xl-n2,
    .my-xl-n2 {
      margin-top: -0.5rem !important;
    }
    .mr-xl-n2,
    .mx-xl-n2 {
      margin-right: -0.5rem !important;
    }
    .mb-xl-n2,
    .my-xl-n2 {
      margin-bottom: -0.5rem !important;
    }
    .ml-xl-n2,
    .mx-xl-n2 {
      margin-left: -0.5rem !important;
    }
    .m-xl-n3 {
      margin: -1rem !important;
    }
    .mt-xl-n3,
    .my-xl-n3 {
      margin-top: -1rem !important;
    }
    .mr-xl-n3,
    .mx-xl-n3 {
      margin-right: -1rem !important;
    }
    .mb-xl-n3,
    .my-xl-n3 {
      margin-bottom: -1rem !important;
    }
    .ml-xl-n3,
    .mx-xl-n3 {
      margin-left: -1rem !important;
    }
    .m-xl-n4 {
      margin: -1.5rem !important;
    }
    .mt-xl-n4,
    .my-xl-n4 {
      margin-top: -1.5rem !important;
    }
    .mr-xl-n4,
    .mx-xl-n4 {
      margin-right: -1.5rem !important;
    }
    .mb-xl-n4,
    .my-xl-n4 {
      margin-bottom: -1.5rem !important;
    }
    .ml-xl-n4,
    .mx-xl-n4 {
      margin-left: -1.5rem !important;
    }
    .m-xl-n5 {
      margin: -3rem !important;
    }
    .mt-xl-n5,
    .my-xl-n5 {
      margin-top: -3rem !important;
    }
    .mr-xl-n5,
    .mx-xl-n5 {
      margin-right: -3rem !important;
    }
    .mb-xl-n5,
    .my-xl-n5 {
      margin-bottom: -3rem !important;
    }
    .ml-xl-n5,
    .mx-xl-n5 {
      margin-left: -3rem !important;
    }
    .m-xl-n6 {
      margin: -4.5rem !important;
    }
    .mt-xl-n6,
    .my-xl-n6 {
      margin-top: -4.5rem !important;
    }
    .mr-xl-n6,
    .mx-xl-n6 {
      margin-right: -4.5rem !important;
    }
    .mb-xl-n6,
    .my-xl-n6 {
      margin-bottom: -4.5rem !important;
    }
    .ml-xl-n6,
    .mx-xl-n6 {
      margin-left: -4.5rem !important;
    }
    .m-xl-n7 {
      margin: -6rem !important;
    }
    .mt-xl-n7,
    .my-xl-n7 {
      margin-top: -6rem !important;
    }
    .mr-xl-n7,
    .mx-xl-n7 {
      margin-right: -6rem !important;
    }
    .mb-xl-n7,
    .my-xl-n7 {
      margin-bottom: -6rem !important;
    }
    .ml-xl-n7,
    .mx-xl-n7 {
      margin-left: -6rem !important;
    }
    .m-xl-n8 {
      margin: -7.5rem !important;
    }
    .mt-xl-n8,
    .my-xl-n8 {
      margin-top: -7.5rem !important;
    }
    .mr-xl-n8,
    .mx-xl-n8 {
      margin-right: -7.5rem !important;
    }
    .mb-xl-n8,
    .my-xl-n8 {
      margin-bottom: -7.5rem !important;
    }
    .ml-xl-n8,
    .mx-xl-n8 {
      margin-left: -7.5rem !important;
    }
    .m-xl-n9 {
      margin: -9rem !important;
    }
    .mt-xl-n9,
    .my-xl-n9 {
      margin-top: -9rem !important;
    }
    .mr-xl-n9,
    .mx-xl-n9 {
      margin-right: -9rem !important;
    }
    .mb-xl-n9,
    .my-xl-n9 {
      margin-bottom: -9rem !important;
    }
    .ml-xl-n9,
    .mx-xl-n9 {
      margin-left: -9rem !important;
    }
    .m-xl-n10 {
      margin: -10.5rem !important;
    }
    .mt-xl-n10,
    .my-xl-n10 {
      margin-top: -10.5rem !important;
    }
    .mr-xl-n10,
    .mx-xl-n10 {
      margin-right: -10.5rem !important;
    }
    .mb-xl-n10,
    .my-xl-n10 {
      margin-bottom: -10.5rem !important;
    }
    .ml-xl-n10,
    .mx-xl-n10 {
      margin-left: -10.5rem !important;
    }
    .m-xl-n17 {
      margin: -21rem !important;
    }
    .mt-xl-n17,
    .my-xl-n17 {
      margin-top: -21rem !important;
    }
    .mr-xl-n17,
    .mx-xl-n17 {
      margin-right: -21rem !important;
    }
    .mb-xl-n17,
    .my-xl-n17 {
      margin-bottom: -21rem !important;
    }
    .ml-xl-n17,
    .mx-xl-n17 {
      margin-left: -21rem !important;
    }
    .m-xl-auto {
      margin: auto !important;
    }
    .mt-xl-auto,
    .my-xl-auto {
      margin-top: auto !important;
    }
    .mr-xl-auto,
    .mx-xl-auto {
      margin-right: auto !important;
    }
    .mb-xl-auto,
    .my-xl-auto {
      margin-bottom: auto !important;
    }
    .ml-xl-auto,
    .mx-xl-auto {
      margin-left: auto !important;
    }
  }
  .stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0, 0, 0, 0);
  }
  .text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
      "Courier New", monospace !important;
  }
  .text-justify {
    text-align: justify !important;
  }
  .text-wrap {
    white-space: normal !important;
  }
  .text-nowrap {
    white-space: nowrap !important;
  }
  .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .text-left {
    text-align: left !important;
  }
  .text-right {
    text-align: right !important;
  }
  .text-center {
    text-align: center !important;
  }
  @media (min-width: 576px) {
    .text-sm-left {
      text-align: left !important;
    }
    .text-sm-right {
      text-align: right !important;
    }
    .text-sm-center {
      text-align: center !important;
    }
  }
  @media (min-width: 768px) {
    .text-md-left {
      text-align: left !important;
    }
    .text-md-right {
      text-align: right !important;
    }
    .text-md-center {
      text-align: center !important;
    }
  }
  @media (min-width: 992px) {
    .text-lg-left {
      text-align: left !important;
    }
    .text-lg-right {
      text-align: right !important;
    }
    .text-lg-center {
      text-align: center !important;
    }
  }
  @media (min-width: 1200px) {
    .text-xl-left {
      text-align: left !important;
    }
    .text-xl-right {
      text-align: right !important;
    }
    .text-xl-center {
      text-align: center !important;
    }
  }
  .text-lowercase {
    text-transform: lowercase !important;
  }
  .text-uppercase {
    text-transform: uppercase !important;
  }
  .text-capitalize {
    text-transform: capitalize !important;
  }
  .font-weight-light {
    font-weight: 300 !important;
  }
  .font-weight-lighter {
    font-weight: lighter !important;
  }
  .font-weight-normal {
    font-weight: 400 !important;
  }
  .font-weight-bold {
    font-weight: 600 !important;
  }
  .font-weight-bolder {
    font-weight: 700 !important;
  }
  .font-italic {
    font-style: italic !important;
  }
  .text-white {
    color: #fff !important;
  }
  .text-primary {
    color: #0d6efd !important;
  }
  a.text-primary:focus,
  a.text-primary:hover {
    color: #b62d34 !important;
  }
  .text-secondary {
    color: #eaecf3 !important;
  }
  a.text-secondary:focus,
  a.text-secondary:hover {
    color: #b9c0d7 !important;
  }
  .text-success {
    color: #5cc9a7 !important;
  }
  a.text-success:focus,
  a.text-success:hover {
    color: #36a381 !important;
  }
  .text-info {
    color: #50b5ff !important;
  }
  a.text-info:focus,
  a.text-info:hover {
    color: #0495ff !important;
  }
  .text-warning {
    color: #ffbe3d !important;
  }
  a.text-warning:focus,
  a.text-warning:hover {
    color: #f09f00 !important;
  }
  .text-danger {
    color: #d33139 !important;
  }
  a.text-danger:focus,
  a.text-danger:hover {
    color: #ea1228 !important;
  }
  .text-light {
    color: #eaecf3 !important;
  }
  a.text-light:focus,
  a.text-light:hover {
    color: #b9c0d7 !important;
  }
  .text-dark {
    color: #171347 !important;
  }
  a.text-dark:focus,
  a.text-dark:hover {
    color: #03030b !important;
  }
  .text-neutral {
    color: #fff !important;
  }
  a.text-neutral:focus,
  a.text-neutral:hover {
    color: #d9d9d9 !important;
  }
  .text-white {
    color: #fff !important;
  }
  a.text-white:focus,
  a.text-white:hover {
    color: #d9d9d9 !important;
  }
  .text-body {
    color: #718096 !important;
  }
  .text-muted {
    color: #718096 !important;
  }
  .text-black-50 {
    color: rgba(31, 45, 61, 0.5) !important;
  }
  .text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important;
  }
  .text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }
  .text-decoration-none {
    text-decoration: none !important;
  }
  .text-break {
    word-break: break-word !important;
    word-wrap: break-word !important;
  }
  .text-reset {
    color: inherit !important;
  }
  .visible {
    visibility: visible !important;
  }
  .invisible {
    visibility: hidden !important;
  }
  @media print {
    *,
    ::after,
    ::before {
      text-shadow: none !important;
      box-shadow: none !important;
    }
    a:not(.btn) {
      text-decoration: underline;
    }
    abbr[title]::after {
      content: " (" attr(title) ")";
    }
    pre {
      white-space: pre-wrap !important;
    }
    blockquote,
    pre {
      border: 1px solid #a0aec0;
      page-break-inside: avoid;
    }
    thead {
      display: table-header-group;
    }
    img,
    tr {
      page-break-inside: avoid;
    }
    h2,
    h3,
    p {
      orphans: 3;
      widows: 3;
    }
    h2,
    h3 {
      page-break-after: avoid;
    }
    @page {
      size: a3;
    }
    body {
      min-width: 992px !important;
    }
    .container {
      min-width: 992px !important;
    }
    .navbar {
      display: none;
    }
    .badge {
      border: 1px solid #1f2d3d;
    }
    .table {
      border-collapse: collapse !important;
    }
    .table td,
    .table th {
      background-color: #fff !important;
    }
    .table-bordered td,
    .table-bordered th {
      border: 1px solid #e2e8f0 !important;
    }
    .table-dark {
      color: inherit;
    }
    .table-dark tbody + tbody,
    .table-dark td,
    .table-dark th,
    .table-dark thead th {
      border-color: #eaecf3;
    }
    .table .thead-dark th {
      color: inherit;
      border-color: #eaecf3;
    }
  }
  [data-animate-hover] .animate-this {
    position: relative;
    top: 0;
    transform: translateY(0);
    transition: all 0.2s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    [data-animate-hover] .animate-this {
      transition: none;
    }
  }
  [data-animate-hover="1"]:hover .animate-this {
    transform: translateY(-1rem);
    box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.05);
  }
  [data-animate-hover="2"]:hover .animate-this {
    transform: scale(1.1);
    z-index: 10;
    box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.05);
  }
  [data-animate-items] .animate-item,
  [data-animate-items] .animate-item--visible {
    transition: all 0.2s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    [data-animate-items] .animate-item,
    [data-animate-items] .animate-item--visible {
      transition: none;
    }
  }
  [data-animate-items] .animate-item {
    display: none;
    opacity: 0;
    animation: hide_item 0.2s ease forwards;
    animation-delay: 0.5s;
  }
  [data-animate-items]:hover .animate-item {
    display: block;
    animation: show_item 0.2s ease forwards;
    animation-delay: 0.5s;
  }
  @keyframes text_slide {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(300px);
    }
  }
  @keyframes show_item {
    from {
      opacity: 0;
      transform: translateY(300px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes hide_item {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(300px);
    }
  }
  .hover-blurable {
    position: relative;
  }
  .hover-blurable .blurable-item {
    transition: 1s cubic-bezier(0.19, 1, 0.22, 1);
    filter: blur(0);
    opacity: 1;
  }
  .hover-blurable .blurable-hidden {
    position: absolute;
    top: calc(50% + 7px);
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.2s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .hover-blurable .blurable-hidden {
      transition: none;
    }
  }
  .hover-blurable:hover .blurable-item {
    opacity: 0.8;
    filter: blur(10px);
    transform: scale(0.95);
  }
  .hover-blurable:hover .blurable-hidden {
    opacity: 1;
    top: 50%;
  }
  .bg-light-primary {
    background-color: #33a1ff !important;
  }
  a.bg-light-primary:focus,
  a.bg-light-primary:hover,
  button.bg-light-primary:focus,
  button.bg-light-primary:hover {
    background-color: #008aff !important;
  }
  .bg-dark-primary {
    background-color: #b62d34 !important;
  }
  a.bg-dark-primary:focus,
  a.bg-dark-primary:hover,
  button.bg-dark-primary:focus,
  button.bg-dark-primary:hover {
    background-color: #005399 !important;
  }
  .bg-soft-primary {
    background-color: #e0f1ff;
  }
  .bg-gradient-primary {
    background: linear-gradient(50deg, #008aff 0, #003eff 100%) !important;
  }
  .bg-translucent-primary {
    background-color: rgba(0, 119, 219, 0.6) !important;
  }
  a.bg-translucent-primary:focus,
  a.bg-translucent-primary:hover,
  button.bg-translucent-primary:focus,
  button.bg-translucent-primary:hover {
    background-color: rgba(0, 105, 194, 0.6) !important;
  }
  .bg-light-secondary {
    background-color: #fff !important;
  }
  a.bg-light-secondary:focus,
  a.bg-light-secondary:hover,
  button.bg-light-secondary:focus,
  button.bg-light-secondary:hover {
    background-color: #e6e6e6 !important;
  }
  .bg-dark-secondary {
    background-color: #cacfe0 !important;
  }
  a.bg-dark-secondary:focus,
  a.bg-dark-secondary:hover,
  button.bg-dark-secondary:focus,
  button.bg-dark-secondary:hover {
    background-color: #a9b1ce !important;
  }
  .bg-soft-secondary {
    background-color: #fcfdfe;
  }
  .bg-gradient-secondary {
    background: linear-gradient(50deg, #eaecf3 0, #ebeaf3 100%) !important;
  }
  .bg-translucent-secondary {
    background-color: rgba(211, 215, 230, 0.6) !important;
  }
  a.bg-translucent-secondary:focus,
  a.bg-translucent-secondary:hover,
  button.bg-translucent-secondary:focus,
  button.bg-translucent-secondary:hover {
    background-color: rgba(195, 201, 221, 0.6) !important;
  }
  .bg-light-success {
    background-color: #82d6bc !important;
  }
  a.bg-light-success:focus,
  a.bg-light-success:hover,
  button.bg-light-success:focus,
  button.bg-light-success:hover {
    background-color: #5cc9a7 !important;
  }
  .bg-dark-success {
    background-color: #3cb690 !important;
  }
  a.bg-dark-success:focus,
  a.bg-dark-success:hover,
  button.bg-dark-success:focus,
  button.bg-dark-success:hover {
    background-color: #308f72 !important;
  }
  .bg-soft-success {
    background-color: #ebf9f4;
  }
  .bg-gradient-success {
    background: linear-gradient(50deg, #5cc9a7 0, #5cc9c8 100%) !important;
  }
  .bg-translucent-success {
    background-color: rgba(65, 192, 153, 0.6) !important;
  }
  a.bg-translucent-success:focus,
  a.bg-translucent-success:hover,
  button.bg-translucent-success:focus,
  button.bg-translucent-success:hover {
    background-color: rgba(58, 174, 138, 0.6) !important;
  }
  .bg-light-info {
    background-color: #83cbff !important;
  }
  a.bg-light-info:focus,
  a.bg-light-info:hover,
  button.bg-light-info:focus,
  button.bg-light-info:hover {
    background-color: #50b5ff !important;
  }
  .bg-dark-info {
    background-color: #1d9fff !important;
  }
  a.bg-dark-info:focus,
  a.bg-dark-info:hover,
  button.bg-dark-info:focus,
  button.bg-dark-info:hover {
    background-color: #0086e9 !important;
  }
  .bg-soft-info {
    background-color: #eaf6ff;
  }
  .bg-gradient-info {
    background: linear-gradient(50deg, #50b5ff 0, #5081ff 100%) !important;
  }
  .bg-translucent-info {
    background-color: rgba(44, 166, 255, 0.6) !important;
  }
  a.bg-translucent-info:focus,
  a.bg-translucent-info:hover,
  button.bg-translucent-info:focus,
  button.bg-translucent-info:hover {
    background-color: rgba(19, 155, 255, 0.6) !important;
  }
  .bg-light-warning {
    background-color: #ffcf70 !important;
  }
  a.bg-light-warning:focus,
  a.bg-light-warning:hover,
  button.bg-light-warning:focus,
  button.bg-light-warning:hover {
    background-color: #ffbe3d !important;
  }
  .bg-dark-warning {
    background-color: #ffad0a !important;
  }
  a.bg-dark-warning:focus,
  a.bg-dark-warning:hover,
  button.bg-dark-warning:focus,
  button.bg-dark-warning:hover {
    background-color: #d68e00 !important;
  }
  .bg-soft-warning {
    background-color: #fff7e8;
  }
  .bg-gradient-warning {
    background: linear-gradient(50deg, #ffbe3d 0, #fff83d 100%) !important;
  }
  .bg-translucent-warning {
    background-color: rgba(255, 178, 25, 0.6) !important;
  }
  a.bg-translucent-warning:focus,
  a.bg-translucent-warning:hover,
  button.bg-translucent-warning:focus,
  button.bg-translucent-warning:hover {
    background-color: rgba(255, 169, 0, 0.6) !important;
  }
  .bg-light-danger {
    background-color: #f68692 !important;
  }
  a.bg-light-danger:focus,
  a.bg-light-danger:hover,
  button.bg-light-danger:focus,
  button.bg-light-danger:hover {
    background-color: #d33139 !important;
  }
  .bg-dark-danger {
    background-color: #b62d34 !important;
  }
  a.bg-dark-danger:focus,
  a.bg-dark-danger:hover,
  button.bg-dark-danger:focus,
  button.bg-dark-danger:hover {
    background-color: #d31024 !important;
  }
  .bg-soft-danger {
    background-color: #fdebed;
  }
  .bg-gradient-danger {
    background: linear-gradient(50deg, #d33139 0, #f27657 100%) !important;
  }
  .bg-translucent-danger {
    background-color: rgba(239, 54, 73, 0.6) !important;
  }
  a.bg-translucent-danger:focus,
  a.bg-translucent-danger:hover,
  button.bg-translucent-danger:focus,
  button.bg-translucent-danger:hover {
    background-color: rgba(238, 30, 52, 0.6) !important;
  }
  .bg-light-light {
    background-color: #fff !important;
  }
  a.bg-light-light:focus,
  a.bg-light-light:hover,
  button.bg-light-light:focus,
  button.bg-light-light:hover {
    background-color: #e6e6e6 !important;
  }
  .bg-dark-light {
    background-color: #cacfe0 !important;
  }
  a.bg-dark-light:focus,
  a.bg-dark-light:hover,
  button.bg-dark-light:focus,
  button.bg-dark-light:hover {
    background-color: #a9b1ce !important;
  }
  .bg-soft-light {
    background-color: #fcfdfe;
  }
  .bg-gradient-light {
    background: linear-gradient(50deg, #eaecf3 0, #ebeaf3 100%) !important;
  }
  .bg-translucent-light {
    background-color: rgba(211, 215, 230, 0.6) !important;
  }
  a.bg-translucent-light:focus,
  a.bg-translucent-light:hover,
  button.bg-translucent-light:focus,
  button.bg-translucent-light:hover {
    background-color: rgba(195, 201, 221, 0.6) !important;
  }
  .bg-light-dark {
    background-color: #241e6f !important;
  }
  a.bg-light-dark:focus,
  a.bg-light-dark:hover,
  button.bg-light-dark:focus,
  button.bg-light-dark:hover {
    background-color: #171347 !important;
  }
  .bg-dark-dark {
    background-color: #0a081f !important;
  }
  a.bg-dark-dark:focus,
  a.bg-dark-dark:hover,
  button.bg-dark-dark:focus,
  button.bg-dark-dark:hover {
    background-color: #000 !important;
  }
  .bg-soft-dark {
    background-color: #e3e3e9;
  }
  .bg-gradient-dark {
    background: linear-gradient(50deg, #171347 0, #271347 100%) !important;
  }
  .bg-translucent-dark {
    background-color: rgba(14, 11, 43, 0.6) !important;
  }
  a.bg-translucent-dark:focus,
  a.bg-translucent-dark:hover,
  button.bg-translucent-dark:focus,
  button.bg-translucent-dark:hover {
    background-color: rgba(7, 6, 23, 0.6) !important;
  }
  .bg-light-neutral {
    background-color: #fff !important;
  }
  a.bg-light-neutral:focus,
  a.bg-light-neutral:hover,
  button.bg-light-neutral:focus,
  button.bg-light-neutral:hover {
    background-color: #e6e6e6 !important;
  }
  .bg-dark-neutral {
    background-color: #e6e6e6 !important;
  }
  a.bg-dark-neutral:focus,
  a.bg-dark-neutral:hover,
  button.bg-dark-neutral:focus,
  button.bg-dark-neutral:hover {
    background-color: #ccc !important;
  }
  .bg-soft-neutral {
    background-color: #fff;
  }
  .bg-gradient-neutral {
    background: linear-gradient(50deg, #fff 0, #fff 100%) !important;
  }
  .bg-translucent-neutral {
    background-color: rgba(237, 237, 237, 0.6) !important;
  }
  a.bg-translucent-neutral:focus,
  a.bg-translucent-neutral:hover,
  button.bg-translucent-neutral:focus,
  button.bg-translucent-neutral:hover {
    background-color: rgba(224, 224, 224, 0.6) !important;
  }
  .bg-light-white {
    background-color: #fff !important;
  }
  a.bg-light-white:focus,
  a.bg-light-white:hover,
  button.bg-light-white:focus,
  button.bg-light-white:hover {
    background-color: #e6e6e6 !important;
  }
  .bg-dark-white {
    background-color: #e6e6e6 !important;
  }
  a.bg-dark-white:focus,
  a.bg-dark-white:hover,
  button.bg-dark-white:focus,
  button.bg-dark-white:hover {
    background-color: #ccc !important;
  }
  .bg-soft-white {
    background-color: #fff;
  }
  .bg-gradient-white {
    background: linear-gradient(50deg, #fff 0, #fff 100%) !important;
  }
  .bg-translucent-white {
    background-color: rgba(237, 237, 237, 0.6) !important;
  }
  a.bg-translucent-white:focus,
  a.bg-translucent-white:hover,
  button.bg-translucent-white:focus,
  button.bg-translucent-white:hover {
    background-color: rgba(224, 224, 224, 0.6) !important;
  }
  .bg-blue {
    background-color: #008aff !important;
  }
  a.bg-blue:focus,
  a.bg-blue:hover,
  button.bg-blue:focus,
  button.bg-blue:hover {
    background-color: #b62d34 !important;
  }
  .bg-gradient-blue {
    background: linear-gradient(50deg, #008aff 0, #003eff 100%) !important;
  }
  .bg-indigo {
    background-color: #6e00ff !important;
  }
  a.bg-indigo:focus,
  a.bg-indigo:hover,
  button.bg-indigo:focus,
  button.bg-indigo:hover {
    background-color: #5800cc !important;
  }
  .bg-gradient-indigo {
    background: linear-gradient(50deg, #6e00ff 0, #b0f 100%) !important;
  }
  .bg-purple {
    background-color: #510fa8 !important;
  }
  a.bg-purple:focus,
  a.bg-purple:hover,
  button.bg-purple:focus,
  button.bg-purple:hover {
    background-color: #3a0b79 !important;
  }
  .bg-gradient-purple {
    background: linear-gradient(50deg, #510fa8 0, #7f0fa8 100%) !important;
  }
  .bg-pink {
    background-color: #f074ad !important;
  }
  a.bg-pink:focus,
  a.bg-pink:hover,
  button.bg-pink:focus,
  button.bg-pink:hover {
    background-color: #eb4692 !important;
  }
  .bg-gradient-pink {
    background: linear-gradient(50deg, #f074ad 0, #f07488 100%) !important;
  }
  .bg-red {
    background-color: #d33139 !important;
  }
  a.bg-red:focus,
  a.bg-red:hover,
  button.bg-red:focus,
  button.bg-red:hover {
    background-color: #b62d34 !important;
  }
  .bg-gradient-red {
    background: linear-gradient(50deg, #d33139 0, #f27657 100%) !important;
  }
  .bg-orange {
    background-color: #ffbe3d !important;
  }
  a.bg-orange:focus,
  a.bg-orange:hover,
  button.bg-orange:focus,
  button.bg-orange:hover {
    background-color: #ffad0a !important;
  }
  .bg-gradient-orange {
    background: linear-gradient(50deg, #ffbe3d 0, #fff83d 100%) !important;
  }
  .bg-yellow {
    background-color: #ffc431 !important;
  }
  a.bg-yellow:focus,
  a.bg-yellow:hover,
  button.bg-yellow:focus,
  button.bg-yellow:hover {
    background-color: #fdb500 !important;
  }
  .bg-gradient-yellow {
    background: linear-gradient(50deg, #ffc431 0, #fcff31 100%) !important;
  }
  .bg-green {
    background-color: #5cc9a7 !important;
  }
  a.bg-green:focus,
  a.bg-green:hover,
  button.bg-green:focus,
  button.bg-green:hover {
    background-color: #3cb690 !important;
  }
  .bg-gradient-green {
    background: linear-gradient(50deg, #5cc9a7 0, #5cc9c8 100%) !important;
  }
  .bg-teal {
    background-color: #50b5ff !important;
  }
  a.bg-teal:focus,
  a.bg-teal:hover,
  button.bg-teal:focus,
  button.bg-teal:hover {
    background-color: #1d9fff !important;
  }
  .bg-gradient-teal {
    background: linear-gradient(50deg, #50b5ff 0, #5081ff 100%) !important;
  }
  .bg-cyan {
    background-color: #4bd6e5 !important;
  }
  a.bg-cyan:focus,
  a.bg-cyan:hover,
  button.bg-cyan:focus,
  button.bg-cyan:hover {
    background-color: #20cbdd !important;
  }
  .bg-gradient-cyan {
    background: linear-gradient(50deg, #4bd6e5 0, #4ba8e5 100%) !important;
  }
  .bg-white {
    background-color: #fff !important;
  }
  a.bg-white:focus,
  a.bg-white:hover,
  button.bg-white:focus,
  button.bg-white:hover {
    background-color: #e6e6e6 !important;
  }
  .bg-gradient-white {
    background: linear-gradient(50deg, #fff 0, #fff 100%) !important;
  }
  .bg-gray {
    background-color: #718096 !important;
  }
  a.bg-gray:focus,
  a.bg-gray:hover,
  button.bg-gray:focus,
  button.bg-gray:hover {
    background-color: #5a677a !important;
  }
  .bg-gradient-gray {
    background: linear-gradient(50deg, #718096 0, #717596 100%) !important;
  }
  .bg-gray-dark {
    background-color: #2d3748 !important;
  }
  a.bg-gray-dark:focus,
  a.bg-gray-dark:hover,
  button.bg-gray-dark:focus,
  button.bg-gray-dark:hover {
    background-color: #191f29 !important;
  }
  .bg-gradient-gray-dark {
    background: linear-gradient(50deg, #2d3748 0, #2d2f48 100%) !important;
  }
  .bg-section-primary {
    background-color: #fff !important;
  }
  .bg-section-secondary {
    background-color: #fafbfe !important;
  }
  .bg-section-light {
    background-color: #cbd5e0 !important;
  }
  .bg-section-dark {
    background-color: #121212 !important;
  }
  .bg-brand-facebook {
    background-color: #3b5999 !important;
  }
  a.bg-brand-facebook:focus,
  a.bg-brand-facebook:hover,
  button.bg-brand-facebook:focus,
  button.bg-brand-facebook:hover {
    background-color: #2d4474 !important;
  }
  .bg-brand-twitter {
    background-color: #1da1f2 !important;
  }
  a.bg-brand-twitter:focus,
  a.bg-brand-twitter:hover,
  button.bg-brand-twitter:focus,
  button.bg-brand-twitter:hover {
    background-color: #0c85d0 !important;
  }
  .bg-brand-google-plus {
    background-color: #dd4b39 !important;
  }
  a.bg-brand-google-plus:focus,
  a.bg-brand-google-plus:hover,
  button.bg-brand-google-plus:focus,
  button.bg-brand-google-plus:hover {
    background-color: #c23321 !important;
  }
  .bg-brand-instagram {
    background-color: #e4405f !important;
  }
  a.bg-brand-instagram:focus,
  a.bg-brand-instagram:hover,
  button.bg-brand-instagram:focus,
  button.bg-brand-instagram:hover {
    background-color: #d31e40 !important;
  }
  .bg-brand-pinterest {
    background-color: #bd081c !important;
  }
  a.bg-brand-pinterest:focus,
  a.bg-brand-pinterest:hover,
  button.bg-brand-pinterest:focus,
  button.bg-brand-pinterest:hover {
    background-color: #8c0615 !important;
  }
  .bg-brand-youtube {
    background-color: #cd201f !important;
  }
  a.bg-brand-youtube:focus,
  a.bg-brand-youtube:hover,
  button.bg-brand-youtube:focus,
  button.bg-brand-youtube:hover {
    background-color: #a11918 !important;
  }
  .bg-brand-slack {
    background-color: #3aaf85 !important;
  }
  a.bg-brand-slack:focus,
  a.bg-brand-slack:hover,
  button.bg-brand-slack:focus,
  button.bg-brand-slack:hover {
    background-color: #2d8968 !important;
  }
  .bg-brand-dribbble {
    background-color: #ea4c89 !important;
  }
  a.bg-brand-dribbble:focus,
  a.bg-brand-dribbble:hover,
  button.bg-brand-dribbble:focus,
  button.bg-brand-dribbble:hover {
    background-color: #e51e6b !important;
  }
  .bg-brand-github {
    background-color: #222 !important;
  }
  a.bg-brand-github:focus,
  a.bg-brand-github:hover,
  button.bg-brand-github:focus,
  button.bg-brand-github:hover {
    background-color: #090909 !important;
  }
  .bg-brand-vimeo {
    background-color: #04a0f0 !important;
  }
  a.bg-brand-vimeo:focus,
  a.bg-brand-vimeo:hover,
  button.bg-brand-vimeo:focus,
  button.bg-brand-vimeo:hover {
    background-color: #037fbe !important;
  }
  .bg-gray-100 {
    background-color: #fafbfe !important;
  }
  a.bg-gray-100:focus,
  a.bg-gray-100:hover,
  button.bg-gray-100:focus,
  button.bg-gray-100:hover {
    background-color: #d0d9f6 !important;
  }
  .bg-gray-200 {
    background-color: #eaecf3 !important;
  }
  a.bg-gray-200:focus,
  a.bg-gray-200:hover,
  button.bg-gray-200:focus,
  button.bg-gray-200:hover {
    background-color: #cacfe0 !important;
  }
  .bg-gray-300 {
    background-color: #e2e8f0 !important;
  }
  a.bg-gray-300:focus,
  a.bg-gray-300:hover,
  button.bg-gray-300:focus,
  button.bg-gray-300:hover {
    background-color: #c0cddf !important;
  }
  .bg-gray-400 {
    background-color: #cbd5e0 !important;
  }
  a.bg-gray-400:focus,
  a.bg-gray-400:hover,
  button.bg-gray-400:focus,
  button.bg-gray-400:hover {
    background-color: #abbbcd !important;
  }
  .bg-gray-500 {
    background-color: #a0aec0 !important;
  }
  a.bg-gray-500:focus,
  a.bg-gray-500:hover,
  button.bg-gray-500:focus,
  button.bg-gray-500:hover {
    background-color: #8194ac !important;
  }
  .bg-gray-600 {
    background-color: #718096 !important;
  }
  a.bg-gray-600:focus,
  a.bg-gray-600:hover,
  button.bg-gray-600:focus,
  button.bg-gray-600:hover {
    background-color: #5a677a !important;
  }
  .bg-gray-700 {
    background-color: #4a5568 !important;
  }
  a.bg-gray-700:focus,
  a.bg-gray-700:hover,
  button.bg-gray-700:focus,
  button.bg-gray-700:hover {
    background-color: #353d4a !important;
  }
  .bg-gray-800 {
    background-color: #2d3748 !important;
  }
  a.bg-gray-800:focus,
  a.bg-gray-800:hover,
  button.bg-gray-800:focus,
  button.bg-gray-800:hover {
    background-color: #191f29 !important;
  }
  .bg-gray-900 {
    background-color: #273444 !important;
  }
  a.bg-gray-900:focus,
  a.bg-gray-900:hover,
  button.bg-gray-900:focus,
  button.bg-gray-900:hover {
    background-color: #141b24 !important;
  }
  .bg-black {
    background-color: #1f2d3d !important;
  }
  a.bg-black:focus,
  a.bg-black:hover,
  button.bg-black:focus,
  button.bg-black:hover {
    background-color: #0e141b !important;
  }
  .fill-primary {
    fill: #008aff !important;
  }
  .fill-light-primary {
    fill: #33a1ff !important;
  }
  .fill-dark-primary {
    fill: #b62d34 !important;
  }
  .stroke-primary {
    stroke: #008aff !important;
  }
  .fill-soft-primary {
    fill: #e0f1ff;
  }
  .fill-secondary {
    fill: #eaecf3 !important;
  }
  .fill-light-secondary {
    fill: #fff !important;
  }
  .fill-dark-secondary {
    fill: #cacfe0 !important;
  }
  .stroke-secondary {
    stroke: #eaecf3 !important;
  }
  .fill-soft-secondary {
    fill: #fcfdfe;
  }
  .fill-success {
    fill: #5cc9a7 !important;
  }
  .fill-light-success {
    fill: #82d6bc !important;
  }
  .fill-dark-success {
    fill: #3cb690 !important;
  }
  .stroke-success {
    stroke: #5cc9a7 !important;
  }
  .fill-soft-success {
    fill: #ebf9f4;
  }
  .fill-info {
    fill: #50b5ff !important;
  }
  .fill-light-info {
    fill: #83cbff !important;
  }
  .fill-dark-info {
    fill: #1d9fff !important;
  }
  .stroke-info {
    stroke: #50b5ff !important;
  }
  .fill-soft-info {
    fill: #eaf6ff;
  }
  .fill-warning {
    fill: #ffbe3d !important;
  }
  .fill-light-warning {
    fill: #ffcf70 !important;
  }
  .fill-dark-warning {
    fill: #ffad0a !important;
  }
  .stroke-warning {
    stroke: #ffbe3d !important;
  }
  .fill-soft-warning {
    fill: #fff7e8;
  }
  .fill-danger {
    fill: #d33139 !important;
  }
  .fill-light-danger {
    fill: #f68692 !important;
  }
  .fill-dark-danger {
    fill: #b62d34 !important;
  }
  .stroke-danger {
    stroke: #d33139 !important;
  }
  .fill-soft-danger {
    fill: #fdebed;
  }
  .fill-light {
    fill: #eaecf3 !important;
  }
  .fill-light-light {
    fill: #fff !important;
  }
  .fill-dark-light {
    fill: #cacfe0 !important;
  }
  .stroke-light {
    stroke: #eaecf3 !important;
  }
  .fill-soft-light {
    fill: #fcfdfe;
  }
  .fill-dark {
    fill: #171347 !important;
  }
  .fill-light-dark {
    fill: #241e6f !important;
  }
  .fill-dark-dark {
    fill: #0a081f !important;
  }
  .stroke-dark {
    stroke: #171347 !important;
  }
  .fill-soft-dark {
    fill: #e3e3e9;
  }
  .fill-neutral {
    fill: #fff !important;
  }
  .fill-light-neutral {
    fill: #fff !important;
  }
  .fill-dark-neutral {
    fill: #e6e6e6 !important;
  }
  .stroke-neutral {
    stroke: #fff !important;
  }
  .fill-soft-neutral {
    fill: #fff;
  }
  .fill-white {
    fill: #fff !important;
  }
  .fill-light-white {
    fill: #fff !important;
  }
  .fill-dark-white {
    fill: #e6e6e6 !important;
  }
  .stroke-white {
    stroke: #fff !important;
  }
  .fill-soft-white {
    fill: #fff;
  }
  .fill-blue {
    fill: #008aff !important;
  }
  .stroke-blue {
    stroke: #008aff !important;
  }
  .fill-indigo {
    fill: #6e00ff !important;
  }
  .stroke-indigo {
    stroke: #6e00ff !important;
  }
  .fill-purple {
    fill: #510fa8 !important;
  }
  .stroke-purple {
    stroke: #510fa8 !important;
  }
  .fill-pink {
    fill: #f074ad !important;
  }
  .stroke-pink {
    stroke: #f074ad !important;
  }
  .fill-red {
    fill: #d33139 !important;
  }
  .stroke-red {
    stroke: #d33139 !important;
  }
  .fill-orange {
    fill: #ffbe3d !important;
  }
  .stroke-orange {
    stroke: #ffbe3d !important;
  }
  .fill-yellow {
    fill: #ffc431 !important;
  }
  .stroke-yellow {
    stroke: #ffc431 !important;
  }
  .fill-green {
    fill: #5cc9a7 !important;
  }
  .stroke-green {
    stroke: #5cc9a7 !important;
  }
  .fill-teal {
    fill: #50b5ff !important;
  }
  .stroke-teal {
    stroke: #50b5ff !important;
  }
  .fill-cyan {
    fill: #4bd6e5 !important;
  }
  .stroke-cyan {
    stroke: #4bd6e5 !important;
  }
  .fill-white {
    fill: #fff !important;
  }
  .stroke-white {
    stroke: #fff !important;
  }
  .fill-gray {
    fill: #718096 !important;
  }
  .stroke-gray {
    stroke: #718096 !important;
  }
  .fill-gray-dark {
    fill: #2d3748 !important;
  }
  .stroke-gray-dark {
    stroke: #2d3748 !important;
  }
  .fill-primary-100 {
    fill: #fff !important;
  }
  .fill-primary-200 {
    fill: #cce8ff !important;
  }
  .fill-primary-300 {
    fill: #99d0ff !important;
  }
  .fill-primary-400 {
    fill: #66b9ff !important;
  }
  .fill-primary-500 {
    fill: #33a1ff !important;
  }
  .fill-primary-600 {
    fill: #1a96ff !important;
  }
  .stroke-primary-100 {
    stroke: #fff !important;
  }
  .stroke-primary-200 {
    stroke: #cce8ff !important;
  }
  .stroke-primary-300 {
    stroke: #99d0ff !important;
  }
  .stroke-primary-400 {
    stroke: #66b9ff !important;
  }
  .stroke-primary-500 {
    stroke: #33a1ff !important;
  }
  .stroke-primary-600 {
    stroke: #1a96ff !important;
  }
  .fill-section-primary {
    fill: #f5f5f5 !important;
  }
  .stroke-section-primary {
    stroke: #fff !important;
  }
  .fill-section-secondary {
    fill: #fafbfe !important;
  }
  .stroke-section-secondary {
    stroke: #fafbfe !important;
  }
  .fill-section-light {
    fill: #cbd5e0 !important;
  }
  .stroke-section-light {
    stroke: #cbd5e0 !important;
  }
  .fill-section-dark {
    fill: #0e0b2b !important;
  }
  .stroke-section-dark {
    stroke: #0e0b2b !important;
  }
  .bg-section-fade {
    background: linear-gradient(
      177.86deg,
      #fafbfe 0,
      rgba(255, 255, 255, 0) 100%
    );
  }
  .bg-cover {
    position: relative;
    background-repeat: no-repeat;
  }
  .bg-absolute-cover {
    background-repeat: no-repeat;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
  .bg-absolute-cover svg {
    height: 1000px;
    pointer-events: none;
  }
  @media (max-width: 1199.98px) {
    .bg-lg-cover {
      background-image: none !important;
    }
  }
  @media (max-width: 991.98px) {
    .bg-md-cover {
      background-image: none !important;
    }
  }
  .bg-size--cover {
    background: no-repeat center center/cover;
  }
  .bg-size--contain {
    background-size: contain;
  }
  .bg-text {
    color: rgba(255, 255, 255, 0.2);
    position: absolute;
    top: 50%;
    left: 0;
    white-space: nowrap;
    transform: translate(0, -50%);
    text-transform: uppercase;
    font-size: 40rem;
    font-weight: 700;
  }
  .bg-inner--text {
    height: 1em;
    line-height: 1em;
    transform: none;
    opacity: 1;
    transition: 2s 0s;
    display: block;
    animation: text_slide 40s linear infinite;
    width: auto;
    max-width: none;
    white-space: nowrap;
    display: inline-block;
  }
  @media (max-width: 991.98px) {
    .bg-img-holder {
      background-image: none !important;
    }
    .bg-img-holder img {
      max-width: 100%;
    }
  }
  @media (min-width: 992px) {
    .bg-img-holder {
      position: absolute;
      height: 100%;
      min-height: 20rem;
      background-repeat: no-repeat;
      z-index: -1;
    }
    .bg-img-holder img {
      display: none;
    }
  }
  .img-bg:after {
    position: absolute;
    content: "";
    background: none no-repeat 50%;
    background-image: none;
    background-size: auto auto;
    background-size: contain;
    width: 783px;
    height: 725px;
    left: 31px;
    top: -168px;
    z-index: 1;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='783' height='726' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M606.14 725.179c144.64-.87 225.447-587.194 144.64-694.046-73.915-97.74-579.852 50.733-716.756 222.443-12.763 16.009-22.32 32.22-28.019 48.451-66.83 190.337 442.822 424.098 600.136 423.152z' fill='%23eaecf3' fill-rule='evenodd'/%3E%3C/svg%3E");
  }
  .img-bg img {
    position: relative;
    z-index: 2;
  }
  .img-as-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .blend-mode--multiply {
    mix-blend-mode: multiply;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .blend-mode--multiply:after {
      content: "";
      background: rgba(0, 0, 214, 0.5);
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
    }
  }
  .blend-mode--color-burn {
    mix-blend-mode: color-burn;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .blend-mode--color-burn:after {
      content: "";
      background: rgba(0, 0, 214, 0.5);
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
    }
  }
  .border-sm {
    border-width: 2px !important;
  }
  .border-md {
    border-width: 3px !important;
  }
  .border-lg {
    border-width: 4px !important;
  }
  .border-dashed {
    border-style: dashed !important;
  }
  .border-dotted {
    border-style: dotted !important;
  }
  .border-groove {
    border-style: groove !important;
  }
  .rounded-xl {
    border-radius: 0.75rem !important;
  }
  .rounded-left-pill {
    border-top-left-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important;
  }
  .rounded-right-pill {
    border-top-right-radius: 50rem !important;
    border-bottom-right-radius: 50rem !important;
  }
  .rounded-bottom-left {
    border-bottom-left-radius: 90px;
  }
  .border-soft-primary {
    border-color: #66b9ff !important;
  }
  .border-dark-primary {
    border-color: #007ce6 !important;
  }
  .border-soft-secondary {
    border-color: #fff !important;
  }
  .border-dark-secondary {
    border-color: #daddea !important;
  }
  .border-soft-success {
    border-color: #a9e2d0 !important;
  }
  .border-dark-success {
    border-color: #49c39d !important;
  }
  .border-soft-info {
    border-color: #b6e0ff !important;
  }
  .border-dark-info {
    border-color: #37aaff !important;
  }
  .border-soft-warning {
    border-color: #ffe0a3 !important;
  }
  .border-dark-warning {
    border-color: #ffb524 !important;
  }
  .border-soft-danger {
    border-color: #f9b6bd !important;
  }
  .border-dark-danger {
    border-color: #f03f52 !important;
  }
  .border-soft-light {
    border-color: #fff !important;
  }
  .border-dark-light {
    border-color: #daddea !important;
  }
  .border-soft-dark {
    border-color: #312997 !important;
  }
  .border-dark-dark {
    border-color: #100e33 !important;
  }
  .border-soft-neutral {
    border-color: #fff !important;
  }
  .border-dark-neutral {
    border-color: #f2f2f2 !important;
  }
  .border-soft-white {
    border-color: #fff !important;
  }
  .border-dark-white {
    border-color: #f2f2f2 !important;
  }
  .delimiter-bottom,
  .delimiter-top {
    position: relative;
    border: 0;
  }
  .delimiter-top:before {
    content: "";
    display: block;
    width: 80%;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -40%;
    height: 1px;
    background: radial-gradient(
      ellipse at center,
      #d1dbe7 0,
      rgba(255, 255, 255, 0) 75%
    );
  }
  .delimiter-bottom:after {
    content: "";
    display: block;
    width: 80%;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -40%;
    height: 1px;
    background: radial-gradient(
      ellipse at center,
      #d1dbe7 0,
      rgba(255, 255, 255, 0) 75%
    );
  }
  .delimiter-dark:after,
  .delimiter-dark:before {
    background: radial-gradient(
      ellipse at center,
      rgba(255, 255, 255, 0.2) 0,
      rgba(31, 45, 61, 0) 75%
    );
  }
  .floating {
    animation: floating 3s ease infinite;
    will-change: transform;
  }
  .floating:hover {
    animation-play-state: paused;
  }
  .floating-lg {
    animation: floating-lg 3s ease infinite;
  }
  .floating-sm {
    animation: floating-sm 3s ease infinite;
  }
  @keyframes floating-lg {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(15px);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes floating {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes floating-sm {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(5px);
    }
    100% {
      transform: translateY(0);
    }
  }
  .floatfix:after,
  .floatfix:before {
    content: "";
    display: table;
  }
  .floatfix:after {
    clear: both;
  }
  .overflow-visible {
    overflow: visible !important;
  }
  .overflow-visible-x {
    overflow-x: visible !important;
  }
  .overflow-visible-y {
    overflow-y: visible !important;
  }
  .overflow-hidden {
    overflow: hidden !important;
  }
  .overflow-hidden-x {
    overflow-x: hidden !important;
  }
  .overflow-hidden-y {
    overflow-y: hidden !important;
  }
  .overflow-scroll-x {
    overflow-x: scroll !important;
  }
  .overflow-scroll-y {
    overflow-y: scroll !important;
  }
  [class*="alpha"] {
    transition: all 0.2s ease;
  }
  [class*="faded"] {
    position: relative;
  }
  [class*="faded"]:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 30%;
  }
  .faded-top:before {
    top: 0;
    background: linear-gradient(to top, transparent 0, rgba(0, 0, 0, 0.6) 100%);
  }
  .faded-bottom:before {
    bottom: 0;
    background: linear-gradient(
      to bottom,
      transparent 0,
      rgba(0, 0, 0, 0.6) 100%
    );
  }
  [class*="hover-"] {
    transition: all 0.2s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    [class*="hover-"] {
      transition: none;
    }
  }
  .img-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .img-grayscale {
    filter: grayscale(100%);
  }
  .img-saturate {
    filter: saturate(150%);
  }
  .hover-opacity-0:hover,
  .opacity-0,
  .opacity-container:hover .hover-opacity-0 {
    opacity: 0;
  }
  .hover-opacity-1:hover,
  .opacity-1,
  .opacity-container:hover .hover-opacity-1 {
    opacity: 0.1;
  }
  .hover-opacity-2:hover,
  .opacity-2,
  .opacity-container:hover .hover-opacity-2 {
    opacity: 0.2;
  }
  .hover-opacity-3:hover,
  .opacity-3,
  .opacity-container:hover .hover-opacity-3 {
    opacity: 0.3;
  }
  .hover-opacity-4:hover,
  .opacity-4,
  .opacity-container:hover .hover-opacity-4 {
    opacity: 0.4;
  }
  .hover-opacity-5:hover,
  .opacity-5,
  .opacity-container:hover .hover-opacity-5 {
    opacity: 0.5;
  }
  .hover-opacity-6:hover,
  .opacity-6,
  .opacity-container:hover .hover-opacity-6 {
    opacity: 0.6;
  }
  .hover-opacity-7:hover,
  .opacity-7,
  .opacity-container:hover .hover-opacity-7 {
    opacity: 0.7;
  }
  .hover-opacity-8:hover,
  .opacity-8,
  .opacity-container:hover .hover-opacity-8 {
    opacity: 0.8;
  }
  .hover-opacity-9:hover,
  .opacity-9,
  .opacity-container:hover .hover-opacity-9 {
    opacity: 0.9;
  }
  .hover-opacity-10:hover,
  .opacity-10,
  .opacity-container:hover .hover-opacity-10 {
    opacity: 1;
  }
  .position-absolute {
    position: absolute !important;
  }
  .position-relative {
    position: relative !important;
  }
  .position-static {
    position: static !important;
  }
  @media (min-width: 576px) {
    .position-sm-absolute {
      position: absolute !important;
    }
    .position-sm-relative {
      position: relative !important;
    }
    .position-sm-static {
      position: static !important;
    }
  }
  @media (min-width: 768px) {
    .position-md-absolute {
      position: absolute !important;
    }
    .position-md-relative {
      position: relative !important;
    }
    .position-md-static {
      position: static !important;
    }
  }
  @media (min-width: 992px) {
    .position-lg-absolute {
      position: absolute !important;
    }
    .position-lg-relative {
      position: relative !important;
    }
    .position-lg-static {
      position: static !important;
    }
  }
  @media (min-width: 1200px) {
    .position-xl-absolute {
      position: absolute !important;
    }
    .position-xl-relative {
      position: relative !important;
    }
    .position-xl-static {
      position: static !important;
    }
  }
  .scrollable-x {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .scrollable-y {
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .h-100vh {
    height: 100vh !important;
  }
  .mw-25 {
    max-width: 25% !important;
  }
  .mw-50 {
    max-width: 50% !important;
  }
  .mw-75 {
    max-width: 75% !important;
  }
  .mw-100 {
    max-width: 100% !important;
  }
  .mw-auto {
    max-width: auto !important;
  }
  .mw-10 {
    max-width: 10% !important;
  }
  .mw-15 {
    max-width: 15% !important;
  }
  .mw-80 {
    max-width: 80% !important;
  }
  .mw-90 {
    max-width: 90% !important;
  }
  .mw-110 {
    max-width: 110% !important;
  }
  .mw-120 {
    max-width: 120% !important;
  }
  .mw-130 {
    max-width: 130% !important;
  }
  @media (min-width: 576px) {
    .mw-sm-25 {
      max-width: 25% !important;
    }
    .mw-sm-50 {
      max-width: 50% !important;
    }
    .mw-sm-75 {
      max-width: 75% !important;
    }
    .mw-sm-100 {
      max-width: 100% !important;
    }
    .mw-sm-auto {
      max-width: auto !important;
    }
    .mw-sm-10 {
      max-width: 10% !important;
    }
    .mw-sm-15 {
      max-width: 15% !important;
    }
    .mw-sm-80 {
      max-width: 80% !important;
    }
    .mw-sm-90 {
      max-width: 90% !important;
    }
    .mw-sm-110 {
      max-width: 110% !important;
    }
    .mw-sm-120 {
      max-width: 120% !important;
    }
    .mw-sm-130 {
      max-width: 130% !important;
    }
  }
  @media (min-width: 768px) {
    .mw-md-25 {
      max-width: 25% !important;
    }
    .mw-md-50 {
      max-width: 50% !important;
    }
    .mw-md-75 {
      max-width: 75% !important;
    }
    .mw-md-100 {
      max-width: 100% !important;
    }
    .mw-md-auto {
      max-width: auto !important;
    }
    .mw-md-10 {
      max-width: 10% !important;
    }
    .mw-md-15 {
      max-width: 15% !important;
    }
    .mw-md-80 {
      max-width: 80% !important;
    }
    .mw-md-90 {
      max-width: 90% !important;
    }
    .mw-md-110 {
      max-width: 110% !important;
    }
    .mw-md-120 {
      max-width: 120% !important;
    }
    .mw-md-130 {
      max-width: 130% !important;
    }
  }
  @media (min-width: 992px) {
    .mw-lg-25 {
      max-width: 25% !important;
    }
    .mw-lg-50 {
      max-width: 50% !important;
    }
    .mw-lg-75 {
      max-width: 75% !important;
    }
    .mw-lg-100 {
      max-width: 100% !important;
    }
    .mw-lg-auto {
      max-width: auto !important;
    }
    .mw-lg-10 {
      max-width: 10% !important;
    }
    .mw-lg-15 {
      max-width: 15% !important;
    }
    .mw-lg-80 {
      max-width: 80% !important;
    }
    .mw-lg-90 {
      max-width: 90% !important;
    }
    .mw-lg-110 {
      max-width: 110% !important;
    }
    .mw-lg-120 {
      max-width: 120% !important;
    }
    .mw-lg-130 {
      max-width: 130% !important;
    }
  }
  @media (min-width: 1200px) {
    .mw-xl-25 {
      max-width: 25% !important;
    }
    .mw-xl-50 {
      max-width: 50% !important;
    }
    .mw-xl-75 {
      max-width: 75% !important;
    }
    .mw-xl-100 {
      max-width: 100% !important;
    }
    .mw-xl-auto {
      max-width: auto !important;
    }
    .mw-xl-10 {
      max-width: 10% !important;
    }
    .mw-xl-15 {
      max-width: 15% !important;
    }
    .mw-xl-80 {
      max-width: 80% !important;
    }
    .mw-xl-90 {
      max-width: 90% !important;
    }
    .mw-xl-110 {
      max-width: 110% !important;
    }
    .mw-xl-120 {
      max-width: 120% !important;
    }
    .mw-xl-130 {
      max-width: 130% !important;
    }
  }
  .row-grid + .row-grid {
    margin-top: 3rem;
  }
  .row-grid > [class*="col-"] + [class*="col-"] {
    margin-top: 3rem;
  }
  @media (min-width: 992px) {
    .img-fluid.hero {
      // height: 750px !important;
    }
    .row.row-grid > [class*="col-lg-"] + [class*="col-lg-"] {
      margin-top: 0;
    }
  }
  @media (min-width: 768px) {
    .row.row-grid > [class*="col-md-"] + [class*="col-md-"] {
      margin-top: 0;
    }
  }
  @media (min-width: 576px) {
    .row.row-grid > [class*="col-sm-"] + [class*="col-sm-"] {
      margin-top: 0;
    }
  }
  .top-0 {
    top: 0 !important;
  }
  .right-0 {
    right: 0 !important;
  }
  .bottom-0 {
    bottom: 0 !important;
  }
  .left-0 {
    left: 0 !important;
  }
  .top-n0 {
    top: 0 !important;
  }
  .right-n0 {
    right: 0 !important;
  }
  .bottom-n0 {
    bottom: 0 !important;
  }
  .left-n0 {
    left: 0 !important;
  }
  .top-1 {
    top: 0.25rem !important;
  }
  .right-1 {
    right: 0.25rem !important;
  }
  .bottom-1 {
    bottom: 0.25rem !important;
  }
  .left-1 {
    left: 0.25rem !important;
  }
  .top-n1 {
    top: -0.25rem !important;
  }
  .right-n1 {
    right: -0.25rem !important;
  }
  .bottom-n1 {
    bottom: -0.25rem !important;
  }
  .left-n1 {
    left: -0.25rem !important;
  }
  .top-2 {
    top: 0.5rem !important;
  }
  .right-2 {
    right: 0.5rem !important;
  }
  .bottom-2 {
    bottom: 0.5rem !important;
  }
  .left-2 {
    left: 0.5rem !important;
  }
  .top-n2 {
    top: -0.5rem !important;
  }
  .right-n2 {
    right: -0.5rem !important;
  }
  .bottom-n2 {
    bottom: -0.5rem !important;
  }
  .left-n2 {
    left: -0.5rem !important;
  }
  .top-3 {
    top: 1rem !important;
  }
  .right-3 {
    right: 1rem !important;
  }
  .bottom-3 {
    bottom: 1rem !important;
  }
  .left-3 {
    left: 1rem !important;
  }
  .top-n3 {
    top: -1rem !important;
  }
  .right-n3 {
    right: -1rem !important;
  }
  .bottom-n3 {
    bottom: -1rem !important;
  }
  .left-n3 {
    left: -1rem !important;
  }
  .top-4 {
    top: 1.5rem !important;
  }
  .right-4 {
    right: 1.5rem !important;
  }
  .bottom-4 {
    bottom: 1.5rem !important;
  }
  .left-4 {
    left: 1.5rem !important;
  }
  .top-n4 {
    top: -1.5rem !important;
  }
  .right-n4 {
    right: -1.5rem !important;
  }
  .bottom-n4 {
    bottom: -1.5rem !important;
  }
  .left-n4 {
    left: -1.5rem !important;
  }
  .top-5 {
    top: 3rem !important;
  }
  .right-5 {
    right: 3rem !important;
  }
  .bottom-5 {
    bottom: 3rem !important;
  }
  .left-5 {
    left: 3rem !important;
  }
  .top-n5 {
    top: -3rem !important;
  }
  .right-n5 {
    right: -3rem !important;
  }
  .bottom-n5 {
    bottom: -3rem !important;
  }
  .left-n5 {
    left: -3rem !important;
  }
  .top-6 {
    top: 4.5rem !important;
  }
  .right-6 {
    right: 4.5rem !important;
  }
  .bottom-6 {
    bottom: 4.5rem !important;
  }
  .left-6 {
    left: 4.5rem !important;
  }
  .top-n6 {
    top: -4.5rem !important;
  }
  .right-n6 {
    right: -4.5rem !important;
  }
  .bottom-n6 {
    bottom: -4.5rem !important;
  }
  .left-n6 {
    left: -4.5rem !important;
  }
  .top-7 {
    top: 6rem !important;
  }
  .right-7 {
    right: 6rem !important;
  }
  .bottom-7 {
    bottom: 6rem !important;
  }
  .left-7 {
    left: 6rem !important;
  }
  .top-n7 {
    top: -6rem !important;
  }
  .right-n7 {
    right: -6rem !important;
  }
  .bottom-n7 {
    bottom: -6rem !important;
  }
  .left-n7 {
    left: -6rem !important;
  }
  .top-8 {
    top: 7.5rem !important;
  }
  .right-8 {
    right: 7.5rem !important;
  }
  .bottom-8 {
    bottom: 7.5rem !important;
  }
  .left-8 {
    left: 7.5rem !important;
  }
  .top-n8 {
    top: -7.5rem !important;
  }
  .right-n8 {
    right: -7.5rem !important;
  }
  .bottom-n8 {
    bottom: -7.5rem !important;
  }
  .left-n8 {
    left: -7.5rem !important;
  }
  .top-9 {
    top: 9rem !important;
  }
  .right-9 {
    right: 9rem !important;
  }
  .bottom-9 {
    bottom: 9rem !important;
  }
  .left-9 {
    left: 9rem !important;
  }
  .top-n9 {
    top: -9rem !important;
  }
  .right-n9 {
    right: -9rem !important;
  }
  .bottom-n9 {
    bottom: -9rem !important;
  }
  .left-n9 {
    left: -9rem !important;
  }
  .top-10 {
    top: 10.5rem !important;
  }
  .right-10 {
    right: 10.5rem !important;
  }
  .bottom-10 {
    bottom: 10.5rem !important;
  }
  .left-10 {
    left: 10.5rem !important;
  }
  .top-n10 {
    top: -10.5rem !important;
  }
  .right-n10 {
    right: -10.5rem !important;
  }
  .bottom-n10 {
    bottom: -10.5rem !important;
  }
  .left-n10 {
    left: -10.5rem !important;
  }
  .top-17 {
    top: 21rem !important;
  }
  .right-17 {
    right: 21rem !important;
  }
  .bottom-17 {
    bottom: 21rem !important;
  }
  .left-17 {
    left: 21rem !important;
  }
  .top-n17 {
    top: -21rem !important;
  }
  .right-n17 {
    right: -21rem !important;
  }
  .bottom-n17 {
    bottom: -21rem !important;
  }
  .left-n17 {
    left: -21rem !important;
  }
  @media (min-width: 576px) {
    .top-sm-0 {
      top: 0 !important;
    }
    .right-sm-0 {
      right: 0 !important;
    }
    .bottom-sm-0 {
      bottom: 0 !important;
    }
    .left-sm-0 {
      left: 0 !important;
    }
    .top-sm-n0 {
      top: 0 !important;
    }
    .right-sm-n0 {
      right: 0 !important;
    }
    .bottom-sm-n0 {
      bottom: 0 !important;
    }
    .left-sm-n0 {
      left: 0 !important;
    }
    .top-sm-1 {
      top: 0.25rem !important;
    }
    .right-sm-1 {
      right: 0.25rem !important;
    }
    .bottom-sm-1 {
      bottom: 0.25rem !important;
    }
    .left-sm-1 {
      left: 0.25rem !important;
    }
    .top-sm-n1 {
      top: -0.25rem !important;
    }
    .right-sm-n1 {
      right: -0.25rem !important;
    }
    .bottom-sm-n1 {
      bottom: -0.25rem !important;
    }
    .left-sm-n1 {
      left: -0.25rem !important;
    }
    .top-sm-2 {
      top: 0.5rem !important;
    }
    .right-sm-2 {
      right: 0.5rem !important;
    }
    .bottom-sm-2 {
      bottom: 0.5rem !important;
    }
    .left-sm-2 {
      left: 0.5rem !important;
    }
    .top-sm-n2 {
      top: -0.5rem !important;
    }
    .right-sm-n2 {
      right: -0.5rem !important;
    }
    .bottom-sm-n2 {
      bottom: -0.5rem !important;
    }
    .left-sm-n2 {
      left: -0.5rem !important;
    }
    .top-sm-3 {
      top: 1rem !important;
    }
    .right-sm-3 {
      right: 1rem !important;
    }
    .bottom-sm-3 {
      bottom: 1rem !important;
    }
    .left-sm-3 {
      left: 1rem !important;
    }
    .top-sm-n3 {
      top: -1rem !important;
    }
    .right-sm-n3 {
      right: -1rem !important;
    }
    .bottom-sm-n3 {
      bottom: -1rem !important;
    }
    .left-sm-n3 {
      left: -1rem !important;
    }
    .top-sm-4 {
      top: 1.5rem !important;
    }
    .right-sm-4 {
      right: 1.5rem !important;
    }
    .bottom-sm-4 {
      bottom: 1.5rem !important;
    }
    .left-sm-4 {
      left: 1.5rem !important;
    }
    .top-sm-n4 {
      top: -1.5rem !important;
    }
    .right-sm-n4 {
      right: -1.5rem !important;
    }
    .bottom-sm-n4 {
      bottom: -1.5rem !important;
    }
    .left-sm-n4 {
      left: -1.5rem !important;
    }
    .top-sm-5 {
      top: 3rem !important;
    }
    .right-sm-5 {
      right: 3rem !important;
    }
    .bottom-sm-5 {
      bottom: 3rem !important;
    }
    .left-sm-5 {
      left: 3rem !important;
    }
    .top-sm-n5 {
      top: -3rem !important;
    }
    .right-sm-n5 {
      right: -3rem !important;
    }
    .bottom-sm-n5 {
      bottom: -3rem !important;
    }
    .left-sm-n5 {
      left: -3rem !important;
    }
    .top-sm-6 {
      top: 4.5rem !important;
    }
    .right-sm-6 {
      right: 4.5rem !important;
    }
    .bottom-sm-6 {
      bottom: 4.5rem !important;
    }
    .left-sm-6 {
      left: 4.5rem !important;
    }
    .top-sm-n6 {
      top: -4.5rem !important;
    }
    .right-sm-n6 {
      right: -4.5rem !important;
    }
    .bottom-sm-n6 {
      bottom: -4.5rem !important;
    }
    .left-sm-n6 {
      left: -4.5rem !important;
    }
    .top-sm-7 {
      top: 6rem !important;
    }
    .right-sm-7 {
      right: 6rem !important;
    }
    .bottom-sm-7 {
      bottom: 6rem !important;
    }
    .left-sm-7 {
      left: 6rem !important;
    }
    .top-sm-n7 {
      top: -6rem !important;
    }
    .right-sm-n7 {
      right: -6rem !important;
    }
    .bottom-sm-n7 {
      bottom: -6rem !important;
    }
    .left-sm-n7 {
      left: -6rem !important;
    }
    .top-sm-8 {
      top: 7.5rem !important;
    }
    .right-sm-8 {
      right: 7.5rem !important;
    }
    .bottom-sm-8 {
      bottom: 7.5rem !important;
    }
    .left-sm-8 {
      left: 7.5rem !important;
    }
    .top-sm-n8 {
      top: -7.5rem !important;
    }
    .right-sm-n8 {
      right: -7.5rem !important;
    }
    .bottom-sm-n8 {
      bottom: -7.5rem !important;
    }
    .left-sm-n8 {
      left: -7.5rem !important;
    }
    .top-sm-9 {
      top: 9rem !important;
    }
    .right-sm-9 {
      right: 9rem !important;
    }
    .bottom-sm-9 {
      bottom: 9rem !important;
    }
    .left-sm-9 {
      left: 9rem !important;
    }
    .top-sm-n9 {
      top: -9rem !important;
    }
    .right-sm-n9 {
      right: -9rem !important;
    }
    .bottom-sm-n9 {
      bottom: -9rem !important;
    }
    .left-sm-n9 {
      left: -9rem !important;
    }
    .top-sm-10 {
      top: 10.5rem !important;
    }
    .right-sm-10 {
      right: 10.5rem !important;
    }
    .bottom-sm-10 {
      bottom: 10.5rem !important;
    }
    .left-sm-10 {
      left: 10.5rem !important;
    }
    .top-sm-n10 {
      top: -10.5rem !important;
    }
    .right-sm-n10 {
      right: -10.5rem !important;
    }
    .bottom-sm-n10 {
      bottom: -10.5rem !important;
    }
    .left-sm-n10 {
      left: -10.5rem !important;
    }
    .top-sm-17 {
      top: 21rem !important;
    }
    .right-sm-17 {
      right: 21rem !important;
    }
    .bottom-sm-17 {
      bottom: 21rem !important;
    }
    .left-sm-17 {
      left: 21rem !important;
    }
    .top-sm-n17 {
      top: -21rem !important;
    }
    .right-sm-n17 {
      right: -21rem !important;
    }
    .bottom-sm-n17 {
      bottom: -21rem !important;
    }
    .left-sm-n17 {
      left: -21rem !important;
    }
  }
  @media (min-width: 768px) {
    .top-md-0 {
      top: 0 !important;
    }
    .right-md-0 {
      right: 0 !important;
    }
    .bottom-md-0 {
      bottom: 0 !important;
    }
    .left-md-0 {
      left: 0 !important;
    }
    .top-md-n0 {
      top: 0 !important;
    }
    .right-md-n0 {
      right: 0 !important;
    }
    .bottom-md-n0 {
      bottom: 0 !important;
    }
    .left-md-n0 {
      left: 0 !important;
    }
    .top-md-1 {
      top: 0.25rem !important;
    }
    .right-md-1 {
      right: 0.25rem !important;
    }
    .bottom-md-1 {
      bottom: 0.25rem !important;
    }
    .left-md-1 {
      left: 0.25rem !important;
    }
    .top-md-n1 {
      top: -0.25rem !important;
    }
    .right-md-n1 {
      right: -0.25rem !important;
    }
    .bottom-md-n1 {
      bottom: -0.25rem !important;
    }
    .left-md-n1 {
      left: -0.25rem !important;
    }
    .top-md-2 {
      top: 0.5rem !important;
    }
    .right-md-2 {
      right: 0.5rem !important;
    }
    .bottom-md-2 {
      bottom: 0.5rem !important;
    }
    .left-md-2 {
      left: 0.5rem !important;
    }
    .top-md-n2 {
      top: -0.5rem !important;
    }
    .right-md-n2 {
      right: -0.5rem !important;
    }
    .bottom-md-n2 {
      bottom: -0.5rem !important;
    }
    .left-md-n2 {
      left: -0.5rem !important;
    }
    .top-md-3 {
      top: 1rem !important;
    }
    .right-md-3 {
      right: 1rem !important;
    }
    .bottom-md-3 {
      bottom: 1rem !important;
    }
    .left-md-3 {
      left: 1rem !important;
    }
    .top-md-n3 {
      top: -1rem !important;
    }
    .right-md-n3 {
      right: -1rem !important;
    }
    .bottom-md-n3 {
      bottom: -1rem !important;
    }
    .left-md-n3 {
      left: -1rem !important;
    }
    .top-md-4 {
      top: 1.5rem !important;
    }
    .right-md-4 {
      right: 1.5rem !important;
    }
    .bottom-md-4 {
      bottom: 1.5rem !important;
    }
    .left-md-4 {
      left: 1.5rem !important;
    }
    .top-md-n4 {
      top: -1.5rem !important;
    }
    .right-md-n4 {
      right: -1.5rem !important;
    }
    .bottom-md-n4 {
      bottom: -1.5rem !important;
    }
    .left-md-n4 {
      left: -1.5rem !important;
    }
    .top-md-5 {
      top: 3rem !important;
    }
    .right-md-5 {
      right: 3rem !important;
    }
    .bottom-md-5 {
      bottom: 3rem !important;
    }
    .left-md-5 {
      left: 3rem !important;
    }
    .top-md-n5 {
      top: -3rem !important;
    }
    .right-md-n5 {
      right: -3rem !important;
    }
    .bottom-md-n5 {
      bottom: -3rem !important;
    }
    .left-md-n5 {
      left: -3rem !important;
    }
    .top-md-6 {
      top: 4.5rem !important;
    }
    .right-md-6 {
      right: 4.5rem !important;
    }
    .bottom-md-6 {
      bottom: 4.5rem !important;
    }
    .left-md-6 {
      left: 4.5rem !important;
    }
    .top-md-n6 {
      top: -4.5rem !important;
    }
    .right-md-n6 {
      right: -4.5rem !important;
    }
    .bottom-md-n6 {
      bottom: -4.5rem !important;
    }
    .left-md-n6 {
      left: -4.5rem !important;
    }
    .top-md-7 {
      top: 6rem !important;
    }
    .right-md-7 {
      right: 6rem !important;
    }
    .bottom-md-7 {
      bottom: 6rem !important;
    }
    .left-md-7 {
      left: 6rem !important;
    }
    .top-md-n7 {
      top: -6rem !important;
    }
    .right-md-n7 {
      right: -6rem !important;
    }
    .bottom-md-n7 {
      bottom: -6rem !important;
    }
    .left-md-n7 {
      left: -6rem !important;
    }
    .top-md-8 {
      top: 7.5rem !important;
    }
    .right-md-8 {
      right: 7.5rem !important;
    }
    .bottom-md-8 {
      bottom: 7.5rem !important;
    }
    .left-md-8 {
      left: 7.5rem !important;
    }
    .top-md-n8 {
      top: -7.5rem !important;
    }
    .right-md-n8 {
      right: -7.5rem !important;
    }
    .bottom-md-n8 {
      bottom: -7.5rem !important;
    }
    .left-md-n8 {
      left: -7.5rem !important;
    }
    .top-md-9 {
      top: 9rem !important;
    }
    .right-md-9 {
      right: 9rem !important;
    }
    .bottom-md-9 {
      bottom: 9rem !important;
    }
    .left-md-9 {
      left: 9rem !important;
    }
    .top-md-n9 {
      top: -9rem !important;
    }
    .right-md-n9 {
      right: -9rem !important;
    }
    .bottom-md-n9 {
      bottom: -9rem !important;
    }
    .left-md-n9 {
      left: -9rem !important;
    }
    .top-md-10 {
      top: 10.5rem !important;
    }
    .right-md-10 {
      right: 10.5rem !important;
    }
    .bottom-md-10 {
      bottom: 10.5rem !important;
    }
    .left-md-10 {
      left: 10.5rem !important;
    }
    .top-md-n10 {
      top: -10.5rem !important;
    }
    .right-md-n10 {
      right: -10.5rem !important;
    }
    .bottom-md-n10 {
      bottom: -10.5rem !important;
    }
    .left-md-n10 {
      left: -10.5rem !important;
    }
    .top-md-17 {
      top: 21rem !important;
    }
    .right-md-17 {
      right: 21rem !important;
    }
    .bottom-md-17 {
      bottom: 21rem !important;
    }
    .left-md-17 {
      left: 21rem !important;
    }
    .top-md-n17 {
      top: -21rem !important;
    }
    .right-md-n17 {
      right: -21rem !important;
    }
    .bottom-md-n17 {
      bottom: -21rem !important;
    }
    .left-md-n17 {
      left: -21rem !important;
    }
  }
  @media (min-width: 992px) {
    .top-lg-0 {
      top: 0 !important;
    }
    .right-lg-0 {
      right: 0 !important;
    }
    .bottom-lg-0 {
      bottom: 0 !important;
    }
    .left-lg-0 {
      left: 0 !important;
    }
    .top-lg-n0 {
      top: 0 !important;
    }
    .right-lg-n0 {
      right: 0 !important;
    }
    .bottom-lg-n0 {
      bottom: 0 !important;
    }
    .left-lg-n0 {
      left: 0 !important;
    }
    .top-lg-1 {
      top: 0.25rem !important;
    }
    .right-lg-1 {
      right: 0.25rem !important;
    }
    .bottom-lg-1 {
      bottom: 0.25rem !important;
    }
    .left-lg-1 {
      left: 0.25rem !important;
    }
    .top-lg-n1 {
      top: -0.25rem !important;
    }
    .right-lg-n1 {
      right: -0.25rem !important;
    }
    .bottom-lg-n1 {
      bottom: -0.25rem !important;
    }
    .left-lg-n1 {
      left: -0.25rem !important;
    }
    .top-lg-2 {
      top: 0.5rem !important;
    }
    .right-lg-2 {
      right: 0.5rem !important;
    }
    .bottom-lg-2 {
      bottom: 0.5rem !important;
    }
    .left-lg-2 {
      left: 0.5rem !important;
    }
    .top-lg-n2 {
      top: -0.5rem !important;
    }
    .right-lg-n2 {
      right: -0.5rem !important;
    }
    .bottom-lg-n2 {
      bottom: -0.5rem !important;
    }
    .left-lg-n2 {
      left: -0.5rem !important;
    }
    .top-lg-3 {
      top: 1rem !important;
    }
    .right-lg-3 {
      right: 1rem !important;
    }
    .bottom-lg-3 {
      bottom: 1rem !important;
    }
    .left-lg-3 {
      left: 1rem !important;
    }
    .top-lg-n3 {
      top: -1rem !important;
    }
    .right-lg-n3 {
      right: -1rem !important;
    }
    .bottom-lg-n3 {
      bottom: -1rem !important;
    }
    .left-lg-n3 {
      left: -1rem !important;
    }
    .top-lg-4 {
      top: 1.5rem !important;
    }
    .right-lg-4 {
      right: 1.5rem !important;
    }
    .bottom-lg-4 {
      bottom: 1.5rem !important;
    }
    .left-lg-4 {
      left: 1.5rem !important;
    }
    .top-lg-n4 {
      top: -1.5rem !important;
    }
    .right-lg-n4 {
      right: -1.5rem !important;
    }
    .bottom-lg-n4 {
      bottom: -1.5rem !important;
    }
    .left-lg-n4 {
      left: -1.5rem !important;
    }
    .top-lg-5 {
      top: 3rem !important;
    }
    .right-lg-5 {
      right: 3rem !important;
    }
    .bottom-lg-5 {
      bottom: 3rem !important;
    }
    .left-lg-5 {
      left: 3rem !important;
    }
    .top-lg-n5 {
      top: -3rem !important;
    }
    .right-lg-n5 {
      right: -3rem !important;
    }
    .bottom-lg-n5 {
      bottom: -3rem !important;
    }
    .left-lg-n5 {
      left: -3rem !important;
    }
    .top-lg-6 {
      top: 4.5rem !important;
    }
    .right-lg-6 {
      right: 4.5rem !important;
    }
    .bottom-lg-6 {
      bottom: 4.5rem !important;
    }
    .left-lg-6 {
      left: 4.5rem !important;
    }
    .top-lg-n6 {
      top: -4.5rem !important;
    }
    .right-lg-n6 {
      right: -4.5rem !important;
    }
    .bottom-lg-n6 {
      bottom: -4.5rem !important;
    }
    .left-lg-n6 {
      left: -4.5rem !important;
    }
    .top-lg-7 {
      top: 6rem !important;
    }
    .right-lg-7 {
      right: 6rem !important;
    }
    .bottom-lg-7 {
      bottom: 6rem !important;
    }
    .left-lg-7 {
      left: 6rem !important;
    }
    .top-lg-n7 {
      top: -6rem !important;
    }
    .right-lg-n7 {
      right: -6rem !important;
    }
    .bottom-lg-n7 {
      bottom: -6rem !important;
    }
    .left-lg-n7 {
      left: -6rem !important;
    }
    .top-lg-8 {
      top: 7.5rem !important;
    }
    .right-lg-8 {
      right: 7.5rem !important;
    }
    .bottom-lg-8 {
      bottom: 7.5rem !important;
    }
    .left-lg-8 {
      left: 7.5rem !important;
    }
    .top-lg-n8 {
      top: -7.5rem !important;
    }
    .right-lg-n8 {
      right: -7.5rem !important;
    }
    .bottom-lg-n8 {
      bottom: -7.5rem !important;
    }
    .left-lg-n8 {
      left: -7.5rem !important;
    }
    .top-lg-9 {
      top: 9rem !important;
    }
    .right-lg-9 {
      right: 9rem !important;
    }
    .bottom-lg-9 {
      bottom: 9rem !important;
    }
    .left-lg-9 {
      left: 9rem !important;
    }
    .top-lg-n9 {
      top: -9rem !important;
    }
    .right-lg-n9 {
      right: -9rem !important;
    }
    .bottom-lg-n9 {
      bottom: -9rem !important;
    }
    .left-lg-n9 {
      left: -9rem !important;
    }
    .top-lg-10 {
      top: 10.5rem !important;
    }
    .right-lg-10 {
      right: 10.5rem !important;
    }
    .bottom-lg-10 {
      bottom: 10.5rem !important;
    }
    .left-lg-10 {
      left: 10.5rem !important;
    }
    .top-lg-n10 {
      top: -10.5rem !important;
    }
    .right-lg-n10 {
      right: -10.5rem !important;
    }
    .bottom-lg-n10 {
      bottom: -10.5rem !important;
    }
    .left-lg-n10 {
      left: -10.5rem !important;
    }
    .top-lg-17 {
      top: 21rem !important;
    }
    .right-lg-17 {
      right: 21rem !important;
    }
    .bottom-lg-17 {
      bottom: 21rem !important;
    }
    .left-lg-17 {
      left: 21rem !important;
    }
    .top-lg-n17 {
      top: -21rem !important;
    }
    .right-lg-n17 {
      right: -21rem !important;
    }
    .bottom-lg-n17 {
      bottom: -21rem !important;
    }
    .left-lg-n17 {
      left: -21rem !important;
    }
  }
  @media (min-width: 1200px) {
    .top-xl-0 {
      top: 0 !important;
    }
    .right-xl-0 {
      right: 0 !important;
    }
    .bottom-xl-0 {
      bottom: 0 !important;
    }
    .left-xl-0 {
      left: 0 !important;
    }
    .top-xl-n0 {
      top: 0 !important;
    }
    .right-xl-n0 {
      right: 0 !important;
    }
    .bottom-xl-n0 {
      bottom: 0 !important;
    }
    .left-xl-n0 {
      left: 0 !important;
    }
    .top-xl-1 {
      top: 0.25rem !important;
    }
    .right-xl-1 {
      right: 0.25rem !important;
    }
    .bottom-xl-1 {
      bottom: 0.25rem !important;
    }
    .left-xl-1 {
      left: 0.25rem !important;
    }
    .top-xl-n1 {
      top: -0.25rem !important;
    }
    .right-xl-n1 {
      right: -0.25rem !important;
    }
    .bottom-xl-n1 {
      bottom: -0.25rem !important;
    }
    .left-xl-n1 {
      left: -0.25rem !important;
    }
    .top-xl-2 {
      top: 0.5rem !important;
    }
    .right-xl-2 {
      right: 0.5rem !important;
    }
    .bottom-xl-2 {
      bottom: 0.5rem !important;
    }
    .left-xl-2 {
      left: 0.5rem !important;
    }
    .top-xl-n2 {
      top: -0.5rem !important;
    }
    .right-xl-n2 {
      right: -0.5rem !important;
    }
    .bottom-xl-n2 {
      bottom: -0.5rem !important;
    }
    .left-xl-n2 {
      left: -0.5rem !important;
    }
    .top-xl-3 {
      top: 1rem !important;
    }
    .right-xl-3 {
      right: 1rem !important;
    }
    .bottom-xl-3 {
      bottom: 1rem !important;
    }
    .left-xl-3 {
      left: 1rem !important;
    }
    .top-xl-n3 {
      top: -1rem !important;
    }
    .right-xl-n3 {
      right: -1rem !important;
    }
    .bottom-xl-n3 {
      bottom: -1rem !important;
    }
    .left-xl-n3 {
      left: -1rem !important;
    }
    .top-xl-4 {
      top: 1.5rem !important;
    }
    .right-xl-4 {
      right: 1.5rem !important;
    }
    .bottom-xl-4 {
      bottom: 1.5rem !important;
    }
    .left-xl-4 {
      left: 1.5rem !important;
    }
    .top-xl-n4 {
      top: -1.5rem !important;
    }
    .right-xl-n4 {
      right: -1.5rem !important;
    }
    .bottom-xl-n4 {
      bottom: -1.5rem !important;
    }
    .left-xl-n4 {
      left: -1.5rem !important;
    }
    .top-xl-5 {
      top: 3rem !important;
    }
    .right-xl-5 {
      right: 3rem !important;
    }
    .bottom-xl-5 {
      bottom: 3rem !important;
    }
    .left-xl-5 {
      left: 3rem !important;
    }
    .top-xl-n5 {
      top: -3rem !important;
    }
    .right-xl-n5 {
      right: -3rem !important;
    }
    .bottom-xl-n5 {
      bottom: -3rem !important;
    }
    .left-xl-n5 {
      left: -3rem !important;
    }
    .top-xl-6 {
      top: 4.5rem !important;
    }
    .right-xl-6 {
      right: 4.5rem !important;
    }
    .bottom-xl-6 {
      bottom: 4.5rem !important;
    }
    .left-xl-6 {
      left: 4.5rem !important;
    }
    .top-xl-n6 {
      top: -4.5rem !important;
    }
    .right-xl-n6 {
      right: -4.5rem !important;
    }
    .bottom-xl-n6 {
      bottom: -4.5rem !important;
    }
    .left-xl-n6 {
      left: -4.5rem !important;
    }
    .top-xl-7 {
      top: 6rem !important;
    }
    .right-xl-7 {
      right: 6rem !important;
    }
    .bottom-xl-7 {
      bottom: 6rem !important;
    }
    .left-xl-7 {
      left: 6rem !important;
    }
    .top-xl-n7 {
      top: -6rem !important;
    }
    .right-xl-n7 {
      right: -6rem !important;
    }
    .bottom-xl-n7 {
      bottom: -6rem !important;
    }
    .left-xl-n7 {
      left: -6rem !important;
    }
    .top-xl-8 {
      top: 7.5rem !important;
    }
    .right-xl-8 {
      right: 7.5rem !important;
    }
    .bottom-xl-8 {
      bottom: 7.5rem !important;
    }
    .left-xl-8 {
      left: 7.5rem !important;
    }
    .top-xl-n8 {
      top: -7.5rem !important;
    }
    .right-xl-n8 {
      right: -7.5rem !important;
    }
    .bottom-xl-n8 {
      bottom: -7.5rem !important;
    }
    .left-xl-n8 {
      left: -7.5rem !important;
    }
    .top-xl-9 {
      top: 9rem !important;
    }
    .right-xl-9 {
      right: 9rem !important;
    }
    .bottom-xl-9 {
      bottom: 9rem !important;
    }
    .left-xl-9 {
      left: 9rem !important;
    }
    .top-xl-n9 {
      top: -9rem !important;
    }
    .right-xl-n9 {
      right: -9rem !important;
    }
    .bottom-xl-n9 {
      bottom: -9rem !important;
    }
    .left-xl-n9 {
      left: -9rem !important;
    }
    .top-xl-10 {
      top: 10.5rem !important;
    }
    .right-xl-10 {
      right: 10.5rem !important;
    }
    .bottom-xl-10 {
      bottom: 10.5rem !important;
    }
    .left-xl-10 {
      left: 10.5rem !important;
    }
    .top-xl-n10 {
      top: -10.5rem !important;
    }
    .right-xl-n10 {
      right: -10.5rem !important;
    }
    .bottom-xl-n10 {
      bottom: -10.5rem !important;
    }
    .left-xl-n10 {
      left: -10.5rem !important;
    }
    .top-xl-17 {
      top: 21rem !important;
    }
    .right-xl-17 {
      right: 21rem !important;
    }
    .bottom-xl-17 {
      bottom: 21rem !important;
    }
    .left-xl-17 {
      left: 21rem !important;
    }
    .top-xl-n17 {
      top: -21rem !important;
    }
    .right-xl-n17 {
      right: -21rem !important;
    }
    .bottom-xl-n17 {
      bottom: -21rem !important;
    }
    .left-xl-n17 {
      left: -21rem !important;
    }
  }
  .center {
    left: 50%;
    transform: translateX(-50%);
  }
  .middle {
    top: 50%;
    transform: translateY(-50%);
  }
  [data-negative-margin].is_stuck {
    margin: 0 !important;
  }
  [class*="shadow"] {
    transition: all 0.2s ease;
  }
  .shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(31, 45, 61, 0.1),
      0 10px 10px -5px rgba(31, 45, 61, 0.04) !important;
  }
  .shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(31, 45, 61, 0.25) !important;
  }
  .hover-shadow-sm:hover {
    box-shadow: 0 0.125rem 0.25rem rgba(31, 45, 61, 0.08) !important;
  }
  .hover-shadow:hover {
    box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.05) !important;
  }
  .hover-shadow-lg:hover {
    box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125) !important;
  }
  .hover-shadow-xl:hover {
    box-shadow: 0 20px 25px -5px rgba(31, 45, 61, 0.1),
      0 10px 10px -5px rgba(31, 45, 61, 0.04) !important;
  }
  .hover-shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px rgba(31, 45, 61, 0.25) !important;
  }
  .hover-shadow-none:hover,
  .shadow-none {
    box-shadow: none !important;
  }
  .shadow-primary-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 138, 255, 0.4) !important;
  }
  .shadow-primary {
    box-shadow: 0 0.5rem 1rem rgba(0, 138, 255, 0.4) !important;
  }
  .shadow-primary-lg {
    box-shadow: 0 1rem 3rem rgba(0, 138, 255, 0.4) !important;
  }
  .shadow-secondary-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(234, 236, 243, 0.4) !important;
  }
  .shadow-secondary {
    box-shadow: 0 0.5rem 1rem rgba(234, 236, 243, 0.4) !important;
  }
  .shadow-secondary-lg {
    box-shadow: 0 1rem 3rem rgba(234, 236, 243, 0.4) !important;
  }
  .shadow-success-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(92, 201, 167, 0.4) !important;
  }
  .shadow-success {
    box-shadow: 0 0.5rem 1rem rgba(92, 201, 167, 0.4) !important;
  }
  .shadow-success-lg {
    box-shadow: 0 1rem 3rem rgba(92, 201, 167, 0.4) !important;
  }
  .shadow-info-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(80, 181, 255, 0.4) !important;
  }
  .shadow-info {
    box-shadow: 0 0.5rem 1rem rgba(80, 181, 255, 0.4) !important;
  }
  .shadow-info-lg {
    box-shadow: 0 1rem 3rem rgba(80, 181, 255, 0.4) !important;
  }
  .shadow-warning-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(255, 190, 61, 0.4) !important;
  }
  .shadow-warning {
    box-shadow: 0 0.5rem 1rem rgba(255, 190, 61, 0.4) !important;
  }
  .shadow-warning-lg {
    box-shadow: 0 1rem 3rem rgba(255, 190, 61, 0.4) !important;
  }
  .shadow-danger-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(242, 87, 103, 0.4) !important;
  }
  .shadow-danger {
    box-shadow: 0 0.5rem 1rem rgba(242, 87, 103, 0.4) !important;
  }
  .shadow-danger-lg {
    box-shadow: 0 1rem 3rem rgba(242, 87, 103, 0.4) !important;
  }
  .shadow-light-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(234, 236, 243, 0.4) !important;
  }
  .shadow-light {
    box-shadow: 0 0.5rem 1rem rgba(234, 236, 243, 0.4) !important;
  }
  .shadow-light-lg {
    box-shadow: 0 1rem 3rem rgba(234, 236, 243, 0.4) !important;
  }
  .shadow-dark-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(23, 19, 71, 0.4) !important;
  }
  .shadow-dark {
    box-shadow: 0 0.5rem 1rem rgba(23, 19, 71, 0.4) !important;
  }
  .shadow-dark-lg {
    box-shadow: 0 1rem 3rem rgba(23, 19, 71, 0.4) !important;
  }
  .shadow-neutral-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(255, 255, 255, 0.4) !important;
  }
  .shadow-neutral {
    box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.4) !important;
  }
  .shadow-neutral-lg {
    box-shadow: 0 1rem 3rem rgba(255, 255, 255, 0.4) !important;
  }
  .shadow-white-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(255, 255, 255, 0.4) !important;
  }
  .shadow-white {
    box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.4) !important;
  }
  .shadow-white-lg {
    box-shadow: 0 1rem 3rem rgba(255, 255, 255, 0.4) !important;
  }
  .font-weight-300 {
    font-weight: 300 !important;
  }
  .font-weight-400 {
    font-weight: 400 !important;
  }
  .font-weight-500 {
    font-weight: 500 !important;
  }
  .font-weight-600 {
    font-weight: 600 !important;
  }
  .font-weight-700 {
    font-weight: 700 !important;
  }
  .font-weight-800 {
    font-weight: 800 !important;
  }
  .font-weight-900 {
    font-weight: 900 !important;
  }
  .font-weight-bolder {
    font-weight: 700 !important;
  }
  .text-underline {
    text-decoration: underline !important;
  }
  .text-underline--dashed {
    border-bottom: 1px dashed !important;
  }
  .text-line-through {
    text-decoration: line-through !important;
  }
  a.text-muted:hover {
    color: #5a677a !important;
  }
  .text-xs {
    font-size: 0.75rem !important;
  }
  .text-sm {
    font-size: 0.875rem !important;
  }
  .text-lg {
    font-size: 1.25rem !important;
  }
  .text-xl {
    font-size: 1.5rem !important;
  }
  .lh-100 {
    line-height: 1 !important;
  }
  .lh-110 {
    line-height: 1.1 !important;
  }
  .lh-120 {
    line-height: 1.2 !important;
  }
  .lh-130 {
    line-height: 1.3 !important;
  }
  .lh-140 {
    line-height: 1.4 !important;
  }
  .lh-150 {
    line-height: 1.5 !important;
  }
  .lh-160 {
    line-height: 1.6 !important;
  }
  .lh-170 {
    line-height: 1.7 !important;
  }
  .lh-180 {
    line-height: 1.8 !important;
  }
  .lh-190 {
    line-height: 1.9 !important;
  }
  .lh-200 {
    line-height: 2 !important;
  }
  .ls-1 {
    letter-spacing: 0.0625rem !important;
  }
  .ls-15 {
    letter-spacing: 0.09375rem !important;
  }
  .ls-2 {
    letter-spacing: 0.125rem !important;
  }
  .text-limit {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .transform-none {
    transform: none !important;
  }
  .hover-translate-y-n3:hover,
  .translate-y-n3 {
    transform: translateY(-3px) !important;
  }
  .hover-translate-y-n10:hover,
  .translate-y-n10 {
    transform: translateY(-10px) !important;
  }
  .hover-translate-x-50:hover,
  .translate-x-50 {
    transform: translateX(50%) !important;
  }
  .translate-y-50 {
    transform: translateY(50%) !important;
  }
  .translate-x-100 {
    transform: translateX(100%) !important;
  }
  .translate-y-100 {
    transform: translateY(100%) !important;
  }
  .translate-x-n50 {
    transform: translateX(-50%) !important;
  }
  .translate-y-n50 {
    transform: translateY(-50%) !important;
  }
  .translate-x-n100 {
    transform: translateX(-100%) !important;
  }
  .translate-y-n100 {
    transform: translateY(-100%) !important;
  }
  .scale-90 {
    transform: scale(0.9) !important;
  }
  .scale-x-90 {
    transform: scaleX(0.9) !important;
  }
  .scale-y-90 {
    transform: scaleY(0.9) !important;
  }
  .scale-100 {
    transform: scale(1) !important;
  }
  .scale-x-100 {
    transform: scaleX(1) !important;
  }
  .scale-y-100 {
    transform: scaleY(1) !important;
  }
  .hover-scale-105:hover,
  .scale-105 {
    transform: scale(1.05) !important;
  }
  .hover-scale-x-105:hover,
  .scale-x-105 {
    transform: scaleX(1.05) !important;
  }
  .scale-y-105 {
    transform: scaleY(1.05) !important;
  }
  .hover-scale-110:hover,
  .scale-110 {
    transform: scale(1.1) !important;
  }
  .hover-scale-x-110:hover,
  .scale-x-110 {
    transform: scaleX(1.1) !important;
  }
  .scale-y-110 {
    transform: scaleY(1.1) !important;
  }
  .hover-rotate-90:hover,
  .rotate-90 {
    transform: rotate(90deg) !important;
  }
  .hover-rotate-180:hover,
  .rotate-180 {
    transform: rotate(180deg) !important;
  }
  .hover-rotate-270:hover,
  .rotate-270 {
    transform: rotate(270deg) !important;
  }
  .hover-rotate-360:hover,
  .rotate-360 {
    transform: rotate(360deg) !important;
  }
  .hover-rotate-n90:hover,
  .rotate-n90 {
    transform: rotate(-90deg) !important;
  }
  .skew-x-5 {
    transform: skewX(5deg) !important;
  }
  .skew-y-5 {
    transform: skewY(5deg) !important;
  }
  .skew-x-n10 {
    transform: skewX(-10deg) !important;
  }
  .skew-y-n10 {
    transform: skewY(-10deg) !important;
  }
  .transform-origin-t {
    transform-origin: 50% 0 !important;
  }
  .transform-origin-r {
    transform-origin: 100% 50% !important;
  }
  .transform-origin-b {
    transform-origin: 50% 100% !important;
  }
  .transform-origin-l {
    transform-origin: 0 50% !important;
  }
  .perspective-left {
    transform: scale(1) perspective(2000px) rotateY(18deg) rotateX(0) rotate(0);
  }
  .perspective-right {
    transform: scale(1) perspective(1040px) rotateY(-18deg) rotateX(0) rotate(0);
  }
  @media (min-width: 576px) {
    .perspective-sm-left {
      transform: scale(1) perspective(2000px) rotateY(18deg) rotateX(0)
        rotate(0);
    }
    .perspective-sm-right {
      transform: scale(1) perspective(1040px) rotateY(-18deg) rotateX(0)
        rotate(0);
    }
  }
  @media (min-width: 768px) {
    .perspective-md-left {
      transform: scale(1) perspective(2000px) rotateY(18deg) rotateX(0)
        rotate(0);
    }
    .perspective-md-right {
      transform: scale(1) perspective(1040px) rotateY(-18deg) rotateX(0)
        rotate(0);
    }
  }
  @media (min-width: 992px) {
    .perspective-lg-left {
      transform: scale(1) perspective(2000px) rotateY(18deg) rotateX(0)
        rotate(0);
    }
    .perspective-lg-right {
      transform: scale(1) perspective(1040px) rotateY(-18deg) rotateX(0)
        rotate(0);
    }
  }
  @media (min-width: 1200px) {
    .perspective-xl-left {
      transform: scale(1) perspective(2000px) rotateY(18deg) rotateX(0)
        rotate(0);
    }
    .perspective-xl-right {
      transform: scale(1) perspective(1040px) rotateY(-18deg) rotateX(0)
        rotate(0);
    }
  }
  .zindex-0 {
    z-index: 0 !important;
  }
  .zindex-100 {
    z-index: 100 !important;
  }
  .zindex-101 {
    z-index: 101 !important;
  }
  .zindex-102 {
    z-index: 102 !important;
  }
  :root {
    $primary-dark: #b62d34;
    $primary-light: #33a1ff;
    $secondary-dark: #cacfe0;
    $secondary-light: white;
    $success-dark: #3cb690;
    $success-light: #82d6bc;
    $info-dark: #1d9fff;
    $info-light: #83cbff;
    $warning-dark: #ffad0a;
    $warning-light: #ffcf70;
    $danger-dark: #b62d34;
    $danger-light: #f68692;
    $light-dark: #cacfe0;
    $light-light: white;
    $dark-dark: #0a081f;
    $dark-light: #241e6f;
    $neutral-dark: #e6e6e6;
    $neutral-light: white;
    $white-dark: #e6e6e6;
    $white-light: white;
  }
  body,
  html {
    overflow-x: hidden;
  }
  iframe {
    border: 0;
  }
  figcaption,
  figure,
  main {
    display: block;
    margin: 0;
  }
  figure {
    max-width: 100%;
    min-height: 1px;
  }
  main {
    position: relative;
  }
  button:focus {
    outline: 0;
  }
  svg {
    vertical-align: middle;
    overflow: hidden;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .img-fluid {
      width: 100%;
      height: auto;
    }
  }
  .action-item {
    display: inline-flex;
    align-items: center;
    font-size: 0.875rem;
    cursor: pointer;
    color: #718096;
    background: 0 0;
    border: 0;
    padding: 0.25rem 0.25rem;
    transition: color 0.3s;
  }
  @media (prefers-reduced-motion: reduce) {
    .action-item {
      transition: none;
    }
  }
  .action-item:hover {
    color: #8e9aac;
  }
  .action-item svg {
    position: relative;
    bottom: 0.125rem;
  }
  .action-item span {
    padding-left: 0.35rem;
    display: inline-block;
  }
  .actions-dark .action-item {
    color: rgba(255, 255, 255, 0.7);
  }
  .actions-dark .action-item:hover {
    color: #fff;
  }
  .action-favorite {
    color: #ffc431;
  }
  .action-favorite.active,
  .action-favorite:hover {
    color: #ffba0d;
  }
  .action-love {
    color: #d33139;
  }
  .action-love.active,
  .action-love:hover {
    color: #ef3649;
  }
  .action-like {
    color: #008aff;
  }
  .action-like.active,
  .action-like:hover {
    color: #0077db;
  }
  .action-item-lg {
    font-size: 1.25rem;
  }
  .card > .actions {
    position: absolute;
    right: 0.875rem;
    top: 1rem;
  }
  .card-stats .actions {
    right: 0.75rem;
    top: 0.75rem;
  }
  .actions-toolbar {
    position: relative;
  }
  .actions-search {
    display: none;
    opacity: 0;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-color: #eaecf3;
    z-index: 1;
    box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.05);
    border-radius: 0.75rem;
    transition: all 0.2s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .actions-search {
      transition: none;
    }
  }
  .actions-search.show {
    display: flex;
    opacity: 1;
  }
  .alert {
    font-size: 0.875rem;
  }
  .alert strong {
    font-weight: 600;
  }
  .alert hr {
    margin: 0.875rem 0;
  }
  .alert p:last-child {
    margin-bottom: 0;
  }
  .alert-heading {
    font-weight: 600;
    font-size: 1.5rem;
    margin-top: 0.15rem;
  }
  .alert-flush {
    border-radius: 0;
    border-left: 0;
    border-right: 0;
  }
  .alert-modern {
    display: inline-block;
    padding: 0.275rem 1rem 0.375rem 0.375rem;
    border-radius: 50rem;
  }
  .alert-modern::after {
    display: inline-block;
    margin-left: 0.5rem;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Free";
    font-weight: 700;
    font-size: 12px;
    content: "\f105";
  }
  .alert-modern:empty::after {
    margin-left: 0;
  }
  .alert-modern .badge + .alert-content {
    margin-left: 0.5rem;
  }
  @media (max-width: 575.98px) {
    .alert-modern {
      text-align: center;
    }
    .alert-modern:after {
      content: "";
    }
    .alert-modern .alert-content {
      display: block;
      margin-top: 0.5rem;
    }
  }
  .alert-dark {
    background-color: #171347;
    color: #fff;
    border: 0;
  }
  .alert-dark .alert-link {
    color: #fff;
  }
  .alert-dark .close > span:not(.sr-only) {
    color: #fff;
  }
  .alert-dark .close:focus > span:not(.sr-only),
  .alert-dark .close:hover > span:not(.sr-only) {
    color: #fff;
  }
  .alert-dark hr {
    border-color: rgba(232, 236, 184, 0.1);
  }
  [class*="alert-outline-"] {
    border-right: 1px solid;
  }
  [class*="alert-outline-"] .alert-group-prepend {
    padding-right: 0.875rem;
  }
  .alert-outline-primary {
    background: 0 0;
    color: #0f5da2;
    border-color: #008aff;
  }
  .alert-outline-primary hr {
    border-top-color: #1a96ff;
  }
  .alert-outline-primary .alert-link {
    color: #0f5da2;
  }
  .alert-outline-primary .alert-group-prepend {
    border-right: 1px solid #008aff;
  }
  .alert-outline-secondary {
    background: 0 0;
    color: #89909c;
    border-color: #eaecf3;
  }
  .alert-outline-secondary hr {
    border-top-color: #fafbfc;
  }
  .alert-outline-secondary .alert-link {
    color: #89909c;
  }
  .alert-outline-secondary .alert-group-prepend {
    border-right: 1px solid #eaecf3;
  }
  .alert-outline-success {
    background: 0 0;
    color: #3f7e74;
    border-color: #5cc9a7;
  }
  .alert-outline-success hr {
    border-top-color: #6fcfb1;
  }
  .alert-outline-success .alert-link {
    color: #3f7e74;
  }
  .alert-outline-success .alert-group-prepend {
    border-right: 1px solid #5cc9a7;
  }
  .alert-outline-info {
    background: 0 0;
    color: #3874a2;
    border-color: #50b5ff;
  }
  .alert-outline-info hr {
    border-top-color: #6ac0ff;
  }
  .alert-outline-info .alert-link {
    color: #3874a2;
  }
  .alert-outline-info .alert-group-prepend {
    border-right: 1px solid #50b5ff;
  }
  .alert-outline-warning {
    background: 0 0;
    color: #93783d;
    border-color: #ffbe3d;
  }
  .alert-outline-warning hr {
    border-top-color: #ffc757;
  }
  .alert-outline-warning .alert-link {
    color: #93783d;
  }
  .alert-outline-warning .alert-group-prepend {
    border-right: 1px solid #ffbe3d;
  }
  .alert-outline-danger {
    background: 0 0;
    color: #8d4353;
    border-color: #d33139;
  }
  .alert-outline-danger hr {
    border-top-color: #f46f7c;
  }
  .alert-outline-danger .alert-link {
    color: #8d4353;
  }
  .alert-outline-danger .alert-group-prepend {
    border-right: 1px solid #d33139;
  }
  .alert-outline-light {
    background: 0 0;
    color: #89909c;
    border-color: #eaecf3;
  }
  .alert-outline-light hr {
    border-top-color: #fafbfc;
  }
  .alert-outline-light .alert-link {
    color: #89909c;
  }
  .alert-outline-light .alert-group-prepend {
    border-right: 1px solid #eaecf3;
  }
  .alert-outline-dark {
    background: 0 0;
    color: #1b1f42;
    border-color: #171347;
  }
  .alert-outline-dark hr {
    border-top-color: #1e185b;
  }
  .alert-outline-dark .alert-link {
    color: #1b1f42;
  }
  .alert-outline-dark .alert-group-prepend {
    border-right: 1px solid #171347;
  }
  .alert-outline-neutral {
    background: 0 0;
    color: #939aa2;
    border-color: #fff;
  }
  .alert-outline-neutral hr {
    border-top-color: #fff;
  }
  .alert-outline-neutral .alert-link {
    color: #939aa2;
  }
  .alert-outline-neutral .alert-group-prepend {
    border-right: 1px solid #fff;
  }
  .alert-outline-white {
    background: 0 0;
    color: #939aa2;
    border-color: #fff;
  }
  .alert-outline-white hr {
    border-top-color: #fff;
  }
  .alert-outline-white .alert-link {
    color: #939aa2;
  }
  .alert-outline-white .alert-group-prepend {
    border-right: 1px solid #fff;
  }
  .alert-messages:not(:last-child) {
    margin-bottom: 1.25rem;
  }
  .alert-notify {
    display: flex !important;
    max-width: 600px;
    width: calc(100% - 30px);
    padding-right: 80px;
    box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125);
  }
  .alert-notify:hover {
    z-index: 1081 !important;
  }
  .alert-group {
    display: flex;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
  }
  .alert-group .alert-group-prepend {
    display: inline-flex;
    vertical-align: middle;
    margin-right: 0.5rem;
  }
  .alert-group .alert-action,
  .alert-group .alert-content,
  .alert-group .alert-group-prepend {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }
  .alert-group .alert-action {
    margin-left: auto;
  }
  .alert-group .btn {
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.6;
  }
  .alert-group[class*="alert-outline"] .alert-group-prepend {
    margin-right: 0.875rem;
  }
  .alert-group-icon {
    display: inline-block;
    min-width: 26px;
    text-align: center;
  }
  .alert-dismissible .alert-group-prepend {
    display: block;
  }
  .alert-dismissible .close {
    top: 50%;
    right: 0.875rem;
    transform: translateY(-50%);
    padding: 0;
  }
  .avatar {
    position: relative;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    font-size: 1rem;
    font-weight: 600;
    height: 3.125rem;
    width: 3.125rem;
    border-radius: 0.375rem;
  }
  .avatar img {
    width: 100%;
    border-radius: 0.375rem;
  }
  .avatar.rounded-circle img {
    border-radius: 50%;
  }
  .avatar span {
    background-color: #008aff;
  }
  .avatar + .avatar {
    margin-left: 0.25rem;
  }
  .avatar + .avatar-content {
    display: inline-block;
    margin-left: 0.75rem;
  }
  .avatar-2xl {
    width: 7.5rem;
    height: 7.5rem;
    font-size: 1.375rem;
  }
  .avatar-xl {
    width: 6rem;
    height: 6rem;
    font-size: 1.375rem;
  }
  .avatar-lg {
    width: 4rem;
    height: 4rem;
    font-size: 1.25rem;
  }
  .avatar-sm {
    width: 2.4375rem;
    height: 2.4375rem;
    font-size: 0.75rem;
    border-radius: 0.2rem;
  }
  .avatar-xs {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 0.675rem;
    border-radius: 0.2rem;
  }
  .btn-avatar-action {
    display: none;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 100%;
    font-size: 12px;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.8);
    color: #ccc;
  }
  .avatar:hover .btn-avatar-action {
    display: block;
  }
  .avatar-group {
    display: inline-block;
    line-height: 1;
  }
  .avatar-group .avatar {
    z-index: 1;
    transition: margin 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .avatar-group .avatar {
      transition: none;
    }
  }
  .avatar-group .avatar img {
    border: 2px solid #fff;
  }
  .avatar-group .avatar:hover {
    z-index: 2;
  }
  .avatar-group .avatar + .avatar {
    margin-left: -1.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .avatar-group .avatar-sm + .avatar-sm {
    margin-left: -1rem;
  }
  .avatar-group:hover .avatar {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .avatar-group:hover .avatar-sm {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
  }
  .hover-avatar-ungroup:hover .avatar:not(:first-child) {
    margin-left: 0;
  }
  .avatar-parent-child {
    display: inline-block;
    position: relative;
  }
  .avatar-child {
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #fff;
    border: 2px solid #fff;
    border-radius: 0.2rem;
  }
  .avatar.rounded-circle + .avatar-child {
    border-radius: 50%;
  }
  .avatar + .avatar-child {
    width: 20px;
    height: 20px;
  }
  .avatar-xl + .avatar-child {
    width: 28px;
    height: 28px;
  }
  .avatar-lg + .avatar-child {
    width: 24px;
    height: 24px;
  }
  .avatar-sm + .avatar-child {
    width: 16px;
    height: 16px;
  }
  .avatar + .avatar-badge {
    width: 14px;
    height: 14px;
    right: -6px;
    bottom: 15px;
  }
  .avatar-xl + .avatar-badge {
    width: 18px;
    height: 18px;
    right: -5px;
    bottom: 20px;
  }
  .avatar-lg + .avatar-badge {
    width: 16px;
    height: 16px;
    right: -5px;
    bottom: 20px;
  }
  .avatar-sm + .badge {
    width: 12px;
    height: 12px;
    right: -6px;
    bottom: 10px;
  }
  .avatar-connect {
    position: relative;
  }
  .avatar-connect:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    border-bottom: 2px dashed #e2e8f0;
  }
  .avatar-connect ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .badge {
    display: inline;
    text-transform: none;
  }
  .badge i:not(:last-child),
  .badge svg {
    margin-right: 0.15rem;
  }
  .badge-group .badge {
    display: inline-block;
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
    margin-right: 0.25rem;
  }
  .badge-xs {
    padding: 0.25rem 0.5rem;
    font-size: 60%;
  }
  .badge-md {
    padding: 0.65em 1em;
  }
  .badge-lg {
    padding: 0.85em 1.375em;
  }
  .badge-inline {
    margin-right: 0.625rem;
  }
  .badge-inline + span {
    top: 2px;
    position: relative;
  }
  .badge-inline + span > a {
    text-decoration: underline;
  }
  .btn .badge:not(:first-child) {
    margin-left: 0.5rem;
  }
  .btn .badge:not(:last-child) {
    margin-right: 0.5rem;
  }
  .badge-soft-primary {
    color: #008aff;
    background-color: #cce8ff;
  }
  .badge-soft-primary[href]:focus,
  .badge-soft-primary[href]:hover {
    color: #008aff;
    text-decoration: none;
    background-color: #b3ddff;
  }
  .badge-soft-secondary {
    color: #eaecf3;
    background-color: #fbfbfd;
  }
  .badge-soft-secondary[href]:focus,
  .badge-soft-secondary[href]:hover {
    color: #eaecf3;
    text-decoration: none;
    background-color: #eaeaf5;
  }
  .badge-soft-success {
    color: #5cc9a7;
    background-color: #def4ed;
  }
  .badge-soft-success[href]:focus,
  .badge-soft-success[href]:hover {
    color: #5cc9a7;
    text-decoration: none;
    background-color: #cbeee3;
  }
  .badge-soft-info {
    color: #50b5ff;
    background-color: #dcf0ff;
  }
  .badge-soft-info[href]:focus,
  .badge-soft-info[href]:hover {
    color: #50b5ff;
    text-decoration: none;
    background-color: #c3e5ff;
  }
  .badge-soft-warning {
    color: #ffbe3d;
    background-color: #fff2d8;
  }
  .badge-soft-warning[href]:focus,
  .badge-soft-warning[href]:hover {
    color: #ffbe3d;
    text-decoration: none;
    background-color: #ffeabf;
  }
  .badge-soft-danger {
    color: #d33139;
    background-color: #fcdde1;
  }
  .badge-soft-danger[href]:focus,
  .badge-soft-danger[href]:hover {
    color: #d33139;
    text-decoration: none;
    background-color: #fac6cc;
  }
  .badge-soft-light {
    color: #eaecf3;
    background-color: #fbfbfd;
  }
  .badge-soft-light[href]:focus,
  .badge-soft-light[href]:hover {
    color: #eaecf3;
    text-decoration: none;
    background-color: #eaeaf5;
  }
  .badge-soft-dark {
    color: #171347;
    background-color: #d1d0da;
  }
  .badge-soft-dark[href]:focus,
  .badge-soft-dark[href]:hover {
    color: #171347;
    text-decoration: none;
    background-color: #c3c2cf;
  }
  .badge-soft-neutral {
    color: #fff;
    background-color: #fff;
  }
  .badge-soft-neutral[href]:focus,
  .badge-soft-neutral[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #f2f2f2;
  }
  .badge-soft-white {
    color: #fff;
    background-color: #fff;
  }
  .badge-soft-white[href]:focus,
  .badge-soft-white[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #f2f2f2;
  }
  .badge-dot {
    padding-left: 0;
    padding-right: 0;
    background: 0 0;
    color: #4a5568;
    font-size: 0.875rem;
  }
  .badge-dot i {
    display: inline-block;
    vertical-align: middle;
    width: 0.375rem;
    height: 0.375rem;
    border-radius: 50%;
    margin-right: 0.375rem;
  }
  .badge-dot.badge-md i {
    width: 0.5rem;
    height: 0.5rem;
  }
  .badge-dot.badge-lg i {
    width: 0.625rem;
    height: 0.625rem;
  }
  .badge-circle {
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    width: 1.25rem;
    height: 1.25rem;
    vertical-align: middle;
    font-size: 0.75rem;
    font-weight: 600;
  }
  .badge-circle.badge-xs {
    width: 1rem;
    height: 1rem;
    font-size: 0.5rem;
  }
  .badge-circle.badge-md {
    width: 1.5rem;
    height: 1.5rem;
  }
  .badge-circle.badge-lg {
    width: 2rem;
    height: 2rem;
  }
  .badge-circle svg {
    margin: 0;
  }
  .btn .badge-floating {
    position: absolute;
    top: -50%;
    right: 0.5rem;
    transform: translate(50%, 50%);
    margin: 0;
    border: 3px solid;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .btn .badge-floating.badge-circle {
    right: 0;
  }
  .breadcrumb {
    border: 1px solid #e2e8f0;
  }
  .breadcrumb-sm {
    font-size: 0.875rem;
  }
  .breadcrumb-links {
    border: 0;
    background-color: transparent;
    border-radius: 0;
  }
  .breadcrumb-links .breadcrumb-item + .breadcrumb-item::before {
    content: "";
    width: 0.3rem;
    height: 0.6rem;
    transform: translateY(50%);
    margin-right: 0.5rem;
    -webkit-mask: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxMHB4IiBoZWlnaHQ9IjE2cHgiIHZpZXdCb3g9IjAgMCAxMCAxNiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5TaGFwZTwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZGVmcz48L2RlZnM+ICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+ICAgICAgICA8ZyBpZD0iY2hldnJvbi1yaWdodCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMi4wMDAwMDAsIDIuMDAwMDAwKSIgc3Ryb2tlPSIjMDAwMDAwIiBzdHJva2Utd2lkdGg9IjIuNSI+ICAgICAgICAgICAgPHBvbHlsaW5lIGlkPSJTaGFwZSIgcG9pbnRzPSIwIDEyIDYgNiAwIDAiPjwvcG9seWxpbmU+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=)
      no-repeat 50% 50%;
    mask: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxMHB4IiBoZWlnaHQ9IjE2cHgiIHZpZXdCb3g9IjAgMCAxMCAxNiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5TaGFwZTwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZGVmcz48L2RlZnM+ICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+ICAgICAgICA8ZyBpZD0iY2hldnJvbi1yaWdodCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMi4wMDAwMDAsIDIuMDAwMDAwKSIgc3Ryb2tlPSIjMDAwMDAwIiBzdHJva2Utd2lkdGg9IjIuNSI+ICAgICAgICAgICAgPHBvbHlsaW5lIGlkPSJTaGFwZSIgcG9pbnRzPSIwIDEyIDYgNiAwIDAiPjwvcG9seWxpbmU+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=)
      no-repeat 50% 50%;
    -webkit-mask-size: contain;
    mask-size: contain;
    background: #cbd5e0;
  }
  .breadcrumb-dark .breadcrumb-item a {
    color: rgba(255, 255, 255, 0.8);
  }
  .breadcrumb-dark .breadcrumb-item a:hover {
    color: #fff;
  }
  .breadcrumb-dark .breadcrumb-item.active {
    color: rgba(255, 255, 255, 0.6);
  }
  .breadcrumb-dark .breadcrumb-item + .breadcrumb-item::before {
    color: rgba(255, 255, 255, 0.8);
  }
  .breadcrumb-light .breadcrumb-item a {
    color: rgba(23, 19, 71, 0.8);
  }
  .breadcrumb-light .breadcrumb-item a:hover {
    color: #171347;
  }
  .breadcrumb-light .breadcrumb-item + .breadcrumb-item::before {
    color: rgba(23, 19, 71, 0.8);
  }
  .btn {
    position: relative;
    transition: all 0.2s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none;
    }
  }
  .btn + .btn {
    margin-left: 0.5rem;
  }
  .btn-group > .btn {
    margin: 0;
  }
  .btn-neutral {
    background-color: transparent;
    color: #273444;
    border: 2px solid #e2e8f0;
  }
  .btn-neutral:hover {
    border-color: #d33139;
    background-color: transparent;
  }
  .btn-neutral:not(:disabled):not(.disabled):active,
  .btn-neutral:not(:disabled):not(.disabled):focus {
    border-color: #d33139;
    color: #d33139;
    background-color: transparent;
  }
  .btn-neutral:not(:disabled):not(.disabled).active {
    border-color: #008aff;
    color: #008aff;
    background-color: #fff;
  }
  .btn-xs {
    padding: 0.375rem 1rem;
    font-size: 0.75rem;
    line-height: 1.5;
    border-radius: 0.375rem;
  }
  .btn-xl {
    padding: 1.5rem 2rem;
    font-size: 1.5rem;
    line-height: 1.5;
    border-radius: 0.75rem;
  }
  @media (max-width: 1200px) {
    .btn-xl {
      font-size: calc(1.275rem + 0.3vw);
    }
  }
  .btn-nobg {
    padding: 0;
    background: 0 0 !important;
  }
  .btn-nobg:hover {
    background: 0 0 !important;
    border-color: transparent !important;
  }
  .btn-nobg--hover:hover {
    background-color: transparent;
  }
  .btn-soft-primary {
    color: #008aff;
    background-color: #cce8ff;
    border-color: #cce8ff;
    box-shadow: none;
  }
  .btn-soft-primary:hover {
    color: #fff;
    background-color: #008aff;
    border-color: #008aff;
    box-shadow: 0 4px 11px rgba(204, 232, 255, 0.35);
  }
  .btn-soft-primary.focus,
  .btn-soft-primary:focus {
    box-shadow: none, 0 0 0 0 rgba(6, 125, 227, 0.5);
  }
  .btn-soft-primary.disabled,
  .btn-soft-primary:disabled {
    color: #008aff;
    background-color: #cce8ff;
    border-color: #cce8ff;
  }
  .btn-soft-primary:not(:disabled):not(.disabled).active,
  .btn-soft-primary:not(:disabled):not(.disabled):active,
  .show > .btn-soft-primary.dropdown-toggle {
    color: #fff;
    background-color: #008aff;
    border-color: #008aff;
  }
  .btn-soft-primary:not(:disabled):not(.disabled).active:focus,
  .btn-soft-primary:not(:disabled):not(.disabled):active:focus,
  .show > .btn-soft-primary.dropdown-toggle:focus {
    box-shadow: none, 0 0 0 0 rgba(6, 125, 227, 0.5);
  }
  .btn-soft-secondary {
    color: #eaecf3;
    background-color: #fbfbfd;
    border-color: #fbfbfd;
    box-shadow: none;
  }
  .btn-soft-secondary:hover {
    color: #273444;
    background-color: #eaecf3;
    border-color: #eaecf3;
    box-shadow: 0 4px 11px rgba(251, 251, 253, 0.35);
  }
  .btn-soft-secondary.focus,
  .btn-soft-secondary:focus {
    box-shadow: none, 0 0 0 0 rgba(205, 208, 217, 0.5);
  }
  .btn-soft-secondary.disabled,
  .btn-soft-secondary:disabled {
    color: #eaecf3;
    background-color: #fbfbfd;
    border-color: #fbfbfd;
  }
  .btn-soft-secondary:not(:disabled):not(.disabled).active,
  .btn-soft-secondary:not(:disabled):not(.disabled):active,
  .show > .btn-soft-secondary.dropdown-toggle {
    color: #273444;
    background-color: #eaecf3;
    border-color: #eaecf3;
  }
  .btn-soft-secondary:not(:disabled):not(.disabled).active:focus,
  .btn-soft-secondary:not(:disabled):not(.disabled):active:focus,
  .show > .btn-soft-secondary.dropdown-toggle:focus {
    box-shadow: none, 0 0 0 0 rgba(205, 208, 217, 0.5);
  }
  .btn-soft-success {
    color: #5cc9a7;
    background-color: #def4ed;
    border-color: #def4ed;
    box-shadow: none;
  }
  .btn-soft-success:hover {
    color: #fff;
    background-color: #5cc9a7;
    border-color: #5cc9a7;
    box-shadow: 0 4px 11px rgba(222, 244, 237, 0.35);
  }
  .btn-soft-success.focus,
  .btn-soft-success:focus {
    box-shadow: none, 0 0 0 0 rgba(84, 179, 152, 0.5);
  }
  .btn-soft-success.disabled,
  .btn-soft-success:disabled {
    color: #5cc9a7;
    background-color: #def4ed;
    border-color: #def4ed;
  }
  .btn-soft-success:not(:disabled):not(.disabled).active,
  .btn-soft-success:not(:disabled):not(.disabled):active,
  .show > .btn-soft-success.dropdown-toggle {
    color: #fff;
    background-color: #5cc9a7;
    border-color: #5cc9a7;
  }
  .btn-soft-success:not(:disabled):not(.disabled).active:focus,
  .btn-soft-success:not(:disabled):not(.disabled):active:focus,
  .show > .btn-soft-success.dropdown-toggle:focus {
    box-shadow: none, 0 0 0 0 rgba(84, 179, 152, 0.5);
  }
  .btn-soft-info {
    color: #50b5ff;
    background-color: #dcf0ff;
    border-color: #dcf0ff;
    box-shadow: none;
  }
  .btn-soft-info:hover {
    color: #fff;
    background-color: #50b5ff;
    border-color: #50b5ff;
    box-shadow: 0 4px 11px rgba(220, 240, 255, 0.35);
  }
  .btn-soft-info.focus,
  .btn-soft-info:focus {
    box-shadow: none, 0 0 0 0 rgba(74, 162, 227, 0.5);
  }
  .btn-soft-info.disabled,
  .btn-soft-info:disabled {
    color: #50b5ff;
    background-color: #dcf0ff;
    border-color: #dcf0ff;
  }
  .btn-soft-info:not(:disabled):not(.disabled).active,
  .btn-soft-info:not(:disabled):not(.disabled):active,
  .show > .btn-soft-info.dropdown-toggle {
    color: #fff;
    background-color: #50b5ff;
    border-color: #50b5ff;
  }
  .btn-soft-info:not(:disabled):not(.disabled).active:focus,
  .btn-soft-info:not(:disabled):not(.disabled):active:focus,
  .show > .btn-soft-info.dropdown-toggle:focus {
    box-shadow: none, 0 0 0 0 rgba(74, 162, 227, 0.5);
  }
  .btn-soft-warning {
    color: #ffbe3d;
    background-color: #fff2d8;
    border-color: #fff2d8;
    box-shadow: none;
  }
  .btn-soft-warning:hover {
    color: #fff;
    background-color: #ffbe3d;
    border-color: #ffbe3d;
    box-shadow: 0 4px 11px rgba(255, 242, 216, 0.35);
  }
  .btn-soft-warning.focus,
  .btn-soft-warning:focus {
    box-shadow: none, 0 0 0 0 rgba(223, 169, 62, 0.5);
  }
  .btn-soft-warning.disabled,
  .btn-soft-warning:disabled {
    color: #ffbe3d;
    background-color: #fff2d8;
    border-color: #fff2d8;
  }
  .btn-soft-warning:not(:disabled):not(.disabled).active,
  .btn-soft-warning:not(:disabled):not(.disabled):active,
  .show > .btn-soft-warning.dropdown-toggle {
    color: #fff;
    background-color: #ffbe3d;
    border-color: #ffbe3d;
  }
  .btn-soft-warning:not(:disabled):not(.disabled).active:focus,
  .btn-soft-warning:not(:disabled):not(.disabled):active:focus,
  .show > .btn-soft-warning.dropdown-toggle:focus {
    box-shadow: none, 0 0 0 0 rgba(223, 169, 62, 0.5);
  }
  .btn-soft-danger {
    color: #d33139;
    background-color: #fcdde1;
    border-color: #fcdde1;
    box-shadow: none;
  }
  .btn-soft-danger:hover {
    color: #fff;
    background-color: #d33139;
    border-color: #d33139;
    box-shadow: 0 4px 11px rgba(252, 221, 225, 0.35);
  }
  .btn-soft-danger.focus,
  .btn-soft-danger:focus {
    box-shadow: none, 0 0 0 0 rgba(212, 82, 98, 0.5);
  }
  .btn-soft-danger.disabled,
  .btn-soft-danger:disabled {
    color: #d33139;
    background-color: #fcdde1;
    border-color: #fcdde1;
  }
  .btn-soft-danger:not(:disabled):not(.disabled).active,
  .btn-soft-danger:not(:disabled):not(.disabled):active,
  .show > .btn-soft-danger.dropdown-toggle {
    color: #fff;
    background-color: #d33139;
    border-color: #d33139;
  }
  .btn-soft-danger:not(:disabled):not(.disabled).active:focus,
  .btn-soft-danger:not(:disabled):not(.disabled):active:focus,
  .show > .btn-soft-danger.dropdown-toggle:focus {
    box-shadow: none, 0 0 0 0 rgba(212, 82, 98, 0.5);
  }
  .btn-soft-light {
    color: #eaecf3;
    background-color: #fbfbfd;
    border-color: #fbfbfd;
    box-shadow: none;
  }
  .btn-soft-light:hover {
    color: #273444;
    background-color: #eaecf3;
    border-color: #eaecf3;
    box-shadow: 0 4px 11px rgba(251, 251, 253, 0.35);
  }
  .btn-soft-light.focus,
  .btn-soft-light:focus {
    box-shadow: none, 0 0 0 0 rgba(205, 208, 217, 0.5);
  }
  .btn-soft-light.disabled,
  .btn-soft-light:disabled {
    color: #eaecf3;
    background-color: #fbfbfd;
    border-color: #fbfbfd;
  }
  .btn-soft-light:not(:disabled):not(.disabled).active,
  .btn-soft-light:not(:disabled):not(.disabled):active,
  .show > .btn-soft-light.dropdown-toggle {
    color: #273444;
    background-color: #eaecf3;
    border-color: #eaecf3;
  }
  .btn-soft-light:not(:disabled):not(.disabled).active:focus,
  .btn-soft-light:not(:disabled):not(.disabled):active:focus,
  .show > .btn-soft-light.dropdown-toggle:focus {
    box-shadow: none, 0 0 0 0 rgba(205, 208, 217, 0.5);
  }
  .btn-soft-dark {
    color: #171347;
    background-color: #d1d0da;
    border-color: #d1d0da;
    box-shadow: none;
  }
  .btn-soft-dark:hover {
    color: #fff;
    background-color: #171347;
    border-color: #171347;
    box-shadow: 0 4px 11px rgba(209, 208, 218, 0.35);
  }
  .btn-soft-dark.focus,
  .btn-soft-dark:focus {
    box-shadow: none, 0 0 0 0 rgba(25, 24, 71, 0.5);
  }
  .btn-soft-dark.disabled,
  .btn-soft-dark:disabled {
    color: #171347;
    background-color: #d1d0da;
    border-color: #d1d0da;
  }
  .btn-soft-dark:not(:disabled):not(.disabled).active,
  .btn-soft-dark:not(:disabled):not(.disabled):active,
  .show > .btn-soft-dark.dropdown-toggle {
    color: #fff;
    background-color: #171347;
    border-color: #171347;
  }
  .btn-soft-dark:not(:disabled):not(.disabled).active:focus,
  .btn-soft-dark:not(:disabled):not(.disabled):active:focus,
  .show > .btn-soft-dark.dropdown-toggle:focus {
    box-shadow: none, 0 0 0 0 rgba(25, 24, 71, 0.5);
  }
  .btn-soft-neutral {
    color: #fff;
    background-color: #fff;
    border-color: #fff;
    box-shadow: none;
  }
  .btn-soft-neutral:hover {
    color: #273444;
    background-color: #fff;
    border-color: #fff;
    box-shadow: 0 4px 11px rgba(255, 255, 255, 0.35);
  }
  .btn-soft-neutral.focus,
  .btn-soft-neutral:focus {
    box-shadow: none, 0 0 0 0 rgba(223, 225, 227, 0.5);
  }
  .btn-soft-neutral.disabled,
  .btn-soft-neutral:disabled {
    color: #fff;
    background-color: #fff;
    border-color: #fff;
  }
  .btn-soft-neutral:not(:disabled):not(.disabled).active,
  .btn-soft-neutral:not(:disabled):not(.disabled):active,
  .show > .btn-soft-neutral.dropdown-toggle {
    color: #273444;
    background-color: #fff;
    border-color: #fff;
  }
  .btn-soft-neutral:not(:disabled):not(.disabled).active:focus,
  .btn-soft-neutral:not(:disabled):not(.disabled):active:focus,
  .show > .btn-soft-neutral.dropdown-toggle:focus {
    box-shadow: none, 0 0 0 0 rgba(223, 225, 227, 0.5);
  }
  .btn-soft-white {
    color: #fff;
    background-color: #fff;
    border-color: #fff;
    box-shadow: none;
  }
  .btn-soft-white:hover {
    color: #273444;
    background-color: #fff;
    border-color: #fff;
    box-shadow: 0 4px 11px rgba(255, 255, 255, 0.35);
  }
  .btn-soft-white.focus,
  .btn-soft-white:focus {
    box-shadow: none, 0 0 0 0 rgba(223, 225, 227, 0.5);
  }
  .btn-soft-white.disabled,
  .btn-soft-white:disabled {
    color: #fff;
    background-color: #fff;
    border-color: #fff;
  }
  .btn-soft-white:not(:disabled):not(.disabled).active,
  .btn-soft-white:not(:disabled):not(.disabled):active,
  .show > .btn-soft-white.dropdown-toggle {
    color: #273444;
    background-color: #fff;
    border-color: #fff;
  }
  .btn-soft-white:not(:disabled):not(.disabled).active:focus,
  .btn-soft-white:not(:disabled):not(.disabled):active:focus,
  .show > .btn-soft-white.dropdown-toggle:focus {
    box-shadow: none, 0 0 0 0 rgba(223, 225, 227, 0.5);
  }
  .btn-facebook {
    color: #fff;
    background-color: #3b5999;
    border-color: #3b5999;
    box-shadow: none;
  }
  .btn-facebook:hover {
    color: #fff;
    background-color: #30497d;
    border-color: #2d4474;
  }
  .btn-facebook.focus,
  .btn-facebook:focus {
    box-shadow: none, 0 0 0 rgba(59, 89, 153, 0.35);
  }
  .btn-facebook.disabled,
  .btn-facebook:disabled {
    color: #fff;
    background-color: #3b5999;
    border-color: #3b5999;
  }
  .btn-facebook:not(:disabled):not(.disabled).active,
  .btn-facebook:not(:disabled):not(.disabled):active,
  .show > .btn-facebook.dropdown-toggle {
    color: #fff;
    background-color: #2d4474;
    border-color: #293e6b;
  }
  .btn-facebook:not(:disabled):not(.disabled).active:focus,
  .btn-facebook:not(:disabled):not(.disabled):active:focus,
  .show > .btn-facebook.dropdown-toggle:focus {
    box-shadow: 0 0 0 rgba(59, 89, 153, 0.35);
  }
  .btn-twitter {
    color: #fff;
    background-color: #1da1f2;
    border-color: #1da1f2;
    box-shadow: none;
  }
  .btn-twitter:hover {
    color: #fff;
    background-color: #0d8ddc;
    border-color: #0c85d0;
  }
  .btn-twitter.focus,
  .btn-twitter:focus {
    box-shadow: none, 0 0 0 rgba(29, 161, 242, 0.35);
  }
  .btn-twitter.disabled,
  .btn-twitter:disabled {
    color: #fff;
    background-color: #1da1f2;
    border-color: #1da1f2;
  }
  .btn-twitter:not(:disabled):not(.disabled).active,
  .btn-twitter:not(:disabled):not(.disabled):active,
  .show > .btn-twitter.dropdown-toggle {
    color: #fff;
    background-color: #0c85d0;
    border-color: #0b7ec4;
  }
  .btn-twitter:not(:disabled):not(.disabled).active:focus,
  .btn-twitter:not(:disabled):not(.disabled):active:focus,
  .show > .btn-twitter.dropdown-toggle:focus {
    box-shadow: 0 0 0 rgba(29, 161, 242, 0.35);
  }
  .btn-google-plus {
    color: #fff;
    background-color: #dd4b39;
    border-color: #dd4b39;
    box-shadow: none;
  }
  .btn-google-plus:hover {
    color: #fff;
    background-color: #cd3623;
    border-color: #c23321;
  }
  .btn-google-plus.focus,
  .btn-google-plus:focus {
    box-shadow: none, 0 0 0 rgba(221, 75, 57, 0.35);
  }
  .btn-google-plus.disabled,
  .btn-google-plus:disabled {
    color: #fff;
    background-color: #dd4b39;
    border-color: #dd4b39;
  }
  .btn-google-plus:not(:disabled):not(.disabled).active,
  .btn-google-plus:not(:disabled):not(.disabled):active,
  .show > .btn-google-plus.dropdown-toggle {
    color: #fff;
    background-color: #c23321;
    border-color: #b7301f;
  }
  .btn-google-plus:not(:disabled):not(.disabled).active:focus,
  .btn-google-plus:not(:disabled):not(.disabled):active:focus,
  .show > .btn-google-plus.dropdown-toggle:focus {
    box-shadow: 0 0 0 rgba(221, 75, 57, 0.35);
  }
  .btn-instagram {
    color: #fff;
    background-color: #e4405f;
    border-color: #e4405f;
    box-shadow: none;
  }
  .btn-instagram:hover {
    color: #fff;
    background-color: #de1f44;
    border-color: #d31e40;
  }
  .btn-instagram.focus,
  .btn-instagram:focus {
    box-shadow: none, 0 0 0 rgba(228, 64, 95, 0.35);
  }
  .btn-instagram.disabled,
  .btn-instagram:disabled {
    color: #fff;
    background-color: #e4405f;
    border-color: #e4405f;
  }
  .btn-instagram:not(:disabled):not(.disabled).active,
  .btn-instagram:not(:disabled):not(.disabled):active,
  .show > .btn-instagram.dropdown-toggle {
    color: #fff;
    background-color: #d31e40;
    border-color: #c81c3d;
  }
  .btn-instagram:not(:disabled):not(.disabled).active:focus,
  .btn-instagram:not(:disabled):not(.disabled):active:focus,
  .show > .btn-instagram.dropdown-toggle:focus {
    box-shadow: 0 0 0 rgba(228, 64, 95, 0.35);
  }
  .btn-pinterest {
    color: #fff;
    background-color: #bd081c;
    border-color: #bd081c;
    box-shadow: none;
  }
  .btn-pinterest:hover {
    color: #fff;
    background-color: #980617;
    border-color: #8c0615;
  }
  .btn-pinterest.focus,
  .btn-pinterest:focus {
    box-shadow: none, 0 0 0 rgba(189, 8, 28, 0.35);
  }
  .btn-pinterest.disabled,
  .btn-pinterest:disabled {
    color: #fff;
    background-color: #bd081c;
    border-color: #bd081c;
  }
  .btn-pinterest:not(:disabled):not(.disabled).active,
  .btn-pinterest:not(:disabled):not(.disabled):active,
  .show > .btn-pinterest.dropdown-toggle {
    color: #fff;
    background-color: #8c0615;
    border-color: #800513;
  }
  .btn-pinterest:not(:disabled):not(.disabled).active:focus,
  .btn-pinterest:not(:disabled):not(.disabled):active:focus,
  .show > .btn-pinterest.dropdown-toggle:focus {
    box-shadow: 0 0 0 rgba(189, 8, 28, 0.35);
  }
  .btn-youtube {
    color: #fff;
    background-color: #cd201f;
    border-color: #cd201f;
    box-shadow: none;
  }
  .btn-youtube:hover {
    color: #fff;
    background-color: #ac1b1a;
    border-color: #a11918;
  }
  .btn-youtube.focus,
  .btn-youtube:focus {
    box-shadow: none, 0 0 0 rgba(205, 32, 31, 0.35);
  }
  .btn-youtube.disabled,
  .btn-youtube:disabled {
    color: #fff;
    background-color: #cd201f;
    border-color: #cd201f;
  }
  .btn-youtube:not(:disabled):not(.disabled).active,
  .btn-youtube:not(:disabled):not(.disabled):active,
  .show > .btn-youtube.dropdown-toggle {
    color: #fff;
    background-color: #a11918;
    border-color: #961717;
  }
  .btn-youtube:not(:disabled):not(.disabled).active:focus,
  .btn-youtube:not(:disabled):not(.disabled):active:focus,
  .show > .btn-youtube.dropdown-toggle:focus {
    box-shadow: 0 0 0 rgba(205, 32, 31, 0.35);
  }
  .btn-slack {
    color: #fff;
    background-color: #3aaf85;
    border-color: #3aaf85;
    box-shadow: none;
  }
  .btn-slack:hover {
    color: #fff;
    background-color: #30926f;
    border-color: #2d8968;
  }
  .btn-slack.focus,
  .btn-slack:focus {
    box-shadow: none, 0 0 0 rgba(58, 175, 133, 0.35);
  }
  .btn-slack.disabled,
  .btn-slack:disabled {
    color: #fff;
    background-color: #3aaf85;
    border-color: #3aaf85;
  }
  .btn-slack:not(:disabled):not(.disabled).active,
  .btn-slack:not(:disabled):not(.disabled):active,
  .show > .btn-slack.dropdown-toggle {
    color: #fff;
    background-color: #2d8968;
    border-color: #2a7f61;
  }
  .btn-slack:not(:disabled):not(.disabled).active:focus,
  .btn-slack:not(:disabled):not(.disabled):active:focus,
  .show > .btn-slack.dropdown-toggle:focus {
    box-shadow: 0 0 0 rgba(58, 175, 133, 0.35);
  }
  .btn-dribbble {
    color: #fff;
    background-color: #ea4c89;
    border-color: #ea4c89;
    box-shadow: none;
  }
  .btn-dribbble:hover {
    color: #fff;
    background-color: #e62a72;
    border-color: #e51e6b;
  }
  .btn-dribbble.focus,
  .btn-dribbble:focus {
    box-shadow: none, 0 0 0 rgba(234, 76, 137, 0.35);
  }
  .btn-dribbble.disabled,
  .btn-dribbble:disabled {
    color: #fff;
    background-color: #ea4c89;
    border-color: #ea4c89;
  }
  .btn-dribbble:not(:disabled):not(.disabled).active,
  .btn-dribbble:not(:disabled):not(.disabled):active,
  .show > .btn-dribbble.dropdown-toggle {
    color: #fff;
    background-color: #e51e6b;
    border-color: #dc1a65;
  }
  .btn-dribbble:not(:disabled):not(.disabled).active:focus,
  .btn-dribbble:not(:disabled):not(.disabled):active:focus,
  .show > .btn-dribbble.dropdown-toggle:focus {
    box-shadow: 0 0 0 rgba(234, 76, 137, 0.35);
  }
  .btn-github {
    color: #fff;
    background-color: #222;
    border-color: #222;
    box-shadow: none;
  }
  .btn-github:hover {
    color: #fff;
    background-color: #0f0f0f;
    border-color: #090909;
  }
  .btn-github.focus,
  .btn-github:focus {
    box-shadow: none, 0 0 0 rgba(34, 34, 34, 0.35);
  }
  .btn-github.disabled,
  .btn-github:disabled {
    color: #fff;
    background-color: #222;
    border-color: #222;
  }
  .btn-github:not(:disabled):not(.disabled).active,
  .btn-github:not(:disabled):not(.disabled):active,
  .show > .btn-github.dropdown-toggle {
    color: #fff;
    background-color: #090909;
    border-color: #020202;
  }
  .btn-github:not(:disabled):not(.disabled).active:focus,
  .btn-github:not(:disabled):not(.disabled):active:focus,
  .show > .btn-github.dropdown-toggle:focus {
    box-shadow: 0 0 0 rgba(34, 34, 34, 0.35);
  }
  .btn-vimeo {
    color: #fff;
    background-color: #04a0f0;
    border-color: #04a0f0;
    box-shadow: none;
  }
  .btn-vimeo:hover {
    color: #fff;
    background-color: #0387ca;
    border-color: #037fbe;
  }
  .btn-vimeo.focus,
  .btn-vimeo:focus {
    box-shadow: none, 0 0 0 rgba(4, 160, 240, 0.35);
  }
  .btn-vimeo.disabled,
  .btn-vimeo:disabled {
    color: #fff;
    background-color: #04a0f0;
    border-color: #04a0f0;
  }
  .btn-vimeo:not(:disabled):not(.disabled).active,
  .btn-vimeo:not(:disabled):not(.disabled):active,
  .show > .btn-vimeo.dropdown-toggle {
    color: #fff;
    background-color: #037fbe;
    border-color: #0376b1;
  }
  .btn-vimeo:not(:disabled):not(.disabled).active:focus,
  .btn-vimeo:not(:disabled):not(.disabled):active:focus,
  .show > .btn-vimeo.dropdown-toggle:focus {
    box-shadow: 0 0 0 rgba(4, 160, 240, 0.35);
  }
  .btn i:not(:first-child),
  .btn svg:not(:first-child) {
    margin-left: 0.5rem;
  }
  .btn i:not(:last-child),
  .btn svg:not(:last-child) {
    margin-right: 0.5rem;
  }
  .btn-icon .btn-inner--text:not(:first-child) {
    margin-left: 0.75em;
  }
  .btn-icon .btn-inner--text:not(:last-child) {
    margin-right: 0.75em;
  }
  .btn-icon .btn-inner--icon img {
    width: 20px;
  }
  .btn-icon-only {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 3.125rem;
    height: 3.125rem;
    padding: 0;
  }
  .btn-icon-only.btn-xs {
    width: 2rem;
    height: 2rem;
  }
  .btn-group-sm > .btn-icon-only.btn,
  .btn-icon-only.btn-sm {
    width: 2.4375rem;
    height: 2.4375rem;
  }
  .btn-group-lg > .btn-icon-only.btn,
  .btn-icon-only.btn-lg {
    width: 4rem;
    height: 4rem;
  }
  .btn-square-lg {
    width: 150px !important;
    height: 150px !important;
  }
  .btn-square {
    width: 108px !important;
    height: 108px !important;
    padding: 1rem;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .btn-square-sm {
    width: 50px !important;
    height: 50px !important;
  }
  .btn-square-xs {
    width: 25px !important;
    height: 25px !important;
  }
  .btn-animated {
    position: relative;
    overflow: hidden;
  }
  .btn-animated .btn-inner--visible {
    position: relative;
  }
  .btn-animated .btn-inner--hidden {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
  .btn-animated-x .btn-inner--visible {
    right: 0;
    transition: right 0.3s ease 0s;
  }
  @media (prefers-reduced-motion: reduce) {
    .btn-animated-x .btn-inner--visible {
      transition: none;
    }
  }
  .btn-animated-x .btn-inner--hidden {
    right: -100%;
    transition: right 0.3s ease 0s;
  }
  @media (prefers-reduced-motion: reduce) {
    .btn-animated-x .btn-inner--hidden {
      transition: none;
    }
  }
  .btn-animated-x:hover .btn-inner--hidden {
    right: 0;
  }
  .btn-animated-x:hover .btn-inner--visible {
    right: 150%;
  }
  .btn-animated-y .btn-inner--visible {
    top: 0;
    transition: top 0.3s ease 0s;
  }
  @media (prefers-reduced-motion: reduce) {
    .btn-animated-y .btn-inner--visible {
      transition: none;
    }
  }
  .btn-animated-y .btn-inner--hidden {
    left: 0;
    top: -100%;
    transition: top 0.3s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .btn-animated-y .btn-inner--hidden {
      transition: none;
    }
  }
  .btn-animated-y:hover .btn-inner--hidden {
    top: 50%;
  }
  .btn-animated-y:hover .btn-inner--visible {
    top: 100px;
  }
  .btn-action-label .btn-label:hover {
    background: 0 0;
    color: #2d3748;
  }
  .btn-action-label .btn-secondary:hover {
    color: #008aff;
  }
  .btn-icon-label {
    position: relative;
  }
  .btn-icon-label .btn-inner--icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    line-height: 1.8;
    border-radius: 0;
    text-align: center;
    margin: 0;
    width: 3em;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .btn-icon-label .btn-inner--icon:not(:first-child) {
    right: 0;
    top: 0;
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
  }
  .btn-icon-label .btn-inner--icon:not(:last-child) {
    left: 0;
    top: 0;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
  }
  .btn-icon-label .btn-inner--text:not(:first-child) {
    padding-left: 3em;
  }
  .btn-icon-label .btn-inner--text:not(:last-child) {
    padding-right: 3em;
  }
  .btn-app-store {
    background: #000;
    color: #fff;
    position: relative;
    padding-left: 4.5rem;
    padding-right: 1.5rem;
  }
  .btn-app-store i,
  .btn-app-store svg {
    width: 1em;
    font-size: 2.5rem;
    position: absolute;
    left: 1.25rem;
    top: 50%;
    transform: translateY(-50%);
  }
  .btn-app-store .btn-inner--text {
    display: block;
    font-size: 0.75rem;
    line-height: 0.75rem;
    margin-bottom: 0.125rem;
  }
  .btn-app-store .btn-inner--brand {
    display: block;
    text-align: left;
    font-size: 21px;
    line-height: 21px;
  }
  .btn-app-store:hover {
    background-color: #0077db;
    color: #fff;
  }
  .btn-group-options .btn {
    min-height: 4.8rem;
    display: flex;
    align-items: center;
  }
  .btn-group-colors > .btn {
    box-shadow: none;
    border-radius: 50% !important;
    width: 30px;
    height: 30px;
    padding: 0;
    margin-right: 0.5rem;
    margin-bottom: 0.25rem;
    position: relative;
  }
  .btn-group-colors > .btn:not([class*="bg-"]) {
    border-color: #fafbfe !important;
  }
  .btn-group-colors > .btn:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    line-height: 28px;
    color: #fff;
    transform: scale(0);
    opacity: 0;
    content: "";
    font-family: "Font Awesome 5 Free";
    font-size: 12px;
    transition: transform 0.2s, opacity 0.2s;
  }
  @media (prefers-reduced-motion: reduce) {
    .btn-group-colors > .btn:before {
      transition: none;
    }
  }
  .btn-group-colors > .btn.btn:not([class*="bg-"]) {
    border: 1px solid #d1dbe7;
  }
  .btn-group-colors > .btn.btn:not([class*="bg-"]):before {
    color: #718096;
  }
  .btn-group-colors > .btn.active:before {
    transform: scale(1);
    opacity: 1;
  }
  .card {
    position: relative;
    margin-bottom: 30px;
    box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.05);
  }
  .card.hover-scale-110:hover {
    z-index: 2;
    border-radius: 0.75rem !important;
  }
  .card.rounded-top-right {
    border-radius: 0.75rem 5rem 5rem 0.75rem 0.75rem !important;
  }
  .card.rounded-top-left {
    border-radius: 5rem 0.75rem 0.75rem 0.75rem !important;
  }
  .card.rounded-bottom-right {
    border-radius: 0.75rem 0.75rem 5rem 0.75rem !important;
  }
  .card.rounded-bottom-left {
    border-radius: 0.75rem 0.75rem 0.75rem 5rem !important;
  }
  .card.rounded-diagonal-left {
    border-radius: 5rem 0.75rem 5rem 0.75rem !important;
  }
  .card.rounded-diagonal-right {
    border-radius: 0.75rem 5rem 0.75rem 5rem !important;
  }
  .card-fluid {
    height: calc(100% - 30px);
  }
  .card-header {
    border-bottom: 0;
    padding-bottom: 0;
  }
  .card-header .action-item {
    padding-top: 0;
    padding-bottom: 0;
  }
  .card-btn {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }
  .card-deck + .row {
    margin-top: 30px;
  }
  .card-wrapper .card:last-child {
    margin-bottom: 0 !important;
  }
  .card-img-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-size: cover;
    border-radius: 0.75rem;
    backface-visibility: hidden;
    transition: all 0.2s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .card-img-bg {
      transition: none;
    }
  }
  .card-img-bg ~ .card-body {
    position: relative;
  }
  .card:hover .card-img-bg {
    transform: scale(1.1);
  }
  .card .animate-this,
  .card .card-image,
  .card > a {
    min-height: 1px;
  }
  .accordion .card {
    margin-bottom: 0;
  }
  .accordion .card-header {
    position: relative;
    cursor: pointer;
  }
  .accordion .card-header:after {
    content: "+";
    position: absolute;
    right: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
    font-family: "Nunito Sans", sans-serif;
    font-weight: 700;
  }
  .accordion .card-header[aria-expanded="false"]:after {
    content: "+";
  }
  .accordion .card-header[aria-expanded="true"]:after {
    content: "-";
  }
  .accordion .card-header[aria-expanded="true"] .heading {
    color: #008aff;
  }
  .accordion-spaced .card {
    margin-bottom: 1.5rem;
    border-radius: 0.75rem !important;
  }
  .card-overlay .card-img-overlay {
    color: #fff;
  }
  .card-overlay .card-footer {
    background-color: #fff;
    border: 0;
  }
  .card-hover-overlay {
    overflow: hidden;
  }
  .card-hover-overlay .card-img-overlay {
    opacity: 0;
    background-color: rgba(23, 19, 71, 0.4);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: opacity 0.35s;
  }
  @media (prefers-reduced-motion: reduce) {
    .card-hover-overlay .card-img-overlay {
      transition: none;
    }
  }
  .card-hover-overlay .card-img-overlay:after,
  .card-hover-overlay .card-img-overlay:before {
    pointer-events: none;
  }
  .card-hover-overlay .card-img-overlay .actions .action-item,
  .card-hover-overlay .card-img-overlay .overlay-actions,
  .card-hover-overlay .card-img-overlay .overlay-text {
    transform: translate3d(0, 200%, 0);
    transition: transform 0.35s;
  }
  @media (prefers-reduced-motion: reduce) {
    .card-hover-overlay .card-img-overlay .actions .action-item,
    .card-hover-overlay .card-img-overlay .overlay-actions,
    .card-hover-overlay .card-img-overlay .overlay-text {
      transition: none;
    }
  }
  .card-hover-overlay:hover .card-img-overlay {
    opacity: 1;
  }
  .card-hover-overlay:hover .card-img-overlay .actions .action-item,
  .card-hover-overlay:hover .card-img-overlay .overlay-actions,
  .card-hover-overlay:hover .card-img-overlay .overlay-text {
    transform: translate3d(0, 0, 0);
  }
  .card-hover-overlay:hover
    .card-img-overlay
    .actions
    .action-item:first-child {
    transition-delay: 0.1s;
  }
  .card-hover-overlay:hover
    .card-img-overlay
    .actions
    .action-item:nth-child(2) {
    transition-delay: 0.15s;
  }
  .card-hover-overlay:hover
    .card-img-overlay
    .actions
    .action-item:nth-child(3) {
    transition-delay: 0.2s;
  }
  .card-group .card .mask {
    border-radius: 0;
  }
  .card-columns-2 {
    column-count: 2 !important;
  }
  @media (min-width: 992px) {
    .card-columns-3 {
      column-count: 3 !important;
    }
  }
  @media (max-width: 1199.98px) {
    .card-columns-3 {
      column-count: 2 !important;
    }
  }
  @media (max-width: 767.98px) {
    .card-columns-3 {
      column-count: 1 !important;
    }
  }
  @media (min-width: 992px) {
    .card-columns-4 {
      column-count: 4 !important;
    }
  }
  .card-pricing .card-header {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .card-pricing .list-unstyled li {
    padding: 0.5rem 0;
  }
  .card-pricing.popular {
    z-index: 1;
  }
  .card-pricing.scale-110 {
    border-radius: 0.75rem !important;
  }
  @media (max-width: 991.98px) {
    .card-pricing.scale-110 {
      transform: scale(1) !important;
    }
  }
  .close > span:not(.sr-only) {
    color: rgba(31, 45, 61, 0.5);
  }
  .close:focus > span:not(.sr-only),
  .close:hover > span:not(.sr-only) {
    color: rgba(31, 45, 61, 0.8);
  }
  .custom-control-label {
    line-height: 1.8;
  }
  .checklist .custom-control-input:checked ~ .custom-control-label {
    text-decoration: line-through;
  }
  .checklist .card {
    margin-bottom: 0.5rem;
  }
  .checklist .card:last-child {
    margin-bottom: 0;
  }
  .custom-color {
    list-style: none;
    padding: 0;
  }
  .custom-color:after,
  .custom-color:before {
    content: "";
    display: table;
  }
  .custom-color:after {
    clear: both;
  }
  .custom-color label {
    width: 2.25rem;
    height: 2.25rem;
    float: left;
    padding: 0.375rem;
    margin-bottom: 0;
    margin-right: 0.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.7;
    border: 1px solid transparent;
    border-radius: 0.375rem;
    transition: all 0.2s ease;
    transform: scale(0.95);
  }
  @media (prefers-reduced-motion: reduce) {
    .custom-color label {
      transition: none;
    }
  }
  .custom-color label:hover {
    cursor: pointer;
    opacity: 1;
  }
  .custom-color label i,
  .custom-color label svg {
    opacity: 0;
    color: rgba(255, 255, 255, 0.9);
  }
  .custom-color input {
    left: -9999px;
    position: absolute;
  }
  .custom-color input:checked + label {
    transform: scale(1.1);
    opacity: 1;
  }
  .custom-color input:checked + label i,
  .custom-color input:checked + label svg {
    opacity: 1;
  }
  .custom-color-wide label {
    height: 1.5rem;
  }
  .custom-color-xl label {
    width: 5rem;
    height: 5rem;
  }
  .custom-color-xl.custom-color-wide label {
    width: 5rem;
    height: 3.5rem;
  }
  .custom-color-lg label {
    width: 3.25rem;
    height: 3.25rem;
  }
  .custom-color-sm label {
    width: 1.25rem;
    height: 1.25rem;
  }
  .custom-alphanumeric {
    list-style: none;
    padding: 0;
  }
  .custom-alphanumeric + .custom-alphanumeric {
    margin-left: 0.25rem;
  }
  .custom-alphanumeric:after,
  .custom-alphanumeric:before {
    content: "";
    display: table;
  }
  .custom-alphanumeric:after {
    clear: both;
  }
  .custom-alphanumeric > :not(:last-child) label {
    margin-right: 0.375rem;
  }
  .custom-alphanumeric label {
    width: 2.25rem;
    height: 2.25rem;
    float: left;
    padding: 0.375rem 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #718096;
    font-weight: 400;
    font-size: 0.875rem;
    background: 0 0;
    text-transform: uppercase;
    transition: all 0.3s ease;
    transform: scale(0.95);
    background: #e2e8f0;
    border: 0 solid #eaecf3;
    border-radius: 0.375rem;
  }
  .custom-alphanumeric label img {
    max-width: 100%;
  }
  .custom-alphanumeric label i,
  .custom-alphanumeric label svg {
    opacity: 0.8;
  }
  .custom-alphanumeric label:hover {
    cursor: pointer;
    border-color: #e2e8f0;
  }
  .custom-alphanumeric input {
    left: -9999px;
    position: absolute;
  }
  .custom-alphanumeric input:checked ~ label {
    color: #007ce6;
    border-color: #007ce6;
    transform: scale(1.1);
  }
  .custom-alphanumeric input:checked ~ label i,
  .custom-alphanumeric input:checked ~ label svg {
    opacity: 1;
  }
  .custom-alphanumeric-xl label {
    width: 5rem;
    height: 5rem;
    font-size: 1.5rem;
  }
  .custom-alphanumeric-lg label {
    width: 3.25rem;
    height: 3.25rem;
    font-size: 1.25rem;
  }
  .custom-alphanumeric-sm label {
    width: 1.25rem;
    height: 1.25rem;
  }
  .custom-input-file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    outline: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  .custom-input-file + label {
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: block;
    overflow: hidden;
    padding: 0.625rem 1.25rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.375rem;
    color: #4a5568;
    background-color: #fff;
    outline: 0;
    margin: 0;
  }
  .custom-input-file + label i {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    margin-top: -0.25em;
    margin-right: 0.5em;
  }
  .custom-input-file + label:hover,
  .custom-input-file.has-focus + label,
  .custom-input-file:focus + label {
    background-color: #fff;
  }
  .no-js .custom-input-file + label {
    display: none;
  }
  .custom-input-file--2 + label {
    color: #fff;
    border-color: #008aff;
    background: #008aff;
  }
  .custom-input-file--2 + label:hover,
  .custom-input-file--2.has-focus + label,
  .custom-input-file--2:focus + label {
    color: #fff;
    border-color: #b62d34;
    background: #b62d34;
  }
  .custom-input-file-link + label {
    padding: 0;
    border: 0;
    background: 0 0;
    color: #008aff;
    font-size: 0.875rem;
    font-weight: 600;
  }
  .custom-input-file-link + label:hover,
  .custom-input-file-link.has-focus + label,
  .custom-input-file-link:focus + label {
    background-color: transparent;
  }
  .custom-switch {
    min-height: 1.5rem;
  }
  .custom-switch .custom-control-label::before {
    top: 0;
    height: 1.5rem;
    border-radius: 0.75rem;
  }
  .custom-switch .custom-control-label::after {
    top: 0.1875rem;
    left: -3.5625rem;
    background-color: #fff;
  }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(1.5rem);
  }
  .custom-rating {
    position: relative;
    display: inline-flex;
    flex-direction: row-reverse;
    min-height: 1.7rem;
  }
  .custom-rating input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  .custom-rating label {
    width: 15px;
    height: 15px;
    margin-right: 0.375rem;
    white-space: nowrap;
    position: relative;
    margin-bottom: 0;
    vertical-align: middle;
    cursor: pointer;
  }
  .custom-rating label:before {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: transparent;
    color: #a0aec0;
    border-radius: 0.375rem;
    content: "\f005";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    transition: all 0.2s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .custom-rating label:before {
      transition: none;
    }
  }
  .custom-rating > input:checked ~ label:before {
    background-color: transparent;
    color: #ffc431;
  }
  .custom-rating > label:hover:before,
  .custom-rating > label:hover ~ label:before {
    background-color: transparent;
    color: #ffc431;
  }
  .custom-rating > input:checked + label:hover:before,
  .custom-rating > input:checked + label:hover ~ label:before,
  .custom-rating > input:checked ~ label:hover:before,
  .custom-rating > input:checked ~ label:hover ~ label:before,
  .custom-rating > label:hover ~ input:checked ~ label:before {
    background-color: rgba(0, 0, 0, 0);
    color: rgba(255, 196, 49, 0.6);
  }
  .dropdown,
  .dropleft,
  .dropright,
  .dropup {
    display: inline-block;
  }
  .dropdown-menu {
    font-size: 0.875rem;
  }
  .dropdown-menu .dropdown-item > i,
  .dropdown-menu .dropdown-item > svg {
    margin-right: 1rem;
    font-size: 1rem;
  }
  .dropdown-menu .dropdown-item img,
  .dropdown-menu .dropdown-item svg {
    height: 14px;
    margin-right: 0.5rem;
    position: relative;
    top: -1px;
  }
  .dropdown-menu .dropdown-item .icon-flag {
    width: 19px;
  }
  .dropdown-menu .list-group-item .media p {
    color: #4a5568;
    line-height: 1.4;
  }
  .dropdown-menu .list-group-item .media:hover .heading {
    color: #008aff;
  }
  .dropdown-menu-dark {
    background: #282f37;
    border: 0;
  }
  .dropdown-menu-dark .dropdown-item {
    color: rgba(255, 255, 255, 0.9);
  }
  .dropdown-menu-dark .dropdown-item:active,
  .dropdown-menu-dark .dropdown-item:focus,
  .dropdown-menu-dark .dropdown-item:hover {
    color: #fff;
    background: rgba(45, 55, 72, 0.1);
  }
  .dropdown-menu-dark .dropdown-divider {
    background: rgba(31, 45, 61, 0.2);
  }
  @media (min-width: 992px) {
    .dropdown-menu-arrow:before {
      content: "";
      background: #fff;
      box-shadow: none;
      display: block;
      height: 16px;
      width: 16px;
      left: 1.25rem;
      position: absolute;
      bottom: 100%;
      transform: rotate(-45deg) translateY(1rem);
      z-index: -5;
      border-radius: 0.2rem;
    }
    .dropdown-menu-arrow[x-placement="bottom-end"]:before {
      bottom: 100%;
      transform: rotate(-45deg) translateY(1rem);
    }
    .dropdown-menu-arrow[x-placement="top-end"]:before {
      bottom: 0;
      transform: rotate(-45deg) translateY(0.5rem);
    }
    .dropdown-menu-arrow.dropdown-menu-right:before {
      right: 2.5rem;
      left: auto;
    }
    .dropdown-menu-sm {
      min-width: 10rem !important;
      border: 0.2rem;
    }
    .dropdown-menu-sm.dropdown-menu-arrow.dropdown-menu-right:before {
      right: 2rem;
      left: auto;
    }
    .dropdown-menu-md {
      min-width: 22rem !important;
      border-radius: 0.375rem;
    }
    .dropdown-menu-lg {
      min-width: 32rem !important;
      border-radius: 0.375rem;
    }
    .dropdown-menu-xl {
      min-width: 46rem !important;
      border-radius: 0.375rem;
    }
  }
  @media (min-width: 992px) {
    .dropdown-fluid {
      position: static;
    }
    .dropdown-fluid .dropdown-menu {
      width: calc(100% - 30px);
      max-width: 1200px;
    }
    .dropdown-fluid .dropdown-menu {
      left: 15px;
      right: auto;
    }
    .dropdown-fluid .dropdown-menu-right {
      right: 15px;
      left: auto;
    }
  }
  @media (min-width: 992px) {
    .dropdown-animate > .dropdown-menu {
      opacity: 0;
      position: absolute;
      display: block;
      pointer-events: none;
    }
    .dropdown-animate:not([data-toggle="hover"]) .dropdown-menu.show {
      pointer-events: auto;
      animation: show-dropdown 0.3s ease forwards;
    }
    .dropdown-animate:not([data-toggle="hover"]) .dropdown-menu.hide {
      display: block;
      animation: hide-dropdown 0.3s ease backwards;
    }
    .dropdown-animate[data-toggle="hover"] > .dropdown-menu {
      display: block;
      margin: 0;
      left: 50%;
      transform: translateX(-50%) translateY(-3px) scale(0.97);
      transition: all 0.2s ease;
    }
  }
  @media (min-width: 992px) and (prefers-reduced-motion: reduce) {
    .dropdown-animate[data-toggle="hover"] > .dropdown-menu {
      transition: none;
    }
  }
  @media (min-width: 992px) {
    .dropdown-animate[data-toggle="hover"]:hover > .dropdown-menu {
      display: block;
      opacity: 1;
      pointer-events: auto;
      visibility: visible;
      transform: translateX(-50%) translateY(-3px) scale(1);
    }
  }
  @keyframes show-dropdown {
    0% {
      opacity: 0;
      transform: translateX(0) translateY(-3px) scale(0.97);
      transition: all 0.3s ease;
    }
    100% {
      transform: translateX(0) translateY(-3px) scale(1);
      opacity: 1;
    }
  }
  @keyframes hide-dropdown {
    from {
      opacity: 1;
      transform: translateX(0) translateY(-3px) scale(1);
      transition: all 0.3s ease;
    }
    to {
      opacity: 0;
      transform: translateX(0) translateY(-3px) scale(0.97);
    }
  }
  .dropdown-menu .dropdown-menu {
    left: 100%;
    margin: 0;
    right: auto;
    top: 0;
  }
  .dropdown-submenu {
    display: block;
    position: relative;
  }
  .dropdown-submenu > .dropdown-menu::after {
    border: 0 !important;
  }
  .dropdown-submenu > .dropdown-item:empty::after,
  .dropdown-submenu > .list-group-item:empty::after {
    margin-left: 0;
  }

  .dropdown-submenu[data-toggle="hover"] > .dropdown-menu {
    left: 100%;
    transform: translateX(0) translateY(-3px) scale(0.97);
  }
  .dropdown-submenu[data-toggle="hover"]:hover > .dropdown-menu {
    transform: translateX(0) translateY(-3px) scale(1);
  }
  .dropdown-submenu > .list-group-item {
    border-width: 0 0 1px;
  }
  @media (max-width: 991.98px) {
    .dropdown-submenu .dropdown-menu {
      padding: 0;
      box-shadow: none;
      top: 0;
    }
    .dropdown-submenu .dropdown-item {
      padding-left: 3rem;
    }
  }
  .footer {
    background-color: #fff;
    padding-top: 3rem;
  }
  .footer .nav-link {
    font-size: 0.875rem;
  }
  .footer .list-unstyled li a {
    display: inline-block;
    padding: 0.125rem 0;
    font-size: 0.875rem;
  }
  .footer-light p {
    color: #718096;
  }
  .footer-light .heading {
    color: #121212;
  }
  .footer-light .list-unstyled li a {
    color: #a0aec0;
  }
  .footer-light .list-unstyled li a:hover {
    color: #4a5568;
    text-decoration: underline;
  }
  .footer-light .copyright a {
    color: #4a5568;
  }
  .footer-light .copyright a:hover {
    color: #008aff;
  }
  .footer-dark p {
    color: rgba(255, 255, 255, 0.8);
  }
  .footer-dark .heading {
    color: #fff;
  }
  .footer-dark .list-unstyled li a {
    color: rgba(255, 255, 255, 0.8);
  }
  .footer-dark .list-unstyled li a:hover {
    color: rgba(255, 255, 255, 0.9);
  }
  .footer-dark .copyright a {
    color: rgba(255, 255, 255, 0.9);
  }
  .footer-dark .copyright a:hover {
    color: #fff;
  }
  .footer-dark {
    background-color: #273444;
    color: #a0aec0;
  }
  .footer-dark .heading {
    color: #eaecf3;
  }
  .footer-dark .footer-link,
  .footer-dark .list-unstyled li a,
  .footer-dark .nav .nav-item .nav-link {
    color: #a0aec0;
  }
  .footer-dark .footer-link:hover,
  .footer-dark .list-unstyled li a:hover,
  .footer-dark .nav .nav-item .nav-link:hover {
    color: #fff;
  }
  .footer-dark hr {
    border-color: rgba(216, 203, 187, 0.1);
  }
  .form-control:focus:-ms-input-placeholder {
    color: #cbd5e0;
  }
  .form-control:focus::-ms-input-placeholder {
    color: #cbd5e0;
  }
  .form-control:focus::placeholder {
    color: #cbd5e0;
  }
  .form-control-muted {
    background-color: #edf0f5;
    border-color: #edf0f5;
  }
  .form-control-muted:focus {
    background-color: #edf0f5;
  }
  .form-control-emphasized {
    background-color: #fff;
    border-color: #fff;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .form-control-emphasized:focus {
    background-color: #fff;
    border-color: transparent;
    box-shadow: 0 0 0 2px #008aff;
  }
  .form-control-flush {
    padding: 0;
    border-width: 0;
    background-color: transparent;
    box-shadow: none;
  }
  .form-control-flush:focus {
    background-color: transparent;
    box-shadow: none;
    border-width: 0;
  }
  textarea[resize="none"] {
    resize: none !important;
  }
  textarea[resize="both"] {
    resize: both !important;
  }
  textarea[resize="vertical"] {
    resize: vertical !important;
  }
  textarea[resize="horizontal"] {
    resize: horizontal !important;
  }
  .form-control-xl {
    padding: 1.5rem 2rem;
    font-size: 1.5rem;
    line-height: 1.5;
    border-radius: 0.75rem;
  }
  .form-dark .form-control {
    color: #fff;
    background-color: #070617;
    border: 1px solid #070617;
  }
  .form-dark .form-control:focus {
    background-color: #020207;
    border: 1px solid #020207;
  }
  .form-dark .form-control:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
  }
  .form-dark .form-control::-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
  }
  .form-dark .form-control::placeholder {
    color: rgba(255, 255, 255, 0.8);
  }
  .form-primary .form-control {
    color: #fff;
    background-color: #0069c2;
    border: 1px solid #0069c2;
  }
  .form-primary .form-control:focus {
    background-color: #005ead;
    border: 1px solid #005ead;
  }
  .form-primary .form-control:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
  }
  .form-primary .form-control::-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
  }
  .form-primary .form-control::placeholder {
    color: rgba(255, 255, 255, 0.8);
  }
  .form-warning .form-control {
    color: #fff;
    background-color: #ffa900;
    border: 1px solid #ffa900;
  }
  .form-warning .form-control:focus {
    background-color: #ea9c00;
    border: 1px solid #ea9c00;
  }
  .form-warning .form-control:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
  }
  .form-warning .form-control::-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
  }
  .form-warning .form-control::placeholder {
    color: rgba(255, 255, 255, 0.8);
  }
  .form-danger .form-control {
    color: #fff;
    background-color: #ee1e34;
    border: 1px solid #ee1e34;
  }
  .form-danger .form-control:focus {
    background-color: #e61228;
    border: 1px solid #e61228;
  }
  .form-danger .form-control:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
  }
  .form-danger .form-control::-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
  }
  .form-danger .form-control::placeholder {
    color: rgba(255, 255, 255, 0.8);
  }
  .form-control-label {
    color: #718096;
    font-size: 0.875rem;
    font-weight: 500;
    text-transform: none;
  }
  .has-floating-label {
    position: relative;
  }
  .has-floating-label .control-label {
    font-size: 1rem;
    pointer-events: none;
    position: absolute;
    top: 50%;
    padding: 0 0.625rem;
    margin: 0 0.625rem;
    color: #a0aec0;
    transform: translateY(-50%) scale(1);
    transform-origin: left top;
    transition: 0.3s;
    z-index: 1;
  }
  .has-floating-label .input-group .control-label {
    left: 2rem;
  }
  .has-floating-label.focused .control-label {
    padding: 0;
    margin: 0;
    top: -1.25rem;
    left: 0;
    transform: translateY(0) scale(0.85);
  }
  .has-floating-label .control-label-xl {
    font-size: 1.5rem;
    padding: 0 1rem;
    margin: 0 1rem;
  }
  .has-floating-label .control-label-lg {
    font-size: 1.25rem;
    padding: 0 0.9375rem;
    margin: 0 0.9375rem;
  }
  .has-floating-label .control-label-sm {
    font-size: 0.875rem;
    padding: 0 0.625rem;
    margin: 0 0.625rem;
  }
  .form-stacked .form-control {
    position: relative;
    box-sizing: border-box;
  }
  .form-stacked .form-control:first-of-type {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .form-stacked .form-control:not(:first-of-type):not(:last-of-type) {
    margin-bottom: -1px;
    border-radius: 0;
  }
  .form-stacked .form-control:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .form-stacked .form-control:focus {
    z-index: 2;
  }
  .header-transparent + section {
    padding-top: 7rem !important;
  }
  .header-transparent + .header-1 {
    padding-top: 14rem !important;
  }
  .header-account-page .btn-group-nav {
    position: relative;
    z-index: 1;
    transform: translateY(50%);
  }
  @media (min-width: 768px) {
    .header-web-app .row:last-of-type {
      margin-bottom: -12rem;
      z-index: 2;
      position: relative;
    }
    .header-web-app + section {
      padding-top: 12rem;
    }
  }
  .icon i,
  .icon svg {
    font-size: 1.71429rem;
  }
  .icon:not(.icon-shape) svg {
    width: 3rem;
    height: 3rem;
  }
  .icon[class*="text-"] > svg [fill]:not([fill="none"]) {
    fill: currentColor !important;
  }
  .icon + .icon-text {
    padding-left: 1rem;
    width: calc(100% - 3rem - 1);
  }
  .icon-xl i,
  .icon-xl svg {
    font-size: 2.85714rem;
  }
  .icon-xl:not(.icon-shape) svg {
    width: 5rem;
    height: 5rem;
  }
  .icon-xl + .icon-text {
    width: calc(100% - 5rem - 1);
  }
  .icon-lg i,
  .icon-lg svg {
    font-size: 2.28571rem;
  }
  .icon-lg:not(.icon-shape) svg {
    width: 4rem;
    height: 4rem;
  }
  .icon-lg + .icon-text {
    width: calc(100% - 4rem - 1);
  }
  .icon-sm i,
  .icon-sm svg {
    font-size: 1.14286rem;
  }
  .icon-sm:not(.icon-shape) svg {
    width: 2rem;
    height: 2rem;
  }
  .icon-sm + .icon-text {
    width: calc(100% - 2rem - 1);
  }
  .icon-xs i,
  .icon-xs svg {
    font-size: 0.57143rem;
  }
  .icon-xs:not(.icon-shape) svg {
    width: 1rem;
    height: 1rem;
  }
  .icon-xs + .icon-text {
    width: calc(100% - 1rem - 1);
  }
  .icon-shape {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    vertical-align: middle;
    border-radius: 0.375rem;
    width: 3rem;
    height: 3rem;
  }
  .icon-shape i {
    font-size: 1.125rem;
  }
  .icon-shape svg {
    width: 1.2rem;
    height: 1.2rem;
  }
  .icon-shape.icon-xl {
    width: 5rem;
    height: 5rem;
  }
  .icon-shape.icon-xl i {
    font-size: 2.25rem;
  }
  .icon-shape.icon-xl svg {
    width: 2rem;
    height: 2rem;
  }
  .icon-shape.icon-lg {
    width: 4rem;
    height: 4rem;
  }
  .icon-shape.icon-lg i {
    font-size: 1.75rem;
  }
  .icon-shape.icon-lg svg {
    width: 1.6rem;
    height: 1.6rem;
  }
  .icon-shape.icon-sm {
    width: 2rem;
    height: 2rem;
  }
  .icon-shape.icon-sm i {
    font-size: 0.875rem;
  }
  .icon-shape.icon-sm svg {
    width: 0.8rem;
    height: 0.8rem;
  }
  .icon-shape.icon-xs {
    width: 1rem;
    height: 1rem;
  }
  .icon-shape.icon-xs i {
    font-size: 0.75rem;
  }
  .icon-shape.icon-xs svg {
    width: 0.4rem;
    height: 0.4rem;
  }
  .icon-primary {
    color: #008aff;
    background-color: #cce8ff;
  }
  .icon-outline-primary {
    color: #0063b8;
    border: 3px solid rgba(51, 161, 255, 0.5);
  }
  .icon-secondary {
    color: #eaecf3;
    background-color: #fbfbfd;
  }
  .icon-outline-secondary {
    color: #bac1dc;
    border: 3px solid rgba(255, 255, 255, 0.5);
  }
  .icon-success {
    color: #5cc9a7;
    background-color: #def4ed;
  }
  .icon-outline-success {
    color: #32ac86;
    border: 3px solid rgba(130, 214, 188, 0.5);
  }
  .icon-info {
    color: #50b5ff;
    background-color: #dcf0ff;
  }
  .icon-outline-info {
    color: #0997ff;
    border: 3px solid rgba(131, 203, 255, 0.5);
  }
  .icon-warning {
    color: #ffbe3d;
    background-color: #fff2d8;
  }
  .icon-outline-warning {
    color: #f5a300;
    border: 3px solid rgba(255, 207, 112, 0.5);
  }
  .icon-danger {
    color: #d33139;
    background-color: #fcdde1;
  }
  .icon-outline-danger {
    color: #f30e26;
    border: 3px solid rgba(246, 134, 146, 0.5);
  }
  .icon-light {
    color: #eaecf3;
    background-color: #fbfbfd;
  }
  .icon-outline-light {
    color: #bac1dc;
    border: 3px solid rgba(255, 255, 255, 0.5);
  }
  .icon-dark {
    color: #171347;
    background-color: #d1d0da;
  }
  .icon-outline-dark {
    color: #04030f;
    border: 3px solid rgba(36, 30, 111, 0.5);
  }
  .icon-neutral {
    color: #fff;
    background-color: #fff;
  }
  .icon-outline-neutral {
    color: #dddada;
    border: 3px solid rgba(255, 255, 255, 0.5);
  }
  .icon-white {
    color: #fff;
    background-color: #fff;
  }
  .icon-outline-white {
    color: #dddada;
    border: 3px solid rgba(255, 255, 255, 0.5);
  }
  .icon-actions > a {
    display: inline-block;
    margin-right: 0.75rem;
    color: #718096;
    font-size: 0.875rem;
  }
  .icon-actions > a:last-of-type {
    margin-right: 0;
  }
  .icon-actions > a span {
    margin-left: 0.1875rem;
    font-weight: 600;
    color: #718096;
  }
  .icon-actions > a:hover span {
    color: #5a677a;
  }
  .icon-actions > a,
  .icon-actions > a.active,
  .icon-actions > a:hover {
    color: #2d3748;
  }
  .icon-actions > .favorite.active,
  .icon-actions > .favorite:hover {
    color: #ffc431;
  }
  .icon-actions > .love.active,
  .icon-actions > .love:hover {
    color: #d33139;
  }
  .icon-actions > .like.active,
  .icon-actions > .like:hover {
    color: #008aff;
  }
  .icon-actions-lg a {
    font-size: 1.25rem;
    margin-right: 0.875rem;
  }
  .has-floating-items {
    padding-bottom: 18rem !important;
  }
  .has-floating-items svg {
    height: auto;
  }
  @media (min-width: 768px) {
    .has-floating-items {
      padding-bottom: 18rem !important;
    }
  }
  .floating-items {
    position: relative;
    left: 50%;
    margin-left: -570px;
    z-index: 10;
  }
  .icon-floating {
    position: absolute;
    text-align: center;
    box-shadow: 0 30px 40px 0 rgba(31, 45, 61, 0.1);
    border-radius: 50%;
  }
  .icon-floating svg {
    width: 50px;
  }
  .icon-floating span {
    height: 100%;
  }
  .icon-floating img {
    max-width: 100%;
  }
  .icon-floating img,
  .icon-floating span {
    display: inline-block;
    vertical-align: middle;
  }
  .icon-floating:nth-child(1) {
    top: 140px;
    left: -60px;
    width: 120px;
    height: 120px;
  }
  .icon-floating:nth-child(2) {
    top: 200px;
    left: 180px;
    width: 160px;
    height: 160px;
    animation-delay: 0.5s;
  }
  .icon-floating:nth-child(3) {
    top: 120px;
    left: 480px;
    width: 80px;
    height: 80px;
    animation-delay: 1s;
  }
  .icon-floating:nth-child(4) {
    top: 210px;
    left: 680px;
    width: 140px;
    height: 140px;
    animation-delay: 1.5s;
  }
  .icon-floating:nth-child(5) {
    top: 100px;
    left: 920px;
    width: 100px;
    height: 100px;
    animation-delay: 2s;
  }
  .icon-floating:nth-child(6) {
    top: 200px;
    left: 1120px;
    width: 80px;
    height: 80px;
    animation-delay: 2.5s;
  }
  .icon-floating.icon-sm svg {
    width: 36px;
  }
  .icon-floating.icon-lg svg {
    width: 76px;
  }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .icon-floating:nth-child(1),
    .icon-floating:nth-child(6) {
      display: none;
    }
  }
  @media (max-width: 991.98px) {
    .icon-floating:nth-child(1),
    .icon-floating:nth-child(2),
    .icon-floating:nth-child(3) {
      display: none;
    }
    .icon-floating:nth-child(4),
    .icon-floating:nth-child(5),
    .icon-floating:nth-child(6) {
      width: 100px;
      height: 100px;
      top: 80px;
    }
    .icon-floating:nth-child(4) {
      left: 300px;
    }
    .icon-floating:nth-child(5) {
      left: 500px;
    }
    .icon-floating:nth-child(6) {
      left: 700px;
    }
    .icon-floating svg {
      width: 40px;
    }
  }
  @media (max-width: 767.98px) {
    .icon-floating:nth-child(4),
    .icon-floating:nth-child(5),
    .icon-floating:nth-child(6) {
      width: 80px;
      height: 80px;
      top: 80px;
    }
    .icon-floating:nth-child(4) {
      left: 400px;
    }
    .icon-floating:nth-child(5) {
      left: 530px;
    }
    .icon-floating:nth-child(6) {
      left: 660px;
    }
  }
  .floating-color {
    position: absolute;
    background-color: #fff;
    box-shadow: 0 30px 40px 0 rgba(0, 0, 0, 0.1);
    text-align: center;
    border-radius: 50%;
    animation: floating 3s ease infinite;
  }
  .floating-color span {
    display: inline-block;
    border-radius: 20px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0.875rem;
    color: rgba(255, 255, 255, 0.5);
  }
  .floating-color:nth-child(1) {
    top: 140px;
    left: -60px;
    width: 120px;
    height: 120px;
  }
  .floating-color:nth-child(2) {
    top: 200px;
    left: 180px;
    width: 160px;
    height: 160px;
    animation-delay: 0.5s;
  }
  .floating-color:nth-child(3) {
    top: 120px;
    left: 480px;
    width: 80px;
    height: 80px;
    animation-delay: 1s;
  }
  .floating-color:nth-child(4) {
    top: 210px;
    left: 680px;
    width: 140px;
    height: 140px;
    animation-delay: 1.5s;
  }
  .floating-color:nth-child(5) {
    top: 100px;
    left: 920px;
    width: 100px;
    height: 100px;
    animation-delay: 2s;
  }
  .floating-color:nth-child(6) {
    top: 200px;
    left: 1120px;
    width: 80px;
    height: 80px;
    animation-delay: 2.5s;
  }
  .input-group-text {
    transition: all 0.2s ease;
  }
  .input-group-flush .form-control,
  .input-group-flush .input-group-text {
    border: 0 !important;
  }
  .focused .input-group {
    box-shadow: inset 0 1px 1px rgba(31, 45, 61, 0.075),
      0 0 20px rgba(0, 138, 255, 0.1);
  }
  .focused .input-group .form-control {
    box-shadow: none !important;
  }
  .focused .input-group-text {
    color: #008aff;
    background-color: #eaecf3;
    border-color: rgba(0, 138, 255, 0.5);
  }
  .input-group.input-group-merge .input-group-text {
    color: #a0aec0;
    background-color: #fff;
    border: 1px solid #e2e8f0;
  }
  .input-group.input-group-merge .form-control {
    box-shadow: none;
  }
  .input-group.input-group-merge .form-control + .input-group-append {
    margin-left: -1px;
  }
  .input-group.input-group-merge
    .form-control:focus
    + .input-group-append
    .input-group-text,
  .input-group.input-group-merge
    .form-control:focus
    + .input-group-prepend
    .input-group-text {
    border-color: rgba(0, 138, 255, 0.5);
  }
  .input-group.input-group-merge .form-control-prepend {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
    padding-left: 0;
  }
  .input-group.input-group-merge .form-control-prepend + .input-group-prepend {
    order: -1;
    margin-right: -1px;
  }
  .input-group.input-group-merge
    .form-control-prepend
    + .input-group-prepend
    > .input-group-text {
    border-right: 0;
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .input-group.input-group-merge.rounded-pill
    .form-control:not(.form-control-prepend) {
    border-top-left-radius: 50rem;
    border-bottom-left-radius: 50rem;
  }
  .input-group.input-group-merge.rounded-pill
    .form-control
    + .input-group-append
    > .input-group-text {
    border-top-right-radius: 50rem;
    border-bottom-right-radius: 50rem;
  }
  .input-group.input-group-merge.rounded-pill .form-control-prepend {
    border-top-right-radius: 50rem;
    border-bottom-right-radius: 50rem;
  }
  .input-group.input-group-merge.rounded-pill
    .form-control-prepend
    + .input-group-prepend
    > .input-group-text {
    border-top-left-radius: 50rem;
    border-bottom-left-radius: 50rem;
  }
  .jumbotron {
    position: relative;
  }
  .jumbotron:not([class*="rounded-"]) {
    border-radius: 5rem;
  }
  .jumbotron:not([class*="rounded-"]) > img {
    border-radius: 5rem;
  }
  .jumbotron.rounded-right {
    border-radius: 0 5rem 5rem 0 !important;
  }
  .jumbotron.rounded-left {
    border-radius: 5rem 0 0 5rem !important;
  }
  .jumbotron.rounded-top-right {
    border-radius: 0 5rem 0 0 !important;
  }
  .jumbotron.rounded-top-left {
    border-radius: 5rem 0 0 0 !important;
  }
  .jumbotron.rounded-bottom-right {
    border-radius: 0 0 5rem 0 !important;
  }
  .jumbotron.rounded-bottom-left {
    border-radius: 0 0 0 5rem !important;
  }
  .jumbotron.rounded-diagonal-left {
    border-radius: 5rem 0 5rem 0 !important;
  }
  .jumbotron.rounded-diagonal-right {
    border-radius: 0 5rem 0 5rem !important;
  }
  .docs .header .navbar {
    position: fixed;
    z-index: 1000;
  }
  @media (min-width: 576px) {
    .docs .container-fluid,
    .docs .container-lg,
    .docs .container-md,
    .docs .container-sm,
    .docs .container-xl {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  @media (max-width: 767.98px) {
    .docs .container-fluid,
    .docs .container-lg,
    .docs .container-md,
    .docs .container-sm,
    .docs .container-xl {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .docs .container-docs {
    display: flex;
    flex: 1;
    align-items: stretch;
  }
  .docs .main-content {
    display: flex;
    flex: 1 1 auto;
    padding: 0;
    margin: 0;
    min-width: 0;
    max-width: 100%;
    height: 100vh;
  }
  @media (min-width: 992px) {
    .docs .main-content {
      padding-left: 250px;
    }
  }
  .docs .main-content .container,
  .docs .main-content .container-fluid,
  .docs .main-content .container-lg,
  .docs .main-content .container-md,
  .docs .main-content .container-sm,
  .docs .main-content .container-xl {
    position: relative;
  }
  .docs .main-content-title + .card,
  .docs .main-content-title + .row {
    margin-top: 30px;
  }
  .docs .docs-content {
    margin-top: 80px;
  }
  @media (min-width: 992px) {
    .docs .docs-content {
      padding: 0 70px;
    }
  }
  @media (min-width: 768px) {
    .docs .docs-content {
      padding: 0 50px;
    }
  }
  @media (max-width: 991.98px) {
    .docs .docs-content {
      padding: 0 20px;
    }
  }
  .docs .docs-title {
    padding: 2.5rem;
    margin: 2.5rem 0;
    border: 2px solid #e2e8f0;
    border-radius: 0.5rem;
  }
  .docs .sidenav {
    position: fixed;
    top: 0;
    height: 100vh;
    overflow: auto;
    margin-bottom: 0;
    border: 0;
    z-index: 900;
    border-radius: 0;
    transition: all 0.2s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .docs .sidenav {
      transition: none;
    }
  }
  @media (min-width: 992px) {
    .docs .sidenav {
      max-width: 250px !important;
      height: 100vh;
      transform: translateX(0);
      border-right: 1px solid #e2e8f0;
      border-radius: 0;
    }
  }
  @media (max-width: 991.98px) {
    .docs .sidenav {
      max-width: 0;
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      transform: translateX(-150%);
      border: 0;
    }
  }
  .docs .sidenav.show {
    max-width: 250px !important;
    transform: translateX(0);
    border-right: 1px solid #e2e8f0;
    z-index: 900;
    border-radius: 0;
  }
  .docs .sidenav-header {
    padding: 1.5rem 0;
    margin-bottom: 1.5rem;
  }
  .docs .sidenav-header .navbar-brand img {
    height: 1.5rem;
  }
  .docs .main-content-title + .nav-tabs {
    margin-bottom: 30px;
  }
  .side-content:before {
    content: "";
    position: absolute;
    z-index: -1;
    height: 100%;
    background-color: #fafbfe;
  }
  @media (min-width: 576px) {
    .side-content:before {
      width: 2000px;
      border-radius: 4rem 0 0 4rem !important;
    }
  }
  @media (max-width: 575.98px) {
    .side-content:before {
      width: 100%;
      left: 0;
    }
  }
  @media (min-width: 576px) {
    .side-content-inner {
      padding: 2rem 0 2.5rem 2.5rem;
    }
  }
  @media (max-width: 575.98px) {
    .side-content-inner {
      padding: 2rem 0;
    }
  }
  .sidenav-toggler {
    border: 0;
    background: 0 0;
    cursor: pointer;
  }
  .sidenav-toggler-inner,
  .sidenav-toggler-line {
    width: 18px;
    transition: all 0.2s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .sidenav-toggler-inner,
    .sidenav-toggler-line {
      transition: none;
    }
  }
  .sidenav-toggler-inner {
    position: relative;
  }
  .sidenav-toggler-inner:before {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    left: -11px;
    top: -14px;
    border-radius: 50%;
    transform: scale(0);
    transition: all 0.2s cubic-bezier(0.77, 0, 0.2, 2.25);
  }
  @media (prefers-reduced-motion: reduce) {
    .sidenav-toggler-inner:before {
      transition: none;
    }
  }
  .sidenav-toggler-line {
    height: 2px;
    background-color: rgba(31, 45, 61, 0.6);
    display: block;
    position: relative;
  }
  .sidenav-toggler-line:not(:last-child) {
    margin-bottom: 3px;
  }
  .sidenav-toggler.active .sidenav-toggler-inner:before {
    transform: scale(1);
  }
  .sidenav-toggler.active .sidenav-toggler-line:first-child {
    width: 13px;
    transform: translateX(5px);
  }
  .sidenav-toggler.active .sidenav-toggler-line:last-child {
    width: 13px;
    transform: translateX(5px);
  }
  .navbar-dark .sidenav-toggler-line,
  .sidenav-toggler-dark .sidenav-toggler-line {
    background-color: rgba(255, 255, 255, 0.85) !important;
  }
  .list-group {
    list-style-type: none;
  }
  .list-group-space .list-group-item {
    margin-bottom: 1.5rem;
    border-radius: 0.375rem;
  }
  .list-group-item {
    font-size: 0.875rem;
  }
  .list-group-img {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    vertical-align: top;
    margin: -0.1rem 1.2rem 0 -0.2rem;
  }
  .list-group-content {
    flex: 1;
    min-width: 0;
  }
  .list-group-content p {
    line-height: 1.5;
    margin: 0.2rem 0 0;
  }
  .list-group-heading {
    color: #2d3748;
  }
  .list-group-heading > small {
    float: right;
    color: #a0aec0;
    font-weight: 500;
  }
  .list-group-sm .list-group-item {
    padding: 0.675rem 1.25rem;
    font-size: 0.875rem;
  }
  .list-icon > i,
  .list-icon > svg {
    margin-right: 1rem;
    font-size: 0.875rem;
    position: relative;
  }
  .list-bullet > i,
  .list-bullet > svg {
    margin-right: 1rem;
    font-size: 0.875rem;
    position: relative;
  }
  .list-unstyled .list-link {
    display: block;
    color: #718096;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .list-unstyled .list-link:hover {
    color: #008aff;
  }
  .list-unstyled .list-link > i {
    margin-right: 0.5rem;
    font-size: 0.75rem;
  }
  .list-inline-item {
    vertical-align: middle;
  }
  .map-canvas {
    position: relative;
    width: 100%;
    height: 400px;
    border-radius: 0.375rem;
  }
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.2s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .mask {
      transition: none;
    }
  }
  .mask + .container {
    position: relative;
  }
  .card .mask {
    border-radius: 0.75rem;
  }
  .card .hover-mask {
    opacity: 0;
    transition-delay: 150ms;
  }
  .card:hover .hover-mask {
    opacity: 0.4;
  }
  .mask-body {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 1040;
  }
  .mask-body-dark {
    background: rgba(31, 45, 61, 0.3);
  }
  .mask-body-light {
    background: rgba(255, 255, 255, 0.3);
  }
  .mask-fade-bottom {
    background: linear-gradient(
      0deg,
      #1f2d3d 0,
      #000 0,
      rgba(31, 45, 61, 0) 60%
    );
  }
  .media > i {
    position: relative;
    top: 4px;
  }
  .media-pill {
    position: relative;
    padding: 2px;
    padding-right: 1rem;
    background-color: rgba(31, 45, 61, 0.15);
    border-radius: 50rem;
    transition: all 0.2s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .media-pill {
      transition: none;
    }
  }
  .media-pill:hover {
    background-color: rgba(31, 45, 61, 0.3);
  }
  .media-pill .avatar {
    display: inline;
  }
  .show .media-pill {
    background-color: rgba(31, 45, 61, 0.3);
  }
  .modal-content {
    border: 0;
    border-radius: 0.75rem;
  }
  .modal-fluid .modal-dialog {
    margin-top: 0;
    margin-bottom: 0;
  }
  .modal-fluid .modal-content {
    border-radius: 0;
  }
  .modal-open {
    max-height: 100vh;
  }
  .modal-dialog-aside {
    position: absolute;
    margin: 0;
  }
  .modal-primary .modal-title {
    color: #fff;
  }
  .modal-primary .modal-footer,
  .modal-primary .modal-header {
    border-color: rgba(255, 255, 255, 0.075);
  }
  .modal-primary .modal-content {
    background-color: #008aff;
    color: #fff;
  }
  .modal-primary .modal-content .heading {
    color: #fff;
  }
  .modal-primary .close > span:not(.sr-only) {
    color: #fff;
  }
  .modal-secondary .modal-title {
    color: #273444;
  }
  .modal-secondary .modal-footer,
  .modal-secondary .modal-header {
    border-color: rgba(39, 52, 68, 0.075);
  }
  .modal-secondary .modal-content {
    background-color: #eaecf3;
    color: #273444;
  }
  .modal-secondary .modal-content .heading {
    color: #273444;
  }
  .modal-secondary .close > span:not(.sr-only) {
    color: #fff;
  }
  .modal-success .modal-title {
    color: #fff;
  }
  .modal-success .modal-footer,
  .modal-success .modal-header {
    border-color: rgba(255, 255, 255, 0.075);
  }
  .modal-success .modal-content {
    background-color: #5cc9a7;
    color: #fff;
  }
  .modal-success .modal-content .heading {
    color: #fff;
  }
  .modal-success .close > span:not(.sr-only) {
    color: #fff;
  }
  .modal-info .modal-title {
    color: #fff;
  }
  .modal-info .modal-footer,
  .modal-info .modal-header {
    border-color: rgba(255, 255, 255, 0.075);
  }
  .modal-info .modal-content {
    background-color: #50b5ff;
    color: #fff;
  }
  .modal-info .modal-content .heading {
    color: #fff;
  }
  .modal-info .close > span:not(.sr-only) {
    color: #fff;
  }
  .modal-warning .modal-title {
    color: #fff;
  }
  .modal-warning .modal-footer,
  .modal-warning .modal-header {
    border-color: rgba(255, 255, 255, 0.075);
  }
  .modal-warning .modal-content {
    background-color: #ffbe3d;
    color: #fff;
  }
  .modal-warning .modal-content .heading {
    color: #fff;
  }
  .modal-warning .close > span:not(.sr-only) {
    color: #fff;
  }
  .modal-danger .modal-title {
    color: #fff;
  }
  .modal-danger .modal-footer,
  .modal-danger .modal-header {
    border-color: rgba(255, 255, 255, 0.075);
  }
  .modal-danger .modal-content {
    background-color: #d33139;
    color: #fff;
  }
  .modal-danger .modal-content .heading {
    color: #fff;
  }
  .modal-danger .close > span:not(.sr-only) {
    color: #fff;
  }
  .modal-light .modal-title {
    color: #273444;
  }
  .modal-light .modal-footer,
  .modal-light .modal-header {
    border-color: rgba(39, 52, 68, 0.075);
  }
  .modal-light .modal-content {
    background-color: #eaecf3;
    color: #273444;
  }
  .modal-light .modal-content .heading {
    color: #273444;
  }
  .modal-light .close > span:not(.sr-only) {
    color: #fff;
  }
  .modal-dark .modal-title {
    color: #fff;
  }
  .modal-dark .modal-footer,
  .modal-dark .modal-header {
    border-color: rgba(255, 255, 255, 0.075);
  }
  .modal-dark .modal-content {
    background-color: #171347;
    color: #fff;
  }
  .modal-dark .modal-content .heading {
    color: #fff;
  }
  .modal-dark .close > span:not(.sr-only) {
    color: #fff;
  }
  .modal-neutral .modal-title {
    color: #273444;
  }
  .modal-neutral .modal-footer,
  .modal-neutral .modal-header {
    border-color: rgba(39, 52, 68, 0.075);
  }
  .modal-neutral .modal-content {
    background-color: #fff;
    color: #273444;
  }
  .modal-neutral .modal-content .heading {
    color: #273444;
  }
  .modal-neutral .close > span:not(.sr-only) {
    color: #fff;
  }
  .modal-white .modal-title {
    color: #273444;
  }
  .modal-white .modal-footer,
  .modal-white .modal-header {
    border-color: rgba(39, 52, 68, 0.075);
  }
  .modal-white .modal-content {
    background-color: #fff;
    color: #273444;
  }
  .modal-white .modal-content .heading {
    color: #273444;
  }
  .modal-white .close > span:not(.sr-only) {
    color: #fff;
  }
  .nav-link {
    color: #4a5568;
    font-size: 0.875rem;
  }
  .nav-link:hover {
    color: #273444;
  }
  .nav-link.active {
    color: #273444;
    font-weight: 600;
  }
  @media (min-width: 992px) {
    .nav-link-badge {
      position: relative;
    }
    .nav-link-badge .badge {
      position: relative;
      top: -15px;
      left: -5px;
    }
    .nav-link-badge .nav-link-text {
      position: relative;
    }
    .nav-link-badge .nav-link-text:before {
      position: absolute;
      top: 0;
      left: -7px;
      display: block;
      width: 20px;
      height: 20px;
      margin: auto;
      content: "";
      transform: translate(-50%, -50%) rotate(100deg);
      border-left: 1px dashed #fff;
      border-radius: 10px;
    }
  }
  .nav.overflow-x {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 1px;
  }
  .nav.overflow-x .nav-item {
    width: auto;
    max-width: 100%;
    flex: 0 0 auto;
  }
  .nav-header {
    border-bottom-width: 0;
  }
  .nav-pills-icon .nav-link:not(.active) {
    background-color: #eaecf3;
  }
  .nav-pills-icon .nav-link:not(:last-child) {
    margin-right: 0.5rem;
  }
  @media (min-width: 768px) {
    .nav-pills-circle {
      padding: 0 50px;
    }
    .nav-pills-circle .nav-item {
      min-width: 200px;
      background: 0 0;
      border: 0;
      padding: 0;
      position: relative;
    }
    .nav-pills-circle .nav-link {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 80px;
      margin: auto;
      border-radius: 50%;
      background: #eaecf3;
      color: #273444;
      z-index: 10;
    }
    .nav-pills-circle.nav-pills-sm .nav-link {
      width: 60px;
      height: 60px;
    }
    .nav-pills-circle.nav-pills-connect .nav-item:not(:last-child)::before {
      content: "";
      display: block;
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(50%, 50%);
      height: 1px;
      width: 50%;
      background: #eaecf3;
      z-index: 0;
    }
  }
  .nav-pills-contained {
    padding: 3px;
    background-color: #eaecf3;
    border-radius: 0.375rem;
  }
  .nav-pills-contained .nav-link.active {
    background: #fff;
    color: #4a5568;
  }
  .nav-tabs .nav-item {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .nav-tabs .nav-link {
    padding: 15px 0;
    border-bottom: 1px solid transparent;
    border-left-width: 0;
    border-right-width: 0;
    border-top-width: 0;
  }
  .nav-tabs .nav-link:not(.active) {
    color: #4a5568;
  }
  .nav-tabs .nav-link:not(.active):hover {
    color: #2d3748;
  }
  .nav-tabs .nav-link.active {
    color: #718096;
  }
  .nav-tabs .nav-item:first-child {
    margin-left: 0;
  }
  .nav-tabs .nav-item:last-child {
    margin-right: 0;
  }
  .nav-tabs .nav-item.show .nav-link {
    border-color: transparent;
  }
  .nav-tabs.nav-dark {
    border-color: rgba(255, 255, 255, 0.2);
  }
  .nav-tabs.nav-dark .nav-link:not(.active) {
    color: rgba(255, 255, 255, 0.6);
  }
  .nav-tabs.nav-dark .nav-link:not(.active):hover {
    color: rgba(255, 255, 255, 0.8);
  }
  .nav-tabs.nav-dark .nav-link.active {
    color: #fff;
    border-color: #fff;
  }
  .nav-menu .nav-link {
    padding: 0.35rem 0;
  }
  .nav-menu .nav-link:not(.active):hover {
    color: #2d3748;
  }
  .nav-menu .nav-link:not(.active).active {
    font-weight: 700;
  }
  .navbar {
    position: relative;
    z-index: 101;
  }
  .navbar .container {
    position: relative;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  .navbar-nav .nav-link {
    font-size: 1rem;
    font-weight: 500 !important;
    text-transform: none;
    letter-spacing: 0;
    transition: all 0.15s linear;
  }
  @media (prefers-reduced-motion: reduce) {
    .navbar-nav .nav-link {
      transition: none;
    }
  }
  .navbar-nav .nav-link i,
  .navbar-nav .nav-link img,
  .navbar-nav .nav-link svg {
    margin-right: 0.5rem;
  }
  .navbar-nav .media-pill .avatar {
    width: 36px;
    height: 36px;
  }
  .nav-link > img,
  .nav-link > svg {
    height: 14px;
    margin-right: 0.5rem;
    position: relative;
    top: -1px;
  }
  .nav-link .icon-flag {
    width: 19px;
  }
  .navbar-text {
    font-size: 1rem;
  }
  .navbar-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
  .navbar-img-brand {
    font-size: initial;
  }
  .navbar-shadow {
    box-shadow: 0 0 10px rgba(31, 45, 61, 0.03);
  }
  .header,
  .navbar,
  .navbar-top {
    transition: all 0.2s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .header,
    .navbar,
    .navbar-top {
      transition: none;
    }
  }
  .navbar-border.navbar-dark {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
  }
  .navbar-border.navbar-dark .border-bottom {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
  }
  .navbar-border.navbar-light {
    border-bottom: 1px solid rgba(31, 45, 61, 0.04) !important;
  }
  .navbar-border.navbar-light .border-bottom {
    border-bottom: 1px solid rgba(31, 45, 61, 0.1) !important;
  }
  .navbar-dark .border-bottom {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
  }
  .navbar-light .border-bottom {
    border-bottom: 1px solid rgba(31, 45, 61, 0.1) !important;
  }
  .header-transparent {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1030;
  }
  .header-transparent .navbar-main .container:not(.border-0)::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: -0.75rem;
    left: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
  .header-transparent .navbar-top {
    background-color: transparent !important;
    border: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .header-transparent .navbar:not(.sticky) {
    background-color: transparent !important;
    border: 0;
    box-shadow: none;
  }
  .header-transparent
    + main
    section:first-child:not([data-spotlight])
    .container {
    padding-top: 5rem;
  }
  .header-collapse-show {
    min-height: 100%;
    width: 100%;
    top: 0;
    z-index: 1000;
  }
  .navbar-sticky {
    position: -webkit-sticky;
  }
  .navbar-sticky.sticky {
    position: fixed;
    width: 100%;
    left: 0;
    top: -100px;
    transform: translateY(100px);
    z-index: 1030;
    border-top: 0;
    box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125);
    transition: all 0.2s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .navbar-sticky.sticky {
      transition: none;
    }
  }
  .navbar-top {
    position: relative;
    display: flex;
    align-items: center;
  }
  .navbar-top .navbar-nav {
    flex-direction: row;
  }
  .navbar-top .navbar-nav .nav-item:last-child {
    margin-right: 0;
  }
  .navbar-top .navbar-nav .nav-link {
    padding: 0.5rem 0.5rem;
  }
  @media (max-width: 575.98px) {
    .navbar-expand-sm .navbar-brand img {
      height: 1.75rem;
    }
    .navbar-expand-sm .navbar-brand.dropdown-toggle:after {
      float: none;
      font-size: 1rem;
      opacity: 0.6;
    }
    .navbar-expand-sm .navbar-brand.dropdown-toggle:hover:after {
      opacity: 1;
    }
    .navbar-expand-sm .navbar-nav:not(.flex-row) .nav-link {
      padding: 0.625rem 0;
    }
    .navbar-expand-sm .navbar-nav:not(.flex-row) .dropdown-menu {
      box-shadow: none;
      min-width: auto;
    }
    .navbar-expand-sm .navbar-nav:not(.flex-row) .dropdown-menu .media svg {
      width: 30px;
    }
    .navbar-expand-sm .navbar-nav.flex-row .nav-link {
      padding-left: 0.675rem;
      padding-right: 0.675rem;
    }
    .navbar-expand-sm .navbar-nav.flex-row .dropdown {
      position: static;
    }
    .navbar-expand-sm .navbar-nav.flex-row .dropdown-menu {
      width: calc(100% - 30px);
      position: absolute;
      top: 60px;
      left: 15px;
      right: auto;
    }
    .navbar-expand-sm
      .navbar-nav
      .dropdown:not(.dropdown-submenu)
      > .dropdown-menu {
      display: block !important;
    }
    .navbar-expand-sm .navbar-collapse {
      background: #fff;
    }
    .navbar-expand-sm .navbar-collapse .navbar-nav .nav-item .nav-link {
      color: rgba(31, 45, 61, 0.6);
    }
    .navbar-expand-sm .navbar-collapse .navbar-nav .nav-item-spaced {
      margin-bottom: 1.5rem;
    }
    .navbar-expand-sm .navbar-collapse .navbar-nav .dropdown-item {
      color: rgba(31, 45, 61, 0.8);
    }
    .navbar-expand-sm .navbar-collapse .navbar-toggler {
      position: absolute;
      top: 0;
      right: 0.5rem;
      color: rgba(31, 45, 61, 0.6);
      z-index: 100;
    }
    .navbar-expand-sm .navbar-collapse-fade {
      z-index: 1050;
      height: auto !important;
      opacity: 0;
    }
    .navbar-expand-sm .navbar-collapse-fade.collapsing,
    .navbar-expand-sm .navbar-collapse-fade.show {
      animation: show-navbar-collapse 0.2s ease forwards;
      animation-delay: 0.15s;
    }
    .navbar-expand-sm .navbar-collapse-fade.collapsing-out {
      opacity: 0;
      transition: opacity 0.3s linear;
      animation-delay: 0;
      animation: none;
    }
    .navbar-expand-sm .navbar-collapse-overlay {
      position: fixed;
      top: 0;
      right: 5px;
      left: 5px;
      max-height: calc(100vh - 2rem) !important;
      z-index: 100;
      padding: 1rem 0;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      transform: translateY(-100%);
      opacity: 0;
      transition-property: opacity, transform;
      transform-origin: top right;
      transition: all 0.2s ease-in-out;
      box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125);
      border-radius: 0.375rem;
    }
  }
  @media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
    .navbar-expand-sm .navbar-collapse-overlay {
      transition: none;
    }
  }
  @media (max-width: 575.98px) {
    .navbar-expand-sm .navbar-collapse-overlay.show {
      opacity: 1;
      transform: translateY(0);
      transition: all 0.2s ease-in-out;
    }
  }
  @media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
    .navbar-expand-sm .navbar-collapse-overlay.show {
      transition: none;
    }
  }
  @media (max-width: 575.98px) {
    .navbar-expand-sm .navbar-collapse-overlay .nav-item {
      padding: 0 1.5rem;
    }
  }
  @media (min-width: 576px) {
    .navbar-expand-sm .navbar-brand img {
      height: 1.75rem;
    }
    .navbar-expand-sm .navbar-brand.dropdown-toggle:after {
      display: none;
    }
    .navbar-expand-sm .navbar-nav .nav-link {
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-radius: 0;
    }
    .navbar-expand-sm .navbar-nav .nav-link-icon {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      border-radius: 0;
    }
    .navbar-expand-sm .navbar-nav .nav-link-icon i {
      margin-right: 0;
    }
    .navbar-expand-sm .navbar-collapse .collapse-header {
      display: none;
    }
  }
  @media (max-width: 767.98px) {
    .navbar-expand-md .navbar-brand img {
      height: 1.75rem;
    }
    .navbar-expand-md .navbar-brand.dropdown-toggle:after {
      float: none;
      font-size: 1rem;
      opacity: 0.6;
    }
    .navbar-expand-md .navbar-brand.dropdown-toggle:hover:after {
      opacity: 1;
    }
    .navbar-expand-md .navbar-nav:not(.flex-row) .nav-link {
      padding: 0.625rem 0;
    }
    .navbar-expand-md .navbar-nav:not(.flex-row) .dropdown-menu {
      box-shadow: none;
      min-width: auto;
    }
    .navbar-expand-md .navbar-nav:not(.flex-row) .dropdown-menu .media svg {
      width: 30px;
    }
    .navbar-expand-md .navbar-nav.flex-row .nav-link {
      padding-left: 0.675rem;
      padding-right: 0.675rem;
    }
    .navbar-expand-md .navbar-nav.flex-row .dropdown {
      position: static;
    }
    .navbar-expand-md .navbar-nav.flex-row .dropdown-menu {
      width: calc(100% - 30px);
      position: absolute;
      top: 60px;
      left: 15px;
      right: auto;
    }
    .navbar-expand-md
      .navbar-nav
      .dropdown:not(.dropdown-submenu)
      > .dropdown-menu {
      display: block !important;
    }
    .navbar-expand-md .navbar-collapse {
      background: #fff;
    }
    .navbar-expand-md .navbar-collapse .navbar-nav .nav-item .nav-link {
      color: rgba(31, 45, 61, 0.6);
    }
    .navbar-expand-md .navbar-collapse .navbar-nav .nav-item-spaced {
      margin-bottom: 1.5rem;
    }
    .navbar-expand-md .navbar-collapse .navbar-nav .dropdown-item {
      color: rgba(31, 45, 61, 0.8);
    }
    .navbar-expand-md .navbar-collapse .navbar-toggler {
      position: absolute;
      top: 0;
      right: 0.5rem;
      color: rgba(31, 45, 61, 0.6);
      z-index: 100;
    }
    .navbar-expand-md .navbar-collapse-fade {
      z-index: 1050;
      height: auto !important;
      opacity: 0;
    }
    .navbar-expand-md .navbar-collapse-fade.collapsing,
    .navbar-expand-md .navbar-collapse-fade.show {
      animation: show-navbar-collapse 0.2s ease forwards;
      animation-delay: 0.15s;
    }
    .navbar-expand-md .navbar-collapse-fade.collapsing-out {
      opacity: 0;
      transition: opacity 0.3s linear;
      animation-delay: 0;
      animation: none;
    }
    .navbar-expand-md .navbar-collapse-overlay {
      position: fixed;
      top: 0;
      right: 5px;
      left: 5px;
      max-height: calc(100vh - 2rem) !important;
      z-index: 100;
      padding: 1rem 0;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      transform: translateY(-100%);
      opacity: 0;
      transition-property: opacity, transform;
      transform-origin: top right;
      transition: all 0.2s ease-in-out;
      box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125);
      border-radius: 0.375rem;
    }
  }
  @media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
    .navbar-expand-md .navbar-collapse-overlay {
      transition: none;
    }
  }
  @media (max-width: 767.98px) {
    .navbar-expand-md .navbar-collapse-overlay.show {
      opacity: 1;
      transform: translateY(0);
      transition: all 0.2s ease-in-out;
    }
  }
  @media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
    .navbar-expand-md .navbar-collapse-overlay.show {
      transition: none;
    }
  }
  @media (max-width: 767.98px) {
    .navbar-expand-md .navbar-collapse-overlay .nav-item {
      padding: 0 1.5rem;
    }
  }
  @media (min-width: 768px) {
    .navbar-expand-md .navbar-brand img {
      height: 1.75rem;
    }
    .navbar-expand-md .navbar-brand.dropdown-toggle:after {
      display: none;
    }
    .navbar-expand-md .navbar-nav .nav-link {
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-radius: 0;
    }
    .navbar-expand-md .navbar-nav .nav-link-icon {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      border-radius: 0;
    }
    .navbar-expand-md .navbar-nav .nav-link-icon i {
      margin-right: 0;
    }
    .navbar-expand-md .navbar-collapse .collapse-header {
      display: none;
    }
  }
  @media (max-width: 991.98px) {
    .navbar-expand-lg .navbar-brand img {
      height: auto;
    }
    .navbar-expand-lg .navbar-brand.dropdown-toggle:after {
      float: none;
      font-size: 1rem;
      opacity: 0.6;
    }
    .navbar-expand-lg .navbar-brand.dropdown-toggle:hover:after {
      opacity: 1;
    }
    .navbar-expand-lg .navbar-nav:not(.flex-row) .nav-link {
      padding: 0.625rem 0;
    }
    .navbar-expand-lg .navbar-nav:not(.flex-row) .dropdown-menu {
      box-shadow: none;
      min-width: auto;
    }
    .navbar-expand-lg .navbar-nav:not(.flex-row) .dropdown-menu .media svg {
      width: 30px;
    }
    .navbar-expand-lg .navbar-nav.flex-row .nav-link {
      padding-left: 0.675rem;
      padding-right: 0.675rem;
    }
    .navbar-expand-lg .navbar-nav.flex-row .dropdown {
      position: static;
    }
    .navbar-expand-lg .navbar-nav.flex-row .dropdown-menu {
      width: calc(100% - 30px);
      position: absolute;
      top: 60px;
      left: 15px;
      right: auto;
    }
    .navbar-expand-lg
      .navbar-nav
      .dropdown:not(.dropdown-submenu)
      > .dropdown-menu {
      display: block !important;
    }
    .navbar-expand-lg .navbar-collapse {
      background: #fff;
    }
    .navbar-expand-lg .navbar-collapse .navbar-nav .nav-item .nav-link {
      color: rgba(31, 45, 61, 0.6);
    }
    .navbar-expand-lg .navbar-collapse .navbar-nav .nav-item-spaced {
      margin-bottom: 1.5rem;
    }
    .navbar-expand-lg .navbar-collapse .navbar-nav .dropdown-item {
      color: rgba(31, 45, 61, 0.8);
    }
    .navbar-expand-lg .navbar-collapse .navbar-toggler {
      position: absolute;
      top: 0;
      right: 0.5rem;
      color: rgba(31, 45, 61, 0.6);
      z-index: 100;
    }
    .navbar-expand-lg .navbar-collapse-fade {
      z-index: 1050;
      height: auto !important;
      opacity: 0;
    }
    .navbar-expand-lg .navbar-collapse-fade.collapsing,
    .navbar-expand-lg .navbar-collapse-fade.show {
      animation: show-navbar-collapse 0.2s ease forwards;
      animation-delay: 0.15s;
    }
    .navbar-expand-lg .navbar-collapse-fade.collapsing-out {
      opacity: 0;
      transition: opacity 0.3s linear;
      animation-delay: 0;
      animation: none;
    }
    .navbar-expand-lg .navbar-collapse-overlay {
      position: fixed;
      top: 0;
      right: 5px;
      left: 5px;
      max-height: calc(100vh - 2rem) !important;
      z-index: 100;
      padding: 1rem 0;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      transform: translateY(-100%);
      opacity: 0;
      transition-property: opacity, transform;
      transform-origin: top right;
      transition: all 0.2s ease-in-out;
      box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125);
      border-radius: 0.375rem;
    }
  }
  @media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
    .navbar-expand-lg .navbar-collapse-overlay {
      transition: none;
    }
  }
  @media (max-width: 991.98px) {
    .navbar-expand-lg .navbar-collapse-overlay.show {
      opacity: 1;
      transform: translateY(0);
      transition: all 0.2s ease-in-out;
    }
  }
  @media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
    .navbar-expand-lg .navbar-collapse-overlay.show {
      transition: none;
    }
  }
  @media (max-width: 991.98px) {
    .navbar-expand-lg .navbar-collapse-overlay .nav-item {
      padding: 0 1.5rem;
    }
  }
  @media (min-width: 992px) {
    .navbar-expand-lg .navbar-brand img {
      height: auto;
    }
    .navbar-expand-lg .navbar-brand.dropdown-toggle:after {
      display: none;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-radius: 0;
    }
    .navbar-expand-lg .navbar-nav .nav-link-icon {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      border-radius: 0;
    }
    .navbar-expand-lg .navbar-nav .nav-link-icon i {
      margin-right: 0;
    }
    .navbar-expand-lg .navbar-collapse .collapse-header {
      display: none;
    }
  }
  @media (max-width: 1199.98px) {
    .navbar-expand-xl .navbar-brand img {
      height: 1.75rem;
    }
    .navbar-expand-xl .navbar-brand.dropdown-toggle:after {
      float: none;
      font-size: 1rem;
      opacity: 0.6;
    }
    .navbar-expand-xl .navbar-brand.dropdown-toggle:hover:after {
      opacity: 1;
    }
    .navbar-expand-xl .navbar-nav:not(.flex-row) .nav-link {
      padding: 0.625rem 0;
    }
    .navbar-expand-xl .navbar-nav:not(.flex-row) .dropdown-menu {
      box-shadow: none;
      min-width: auto;
    }
    .navbar-expand-xl .navbar-nav:not(.flex-row) .dropdown-menu .media svg {
      width: 30px;
    }
    .navbar-expand-xl .navbar-nav.flex-row .nav-link {
      padding-left: 0.675rem;
      padding-right: 0.675rem;
    }
    .navbar-expand-xl .navbar-nav.flex-row .dropdown {
      position: static;
    }
    .navbar-expand-xl .navbar-nav.flex-row .dropdown-menu {
      width: calc(100% - 30px);
      position: absolute;
      top: 60px;
      left: 15px;
      right: auto;
    }
    .navbar-expand-xl
      .navbar-nav
      .dropdown:not(.dropdown-submenu)
      > .dropdown-menu {
      display: block !important;
    }
    .navbar-expand-xl .navbar-collapse {
      background: #fff;
    }
    .navbar-expand-xl .navbar-collapse .navbar-nav .nav-item .nav-link {
      color: rgba(31, 45, 61, 0.6);
    }
    .navbar-expand-xl .navbar-collapse .navbar-nav .nav-item-spaced {
      margin-bottom: 1.5rem;
    }
    .navbar-expand-xl .navbar-collapse .navbar-nav .dropdown-item {
      color: rgba(31, 45, 61, 0.8);
    }
    .navbar-expand-xl .navbar-collapse .navbar-toggler {
      position: absolute;
      top: 0;
      right: 0.5rem;
      color: rgba(31, 45, 61, 0.6);
      z-index: 100;
    }
    .navbar-expand-xl .navbar-collapse-fade {
      z-index: 1050;
      height: auto !important;
      opacity: 0;
    }
    .navbar-expand-xl .navbar-collapse-fade.collapsing,
    .navbar-expand-xl .navbar-collapse-fade.show {
      animation: show-navbar-collapse 0.2s ease forwards;
      animation-delay: 0.15s;
    }
    .navbar-expand-xl .navbar-collapse-fade.collapsing-out {
      opacity: 0;
      transition: opacity 0.3s linear;
      animation-delay: 0;
      animation: none;
    }
    .navbar-expand-xl .navbar-collapse-overlay {
      position: fixed;
      top: 0;
      right: 5px;
      left: 5px;
      max-height: calc(100vh - 2rem) !important;
      z-index: 100;
      padding: 1rem 0;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      transform: translateY(-100%);
      opacity: 0;
      transition-property: opacity, transform;
      transform-origin: top right;
      transition: all 0.2s ease-in-out;
      box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125);
      border-radius: 0.375rem;
    }
  }
  @media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
    .navbar-expand-xl .navbar-collapse-overlay {
      transition: none;
    }
  }
  @media (max-width: 1199.98px) {
    .navbar-expand-xl .navbar-collapse-overlay.show {
      opacity: 1;
      transform: translateY(0);
      transition: all 0.2s ease-in-out;
    }
  }
  @media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
    .navbar-expand-xl .navbar-collapse-overlay.show {
      transition: none;
    }
  }
  @media (max-width: 1199.98px) {
    .navbar-expand-xl .navbar-collapse-overlay .nav-item {
      padding: 0 1.5rem;
    }
  }
  @media (min-width: 1200px) {
    .navbar-expand-xl .navbar-brand img {
      height: 1.75rem;
    }
    .navbar-expand-xl .navbar-brand.dropdown-toggle:after {
      display: none;
    }
    .navbar-expand-xl .navbar-nav .nav-link {
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-radius: 0;
    }
    .navbar-expand-xl .navbar-nav .nav-link-icon {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      border-radius: 0;
    }
    .navbar-expand-xl .navbar-nav .nav-link-icon i {
      margin-right: 0;
    }
    .navbar-expand-xl .navbar-collapse .collapse-header {
      display: none;
    }
  }
  .navbar-expand .navbar-brand img {
    height: 1.75rem;
  }
  .navbar-expand .navbar-brand.dropdown-toggle:after {
    float: none;
    font-size: 1rem;
    opacity: 0.6;
  }
  .navbar-expand .navbar-brand.dropdown-toggle:hover:after {
    opacity: 1;
  }
  .navbar-expand .navbar-nav:not(.flex-row) .nav-link {
    padding: 0.625rem 0;
  }
  .navbar-expand .navbar-nav:not(.flex-row) .dropdown-menu {
    box-shadow: none;
    min-width: auto;
  }
  .navbar-expand .navbar-nav:not(.flex-row) .dropdown-menu .media svg {
    width: 30px;
  }
  .navbar-expand .navbar-nav.flex-row .nav-link {
    padding-left: 0.675rem;
    padding-right: 0.675rem;
  }
  .navbar-expand .navbar-nav.flex-row .dropdown {
    position: static;
  }
  .navbar-expand .navbar-nav.flex-row .dropdown-menu {
    width: calc(100% - 30px);
    position: absolute;
    top: 60px;
    left: 15px;
    right: auto;
  }
  .navbar-expand .navbar-nav .dropdown:not(.dropdown-submenu) > .dropdown-menu {
    display: block !important;
  }
  .navbar-expand .navbar-collapse {
    background: #fff;
  }
  .navbar-expand .navbar-collapse .navbar-nav .nav-item .nav-link {
    color: rgba(31, 45, 61, 0.6);
  }
  .navbar-expand .navbar-collapse .navbar-nav .nav-item-spaced {
    margin-bottom: 1.5rem;
  }
  .navbar-expand .navbar-collapse .navbar-nav .dropdown-item {
    color: rgba(31, 45, 61, 0.8);
  }
  .navbar-expand .navbar-collapse .navbar-toggler {
    position: absolute;
    top: 0;
    right: 0.5rem;
    color: rgba(31, 45, 61, 0.6);
    z-index: 100;
  }
  .navbar-expand .navbar-collapse-fade {
    z-index: 1050;
    height: auto !important;
    opacity: 0;
  }
  .navbar-expand .navbar-collapse-fade.collapsing,
  .navbar-expand .navbar-collapse-fade.show {
    animation: show-navbar-collapse 0.2s ease forwards;
    animation-delay: 0.15s;
  }
  .navbar-expand .navbar-collapse-fade.collapsing-out {
    opacity: 0;
    transition: opacity 0.3s linear;
    animation-delay: 0;
    animation: none;
  }
  .navbar-expand .navbar-collapse-overlay {
    position: fixed;
    top: 0;
    right: 5px;
    left: 5px;
    max-height: calc(100vh - 2rem) !important;
    z-index: 100;
    padding: 1rem 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    transform: translateY(-100%);
    opacity: 0;
    transition-property: opacity, transform;
    transform-origin: top right;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125);
    border-radius: 0.375rem;
  }
  @media (prefers-reduced-motion: reduce) {
    .navbar-expand .navbar-collapse-overlay {
      transition: none;
    }
  }
  .navbar-expand .navbar-collapse-overlay.show {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.2s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .navbar-expand .navbar-collapse-overlay.show {
      transition: none;
    }
  }
  .navbar-expand .navbar-collapse-overlay .nav-item {
    padding: 0 1.5rem;
  }
  .navbar-expand .navbar-brand img {
    height: 1.75rem;
  }
  .navbar-expand .navbar-brand.dropdown-toggle:after {
    display: none;
  }
  .navbar-expand .navbar-nav .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 0;
  }
  .navbar-expand .navbar-nav .nav-link-icon {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    border-radius: 0;
  }
  .navbar-expand .navbar-nav .nav-link-icon i {
    margin-right: 0;
  }
  .navbar-expand .navbar-collapse .collapse-header {
    display: none;
  }
  .navbar-toggler-icon {
    width: 1.25em;
    height: 1.25em;
  }
  @keyframes show-navbar-collapse {
    0% {
      opacity: 0;
      transform: translateX(30px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  .pagination {
    margin-bottom: 0;
  }
  .page-item .page-link,
  .page-item > span {
    margin: 0 3px;
    border-radius: 0.2rem;
    text-align: center;
    font-size: 0.875rem;
    box-shadow: 0 0.125rem 0.25rem rgba(31, 45, 61, 0.08);
  }
  .pagination-circle .page-item .page-link,
  .pagination-circle .page-item > span {
    border-radius: 50% !important;
    margin: 0 5px;
    display: block;
    width: 36px;
    height: 36px;
  }
  .pagination-circle.pagination-lg .page-item .page-link,
  .pagination-circle.pagination-lg .page-item > span {
    border-radius: 50% !important;
    margin: 0 5px;
    display: block;
    width: 52px;
    height: 52px;
  }
  .popover {
    border: 0;
  }
  .popover-header {
    font-weight: 600;
  }
  .popover-primary {
    background-color: #008aff;
  }
  .popover-primary .popover-header {
    background-color: #008aff;
    color: #fff;
  }
  .popover-primary .popover-body {
    color: #fff;
  }
  .popover-primary .popover-header {
    border-color: rgba(255, 255, 255, 0.2);
  }
  .popover-primary.bs-popover-auto[x-placement^="top"] .arrow::after,
  .popover-primary.bs-popover-top .arrow::after {
    border-top-color: #008aff;
  }
  .popover-primary.bs-popover-auto[x-placement^="right"] .arrow::after,
  .popover-primary.bs-popover-right .arrow::after {
    border-right-color: #008aff;
  }
  .popover-primary.bs-popover-auto[x-placement^="bottom"] .arrow::after,
  .popover-primary.bs-popover-bottom .arrow::after {
    border-bottom-color: #008aff;
  }
  .popover-primary.bs-popover-auto[x-placement^="left"] .arrow::after,
  .popover-primary.bs-popover-left .arrow::after {
    border-left-color: #008aff;
  }
  .popover-secondary {
    background-color: #eaecf3;
  }
  .popover-secondary .popover-header {
    background-color: #eaecf3;
    color: #273444;
  }
  .popover-secondary .popover-body {
    color: #273444;
  }
  .popover-secondary .popover-header {
    border-color: rgba(39, 52, 68, 0.2);
  }
  .popover-secondary.bs-popover-auto[x-placement^="top"] .arrow::after,
  .popover-secondary.bs-popover-top .arrow::after {
    border-top-color: #eaecf3;
  }
  .popover-secondary.bs-popover-auto[x-placement^="right"] .arrow::after,
  .popover-secondary.bs-popover-right .arrow::after {
    border-right-color: #eaecf3;
  }
  .popover-secondary.bs-popover-auto[x-placement^="bottom"] .arrow::after,
  .popover-secondary.bs-popover-bottom .arrow::after {
    border-bottom-color: #eaecf3;
  }
  .popover-secondary.bs-popover-auto[x-placement^="left"] .arrow::after,
  .popover-secondary.bs-popover-left .arrow::after {
    border-left-color: #eaecf3;
  }
  .popover-success {
    background-color: #5cc9a7;
  }
  .popover-success .popover-header {
    background-color: #5cc9a7;
    color: #fff;
  }
  .popover-success .popover-body {
    color: #fff;
  }
  .popover-success .popover-header {
    border-color: rgba(255, 255, 255, 0.2);
  }
  .popover-success.bs-popover-auto[x-placement^="top"] .arrow::after,
  .popover-success.bs-popover-top .arrow::after {
    border-top-color: #5cc9a7;
  }
  .popover-success.bs-popover-auto[x-placement^="right"] .arrow::after,
  .popover-success.bs-popover-right .arrow::after {
    border-right-color: #5cc9a7;
  }
  .popover-success.bs-popover-auto[x-placement^="bottom"] .arrow::after,
  .popover-success.bs-popover-bottom .arrow::after {
    border-bottom-color: #5cc9a7;
  }
  .popover-success.bs-popover-auto[x-placement^="left"] .arrow::after,
  .popover-success.bs-popover-left .arrow::after {
    border-left-color: #5cc9a7;
  }
  .popover-info {
    background-color: #50b5ff;
  }
  .popover-info .popover-header {
    background-color: #50b5ff;
    color: #fff;
  }
  .popover-info .popover-body {
    color: #fff;
  }
  .popover-info .popover-header {
    border-color: rgba(255, 255, 255, 0.2);
  }
  .popover-info.bs-popover-auto[x-placement^="top"] .arrow::after,
  .popover-info.bs-popover-top .arrow::after {
    border-top-color: #50b5ff;
  }
  .popover-info.bs-popover-auto[x-placement^="right"] .arrow::after,
  .popover-info.bs-popover-right .arrow::after {
    border-right-color: #50b5ff;
  }
  .popover-info.bs-popover-auto[x-placement^="bottom"] .arrow::after,
  .popover-info.bs-popover-bottom .arrow::after {
    border-bottom-color: #50b5ff;
  }
  .popover-info.bs-popover-auto[x-placement^="left"] .arrow::after,
  .popover-info.bs-popover-left .arrow::after {
    border-left-color: #50b5ff;
  }
  .popover-warning {
    background-color: #ffbe3d;
  }
  .popover-warning .popover-header {
    background-color: #ffbe3d;
    color: #fff;
  }
  .popover-warning .popover-body {
    color: #fff;
  }
  .popover-warning .popover-header {
    border-color: rgba(255, 255, 255, 0.2);
  }
  .popover-warning.bs-popover-auto[x-placement^="top"] .arrow::after,
  .popover-warning.bs-popover-top .arrow::after {
    border-top-color: #ffbe3d;
  }
  .popover-warning.bs-popover-auto[x-placement^="right"] .arrow::after,
  .popover-warning.bs-popover-right .arrow::after {
    border-right-color: #ffbe3d;
  }
  .popover-warning.bs-popover-auto[x-placement^="bottom"] .arrow::after,
  .popover-warning.bs-popover-bottom .arrow::after {
    border-bottom-color: #ffbe3d;
  }
  .popover-warning.bs-popover-auto[x-placement^="left"] .arrow::after,
  .popover-warning.bs-popover-left .arrow::after {
    border-left-color: #ffbe3d;
  }
  .popover-danger {
    background-color: #d33139;
  }
  .popover-danger .popover-header {
    background-color: #d33139;
    color: #fff;
  }
  .popover-danger .popover-body {
    color: #fff;
  }
  .popover-danger .popover-header {
    border-color: rgba(255, 255, 255, 0.2);
  }
  .popover-danger.bs-popover-auto[x-placement^="top"] .arrow::after,
  .popover-danger.bs-popover-top .arrow::after {
    border-top-color: #d33139;
  }
  .popover-danger.bs-popover-auto[x-placement^="right"] .arrow::after,
  .popover-danger.bs-popover-right .arrow::after {
    border-right-color: #d33139;
  }
  .popover-danger.bs-popover-auto[x-placement^="bottom"] .arrow::after,
  .popover-danger.bs-popover-bottom .arrow::after {
    border-bottom-color: #d33139;
  }
  .popover-danger.bs-popover-auto[x-placement^="left"] .arrow::after,
  .popover-danger.bs-popover-left .arrow::after {
    border-left-color: #d33139;
  }
  .popover-light {
    background-color: #eaecf3;
  }
  .popover-light .popover-header {
    background-color: #eaecf3;
    color: #273444;
  }
  .popover-light .popover-body {
    color: #273444;
  }
  .popover-light .popover-header {
    border-color: rgba(39, 52, 68, 0.2);
  }
  .popover-light.bs-popover-auto[x-placement^="top"] .arrow::after,
  .popover-light.bs-popover-top .arrow::after {
    border-top-color: #eaecf3;
  }
  .popover-light.bs-popover-auto[x-placement^="right"] .arrow::after,
  .popover-light.bs-popover-right .arrow::after {
    border-right-color: #eaecf3;
  }
  .popover-light.bs-popover-auto[x-placement^="bottom"] .arrow::after,
  .popover-light.bs-popover-bottom .arrow::after {
    border-bottom-color: #eaecf3;
  }
  .popover-light.bs-popover-auto[x-placement^="left"] .arrow::after,
  .popover-light.bs-popover-left .arrow::after {
    border-left-color: #eaecf3;
  }
  .popover-dark {
    background-color: #171347;
  }
  .popover-dark .popover-header {
    background-color: #171347;
    color: #fff;
  }
  .popover-dark .popover-body {
    color: #fff;
  }
  .popover-dark .popover-header {
    border-color: rgba(255, 255, 255, 0.2);
  }
  .popover-dark.bs-popover-auto[x-placement^="top"] .arrow::after,
  .popover-dark.bs-popover-top .arrow::after {
    border-top-color: #171347;
  }
  .popover-dark.bs-popover-auto[x-placement^="right"] .arrow::after,
  .popover-dark.bs-popover-right .arrow::after {
    border-right-color: #171347;
  }
  .popover-dark.bs-popover-auto[x-placement^="bottom"] .arrow::after,
  .popover-dark.bs-popover-bottom .arrow::after {
    border-bottom-color: #171347;
  }
  .popover-dark.bs-popover-auto[x-placement^="left"] .arrow::after,
  .popover-dark.bs-popover-left .arrow::after {
    border-left-color: #171347;
  }
  .popover-neutral {
    background-color: #fff;
  }
  .popover-neutral .popover-header {
    background-color: #fff;
    color: #273444;
  }
  .popover-neutral .popover-body {
    color: #273444;
  }
  .popover-neutral .popover-header {
    border-color: rgba(39, 52, 68, 0.2);
  }
  .popover-neutral.bs-popover-auto[x-placement^="top"] .arrow::after,
  .popover-neutral.bs-popover-top .arrow::after {
    border-top-color: #fff;
  }
  .popover-neutral.bs-popover-auto[x-placement^="right"] .arrow::after,
  .popover-neutral.bs-popover-right .arrow::after {
    border-right-color: #fff;
  }
  .popover-neutral.bs-popover-auto[x-placement^="bottom"] .arrow::after,
  .popover-neutral.bs-popover-bottom .arrow::after {
    border-bottom-color: #fff;
  }
  .popover-neutral.bs-popover-auto[x-placement^="left"] .arrow::after,
  .popover-neutral.bs-popover-left .arrow::after {
    border-left-color: #fff;
  }
  .popover-white {
    background-color: #fff;
  }
  .popover-white .popover-header {
    background-color: #fff;
    color: #273444;
  }
  .popover-white .popover-body {
    color: #273444;
  }
  .popover-white .popover-header {
    border-color: rgba(39, 52, 68, 0.2);
  }
  .popover-white.bs-popover-auto[x-placement^="top"] .arrow::after,
  .popover-white.bs-popover-top .arrow::after {
    border-top-color: #fff;
  }
  .popover-white.bs-popover-auto[x-placement^="right"] .arrow::after,
  .popover-white.bs-popover-right .arrow::after {
    border-right-color: #fff;
  }
  .popover-white.bs-popover-auto[x-placement^="bottom"] .arrow::after,
  .popover-white.bs-popover-bottom .arrow::after {
    border-bottom-color: #fff;
  }
  .popover-white.bs-popover-auto[x-placement^="left"] .arrow::after,
  .popover-white.bs-popover-left .arrow::after {
    border-left-color: #fff;
  }
  .progress-wrapper {
    position: relative;
    padding-top: 1.5rem;
  }
  .progress-wrapper .progress {
    margin-bottom: 1rem;
  }
  .progress-inverse {
    background-color: inverse(#eaecf3);
  }
  .progress-heading {
    font-size: 0.875rem;
    font-weight: 600;
    margin: 0 0 2px;
    padding: 0;
  }
  .progress-text {
    margin-bottom: 0;
  }
  .progress-lg {
    height: 1rem;
  }
  .progress-md {
    height: 0.75rem;
  }
  .progress-sm {
    height: 0.375rem;
  }
  .progress-xs {
    height: 0.125rem;
  }
  .progress-group {
    position: relative;
  }
  .progress-prepend-icon {
    position: absolute;
    transform: translateY(-50%);
    font-size: 20px;
    top: 50%;
  }
  .progress-prepend-icon:not(:first-child) {
    right: -17px;
  }
  .progress-prepend-icon:not(:last-child) {
    left: -17px;
  }
  .progress-tooltip {
    display: inline-block;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 0.25rem 0.375rem;
    line-height: 1;
    font-size: 0.7rem;
    position: relative;
    bottom: 8px;
    border-radius: 3px;
    margin-left: -15px;
  }
  .progress-tooltip:after {
    top: 100%;
    left: 10px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(51, 51, 51, 0);
    border-top-color: rgba(0, 0, 0, 0.8);
    border-width: 5px;
  }
  .progress-label {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    color: #121212;
  }
  .progress-percentage {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
  }
  .static-rating {
    display: inline-block;
  }
  .static-rating .star {
    color: #cbd5e0;
  }
  .static-rating .voted {
    color: #ffc431;
  }
  .static-rating-lg .star {
    font-size: 1.25rem;
  }
  .static-rating-sm .star {
    font-size: 0.75rem;
  }
  .rating {
    display: inline-block;
  }
  .rating .star {
    font-family: "Font Awesome 5 Solid";
    font-weight: 400;
    font-style: normal;
    float: left;
    padding: 0 1px;
    cursor: pointer;
  }
  .rating .star:before {
    content: "\f005";
    display: block;
    font-size: 14px;
    color: #cbd5e0;
    transition: all 0.2s linear;
  }
  .rating:hover .star:before {
    color: #cbd5e0;
  }
  .rating:hover .over:before {
    color: #ffc431;
  }
  .rating .voted:before {
    color: #ffc431;
  }
  .rating-lg .star:before {
    font-size: 18px;
  }
  .rating-xl .star:before {
    font-size: 24px;
  }
  .ribbon {
    position: absolute;
    top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    font-size: 0.75rem;
    font-weight: 600;
    z-index: 10;
  }
  .ribbon-left {
    left: 1rem;
  }
  .ribbon-right {
    right: 1rem;
  }
  .ribbon-primary {
    color: #fff;
    background-color: #33a1ff;
  }
  .ribbon-secondary {
    color: #273444;
    background-color: #fff;
  }
  .ribbon-success {
    color: #fff;
    background-color: #82d6bc;
  }
  .ribbon-info {
    color: #fff;
    background-color: #83cbff;
  }
  .ribbon-warning {
    color: #fff;
    background-color: #ffcf70;
  }
  .ribbon-danger {
    color: #fff;
    background-color: #f68692;
  }
  .ribbon-light {
    color: #273444;
    background-color: #fff;
  }
  .ribbon-dark {
    color: #fff;
    background-color: #241e6f;
  }
  .ribbon-neutral {
    color: #273444;
    background-color: #fff;
  }
  .ribbon-white {
    color: #273444;
    background-color: #fff;
  }
  .sidebar-sticky.is_stuck {
    padding-top: 1.5rem;
  }
  .table thead th {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 0.75rem;
    text-transform: none;
    letter-spacing: 0;
    background-color: transparent;
    border-bottom-width: 1px;
  }
  .table th {
    font-weight: 600;
  }
  .table td .progress {
    height: 3px;
    width: 120px;
    margin: 0;
  }
  .table td,
  .table th {
    font-size: 0.8125rem;
    white-space: nowrap;
  }
  .table.align-items-center td,
  .table.align-items-center th {
    vertical-align: middle;
  }
  .table .thead-dark th,
  .table.table-dark thead th {
    background-color: #1c1757;
    color: #4b40cc;
  }
  .table .thead-dark th a,
  .table.table-dark thead th a {
    color: #4b40cc;
  }
  .table .thead-light th {
    background-color: transparent;
    color: #4a5568;
  }
  .table .thead-light th a {
    color: #4a5568;
  }
  .table-hover tr {
    transition: all 0.2s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .table-hover tr {
      transition: none;
    }
  }
  .table-flush td,
  .table-flush th {
    border-left: 0;
    border-right: 0;
  }
  .table-flush tbody tr:first-child td,
  .table-flush tbody tr:first-child th {
    border-top: 0;
  }
  .table-flush tbody tr:last-child td,
  .table-flush tbody tr:last-child th {
    border-bottom: 0;
  }
  .card .table {
    margin-bottom: 0;
  }
  .card .table td,
  .card .table th {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .table .custom-toggle {
    display: block;
  }
  .table-flush td,
  .table-flush th {
    border-left: 0;
    border-right: 0;
  }
  .table-flush tr:first-child td,
  .table-flush tr:first-child th {
    border-top: 0;
  }
  .table-flush tr:last-child td,
  .table-flush tr:last-child th {
    border-bottom: 0;
  }
  .h1,
  .h2,
  .h3,
  h1,
  h2,
  h3 {
    font-weight: 600;
  }
  .h4,
  .h5,
  .h6,
  h4,
  h5,
  h6 {
    font-weight: 600;
  }
  .display-1 a,
  .display-2 a,
  .display-3 a,
  .display-4 a,
  .h1 a,
  .h2 a,
  .h3 a,
  .h4 a,
  .h5 a,
  .h6 a,
  h1 a,
  h2 a,
  h3 a,
  h4 a,
  h5 a,
  h6 a {
    color: #121212;
  }
  .display-1 a:hover,
  .display-2 a:hover,
  .display-3 a:hover,
  .display-4 a:hover,
  .h1 a:hover,
  .h2 a:hover,
  .h3 a:hover,
  .h4 a:hover,
  .h5 a:hover,
  .h6 a:hover,
  h1 a:hover,
  h2 a:hover,
  h3 a:hover,
  h4 a:hover,
  h5 a:hover,
  h6 a:hover {
    color: #008aff;
  }
  a {
    outline: 0;
    transition: all 0.2s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    a {
      transition: none;
    }
  }
  .surtitle {
    color: #718096;
    font-size: 0.675rem;
    font-weight: 400;
    text-transform: uppercase;
  }
  .fluid-paragraph {
    width: 680px;
    margin: auto;
    padding: 0 1.5rem;
    position: relative;
  }
  .fluid-paragraph-sm {
    width: 580px;
  }
  @media (max-width: 767.98px) {
    .fluid-paragraph {
      width: 100%;
    }
  }
  .text-highlight-primary {
    background-image: linear-gradient(
      transparent 66%,
      rgba(0, 138, 255, 0.15) 34%
    );
    background-repeat: repeat-x;
  }
  .text-highlight-secondary {
    background-image: linear-gradient(
      transparent 66%,
      rgba(234, 236, 243, 0.15) 34%
    );
    background-repeat: repeat-x;
  }
  .text-highlight-success {
    background-image: linear-gradient(
      transparent 66%,
      rgba(92, 201, 167, 0.15) 34%
    );
    background-repeat: repeat-x;
  }
  .text-highlight-info {
    background-image: linear-gradient(
      transparent 66%,
      rgba(80, 181, 255, 0.15) 34%
    );
    background-repeat: repeat-x;
  }
  .text-highlight-warning {
    background-image: linear-gradient(
      transparent 66%,
      rgba(255, 190, 61, 0.15) 34%
    );
    background-repeat: repeat-x;
  }
  .text-highlight-danger {
    background-image: linear-gradient(
      transparent 66%,
      rgba(242, 87, 103, 0.15) 34%
    );
    background-repeat: repeat-x;
  }
  .text-highlight-light {
    background-image: linear-gradient(
      transparent 66%,
      rgba(234, 236, 243, 0.15) 34%
    );
    background-repeat: repeat-x;
  }
  .text-highlight-dark {
    background-image: linear-gradient(
      transparent 66%,
      rgba(23, 19, 71, 0.15) 34%
    );
    background-repeat: repeat-x;
  }
  .text-highlight-neutral {
    background-image: linear-gradient(
      transparent 66%,
      rgba(255, 255, 255, 0.15) 34%
    );
    background-repeat: repeat-x;
  }
  .text-highlight-white {
    background-image: linear-gradient(
      transparent 66%,
      rgba(255, 255, 255, 0.15) 34%
    );
    background-repeat: repeat-x;
  }
  .link {
    transition: all 0.2s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .link {
      transition: none;
    }
  }
  [class*="link-underline-"] {
    padding-bottom: 8px;
    position: relative;
  }
  [class*="link-underline-"]:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 3px;
    border-radius: 50rem;
    transition: all 0.2s cubic-bezier(0.77, 0, 0.2, 2.25);
  }
  @media (prefers-reduced-motion: reduce) {
    [class*="link-underline-"]:after {
      transition: none;
    }
  }
  [class*="link-underline-"]:hover:after {
    width: calc(100% + 8px);
    left: -4px;
  }
  .link-underline-primary {
    color: #008aff;
  }
  .link-underline-primary:after {
    background-color: #008aff;
  }
  .link-underline-primary:hover {
    color: #b62d34;
  }
  .link-underline-secondary {
    color: #eaecf3;
  }
  .link-underline-secondary:after {
    background-color: #eaecf3;
  }
  .link-underline-secondary:hover {
    color: #cacfe0;
  }
  .link-underline-success {
    color: #5cc9a7;
  }
  .link-underline-success:after {
    background-color: #5cc9a7;
  }
  .link-underline-success:hover {
    color: #3cb690;
  }
  .link-underline-info {
    color: #50b5ff;
  }
  .link-underline-info:after {
    background-color: #50b5ff;
  }
  .link-underline-info:hover {
    color: #1d9fff;
  }
  .link-underline-warning {
    color: #ffbe3d;
  }
  .link-underline-warning:after {
    background-color: #ffbe3d;
  }
  .link-underline-warning:hover {
    color: #ffad0a;
  }
  .link-underline-danger {
    color: #d33139;
  }
  .link-underline-danger:after {
    background-color: #d33139;
  }
  .link-underline-danger:hover {
    color: #b62d34;
  }
  .link-underline-light {
    color: #eaecf3;
  }
  .link-underline-light:after {
    background-color: #eaecf3;
  }
  .link-underline-light:hover {
    color: #cacfe0;
  }
  .link-underline-dark {
    color: #171347;
  }
  .link-underline-dark:after {
    background-color: #171347;
  }
  .link-underline-dark:hover {
    color: #0a081f;
  }
  .link-underline-neutral {
    color: #fff;
  }
  .link-underline-neutral:after {
    background-color: #fff;
  }
  .link-underline-neutral:hover {
    color: #e6e6e6;
  }
  .link-underline-white {
    color: #fff;
  }
  .link-underline-white:after {
    background-color: #fff;
  }
  .link-underline-white:hover {
    color: #e6e6e6;
  }
  .blockquote .quote {
    position: absolute;
  }
  .blockquote .quote:before {
    font-family: Arial;
    content: "\201C";
    color: #008aff;
    font-size: 4em;
    line-height: 1;
  }
  .blockquote .quote + .quote-text {
    padding-left: 2.25rem;
  }
  .blockquote footer {
    background: 0 0;
  }
  .blockquote-border-left {
    border-left: 0.5rem solid #008aff;
  }
  .blockquote-card {
    padding: 1.2em 30px 1.2em 75px;
    border-left: 0.5rem solid #008aff;
    position: relative;
  }
  .blockquote-card:before {
    font-family: Arial;
    content: "\201C";
    color: #008aff;
    font-size: 4em;
    position: absolute;
    left: 0.875rem;
    top: -0.875rem;
  }
  .blockquote-card:after {
    content: "";
  }
  .list-icons li i,
  .list-icons li svg {
    margin-right: 1rem;
    font-size: 8px;
    transform: translateY(-50%);
    top: 2px;
    position: relative;
  }
  article h4:not(:first-child),
  article h5:not(:first-child) {
    margin-top: 3rem;
  }
  article h4,
  article h5 {
    margin-bottom: 1.5rem;
  }
  article figure {
    margin: 3rem 0;
  }
  article figure + h5 {
    margin-top: 0 !important;
  }
  article h5 + figure {
    margin-top: 0;
  }
  article p {
    line-height: 1.9;
  }
  .feather {
    width: 1em;
    height: 1em;
    fill: none;
    stroke: currentcolor;
    stroke-width: 3px;
    strokelinecap: round;
    strokelinejoin: round;
  }
  .svg-text {
    display: inline-flex;
    align-items: center;
  }
  .svg-text svg {
    position: relative;
  }
  .customizer {
    position: fixed;
    bottom: 2rem;
    left: 2rem;
    width: 300px;
    height: 60px;
    z-index: 200;
  }
  .customizer .btn-skins {
    background: #6e00ff;
    background: linear-gradient(
      135deg,
      #6e00ff 0,
      #00b8d9 18%,
      #00b8d9 18%,
      #36b37e 37%,
      #ff5630 50%,
      #ff5630 65%,
      #ffab00 83%,
      #ffab00 100%
    );
  }
  .card-translucent-dark {
    background: rgba(255, 255, 255, 0.2);
  }
  .card-translucent-light {
    background: rgba(0, 0, 0, 0.2);
  }
  .card-comment-box {
    width: 100%;
  }
  .card-comment-box input,
  .card-comment-box textarea {
    font-size: 0.875rem;
    border: 0;
    box-shadow: none;
    background: 0 0;
    padding: 0.3125rem 0;
    margin: 0;
    color: #2d3748;
    outline: 0;
    resize: none;
  }
  .card-comment-box input:focus,
  .card-comment-box textarea:focus {
    background: 0 0;
    box-shadow: none;
  }
  .card-serial-number {
    display: flex;
    justify-content: space-between;
  }
  .card-serial-number > div:not(:last-child) {
    display: flex;
    flex: 1 1 auto;
  }
  .card-serial-number > div:not(:last-child):after {
    content: "-";
    flex: 1 1 auto;
    text-align: center;
    position: relative;
    left: -2px;
  }
  @media (max-width: 575.98px) {
    .card-serial-number {
      font-size: 1.25rem;
    }
  }
  .card-profile .card-profile-image {
    width: 7rem;
    height: 7rem;
    border: 0.1875rem solid #fff;
    transform: translateY(-50%);
  }
  .card-profile .card-profile-image:hover {
    transform: scale(1.1) translateY(-50%);
  }
  .card-profile .card-body {
    position: relative;
    z-index: 2;
    margin-top: -3.5rem;
  }
  .card-profile-cover {
    max-height: 300px;
    overflow: hidden;
  }
  .card-product {
    overflow: hidden;
  }
  .card-product .card-image {
    padding: 1.5rem;
  }
  .card-product .card-image img {
    transform: scale(1);
    transition: all 0.2s ease;
    transition-delay: 0.2s;
  }
  @media (prefers-reduced-motion: reduce) {
    .card-product .card-image img {
      transition: none;
    }
  }
  .card-product:hover .card-image img {
    transform: scale(1.1);
  }
  .card-product .card-title {
    font-size: 1rem;
    font-weight: 600;
  }
  .card-product .card-price {
    font-size: 1rem;
    font-weight: 600;
    display: block;
  }
  .card-product .product-colors a {
    display: inline-block;
    float: none !important;
    width: 1rem;
    height: 1rem;
    margin-right: 3px;
    margin-bottom: 0;
    border-radius: 50%;
    transition: all 0.2s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .card-product .product-colors a {
      transition: none;
    }
  }
  .card-product .product-colors a:hover {
    transform: scale(1.1);
  }
  .card-product .card-product-actions {
    opacity: 0;
    width: 80px;
    height: 40px;
    position: absolute;
    top: 10px;
    left: 10px;
  }
  .card-product .card-product-actions.in {
    opacity: 1;
  }
  .card-product .card-product-actions.animated {
    animation-duration: 0.5s;
  }
  .card-product .card-product-actions .action-item {
    display: inline-block;
    float: left;
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
  .card-browser {
    background-color: #171347;
    position: relative;
  }
  .card-browser:before {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: -webkit-linear-gradient(40deg, transparent 0, #273444 100%);
    content: "";
    border-radius: 6px;
    will-change: transform;
  }
  .card-browser .card-header {
    position: relative;
    background-color: rgba(36, 30, 111, 0.8);
    border: 0;
  }
  .card-browser .card-header .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 3px;
  }
  .card-browser .card-body {
    min-height: 300px;
    position: relative;
    overflow-x: hidden;
  }
  .card-browser .card-body pre {
    color: #fff;
    margin: 0;
  }
  .accordion .card {
    margin-bottom: 0;
  }
  .accordion .card-header {
    position: relative;
    cursor: pointer;
  }
  .accordion .card-header:after {
    content: "+";
    position: absolute;
    right: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
    font-family: "Nunito Sans", sans-serif;
    font-weight: 700;
  }
  .accordion .card-header[aria-expanded="false"]:after {
    content: "+";
  }
  .accordion .card-header[aria-expanded="true"]:after {
    content: "-";
  }
  .accordion .card-header[aria-expanded="true"] .heading {
    color: #008aff;
  }
  .accordion-spaced .card {
    margin-bottom: 1.5rem;
    border-radius: 0.75rem !important;
  }
  .client img {
    width: 100%;
    max-width: 6rem;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .collage {
    height: 600px;
  }
  .collage .bg-img-holder {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  .collage .collage-bg {
    z-index: 3;
  }
  .collage .collage-back {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .collage .collage-back img {
    max-width: 100%;
  }
  .collage .collage-front {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  .collage .collage-front img {
    max-height: 100%;
  }
  @media (max-width: 991.98px) {
    .collage .collage-front {
      height: 100% !important;
    }
  }
  .divider {
    position: relative;
    border: 0;
    width: 100%;
  }
  .divider-md {
    width: 60%;
  }
  .divider-sm {
    width: 40%;
  }
  .divider-icon {
    height: 20px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle fill='none' stroke='%23d1dbe7' stroke-width='1' cx='10' cy='10' r='7'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
  .divider-icon:after,
  .divider-icon:before {
    content: "";
    position: absolute;
    top: 50%;
    max-width: calc(50% - (50px / 2));
    width: 100%;
    border-bottom: 1px solid #d1dbe7;
  }
  .divider-icon:before {
    right: calc(50% + (50px / 2));
  }
  .divider-icon:after {
    left: calc(50% + (50px / 2));
  }
  .divider-icon-fade:after,
  .divider-icon-fade:before {
    border: 0;
    height: 1px;
  }
  .divider-icon-fade:before {
    background: radial-gradient(
      ellipse at right,
      #d1dbe7 0,
      rgba(255, 255, 255, 0) 75%
    );
  }
  .divider-icon-fade:after {
    background: radial-gradient(
      ellipse at left,
      #d1dbe7 0,
      rgba(255, 255, 255, 0) 75%
    );
  }
  .divider-fade:before {
    content: "";
    display: block;
    width: 80%;
    position: relative;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 1px;
    background: radial-gradient(
      ellipse at center,
      #d1dbe7 0,
      rgba(255, 255, 255, 0) 75%
    );
  }
  .divider-fade.divider-dark:before {
    background: radial-gradient(
      ellipse at center,
      rgba(255, 255, 255, 0.2) 0,
      rgba(31, 45, 61, 0) 75%
    );
  }
  .divider-vertical {
    width: 1px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    border-left: 1px solid #d1dbe7;
  }
  .divider-vertical-fade {
    border: 0;
    background: radial-gradient(
      ellipse at center,
      #d1dbe7 0,
      rgba(255, 255, 255, 0) 75%
    );
  }
  .frame-laptop {
    position: relative;
  }
  .frame-laptop .frame-inner {
    position: absolute;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 74%;
    height: 82%;
    margin: 3% 13% 0 13%;
  }
  .frame-iphone {
    position: relative;
    z-index: 100;
  }
  .frame-iphone .frame-inner {
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 72%;
    height: 84%;
    margin: 8% 14% 0 14%;
    border-radius: 5%;
  }
  .frame-stack {
    position: relative;
  }
  .frame-stack .frame {
    position: absolute;
  }
  .frame-stack-iphone-iphone {
    padding-bottom: 130%;
  }
  .frame-stack-iphone-iphone > .frame-iphone:first-child {
    bottom: 0;
    left: 0;
    width: 65%;
    z-index: 1;
  }
  .frame-stack-iphone-iphone > .frame-iphone:last-child {
    top: 0;
    right: 0;
    width: 72%;
    z-index: 0;
  }
  .frame-stack-iphone-laptop,
  .frame-stack-laptop-iphone {
    padding-bottom: 62%;
  }
  .frame-stack-iphone-laptop > .frame-laptop,
  .frame-stack-laptop-iphone > .frame-laptop {
    width: 91%;
  }
  .frame-stack-iphone-laptop > .frame-iphone,
  .frame-stack-laptop-iphone > .frame-iphone {
    width: 27%;
    z-index: 1;
  }
  .frame-stack-laptop-iphone > .frame-laptop {
    top: 0;
    left: 0;
  }
  .frame-stack-iphone-laptop > .frame-laptop {
    top: 0;
    right: 0;
  }
  .frame-stack-laptop-iphone > .frame-iphone {
    bottom: 0;
    right: 0;
  }
  .frame-stack-iphone-laptop > .frame-iphone {
    bottom: 0;
    left: 0;
  }
  .list-group-emphasized .list-group-item {
    transform: scale(1);
    transition: all 0.2s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .list-group-emphasized .list-group-item {
      transition: none;
    }
  }
  .list-group-emphasized .list-group-item.active {
    z-index: 11;
    transform: scale(1.05);
    background-color: #fafbfe;
    box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.05);
    border-radius: 0.5rem;
  }
  .list-group-emphasized .list-group-item.active .media a {
    color: #008aff;
  }
  .media-comment + .media-comment {
    margin-top: 1.25rem;
  }
  .media-comment-bubble {
    border-radius: 20px;
    border-top-left-radius: 0;
    position: relative;
    background-color: #f2f2f2;
    padding: 1rem 1.25rem;
  }
  .media-comment-bubble.left-top:after {
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
    left: -16px;
    right: auto;
    top: 0;
    bottom: auto;
    border: 18px solid transparent;
    border-top-color: #f2f2f2;
  }
  .modal-vertical {
    height: calc(100% - 0px);
    max-width: 350px;
    margin: 0 0;
  }
  .modal-vertical.modal-lg {
    max-width: 450px;
  }
  .modal-vertical .modal-content {
    height: inherit;
    border-width: 0;
    border-radius: 0;
  }
  .modal-vertical .modal-body {
    height: inherit;
    overflow-y: auto;
  }
  .modal.fade .modal-dialog-vertical {
    transform: translateX(-100%);
  }
  .modal.show .modal-dialog-vertical {
    transform: translateX(0);
  }
  .modal.fixed-right {
    padding-right: 0 !important;
  }
  .modal.fixed-right .modal-vertical {
    margin-left: auto;
  }
  .modal.fixed-right.fade .modal-vertical {
    transform: translateX(100%);
  }
  .modal.fixed-right.show .modal-vertical {
    transform: translateX(0);
  }
  .nav-dots {
    top: 50%;
    right: 2rem;
    position: fixed;
    z-index: 200;
    transform: translateY(-50%);
  }
  .nav-dots .nav-link {
    display: block;
    margin: 5px;
    position: relative;
  }
  .nav-dots .nav-link:before {
    content: "";
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    height: 6px;
    width: 6px;
    border: 0;
    background: #fff;
    left: 50%;
    top: 50%;
    margin: -2px 0 0 -2px;
    transform: translate(-50%, -50%);
    transition: all 0.1s ease-in-out;
  }
  .nav-dots .nav-link.active:before,
  .nav-dots .nav-link:hover:before {
    height: 12px;
    width: 12px;
  }
  .navbar {
    position: relative;
    z-index: 101;
  }
  .navbar .container {
    position: relative;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  .navbar-nav .nav-link {
    font-size: 1rem;
    font-weight: 600;
    text-transform: none;
    letter-spacing: 0;
    transition: all 0.15s linear;
  }
  @media (prefers-reduced-motion: reduce) {
    .navbar-nav .nav-link {
      transition: none;
    }
  }
  .navbar-nav .nav-link i,
  .navbar-nav .nav-link img,
  .navbar-nav .nav-link svg {
    margin-right: 0.5rem;
  }
  .navbar-nav .media-pill .avatar {
    width: 36px;
    height: 36px;
  }
  .nav-link > img,
  .nav-link > svg {
    height: 14px;
    margin-right: 0.5rem;
    position: relative;
    top: -1px;
  }
  .nav-link .icon-flag {
    width: 19px;
  }
  .navbar-text {
    font-size: 1rem;
  }
  .navbar-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
  .navbar-img-brand {
    font-size: initial;
  }
  .navbar-shadow {
    box-shadow: 0 0 10px rgba(31, 45, 61, 0.03);
  }
  .header,
  .navbar,
  .navbar-top {
    transition: all 0.2s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .header,
    .navbar,
    .navbar-top {
      transition: none;
    }
  }
  .navbar-border.navbar-dark {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
  }
  .navbar-border.navbar-dark .border-bottom {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
  }
  .navbar-border.navbar-light {
    border-bottom: 1px solid rgba(31, 45, 61, 0.04) !important;
  }
  .navbar-border.navbar-light .border-bottom {
    border-bottom: 1px solid rgba(31, 45, 61, 0.1) !important;
  }
  .navbar-dark .border-bottom {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
  }
  .navbar-light .border-bottom {
    border-bottom: 1px solid rgba(31, 45, 61, 0.1) !important;
  }
  .header-transparent {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1030;
  }
  .header-transparent .navbar-main .container:not(.border-0)::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: -0.75rem;
    left: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
  .header-transparent .navbar-top {
    background-color: transparent !important;
    border: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .header-transparent .navbar:not(.sticky) {
    background-color: transparent !important;
    border: 0;
    box-shadow: none;
  }
  .header-transparent
    + main
    section:first-child:not([data-spotlight])
    .container {
    padding-top: 5rem;
  }
  .header-collapse-show {
    min-height: 100%;
    width: 100%;
    top: 0;
    z-index: 1000;
  }
  .navbar-sticky {
    position: -webkit-sticky;
  }
  .navbar-sticky.sticky {
    position: fixed;
    width: 100%;
    left: 0;
    top: -100px;
    transform: translateY(100px);
    z-index: 1030;
    border-top: 0;
    box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125);
    transition: all 0.2s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .navbar-sticky.sticky {
      transition: none;
    }
  }
  .navbar-top {
    position: relative;
    display: flex;
    align-items: center;
  }
  .navbar-top .navbar-nav {
    flex-direction: row;
  }
  .navbar-top .navbar-nav .nav-item:last-child {
    margin-right: 0;
  }
  .navbar-top .navbar-nav .nav-link {
    padding: 0.5rem 0.5rem;
  }
  @media (max-width: 575.98px) {
    .navbar-expand-sm .navbar-brand img {
      height: 1.75rem;
    }
    .navbar-expand-sm .navbar-brand.dropdown-toggle:after {
      float: none;
      font-size: 1rem;
      opacity: 0.6;
    }
    .navbar-expand-sm .navbar-brand.dropdown-toggle:hover:after {
      opacity: 1;
    }
    .navbar-expand-sm .navbar-nav:not(.flex-row) .nav-link {
      padding: 0.625rem 0;
    }
    .navbar-expand-sm .navbar-nav:not(.flex-row) .dropdown-menu {
      box-shadow: none;
      min-width: auto;
    }
    .navbar-expand-sm .navbar-nav:not(.flex-row) .dropdown-menu .media svg {
      width: 30px;
    }
    .navbar-expand-sm .navbar-nav.flex-row .nav-link {
      padding-left: 0.675rem;
      padding-right: 0.675rem;
    }
    .navbar-expand-sm .navbar-nav.flex-row .dropdown {
      position: static;
    }
    .navbar-expand-sm .navbar-nav.flex-row .dropdown-menu {
      width: calc(100% - 30px);
      position: absolute;
      top: 60px;
      left: 15px;
      right: auto;
    }
    .navbar-expand-sm
      .navbar-nav
      .dropdown:not(.dropdown-submenu)
      > .dropdown-menu {
      display: block !important;
    }
    .navbar-expand-sm .navbar-collapse {
      background: #fff;
    }
    .navbar-expand-sm .navbar-collapse .navbar-nav .nav-item .nav-link {
      color: rgba(31, 45, 61, 0.6);
    }
    .navbar-expand-sm .navbar-collapse .navbar-nav .nav-item-spaced {
      margin-bottom: 1.5rem;
    }
    .navbar-expand-sm .navbar-collapse .navbar-nav .dropdown-item {
      color: rgba(31, 45, 61, 0.8);
    }
    .navbar-expand-sm .navbar-collapse .navbar-toggler {
      position: absolute;
      top: 0;
      right: 0.5rem;
      color: rgba(31, 45, 61, 0.6);
      z-index: 100;
    }
    .navbar-expand-sm .navbar-collapse-fade {
      z-index: 1050;
      height: auto !important;
      opacity: 0;
    }
    .navbar-expand-sm .navbar-collapse-fade.collapsing,
    .navbar-expand-sm .navbar-collapse-fade.show {
      animation: show-navbar-collapse 0.2s ease forwards;
      animation-delay: 0.15s;
    }
    .navbar-expand-sm .navbar-collapse-fade.collapsing-out {
      opacity: 0;
      transition: opacity 0.3s linear;
      animation-delay: 0;
      animation: none;
    }
    .navbar-expand-sm .navbar-collapse-overlay {
      position: fixed;
      top: 0;
      right: 5px;
      left: 5px;
      max-height: calc(100vh - 2rem) !important;
      z-index: 100;
      padding: 1rem 0;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      transform: translateY(-100%);
      opacity: 0;
      transition-property: opacity, transform;
      transform-origin: top right;
      transition: all 0.2s ease-in-out;
      box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125);
      border-radius: 0.375rem;
    }
  }
  @media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
    .navbar-expand-sm .navbar-collapse-overlay {
      transition: none;
    }
  }
  @media (max-width: 575.98px) {
    .navbar-expand-sm .navbar-collapse-overlay.show {
      opacity: 1;
      transform: translateY(0);
      transition: all 0.2s ease-in-out;
    }
  }
  @media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
    .navbar-expand-sm .navbar-collapse-overlay.show {
      transition: none;
    }
  }
  @media (max-width: 575.98px) {
    .navbar-expand-sm .navbar-collapse-overlay .nav-item {
      padding: 0 1.5rem;
    }
  }
  @media (min-width: 576px) {
    .navbar-expand-sm .navbar-brand img {
      height: 1.75rem;
    }
    .navbar-expand-sm .navbar-brand.dropdown-toggle:after {
      display: none;
    }
    .navbar-expand-sm .navbar-nav .nav-link {
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-radius: 0;
    }
    .navbar-expand-sm .navbar-nav .nav-link-icon {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      border-radius: 0;
    }
    .navbar-expand-sm .navbar-nav .nav-link-icon i {
      margin-right: 0;
    }
    .navbar-expand-sm .navbar-collapse .collapse-header {
      display: none;
    }
  }
  @media (max-width: 767.98px) {
    .navbar-expand-md .navbar-brand img {
      height: 1.75rem;
    }
    .navbar-expand-md .navbar-brand.dropdown-toggle:after {
      float: none;
      font-size: 1rem;
      opacity: 0.6;
    }
    .navbar-expand-md .navbar-brand.dropdown-toggle:hover:after {
      opacity: 1;
    }
    .navbar-expand-md .navbar-nav:not(.flex-row) .nav-link {
      padding: 0.625rem 0;
    }
    .navbar-expand-md .navbar-nav:not(.flex-row) .dropdown-menu {
      box-shadow: none;
      min-width: auto;
    }
    .navbar-expand-md .navbar-nav:not(.flex-row) .dropdown-menu .media svg {
      width: 30px;
    }
    .navbar-expand-md .navbar-nav.flex-row .nav-link {
      padding-left: 0.675rem;
      padding-right: 0.675rem;
    }
    .navbar-expand-md .navbar-nav.flex-row .dropdown {
      position: static;
    }
    .navbar-expand-md .navbar-nav.flex-row .dropdown-menu {
      width: calc(100% - 30px);
      position: absolute;
      top: 60px;
      left: 15px;
      right: auto;
    }
    .navbar-expand-md
      .navbar-nav
      .dropdown:not(.dropdown-submenu)
      > .dropdown-menu {
      display: block !important;
    }
    .navbar-expand-md .navbar-collapse {
      background: #fff;
    }
    .navbar-expand-md .navbar-collapse .navbar-nav .nav-item .nav-link {
      color: rgba(31, 45, 61, 0.6);
    }
    .navbar-expand-md .navbar-collapse .navbar-nav .nav-item-spaced {
      margin-bottom: 1.5rem;
    }
    .navbar-expand-md .navbar-collapse .navbar-nav .dropdown-item {
      color: rgba(31, 45, 61, 0.8);
    }
    .navbar-expand-md .navbar-collapse .navbar-toggler {
      position: absolute;
      top: 0;
      right: 0.5rem;
      color: rgba(31, 45, 61, 0.6);
      z-index: 100;
    }
    .navbar-expand-md .navbar-collapse-fade {
      z-index: 1050;
      height: auto !important;
      opacity: 0;
    }
    .navbar-expand-md .navbar-collapse-fade.collapsing,
    .navbar-expand-md .navbar-collapse-fade.show {
      animation: show-navbar-collapse 0.2s ease forwards;
      animation-delay: 0.15s;
    }
    .navbar-expand-md .navbar-collapse-fade.collapsing-out {
      opacity: 0;
      transition: opacity 0.3s linear;
      animation-delay: 0;
      animation: none;
    }
    .navbar-expand-md .navbar-collapse-overlay {
      position: fixed;
      top: 0;
      right: 5px;
      left: 5px;
      max-height: calc(100vh - 2rem) !important;
      z-index: 100;
      padding: 1rem 0;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      transform: translateY(-100%);
      opacity: 0;
      transition-property: opacity, transform;
      transform-origin: top right;
      transition: all 0.2s ease-in-out;
      box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125);
      border-radius: 0.375rem;
    }
  }
  @media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
    .navbar-expand-md .navbar-collapse-overlay {
      transition: none;
    }
  }
  @media (max-width: 767.98px) {
    .navbar-expand-md .navbar-collapse-overlay.show {
      opacity: 1;
      transform: translateY(0);
      transition: all 0.2s ease-in-out;
    }
  }
  @media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
    .navbar-expand-md .navbar-collapse-overlay.show {
      transition: none;
    }
  }
  @media (max-width: 767.98px) {
    .navbar-expand-md .navbar-collapse-overlay .nav-item {
      padding: 0 1.5rem;
    }
  }
  @media (min-width: 768px) {
    .navbar-expand-md .navbar-brand img {
      height: 1.75rem;
    }
    .navbar-expand-md .navbar-brand.dropdown-toggle:after {
      display: none;
    }
    .navbar-expand-md .navbar-nav .nav-link {
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-radius: 0;
    }
    .navbar-expand-md .navbar-nav .nav-link-icon {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      border-radius: 0;
    }
    .navbar-expand-md .navbar-nav .nav-link-icon i {
      margin-right: 0;
    }
    .navbar-expand-md .navbar-collapse .collapse-header {
      display: none;
    }
  }
  @media (max-width: 991.98px) {
    .navbar-expand-lg .navbar-brand img {
      height: auto;
    }
    .navbar-expand-lg .navbar-brand.dropdown-toggle:after {
      float: none;
      font-size: 1rem;
      opacity: 0.6;
    }
    .navbar-expand-lg .navbar-brand.dropdown-toggle:hover:after {
      opacity: 1;
    }
    .navbar-expand-lg .navbar-nav:not(.flex-row) .nav-link {
      padding: 0.625rem 0;
    }
    .navbar-expand-lg .navbar-nav:not(.flex-row) .dropdown-menu {
      box-shadow: none;
      min-width: auto;
    }
    .navbar-expand-lg .navbar-nav:not(.flex-row) .dropdown-menu .media svg {
      width: 30px;
    }
    .navbar-expand-lg .navbar-nav.flex-row .nav-link {
      padding-left: 0.675rem;
      padding-right: 0.675rem;
    }
    .navbar-expand-lg .navbar-nav.flex-row .dropdown {
      position: static;
    }
    .navbar-expand-lg .navbar-nav.flex-row .dropdown-menu {
      width: calc(100% - 30px);
      position: absolute;
      top: 60px;
      left: 15px;
      right: auto;
    }
    .navbar-expand-lg
      .navbar-nav
      .dropdown:not(.dropdown-submenu)
      > .dropdown-menu {
      display: block !important;
    }
    .navbar-expand-lg .navbar-collapse {
      background: #fff;
    }
    .navbar-expand-lg .navbar-collapse .navbar-nav .nav-item .nav-link {
      color: rgba(31, 45, 61, 0.6);
    }
    .navbar-expand-lg .navbar-collapse .navbar-nav .nav-item-spaced {
      margin-bottom: 1.5rem;
    }
    .navbar-expand-lg .navbar-collapse .navbar-nav .dropdown-item {
      color: rgba(31, 45, 61, 0.8);
    }
    .navbar-expand-lg .navbar-collapse .navbar-toggler {
      position: absolute;
      top: 0;
      right: 0.5rem;
      color: rgba(31, 45, 61, 0.6);
      z-index: 100;
    }
    .navbar-expand-lg .navbar-collapse-fade {
      z-index: 1050;
      height: auto !important;
      opacity: 0;
    }
    .navbar-expand-lg .navbar-collapse-fade.collapsing,
    .navbar-expand-lg .navbar-collapse-fade.show {
      animation: show-navbar-collapse 0.2s ease forwards;
      animation-delay: 0.15s;
    }
    .navbar-expand-lg .navbar-collapse-fade.collapsing-out {
      opacity: 0;
      transition: opacity 0.3s linear;
      animation-delay: 0;
      animation: none;
    }
    .navbar-expand-lg .navbar-collapse-overlay {
      position: fixed;
      top: 0;
      right: 5px;
      left: 5px;
      max-height: calc(100vh - 2rem) !important;
      z-index: 100;
      padding: 1rem 0;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      transform: translateY(-100%);
      opacity: 0;
      transition-property: opacity, transform;
      transform-origin: top right;
      transition: all 0.2s ease-in-out;
      box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125);
      border-radius: 0.375rem;
    }
  }
  @media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
    .navbar-expand-lg .navbar-collapse-overlay {
      transition: none;
    }
  }
  @media (max-width: 991.98px) {
    .navbar-expand-lg .navbar-collapse-overlay.show {
      opacity: 1;
      transform: translateY(0);
      transition: all 0.2s ease-in-out;
    }
  }
  @media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
    .navbar-expand-lg .navbar-collapse-overlay.show {
      transition: none;
    }
  }
  @media (max-width: 991.98px) {
    .navbar-expand-lg .navbar-collapse-overlay .nav-item {
      padding: 0 1.5rem;
    }
  }
  @media (min-width: 992px) {
    .navbar-expand-lg .navbar-brand img {
      height: auto;
    }
    .navbar-expand-lg .navbar-brand.dropdown-toggle:after {
      display: none;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-radius: 0;
    }
    .navbar-expand-lg .navbar-nav .nav-link-icon {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      border-radius: 0;
    }
    .navbar-expand-lg .navbar-nav .nav-link-icon i {
      margin-right: 0;
    }
    .navbar-expand-lg .navbar-collapse .collapse-header {
      display: none;
    }
  }
  @media (max-width: 1199.98px) {
    .navbar-expand-xl .navbar-brand img {
      height: 1.75rem;
    }
    .navbar-expand-xl .navbar-brand.dropdown-toggle:after {
      float: none;
      font-size: 1rem;
      opacity: 0.6;
    }
    .navbar-expand-xl .navbar-brand.dropdown-toggle:hover:after {
      opacity: 1;
    }
    .navbar-expand-xl .navbar-nav:not(.flex-row) .nav-link {
      padding: 0.625rem 0;
    }
    .navbar-expand-xl .navbar-nav:not(.flex-row) .dropdown-menu {
      box-shadow: none;
      min-width: auto;
    }
    .navbar-expand-xl .navbar-nav:not(.flex-row) .dropdown-menu .media svg {
      width: 30px;
    }
    .navbar-expand-xl .navbar-nav.flex-row .nav-link {
      padding-left: 0.675rem;
      padding-right: 0.675rem;
    }
    .navbar-expand-xl .navbar-nav.flex-row .dropdown {
      position: static;
    }
    .navbar-expand-xl .navbar-nav.flex-row .dropdown-menu {
      width: calc(100% - 30px);
      position: absolute;
      top: 60px;
      left: 15px;
      right: auto;
    }
    .navbar-expand-xl
      .navbar-nav
      .dropdown:not(.dropdown-submenu)
      > .dropdown-menu {
      display: block !important;
    }
    .navbar-expand-xl .navbar-collapse {
      background: #fff;
    }
    .navbar-expand-xl .navbar-collapse .navbar-nav .nav-item .nav-link {
      color: rgba(31, 45, 61, 0.6);
    }
    .navbar-expand-xl .navbar-collapse .navbar-nav .nav-item-spaced {
      margin-bottom: 1.5rem;
    }
    .navbar-expand-xl .navbar-collapse .navbar-nav .dropdown-item {
      color: rgba(31, 45, 61, 0.8);
    }
    .navbar-expand-xl .navbar-collapse .navbar-toggler {
      position: absolute;
      top: 0;
      right: 0.5rem;
      color: rgba(31, 45, 61, 0.6);
      z-index: 100;
    }
    .navbar-expand-xl .navbar-collapse-fade {
      z-index: 1050;
      height: auto !important;
      opacity: 0;
    }
    .navbar-expand-xl .navbar-collapse-fade.collapsing,
    .navbar-expand-xl .navbar-collapse-fade.show {
      animation: show-navbar-collapse 0.2s ease forwards;
      animation-delay: 0.15s;
    }
    .navbar-expand-xl .navbar-collapse-fade.collapsing-out {
      opacity: 0;
      transition: opacity 0.3s linear;
      animation-delay: 0;
      animation: none;
    }
    .navbar-expand-xl .navbar-collapse-overlay {
      position: fixed;
      top: 0;
      right: 5px;
      left: 5px;
      max-height: calc(100vh - 2rem) !important;
      z-index: 100;
      padding: 1rem 0;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      transform: translateY(-100%);
      opacity: 0;
      transition-property: opacity, transform;
      transform-origin: top right;
      transition: all 0.2s ease-in-out;
      box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125);
      border-radius: 0.375rem;
    }
  }
  @media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
    .navbar-expand-xl .navbar-collapse-overlay {
      transition: none;
    }
  }
  @media (max-width: 1199.98px) {
    .navbar-expand-xl .navbar-collapse-overlay.show {
      opacity: 1;
      transform: translateY(0);
      transition: all 0.2s ease-in-out;
    }
  }
  @media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
    .navbar-expand-xl .navbar-collapse-overlay.show {
      transition: none;
    }
  }
  @media (max-width: 1199.98px) {
    .navbar-expand-xl .navbar-collapse-overlay .nav-item {
      padding: 0 1.5rem;
    }
  }
  @media (min-width: 1200px) {
    .navbar-expand-xl .navbar-brand img {
      height: 1.75rem;
    }
    .navbar-expand-xl .navbar-brand.dropdown-toggle:after {
      display: none;
    }
    .navbar-expand-xl .navbar-nav .nav-link {
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-radius: 0;
    }
    .navbar-expand-xl .navbar-nav .nav-link-icon {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      border-radius: 0;
    }
    .navbar-expand-xl .navbar-nav .nav-link-icon i {
      margin-right: 0;
    }
    .navbar-expand-xl .navbar-collapse .collapse-header {
      display: none;
    }
  }
  .navbar-expand .navbar-brand img {
    height: 1.75rem;
  }
  .navbar-expand .navbar-brand.dropdown-toggle:after {
    float: none;
    font-size: 1rem;
    opacity: 0.6;
  }
  .navbar-expand .navbar-brand.dropdown-toggle:hover:after {
    opacity: 1;
  }
  .navbar-expand .navbar-nav:not(.flex-row) .nav-link {
    padding: 0.625rem 0;
  }
  .navbar-expand .navbar-nav:not(.flex-row) .dropdown-menu {
    box-shadow: none;
    min-width: auto;
  }
  .navbar-expand .navbar-nav:not(.flex-row) .dropdown-menu .media svg {
    width: 30px;
  }
  .navbar-expand .navbar-nav.flex-row .nav-link {
    padding-left: 0.675rem;
    padding-right: 0.675rem;
  }
  .navbar-expand .navbar-nav.flex-row .dropdown {
    position: static;
  }
  .navbar-expand .navbar-nav.flex-row .dropdown-menu {
    width: calc(100% - 30px);
    position: absolute;
    top: 60px;
    left: 15px;
    right: auto;
  }
  .navbar-expand .navbar-nav .dropdown:not(.dropdown-submenu) > .dropdown-menu {
    display: block !important;
  }
  .navbar-expand .navbar-collapse {
    background: #fff;
  }
  .navbar-expand .navbar-collapse .navbar-nav .nav-item .nav-link {
    color: rgba(31, 45, 61, 0.6);
  }
  .navbar-expand .navbar-collapse .navbar-nav .nav-item-spaced {
    margin-bottom: 1.5rem;
  }
  .navbar-expand .navbar-collapse .navbar-nav .dropdown-item {
    color: rgba(31, 45, 61, 0.8);
  }
  .navbar-expand .navbar-collapse .navbar-toggler {
    position: absolute;
    top: 0;
    right: 0.5rem;
    color: rgba(31, 45, 61, 0.6);
    z-index: 100;
  }
  .navbar-expand .navbar-collapse-fade {
    z-index: 1050;
    height: auto !important;
    opacity: 0;
  }
  .navbar-expand .navbar-collapse-fade.collapsing,
  .navbar-expand .navbar-collapse-fade.show {
    animation: show-navbar-collapse 0.2s ease forwards;
    animation-delay: 0.15s;
  }
  .navbar-expand .navbar-collapse-fade.collapsing-out {
    opacity: 0;
    transition: opacity 0.3s linear;
    animation-delay: 0;
    animation: none;
  }
  .navbar-expand .navbar-collapse-overlay {
    position: fixed;
    top: 0;
    right: 5px;
    left: 5px;
    max-height: calc(100vh - 2rem) !important;
    z-index: 100;
    padding: 1rem 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    transform: translateY(-100%);
    opacity: 0;
    transition-property: opacity, transform;
    transform-origin: top right;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125);
    border-radius: 0.375rem;
  }
  @media (prefers-reduced-motion: reduce) {
    .navbar-expand .navbar-collapse-overlay {
      transition: none;
    }
  }
  .navbar-expand .navbar-collapse-overlay.show {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.2s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .navbar-expand .navbar-collapse-overlay.show {
      transition: none;
    }
  }
  .navbar-expand .navbar-collapse-overlay .nav-item {
    padding: 0 1.5rem;
  }
  .navbar-expand .navbar-brand img {
    height: 1.75rem;
  }
  .navbar-expand .navbar-brand.dropdown-toggle:after {
    display: none;
  }
  .navbar-expand .navbar-nav .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 0;
  }
  .navbar-expand .navbar-nav .nav-link-icon {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    border-radius: 0;
  }
  .navbar-expand .navbar-nav .nav-link-icon i {
    margin-right: 0;
  }
  .navbar-expand .navbar-collapse .collapse-header {
    display: none;
  }
  .navbar-toggler-icon {
    width: 1.25em;
    height: 1.25em;
  }
  @keyframes show-navbar-collapse {
    0% {
      opacity: 0;
      transform: translateX(30px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  .navbar-vertical .navbar-nav .nav-link {
    padding: 0.375rem 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
  .navbar-vertical .navbar-nav .nav-link.active {
    position: relative;
  }
  .navbar-vertical .navbar-nav .nav-link > i,
  .navbar-vertical .navbar-nav .nav-link > svg {
    min-width: 2rem;
    margin-right: 0;
    font-size: 0.9375rem;
    line-height: 1.7rem;
  }
  .navbar-vertical .navbar-nav .nav-link .dropdown-menu {
    border: none;
  }
  .navbar-vertical .navbar-nav .nav-link .dropdown-menu .dropdown-menu {
    margin-left: 0.5rem;
  }
  .navbar-vertical .navbar-nav .nav-link[data-toggle="collapse"]:after {
    display: inline-block;
    font-style: normal;
    font-size: 0.875rem;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Free";
    font-weight: 700;
    content: "\f105";
    margin-left: auto;
    transition: all 0.2s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .navbar-vertical .navbar-nav .nav-link[data-toggle="collapse"]:after {
      transition: none;
    }
  }
  .navbar-vertical
    .navbar-nav
    .nav-link[data-toggle="collapse"][aria-expanded="true"]:after {
    color: #008aff;
    transform: rotate(90deg);
  }
  .navbar-vertical .navbar-nav > .nav-item {
    margin-top: 2px;
  }
  .navbar-vertical .navbar-nav > .nav-item > .nav-link.active {
    background: #fafbfe;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0.375rem;
  }
  .navbar-vertical .navbar-nav .nav-sm .nav-link {
    font-size: 0.875rem;
  }
  .navbar-vertical .navbar-nav .nav .nav-link {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 2rem;
  }
  .navbar-vertical .navbar-nav .nav .nav .nav-link {
    padding-left: 3.25rem;
  }
  .navbar-vertical.navbar-light {
    background-color: #fff;
    border-color: #e2e8f0;
  }
  .navbar-vertical.navbar-light
    .navbar-nav
    .nav-link[data-toggle="collapse"]:after {
    color: rgba(31, 45, 61, 0.6);
  }
  .navbar-vertical.navbar-dark {
    background-color: #171347;
    border-color: rgba(255, 255, 255, 0.1);
  }
  .navbar-vertical.navbar-dark
    .navbar-nav
    .nav-link[data-toggle="collapse"]:after {
    color: rgba(255, 255, 255, 0.85);
  }
  @media (max-width: 575.98px) {
    .navbar-vertical.navbar-expand-sm .navbar-collapse:before {
      content: "";
      display: block;
      border-top-width: 1px;
      border-top-style: solid;
      margin: 0.75rem -1rem;
    }
    .navbar-vertical.navbar-expand-sm .collapse {
      position: relative;
    }
    .navbar-vertical.navbar-expand-sm .collapse.show .nav:before {
      content: "";
      position: absolute;
      left: 1rem;
      top: 0;
      height: 100%;
      border-left: 1px solid;
    }
    .navbar-vertical.navbar-expand-sm.navbar-light .navbar-collapse::before {
      border-top-color: rgba(0, 0, 0, 0.2);
    }
    .navbar-vertical.navbar-expand-sm.navbar-light .collapse .nav:before {
      border-color: rgba(0, 0, 0, 0.2);
    }
    .navbar-vertical.navbar-expand-sm.navbar-dark .navbar-collapse::before {
      border-top-color: rgba(255, 255, 255, 0.2);
    }
    .navbar-vertical.navbar-expand-sm.navbar-dark .collapse .nav:before {
      border-color: rgba(255, 255, 255, 0.2);
    }
  }
  @media (min-width: 576px) {
    .navbar-vertical.navbar-expand-sm {
      display: block;
      width: 100%;
      max-width: 250px;
      overflow-y: auto;
      z-index: 1000;
      padding-left: 0;
      padding-right: 0;
      padding-top: 1rem;
      border-width: 0 0 1px 0;
      border-style: solid;
      border-radius: 0;
      transition: all 0.2s ease;
      box-shadow: none !important;
    }
  }
  @media (min-width: 576px) and (prefers-reduced-motion: reduce) {
    .navbar-vertical.navbar-expand-sm {
      transition: none;
    }
  }
  @media (min-width: 576px) {
    .navbar-vertical.navbar-expand-sm + .main-content {
      margin-left: 0;
    }
    .navbar-vertical.navbar-expand-sm .nav-link-text,
    .navbar-vertical.navbar-expand-sm .navbar-heading {
      opacity: 0;
      pointer-events: none;
      transition: all 0.2s ease;
    }
  }
  @media (min-width: 576px) and (prefers-reduced-motion: reduce) {
    .navbar-vertical.navbar-expand-sm .nav-link-text,
    .navbar-vertical.navbar-expand-sm .navbar-heading {
      transition: none;
    }
  }
  @media (min-width: 576px) {
    .navbar-vertical.navbar-expand-sm.show {
      max-width: 250px;
      border-radius: 0;
    }
    .navbar-vertical.navbar-expand-sm.show .nav-link-text,
    .navbar-vertical.navbar-expand-sm.show .navbar-heading {
      opacity: 1;
      pointer-events: auto;
    }
    .navbar-vertical.navbar-expand-sm.show + .main-content {
      margin-left: 250px;
    }
    .navbar-vertical.navbar-expand-sm.hide {
      max-width: 0;
    }
    .navbar-vertical.navbar-expand-sm.hide .nav-link-text {
      opacity: 0;
      pointer-events: none;
    }
    .navbar-vertical.navbar-expand-sm.hide + .main-content {
      margin-left: 0;
    }
    .navbar-vertical.navbar-expand-sm .navbar-brand {
      margin-right: 0;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
    }
    .navbar-vertical.navbar-expand-sm .navbar-inner {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
    .navbar-vertical.navbar-expand-sm.fixed-left,
    .navbar-vertical.navbar-expand-sm.fixed-right {
      position: fixed;
      top: 0;
      bottom: 0;
    }
    .navbar-vertical.navbar-expand-sm > [class*="container"] {
      flex-direction: column;
      align-items: stretch;
      min-height: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
  @media all and (min-width: 576px) and (-ms-high-contrast: none),
    (min-width: 576px) and (-ms-high-contrast: active) {
    .navbar-vertical.navbar-expand-sm > [class*="container"] {
      min-height: none;
      height: 100%;
    }
  }
  @media (min-width: 576px) {
    .navbar-vertical.navbar-expand-sm.fixed-left {
      left: 0;
      border-width: 0 1px 0 0;
    }
    .navbar-vertical.navbar-expand-sm.fixed-right {
      right: 0;
      border-width: 0 0 0 1px;
    }
    .navbar-vertical.navbar-expand-sm .navbar-collapse {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      opacity: 1;
    }
    .navbar-vertical.navbar-expand-sm .navbar-collapse > * {
      min-width: 100%;
    }
    .navbar-vertical.navbar-expand-sm .navbar-nav {
      flex-direction: column;
      margin-left: -1.5rem;
      margin-right: -1.5rem;
    }
    .navbar-vertical.navbar-expand-sm .nav .nav-link {
      padding-left: 3.5rem;
    }
    .navbar-vertical.navbar-expand-sm .nav .nav .nav-link {
      padding-left: 4.25rem;
    }
  }
  @media (max-width: 767.98px) {
    .navbar-vertical.navbar-expand-md .navbar-collapse:before {
      content: "";
      display: block;
      border-top-width: 1px;
      border-top-style: solid;
      margin: 0.75rem -1rem;
    }
    .navbar-vertical.navbar-expand-md .collapse {
      position: relative;
    }
    .navbar-vertical.navbar-expand-md .collapse.show .nav:before {
      content: "";
      position: absolute;
      left: 1rem;
      top: 0;
      height: 100%;
      border-left: 1px solid;
    }
    .navbar-vertical.navbar-expand-md.navbar-light .navbar-collapse::before {
      border-top-color: rgba(0, 0, 0, 0.2);
    }
    .navbar-vertical.navbar-expand-md.navbar-light .collapse .nav:before {
      border-color: rgba(0, 0, 0, 0.2);
    }
    .navbar-vertical.navbar-expand-md.navbar-dark .navbar-collapse::before {
      border-top-color: rgba(255, 255, 255, 0.2);
    }
    .navbar-vertical.navbar-expand-md.navbar-dark .collapse .nav:before {
      border-color: rgba(255, 255, 255, 0.2);
    }
  }
  @media (min-width: 768px) {
    .navbar-vertical.navbar-expand-md {
      display: block;
      width: 100%;
      max-width: 250px;
      overflow-y: auto;
      z-index: 1000;
      padding-left: 0;
      padding-right: 0;
      padding-top: 1rem;
      border-width: 0 0 1px 0;
      border-style: solid;
      border-radius: 0;
      transition: all 0.2s ease;
      box-shadow: none !important;
    }
  }
  @media (min-width: 768px) and (prefers-reduced-motion: reduce) {
    .navbar-vertical.navbar-expand-md {
      transition: none;
    }
  }
  @media (min-width: 768px) {
    .navbar-vertical.navbar-expand-md + .main-content {
      margin-left: 0;
    }
    .navbar-vertical.navbar-expand-md .nav-link-text,
    .navbar-vertical.navbar-expand-md .navbar-heading {
      opacity: 0;
      pointer-events: none;
      transition: all 0.2s ease;
    }
  }
  @media (min-width: 768px) and (prefers-reduced-motion: reduce) {
    .navbar-vertical.navbar-expand-md .nav-link-text,
    .navbar-vertical.navbar-expand-md .navbar-heading {
      transition: none;
    }
  }
  @media (min-width: 768px) {
    .navbar-vertical.navbar-expand-md.show {
      max-width: 250px;
      border-radius: 0;
    }
    .navbar-vertical.navbar-expand-md.show .nav-link-text,
    .navbar-vertical.navbar-expand-md.show .navbar-heading {
      opacity: 1;
      pointer-events: auto;
    }
    .navbar-vertical.navbar-expand-md.show + .main-content {
      margin-left: 250px;
    }
    .navbar-vertical.navbar-expand-md.hide {
      max-width: 0;
    }
    .navbar-vertical.navbar-expand-md.hide .nav-link-text {
      opacity: 0;
      pointer-events: none;
    }
    .navbar-vertical.navbar-expand-md.hide + .main-content {
      margin-left: 0;
    }
    .navbar-vertical.navbar-expand-md .navbar-brand {
      margin-right: 0;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
    }
    .navbar-vertical.navbar-expand-md .navbar-inner {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
    .navbar-vertical.navbar-expand-md.fixed-left,
    .navbar-vertical.navbar-expand-md.fixed-right {
      position: fixed;
      top: 0;
      bottom: 0;
    }
    .navbar-vertical.navbar-expand-md > [class*="container"] {
      flex-direction: column;
      align-items: stretch;
      min-height: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
  @media all and (min-width: 768px) and (-ms-high-contrast: none),
    (min-width: 768px) and (-ms-high-contrast: active) {
    .navbar-vertical.navbar-expand-md > [class*="container"] {
      min-height: none;
      height: 100%;
    }
  }
  @media (min-width: 768px) {
    .navbar-vertical.navbar-expand-md.fixed-left {
      left: 0;
      border-width: 0 1px 0 0;
    }
    .navbar-vertical.navbar-expand-md.fixed-right {
      right: 0;
      border-width: 0 0 0 1px;
    }
    .navbar-vertical.navbar-expand-md .navbar-collapse {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      opacity: 1;
    }
    .navbar-vertical.navbar-expand-md .navbar-collapse > * {
      min-width: 100%;
    }
    .navbar-vertical.navbar-expand-md .navbar-nav {
      flex-direction: column;
      margin-left: -1.5rem;
      margin-right: -1.5rem;
    }
    .navbar-vertical.navbar-expand-md .nav .nav-link {
      padding-left: 3.5rem;
    }
    .navbar-vertical.navbar-expand-md .nav .nav .nav-link {
      padding-left: 4.25rem;
    }
  }
  @media (max-width: 991.98px) {
    .navbar-vertical.navbar-expand-lg .navbar-collapse:before {
      content: "";
      display: block;
      border-top-width: 1px;
      border-top-style: solid;
      margin: 0.75rem -1rem;
    }
    .navbar-vertical.navbar-expand-lg .collapse {
      position: relative;
    }
    .navbar-vertical.navbar-expand-lg .collapse.show .nav:before {
      content: "";
      position: absolute;
      left: 1rem;
      top: 0;
      height: 100%;
      border-left: 1px solid;
    }
    .navbar-vertical.navbar-expand-lg.navbar-light .navbar-collapse::before {
      border-top-color: rgba(0, 0, 0, 0.2);
    }
    .navbar-vertical.navbar-expand-lg.navbar-light .collapse .nav:before {
      border-color: rgba(0, 0, 0, 0.2);
    }
    .navbar-vertical.navbar-expand-lg.navbar-dark .navbar-collapse::before {
      border-top-color: rgba(255, 255, 255, 0.2);
    }
    .navbar-vertical.navbar-expand-lg.navbar-dark .collapse .nav:before {
      border-color: rgba(255, 255, 255, 0.2);
    }
  }
  @media (min-width: 992px) {
    .navbar-vertical.navbar-expand-lg {
      display: block;
      width: 100%;
      max-width: 250px;
      overflow-y: auto;
      z-index: 1000;
      padding-left: 0;
      padding-right: 0;
      padding-top: 1rem;
      border-width: 0 0 1px 0;
      border-style: solid;
      border-radius: 0;
      transition: all 0.2s ease;
      box-shadow: none !important;
    }
  }
  @media (min-width: 992px) and (prefers-reduced-motion: reduce) {
    .navbar-vertical.navbar-expand-lg {
      transition: none;
    }
  }
  @media (min-width: 992px) {
    .navbar-vertical.navbar-expand-lg + .main-content {
      margin-left: 0;
    }
    .navbar-vertical.navbar-expand-lg .nav-link-text,
    .navbar-vertical.navbar-expand-lg .navbar-heading {
      opacity: 0;
      pointer-events: none;
      transition: all 0.2s ease;
    }
  }
  @media (min-width: 992px) and (prefers-reduced-motion: reduce) {
    .navbar-vertical.navbar-expand-lg .nav-link-text,
    .navbar-vertical.navbar-expand-lg .navbar-heading {
      transition: none;
    }
  }
  @media (min-width: 992px) {
    .navbar-vertical.navbar-expand-lg.show {
      max-width: 250px;
      border-radius: 0;
    }
    .navbar-vertical.navbar-expand-lg.show .nav-link-text,
    .navbar-vertical.navbar-expand-lg.show .navbar-heading {
      opacity: 1;
      pointer-events: auto;
    }
    .navbar-vertical.navbar-expand-lg.show + .main-content {
      margin-left: 250px;
    }
    .navbar-vertical.navbar-expand-lg.hide {
      max-width: 0;
    }
    .navbar-vertical.navbar-expand-lg.hide .nav-link-text {
      opacity: 0;
      pointer-events: none;
    }
    .navbar-vertical.navbar-expand-lg.hide + .main-content {
      margin-left: 0;
    }
    .navbar-vertical.navbar-expand-lg .navbar-brand {
      margin-right: 0;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
    }
    .navbar-vertical.navbar-expand-lg .navbar-inner {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
    .navbar-vertical.navbar-expand-lg.fixed-left,
    .navbar-vertical.navbar-expand-lg.fixed-right {
      position: fixed;
      top: 0;
      bottom: 0;
    }
    .navbar-vertical.navbar-expand-lg > [class*="container"] {
      flex-direction: column;
      align-items: stretch;
      min-height: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
  @media all and (min-width: 992px) and (-ms-high-contrast: none),
    (min-width: 992px) and (-ms-high-contrast: active) {
    .navbar-vertical.navbar-expand-lg > [class*="container"] {
      min-height: none;
      height: 100%;
    }
  }
  @media (min-width: 992px) {
    .navbar-vertical.navbar-expand-lg.fixed-left {
      left: 0;
      border-width: 0 1px 0 0;
    }
    .navbar-vertical.navbar-expand-lg.fixed-right {
      right: 0;
      border-width: 0 0 0 1px;
    }
    .navbar-vertical.navbar-expand-lg .navbar-collapse {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      opacity: 1;
    }
    .navbar-vertical.navbar-expand-lg .navbar-collapse > * {
      min-width: 100%;
    }
    .navbar-vertical.navbar-expand-lg .navbar-nav {
      flex-direction: column;
      margin-left: -1.5rem;
      margin-right: -1.5rem;
    }
    .navbar-vertical.navbar-expand-lg .nav .nav-link {
      padding-left: 3.5rem;
    }
    .navbar-vertical.navbar-expand-lg .nav .nav .nav-link {
      padding-left: 4.25rem;
    }
  }
  @media (max-width: 1199.98px) {
    .navbar-vertical.navbar-expand-xl .navbar-collapse:before {
      content: "";
      display: block;
      border-top-width: 1px;
      border-top-style: solid;
      margin: 0.75rem -1rem;
    }
    .navbar-vertical.navbar-expand-xl .collapse {
      position: relative;
    }
    .navbar-vertical.navbar-expand-xl .collapse.show .nav:before {
      content: "";
      position: absolute;
      left: 1rem;
      top: 0;
      height: 100%;
      border-left: 1px solid;
    }
    .navbar-vertical.navbar-expand-xl.navbar-light .navbar-collapse::before {
      border-top-color: rgba(0, 0, 0, 0.2);
    }
    .navbar-vertical.navbar-expand-xl.navbar-light .collapse .nav:before {
      border-color: rgba(0, 0, 0, 0.2);
    }
    .navbar-vertical.navbar-expand-xl.navbar-dark .navbar-collapse::before {
      border-top-color: rgba(255, 255, 255, 0.2);
    }
    .navbar-vertical.navbar-expand-xl.navbar-dark .collapse .nav:before {
      border-color: rgba(255, 255, 255, 0.2);
    }
  }
  @media (min-width: 1200px) {
    .navbar-vertical.navbar-expand-xl {
      display: block;
      width: 100%;
      max-width: 250px;
      overflow-y: auto;
      z-index: 1000;
      padding-left: 0;
      padding-right: 0;
      padding-top: 1rem;
      border-width: 0 0 1px 0;
      border-style: solid;
      border-radius: 0;
      transition: all 0.2s ease;
      box-shadow: none !important;
    }
  }
  @media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
    .navbar-vertical.navbar-expand-xl {
      transition: none;
    }
  }
  @media (min-width: 1200px) {
    .navbar-vertical.navbar-expand-xl + .main-content {
      margin-left: 0;
    }
    .navbar-vertical.navbar-expand-xl .nav-link-text,
    .navbar-vertical.navbar-expand-xl .navbar-heading {
      opacity: 0;
      pointer-events: none;
      transition: all 0.2s ease;
    }
  }
  @media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
    .navbar-vertical.navbar-expand-xl .nav-link-text,
    .navbar-vertical.navbar-expand-xl .navbar-heading {
      transition: none;
    }
  }
  @media (min-width: 1200px) {
    .navbar-vertical.navbar-expand-xl.show {
      max-width: 250px;
      border-radius: 0;
    }
    .navbar-vertical.navbar-expand-xl.show .nav-link-text,
    .navbar-vertical.navbar-expand-xl.show .navbar-heading {
      opacity: 1;
      pointer-events: auto;
    }
    .navbar-vertical.navbar-expand-xl.show + .main-content {
      margin-left: 250px;
    }
    .navbar-vertical.navbar-expand-xl.hide {
      max-width: 0;
    }
    .navbar-vertical.navbar-expand-xl.hide .nav-link-text {
      opacity: 0;
      pointer-events: none;
    }
    .navbar-vertical.navbar-expand-xl.hide + .main-content {
      margin-left: 0;
    }
    .navbar-vertical.navbar-expand-xl .navbar-brand {
      margin-right: 0;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
    }
    .navbar-vertical.navbar-expand-xl .navbar-inner {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
    .navbar-vertical.navbar-expand-xl.fixed-left,
    .navbar-vertical.navbar-expand-xl.fixed-right {
      position: fixed;
      top: 0;
      bottom: 0;
    }
    .navbar-vertical.navbar-expand-xl > [class*="container"] {
      flex-direction: column;
      align-items: stretch;
      min-height: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
  @media all and (min-width: 1200px) and (-ms-high-contrast: none),
    (min-width: 1200px) and (-ms-high-contrast: active) {
    .navbar-vertical.navbar-expand-xl > [class*="container"] {
      min-height: none;
      height: 100%;
    }
  }
  @media (min-width: 1200px) {
    .navbar-vertical.navbar-expand-xl.fixed-left {
      left: 0;
      border-width: 0 1px 0 0;
    }
    .navbar-vertical.navbar-expand-xl.fixed-right {
      right: 0;
      border-width: 0 0 0 1px;
    }
    .navbar-vertical.navbar-expand-xl .navbar-collapse {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      opacity: 1;
    }
    .navbar-vertical.navbar-expand-xl .navbar-collapse > * {
      min-width: 100%;
    }
    .navbar-vertical.navbar-expand-xl .navbar-nav {
      flex-direction: column;
      margin-left: -1.5rem;
      margin-right: -1.5rem;
    }
    .navbar-vertical.navbar-expand-xl .nav .nav-link {
      padding-left: 3.5rem;
    }
    .navbar-vertical.navbar-expand-xl .nav .nav .nav-link {
      padding-left: 4.25rem;
    }
  }
  .navbar-vertical.navbar-expand {
    display: block;
    width: 100%;
    max-width: 250px;
    overflow-y: auto;
    z-index: 1000;
    padding-left: 0;
    padding-right: 0;
    padding-top: 1rem;
    border-width: 0 0 1px 0;
    border-style: solid;
    border-radius: 0;
    transition: all 0.2s ease;
    box-shadow: none !important;
  }
  .navbar-vertical.navbar-expand .navbar-collapse:before {
    content: "";
    display: block;
    border-top-width: 1px;
    border-top-style: solid;
    margin: 0.75rem -1rem;
  }
  .navbar-vertical.navbar-expand .collapse {
    position: relative;
  }
  .navbar-vertical.navbar-expand .collapse.show .nav:before {
    content: "";
    position: absolute;
    left: 1rem;
    top: 0;
    height: 100%;
    border-left: 1px solid;
  }
  .navbar-vertical.navbar-expand.navbar-light .navbar-collapse::before {
    border-top-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-vertical.navbar-expand.navbar-light .collapse .nav:before {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-vertical.navbar-expand.navbar-dark .navbar-collapse::before {
    border-top-color: rgba(255, 255, 255, 0.2);
  }
  .navbar-vertical.navbar-expand.navbar-dark .collapse .nav:before {
    border-color: rgba(255, 255, 255, 0.2);
  }
  @media (prefers-reduced-motion: reduce) {
    .navbar-vertical.navbar-expand {
      transition: none;
    }
  }
  .navbar-vertical.navbar-expand + .main-content {
    margin-left: 0;
  }
  .navbar-vertical.navbar-expand .nav-link-text,
  .navbar-vertical.navbar-expand .navbar-heading {
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .navbar-vertical.navbar-expand .nav-link-text,
    .navbar-vertical.navbar-expand .navbar-heading {
      transition: none;
    }
  }
  .navbar-vertical.navbar-expand.show {
    max-width: 250px;
    border-radius: 0;
  }
  .navbar-vertical.navbar-expand.show .nav-link-text,
  .navbar-vertical.navbar-expand.show .navbar-heading {
    opacity: 1;
    pointer-events: auto;
  }
  .navbar-vertical.navbar-expand.show + .main-content {
    margin-left: 250px;
  }
  .navbar-vertical.navbar-expand.hide {
    max-width: 0;
  }
  .navbar-vertical.navbar-expand.hide .nav-link-text {
    opacity: 0;
    pointer-events: none;
  }
  .navbar-vertical.navbar-expand.hide + .main-content {
    margin-left: 0;
  }
  .navbar-vertical.navbar-expand .navbar-brand {
    margin-right: 0;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .navbar-vertical.navbar-expand .navbar-inner {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .navbar-vertical.navbar-expand.fixed-left,
  .navbar-vertical.navbar-expand.fixed-right {
    position: fixed;
    top: 0;
    bottom: 0;
  }
  .navbar-vertical.navbar-expand > [class*="container"] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .navbar-vertical.navbar-expand > [class*="container"] {
      min-height: none;
      height: 100%;
    }
  }
  .navbar-vertical.navbar-expand.fixed-left {
    left: 0;
    border-width: 0 1px 0 0;
  }
  .navbar-vertical.navbar-expand.fixed-right {
    right: 0;
    border-width: 0 0 0 1px;
  }
  .navbar-vertical.navbar-expand .navbar-collapse {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    opacity: 1;
  }
  .navbar-vertical.navbar-expand .navbar-collapse > * {
    min-width: 100%;
  }
  .navbar-vertical.navbar-expand .navbar-nav {
    flex-direction: column;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .navbar-vertical.navbar-expand .nav .nav-link {
    padding-left: 3.5rem;
  }
  .navbar-vertical.navbar-expand .nav .nav .nav-link {
    padding-left: 4.25rem;
  }
  .navbar .dropdown-menu {
    min-width: 16rem;
    padding: 1.25rem 1.75rem;
    background-color: #fff;
    border: 0 solid rgba(31, 45, 61, 0);
    border-radius: 0.5rem;
    box-shadow: 0 1.5rem 4rem rgba(31, 45, 61, 0.15);
  }
  .navbar .dropdown-menu .list-group .list-group-item {
    background: 0 0;
    padding-left: 0;
    padding-right: 0;
  }
  .navbar .dropdown-menu .list-group .list-group-item:hover .heading {
    color: rgba(65, 95, 129, 0.8);
  }
  .navbar .dropdown-header {
    padding: 0;
    color: #3b485e;
  }
  .navbar .dropdown-item {
    padding: 0.375rem 0;
    color: rgba(31, 45, 61, 0.8);
    font-size: 1rem;
    font-weight: 600;
  }
  .navbar .dropdown-item:first-child {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .navbar .dropdown-item:last-child {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .navbar .dropdown-item:focus,
  .navbar .dropdown-item:hover {
    color: rgba(65, 95, 129, 0.8);
    text-decoration: none;
    background: 0 0;
  }
  .navbar .dropdown-item.active,
  .navbar .dropdown-item:active {
    color: rgba(65, 95, 129, 0.8);
    text-decoration: none;
    background-color: transparent;
  }
  .navbar .dropdown-img-left {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.625rem 1.75rem;
    background: no-repeat center center/cover;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .navbar .dropdown-img-left:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 138, 255, 0.8);
    border-radius: inherit;
  }
  .navbar .dropdown-menu-lg,
  .navbar .dropdown-menu-xl {
    padding: 2rem 2.5rem;
  }
  .navbar .dropdown-menu-lg .dropdown-header,
  .navbar .dropdown-menu-xl .dropdown-header {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    color: #008aff;
  }
  @media (max-width: 575.98px) {
    .navbar-expand-sm .navbar-collapse .dropdown-menu {
      padding-left: 0;
      padding-right: 0;
      background: 0 0;
      border-radius: 0;
    }
    .navbar-expand-sm .navbar-collapse .dropdown-menu.show {
      border-bottom: 1px solid #eaecf3;
    }
    .navbar-expand-sm .navbar-collapse .dropdown-menu-single {
      padding-left: 1rem;
      padding-top: 0;
      padding-bottom: 0;
      border-left: 3px solid #008aff;
    }
    .navbar-expand-sm .navbar-collapse .dropdown-item {
      position: relative;
      font-size: 1rem;
      font-weight: 600;
    }
    .navbar-expand-sm .navbar-collapse .dropdown-img-left {
      border-radius: 0.5rem;
    }
    .navbar-expand-sm
      .navbar-collapse
      .dropdown-submenu.show
      .dropdown-toggle:after {
      transform: rotate(90deg);
      transition: all 0.2s ease;
    }
  }
  @media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
    .navbar-expand-sm
      .navbar-collapse
      .dropdown-submenu.show
      .dropdown-toggle:after {
      transition: none;
    }
  }
  @media (max-width: 575.98px) {
    .navbar-expand-sm .navbar-collapse .delimiter {
      border-top: 1px solid #e2e8f0;
      padding-top: 2.5rem;
    }
    .navbar-expand-sm .navbar-collapse .navbar-btn {
      width: 100%;
      margin-top: 1rem;
      padding: 1rem 1.75rem;
      background-color: transparent;
      color: #008aff;
      border: 0;
      border-top: 1px solid #e2e8f0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    .navbar-expand-sm
      .navbar-collapse
      .nav-item.dropdown
      .dropdown-toggle.nav-link:after {
      transform: rotate(-90deg);
      transition: all 0.2s ease;
    }
  }
  @media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
    .navbar-expand-sm
      .navbar-collapse
      .nav-item.dropdown
      .dropdown-toggle.nav-link:after {
      transition: none;
    }
  }
  @media (max-width: 575.98px) {
    .navbar-expand-sm
      .navbar-collapse
      .nav-item.dropdown
      .dropdown-toggle:after {
      font-size: 0.75rem;
      float: right;
    }
    .navbar-expand-sm
      .navbar-collapse
      .nav-item.dropdown.show
      .dropdown-toggle.nav-link:after {
      transform: rotate(0);
    }
  }
  @media (min-width: 576px) {
    .navbar-expand-sm
      .dropdown:not(.dropdown-submenu):not(.dropdown-fluid)
      > .dropdown-menu:not(.dropdown-menu-right) {
      margin-left: -1.25rem;
    }
    .navbar-expand-sm
      .dropdown:not(.dropdown-submenu):not(.dropdown-fluid)
      > .dropdown-menu-right {
      margin-right: -1.25rem;
    }
    .navbar-expand-sm
      .dropdown-menu
      .list-group-emphasized
      > li
      .list-group-item {
      transform: scale(1);
      transition: all 0.2s ease;
    }
  }
  @media (min-width: 576px) and (prefers-reduced-motion: reduce) {
    .navbar-expand-sm
      .dropdown-menu
      .list-group-emphasized
      > li
      .list-group-item {
      transition: none;
    }
  }
  @media (min-width: 576px) {
    .navbar-expand-sm .dropdown-menu .list-group-emphasized > li .media-body * {
      transition: all 0.2s ease;
    }
  }
  @media (min-width: 576px) and (prefers-reduced-motion: reduce) {
    .navbar-expand-sm .dropdown-menu .list-group-emphasized > li .media-body * {
      transition: none;
    }
  }
  @media (min-width: 576px) {
    .navbar-expand-sm
      .dropdown-menu
      .list-group-emphasized
      > li:hover
      .list-group-item {
      z-index: 11;
      transform: scale(1.05);
      background-color: #fafbfe;
      box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.05);
      border-radius: 0.5rem;
    }
    .navbar-expand-sm .list-group .list-group-item:first-child {
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
    }
    .navbar-expand-sm .list-group .list-group-item:last-child {
      border-bottom-right-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }
    .navbar-expand-sm .list-group > li:first-child .list-group-item {
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
    }
    .navbar-expand-sm .list-group > li:last-child .list-group-item {
      border-bottom-right-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }
    .navbar-expand-sm .bg-dropdown-secondary {
      background-color: #fafafa;
    }
    .navbar-expand-sm .dropdown-body-left {
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }
    .navbar-expand-sm .dropdown-body-right {
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
    .navbar-expand-sm .dropdown-submenu .dropdown-menu {
      min-width: 16rem;
      margin-left: 1rem;
    }
    .navbar-expand-sm .dropdown-submenu .dropdown-menu:before {
      content: "";
      border-left: 1.5rem solid transparent;
      position: absolute;
      left: -1rem;
      top: 0;
      height: 100%;
    }
    .navbar-expand-sm .dropdown-submenu .dropdown-item:after {
      right: 0;
    }
    .navbar-expand-sm .dropdown-body {
      padding: 2rem 2.5rem;
    }
    .navbar-expand-sm .delimiter {
      border-right: 1px solid #e2e8f0;
    }
  }
  @media (max-width: 767.98px) {
    .navbar-expand-md .navbar-collapse .dropdown-menu {
      padding-left: 0;
      padding-right: 0;
      background: 0 0;
      border-radius: 0;
    }
    .navbar-expand-md .navbar-collapse .dropdown-menu.show {
      border-bottom: 1px solid #eaecf3;
    }
    .navbar-expand-md .navbar-collapse .dropdown-menu-single {
      padding-left: 1rem;
      padding-top: 0;
      padding-bottom: 0;
      border-left: 3px solid #008aff;
    }
    .navbar-expand-md .navbar-collapse .dropdown-item {
      position: relative;
      font-size: 1rem;
      font-weight: 600;
    }
    .navbar-expand-md .navbar-collapse .dropdown-img-left {
      border-radius: 0.5rem;
    }
    .navbar-expand-md
      .navbar-collapse
      .dropdown-submenu.show
      .dropdown-toggle:after {
      transform: rotate(90deg);
      transition: all 0.2s ease;
    }
  }
  @media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
    .navbar-expand-md
      .navbar-collapse
      .dropdown-submenu.show
      .dropdown-toggle:after {
      transition: none;
    }
  }
  @media (max-width: 767.98px) {
    .navbar-expand-md .navbar-collapse .delimiter {
      border-top: 1px solid #e2e8f0;
      padding-top: 2.5rem;
    }
    .navbar-expand-md .navbar-collapse .navbar-btn {
      width: 100%;
      margin-top: 1rem;
      padding: 1rem 1.75rem;
      background-color: transparent;
      color: #008aff;
      border: 0;
      border-top: 1px solid #e2e8f0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    .navbar-expand-md
      .navbar-collapse
      .nav-item.dropdown
      .dropdown-toggle.nav-link:after {
      transform: rotate(-90deg);
      transition: all 0.2s ease;
    }
  }
  @media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
    .navbar-expand-md
      .navbar-collapse
      .nav-item.dropdown
      .dropdown-toggle.nav-link:after {
      transition: none;
    }
  }
  @media (max-width: 767.98px) {
    .navbar-expand-md
      .navbar-collapse
      .nav-item.dropdown
      .dropdown-toggle:after {
      font-size: 0.75rem;
      float: right;
    }
    .navbar-expand-md
      .navbar-collapse
      .nav-item.dropdown.show
      .dropdown-toggle.nav-link:after {
      transform: rotate(0);
    }
  }
  @media (min-width: 768px) {
    .navbar-expand-md
      .dropdown:not(.dropdown-submenu):not(.dropdown-fluid)
      > .dropdown-menu:not(.dropdown-menu-right) {
      margin-left: -1.25rem;
    }
    .navbar-expand-md
      .dropdown:not(.dropdown-submenu):not(.dropdown-fluid)
      > .dropdown-menu-right {
      margin-right: -1.25rem;
    }
    .navbar-expand-md
      .dropdown-menu
      .list-group-emphasized
      > li
      .list-group-item {
      transform: scale(1);
      transition: all 0.2s ease;
    }
  }
  @media (min-width: 768px) and (prefers-reduced-motion: reduce) {
    .navbar-expand-md
      .dropdown-menu
      .list-group-emphasized
      > li
      .list-group-item {
      transition: none;
    }
  }
  @media (min-width: 768px) {
    .navbar-expand-md .dropdown-menu .list-group-emphasized > li .media-body * {
      transition: all 0.2s ease;
    }
  }
  @media (min-width: 768px) and (prefers-reduced-motion: reduce) {
    .navbar-expand-md .dropdown-menu .list-group-emphasized > li .media-body * {
      transition: none;
    }
  }
  @media (min-width: 768px) {
    .navbar-expand-md
      .dropdown-menu
      .list-group-emphasized
      > li:hover
      .list-group-item {
      z-index: 11;
      transform: scale(1.05);
      background-color: #fafbfe;
      box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.05);
      border-radius: 0.5rem;
    }
    .navbar-expand-md .list-group .list-group-item:first-child {
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
    }
    .navbar-expand-md .list-group .list-group-item:last-child {
      border-bottom-right-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }
    .navbar-expand-md .list-group > li:first-child .list-group-item {
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
    }
    .navbar-expand-md .list-group > li:last-child .list-group-item {
      border-bottom-right-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }
    .navbar-expand-md .bg-dropdown-secondary {
      background-color: #fafafa;
    }
    .navbar-expand-md .dropdown-body-left {
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }
    .navbar-expand-md .dropdown-body-right {
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
    .navbar-expand-md .dropdown-submenu .dropdown-menu {
      min-width: 16rem;
      margin-left: 1rem;
    }
    .navbar-expand-md .dropdown-submenu .dropdown-menu:before {
      content: "";
      border-left: 1.5rem solid transparent;
      position: absolute;
      left: -1rem;
      top: 0;
      height: 100%;
    }
    .navbar-expand-md .dropdown-submenu .dropdown-item:after {
      right: 0;
    }
    .navbar-expand-md .dropdown-body {
      padding: 2rem 2.5rem;
    }
    .navbar-expand-md .delimiter {
      border-right: 1px solid #e2e8f0;
    }
  }
  @media (max-width: 991.98px) {
    .navbar-expand-lg .navbar-collapse .dropdown-menu {
      padding-left: 0;
      padding-right: 0;
      background: 0 0;
      border-radius: 0;
    }
    .navbar-expand-lg .navbar-collapse .dropdown-menu.show {
      border-bottom: 1px solid #eaecf3;
    }
    .navbar-expand-lg .navbar-collapse .dropdown-menu-single {
      padding-left: 1rem;
      padding-top: 0;
      padding-bottom: 0;
      border-left: 3px solid #008aff;
    }
    .navbar-expand-lg .navbar-collapse .dropdown-item {
      position: relative;
      font-size: 1rem;
      font-weight: 600;
    }
    .navbar-expand-lg .navbar-collapse .dropdown-img-left {
      border-radius: 0.5rem;
    }
    .navbar-expand-lg
      .navbar-collapse
      .dropdown-submenu.show
      .dropdown-toggle:after {
      transform: rotate(90deg);
      transition: all 0.2s ease;
    }
  }
  @media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
    .navbar-expand-lg
      .navbar-collapse
      .dropdown-submenu.show
      .dropdown-toggle:after {
      transition: none;
    }
  }
  @media (max-width: 991.98px) {
    .navbar-expand-lg .navbar-collapse .delimiter {
      border-top: 1px solid #e2e8f0;
      padding-top: 2.5rem;
    }
    .navbar-expand-lg .navbar-collapse .navbar-btn {
      width: 100%;
      margin-top: 1rem;
      padding: 1rem 1.75rem;
      background-color: transparent;
      color: #008aff;
      border: 0;
      border-top: 1px solid #e2e8f0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    .navbar-expand-lg
      .navbar-collapse
      .nav-item.dropdown
      .dropdown-toggle.nav-link:after {
      transform: rotate(-90deg);
      transition: all 0.2s ease;
    }
  }
  @media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
    .navbar-expand-lg
      .navbar-collapse
      .nav-item.dropdown
      .dropdown-toggle.nav-link:after {
      transition: none;
    }
  }
  @media (max-width: 991.98px) {
    .navbar-expand-lg
      .navbar-collapse
      .nav-item.dropdown
      .dropdown-toggle:after {
      font-size: 0.75rem;
      float: right;
    }
    .navbar-expand-lg
      .navbar-collapse
      .nav-item.dropdown.show
      .dropdown-toggle.nav-link:after {
      transform: rotate(0);
    }
  }
  @media (min-width: 992px) {
    .navbar-expand-lg
      .dropdown:not(.dropdown-submenu):not(.dropdown-fluid)
      > .dropdown-menu:not(.dropdown-menu-right) {
      margin-left: -1.25rem;
    }
    .navbar-expand-lg
      .dropdown:not(.dropdown-submenu):not(.dropdown-fluid)
      > .dropdown-menu-right {
      margin-right: -1.25rem;
    }
    .navbar-expand-lg
      .dropdown-menu
      .list-group-emphasized
      > li
      .list-group-item {
      transform: scale(1);
      transition: all 0.2s ease;
    }
  }
  @media (min-width: 992px) and (prefers-reduced-motion: reduce) {
    .navbar-expand-lg
      .dropdown-menu
      .list-group-emphasized
      > li
      .list-group-item {
      transition: none;
    }
  }
  @media (min-width: 992px) {
    .navbar-expand-lg .dropdown-menu .list-group-emphasized > li .media-body * {
      transition: all 0.2s ease;
    }
  }
  @media (min-width: 992px) and (prefers-reduced-motion: reduce) {
    .navbar-expand-lg .dropdown-menu .list-group-emphasized > li .media-body * {
      transition: none;
    }
  }
  @media (min-width: 992px) {
    .navbar-expand-lg
      .dropdown-menu
      .list-group-emphasized
      > li:hover
      .list-group-item {
      z-index: 11;
      transform: scale(1.05);
      background-color: #fafbfe;
      box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.05);
      border-radius: 0.5rem;
    }
    .navbar-expand-lg .list-group .list-group-item:first-child {
      // border-top-left-radius: 0.5rem;
      // border-top-right-radius: 0.5rem;
    }
    .navbar-expand-lg .list-group .list-group-item:last-child {
      // border-bottom-right-radius: 0.5rem;
      // border-bottom-left-radius: 0.5rem;
    }
    .navbar-expand-lg .list-group > li:first-child .list-group-item {
      // border-top-left-radius: 0.5rem;
      // border-top-right-radius: 0.5rem;
    }
    .navbar-expand-lg .list-group > li:last-child .list-group-item {
      // border-bottom-right-radius: 0.5rem;
      // border-bottom-left-radius: 0.5rem;
    }
    .navbar-expand-lg .bg-dropdown-secondary {
      background-color: #fafafa;
    }
    .navbar-expand-lg .dropdown-body-left {
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }
    .navbar-expand-lg .dropdown-body-right {
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
    .navbar-expand-lg .dropdown-submenu .dropdown-menu {
      min-width: 16rem;
      margin-left: 1rem;
    }
    .navbar-expand-lg .dropdown-submenu .dropdown-menu:before {
      content: "";
      border-left: 1.5rem solid transparent;
      position: absolute;
      left: -1rem;
      top: 0;
      height: 100%;
    }
    .navbar-expand-lg .dropdown-submenu .dropdown-item:after {
      right: 0;
    }
    .navbar-expand-lg .dropdown-body {
      padding: 2rem 2.5rem;
    }
    .navbar-expand-lg .delimiter {
      border-right: 1px solid #e2e8f0;
    }
  }
  @media (max-width: 1199.98px) {
    .navbar-expand-xl .navbar-collapse .dropdown-menu {
      padding-left: 0;
      padding-right: 0;
      background: 0 0;
      border-radius: 0;
    }
    .navbar-expand-xl .navbar-collapse .dropdown-menu.show {
      border-bottom: 1px solid #eaecf3;
    }
    .navbar-expand-xl .navbar-collapse .dropdown-menu-single {
      padding-left: 1rem;
      padding-top: 0;
      padding-bottom: 0;
      border-left: 3px solid #008aff;
    }
    .navbar-expand-xl .navbar-collapse .dropdown-item {
      position: relative;
      font-size: 1rem;
      font-weight: 600;
    }
    .navbar-expand-xl .navbar-collapse .dropdown-img-left {
      border-radius: 0.5rem;
    }
    .navbar-expand-xl
      .navbar-collapse
      .dropdown-submenu.show
      .dropdown-toggle:after {
      transform: rotate(90deg);
      transition: all 0.2s ease;
    }
  }
  @media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
    .navbar-expand-xl
      .navbar-collapse
      .dropdown-submenu.show
      .dropdown-toggle:after {
      transition: none;
    }
  }
  @media (max-width: 1199.98px) {
    .navbar-expand-xl .navbar-collapse .delimiter {
      border-top: 1px solid #e2e8f0;
      padding-top: 2.5rem;
    }
    .navbar-expand-xl .navbar-collapse .navbar-btn {
      width: 100%;
      margin-top: 1rem;
      padding: 1rem 1.75rem;
      background-color: transparent;
      color: #008aff;
      border: 0;
      border-top: 1px solid #e2e8f0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    .navbar-expand-xl
      .navbar-collapse
      .nav-item.dropdown
      .dropdown-toggle.nav-link:after {
      transform: rotate(-90deg);
      transition: all 0.2s ease;
    }
  }
  @media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
    .navbar-expand-xl
      .navbar-collapse
      .nav-item.dropdown
      .dropdown-toggle.nav-link:after {
      transition: none;
    }
  }
  @media (max-width: 1199.98px) {
    .navbar-expand-xl
      .navbar-collapse
      .nav-item.dropdown
      .dropdown-toggle:after {
      font-size: 0.75rem;
      float: right;
    }
    .navbar-expand-xl
      .navbar-collapse
      .nav-item.dropdown.show
      .dropdown-toggle.nav-link:after {
      transform: rotate(0);
    }
  }
  @media (min-width: 1200px) {
    .navbar-expand-xl
      .dropdown:not(.dropdown-submenu):not(.dropdown-fluid)
      > .dropdown-menu:not(.dropdown-menu-right) {
      margin-left: -1.25rem;
    }
    .navbar-expand-xl
      .dropdown:not(.dropdown-submenu):not(.dropdown-fluid)
      > .dropdown-menu-right {
      margin-right: -1.25rem;
    }
    .navbar-expand-xl
      .dropdown-menu
      .list-group-emphasized
      > li
      .list-group-item {
      transform: scale(1);
      transition: all 0.2s ease;
    }
  }
  @media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
    .navbar-expand-xl
      .dropdown-menu
      .list-group-emphasized
      > li
      .list-group-item {
      transition: none;
    }
  }
  @media (min-width: 1200px) {
    .navbar-expand-xl .dropdown-menu .list-group-emphasized > li .media-body * {
      transition: all 0.2s ease;
    }
  }
  @media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
    .navbar-expand-xl .dropdown-menu .list-group-emphasized > li .media-body * {
      transition: none;
    }
  }
  @media (min-width: 1200px) {
    .navbar-expand-xl
      .dropdown-menu
      .list-group-emphasized
      > li:hover
      .list-group-item {
      z-index: 11;
      transform: scale(1.05);
      background-color: #fafbfe;
      box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.05);
      border-radius: 0.5rem;
    }
    .navbar-expand-xl .list-group .list-group-item:first-child {
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
    }
    .navbar-expand-xl .list-group .list-group-item:last-child {
      border-bottom-right-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }
    .navbar-expand-xl .list-group > li:first-child .list-group-item {
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
    }
    .navbar-expand-xl .list-group > li:last-child .list-group-item {
      border-bottom-right-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }
    .navbar-expand-xl .bg-dropdown-secondary {
      background-color: #fafafa;
    }
    .navbar-expand-xl .dropdown-body-left {
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }
    .navbar-expand-xl .dropdown-body-right {
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
    .navbar-expand-xl .dropdown-submenu .dropdown-menu {
      min-width: 16rem;
      margin-left: 1rem;
    }
    .navbar-expand-xl .dropdown-submenu .dropdown-menu:before {
      content: "";
      border-left: 1.5rem solid transparent;
      position: absolute;
      left: -1rem;
      top: 0;
      height: 100%;
    }
    .navbar-expand-xl .dropdown-submenu .dropdown-item:after {
      right: 0;
    }
    .navbar-expand-xl .dropdown-body {
      padding: 2rem 2.5rem;
    }
    .navbar-expand-xl .delimiter {
      border-right: 1px solid #e2e8f0;
    }
  }
  .navbar-expand .navbar-collapse .dropdown-menu {
    padding-left: 0;
    padding-right: 0;
    background: 0 0;
    border-radius: 0;
  }
  .navbar-expand .navbar-collapse .dropdown-menu.show {
    border-bottom: 1px solid #eaecf3;
  }
  .navbar-expand .navbar-collapse .dropdown-menu-single {
    padding-left: 1rem;
    padding-top: 0;
    padding-bottom: 0;
    border-left: 3px solid #008aff;
  }
  .navbar-expand .navbar-collapse .dropdown-item {
    position: relative;
    font-size: 1rem;
    font-weight: 600;
  }
  .navbar-expand .navbar-collapse .dropdown-img-left {
    border-radius: 0.5rem;
  }
  .navbar-expand
    .navbar-collapse
    .dropdown-submenu.show
    .dropdown-toggle:after {
    transform: rotate(90deg);
    transition: all 0.2s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .navbar-expand
      .navbar-collapse
      .dropdown-submenu.show
      .dropdown-toggle:after {
      transition: none;
    }
  }
  .navbar-expand .navbar-collapse .delimiter {
    border-top: 1px solid #e2e8f0;
    padding-top: 2.5rem;
  }
  .navbar-expand .navbar-collapse .navbar-btn {
    width: 100%;
    margin-top: 1rem;
    padding: 1rem 1.75rem;
    background-color: transparent;
    color: #008aff;
    border: 0;
    border-top: 1px solid #e2e8f0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .navbar-expand
    .navbar-collapse
    .nav-item.dropdown
    .dropdown-toggle.nav-link:after {
    transform: rotate(-90deg);
    transition: all 0.2s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .navbar-expand
      .navbar-collapse
      .nav-item.dropdown
      .dropdown-toggle.nav-link:after {
      transition: none;
    }
  }
  .navbar-expand .navbar-collapse .nav-item.dropdown .dropdown-toggle:after {
    font-size: 0.75rem;
    float: right;
  }
  .navbar-expand
    .navbar-collapse
    .nav-item.dropdown.show
    .dropdown-toggle.nav-link:after {
    transform: rotate(0);
  }
  .navbar-expand
    .dropdown:not(.dropdown-submenu):not(.dropdown-fluid)
    > .dropdown-menu:not(.dropdown-menu-right) {
    margin-left: -1.25rem;
  }
  .navbar-expand
    .dropdown:not(.dropdown-submenu):not(.dropdown-fluid)
    > .dropdown-menu-right {
    margin-right: -1.25rem;
  }
  .navbar-expand .dropdown-menu .list-group-emphasized > li .list-group-item {
    transform: scale(1);
    transition: all 0.2s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .navbar-expand .dropdown-menu .list-group-emphasized > li .list-group-item {
      transition: none;
    }
  }
  .navbar-expand .dropdown-menu .list-group-emphasized > li .media-body * {
    transition: all 0.2s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .navbar-expand .dropdown-menu .list-group-emphasized > li .media-body * {
      transition: none;
    }
  }
  .navbar-expand
    .dropdown-menu
    .list-group-emphasized
    > li:hover
    .list-group-item {
    z-index: 11;
    transform: scale(1.05);
    background-color: #fafbfe;
    box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.05);
    border-radius: 0.5rem;
  }
  .navbar-expand .list-group .list-group-item:first-child {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .navbar-expand .list-group .list-group-item:last-child {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .navbar-expand .list-group > li:first-child .list-group-item {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .navbar-expand .list-group > li:last-child .list-group-item {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .navbar-expand .bg-dropdown-secondary {
    background-color: #fafafa;
  }
  .navbar-expand .dropdown-body-left {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .navbar-expand .dropdown-body-right {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .navbar-expand .dropdown-submenu .dropdown-menu {
    min-width: 16rem;
    margin-left: 1rem;
  }
  .navbar-expand .dropdown-submenu .dropdown-menu:before {
    content: "";
    border-left: 1.5rem solid transparent;
    position: absolute;
    left: -1rem;
    top: 0;
    height: 100%;
  }
  .navbar-expand .dropdown-submenu .dropdown-item:after {
    right: 0;
  }
  .navbar-expand .dropdown-body {
    padding: 2rem 2.5rem;
  }
  .navbar-expand .delimiter {
    border-right: 1px solid #e2e8f0;
  }
  .navbar-search .input-group {
    border-radius: 2rem;
    border: 0 solid;
    transition: background-color 0.4s linear;
    transition-delay: 0.2s;
  }
  @media (prefers-reduced-motion: reduce) {
    .navbar-search .input-group {
      transition: none;
    }
  }
  .navbar-search .input-group .input-group-text {
    background-color: transparent;
    padding-left: 1rem;
    border: 0;
  }
  .navbar-search .form-control {
    width: 250px;
    background-color: transparent;
    border: 0;
    transition: all 0.2s cubic-bezier(0.77, 0, 0.2, 2.25);
  }
  @media (prefers-reduced-motion: reduce) {
    .navbar-search .form-control {
      transition: none;
    }
  }
  .navbar-search .focused .input-group .form-control {
    width: 380px;
  }
  .navbar-search .close {
    display: none;
  }
  .navbar-search-dark .input-group {
    background-color: rgba(31, 45, 61, 0.8);
    border-color: rgba(255, 255, 255, 0.6);
  }
  .navbar-search-dark .input-group-text {
    color: rgba(255, 255, 255, 0.6);
  }
  .navbar-search-dark .form-control {
    color: rgba(255, 255, 255, 0.9);
  }
  .navbar-search-dark .form-control:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
  .navbar-search-dark .form-control::-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
  .navbar-search-dark .form-control::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
  .navbar-search-dark .focused .input-group {
    background-color: rgba(31, 45, 61, 0.9);
    border-color: rgba(255, 255, 255, 0.9);
  }
  .navbar-search-light .input-group {
    background-color: rgba(255, 255, 255, 0.9);
    border-color: rgba(0, 0, 0, 0.6);
  }
  .navbar-search-light .input-group-text {
    color: rgba(0, 0, 0, 0.6);
  }
  .navbar-search-light .form-control {
    color: rgba(0, 0, 0, 0.9);
  }
  .navbar-search-light .form-control:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
  .navbar-search-light .form-control::-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
  .navbar-search-light .form-control::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
  .navbar-search-light .focused .input-group {
    background-color: #fff;
    border-color: rgba(0, 0, 0, 0.9);
  }
  .omnisearch {
    width: 100%;
    margin-top: 1rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    opacity: 0;
    background-color: transparent;
    pointer-events: none;
    transform: translateY(0);
    transition: transform 0.3s, opacity 0.15s;
  }
  @media (prefers-reduced-motion: reduce) {
    .omnisearch {
      transition: none;
    }
  }
  .omnisearch-container {
    margin: auto;
  }
  @media (max-width: 991.98px) {
    .omnisearch-container {
      width: 100%;
    }
  }
  @media (min-width: 768px) {
    .omnisearch-container {
      width: 580px;
    }
  }
  .omnisearch.show {
    opacity: 1;
    transform: translateY(10%);
  }
  .omnisearch .container {
    position: relative;
    height: 100%;
  }
  .omnisearch .omnisearch-form {
    display: block;
    position: relative;
    z-index: 700;
    background: #fff;
    border-radius: 0.5rem;
  }
  .omnisearch .omnisearch-form .input-group-text {
    font-size: 1.25rem;
    background: 0 0;
  }
  .omnisearch .omnisearch-form .form-control {
    display: block;
    height: 58px;
    font-size: 1.25rem;
    color: #718096;
    background-color: transparent;
    background-image: none;
  }
  .omnisearch .omnisearch-form .form-control:-ms-input-placeholder {
    color: #a0aec0;
  }
  .omnisearch .omnisearch-form .form-control::-ms-input-placeholder {
    color: #a0aec0;
  }
  .omnisearch .omnisearch-form .form-control::placeholder {
    color: #a0aec0;
  }
  .omnisearch .omnisearch-suggestions {
    min-height: 150px;
    padding: 1.5rem;
    background: #fff;
    border-radius: 0.5rem;
    position: relative;
    opacity: 0;
    transition: opacity 0.3s;
    transition-delay: 0.21s;
  }
  @media (prefers-reduced-motion: reduce) {
    .omnisearch .omnisearch-suggestions {
      transition: none;
    }
  }
  .omnisearch .omnisearch-suggestions:before {
    background: #fff;
    box-shadow: none;
    content: "";
    display: block;
    height: 16px;
    width: 16px;
    left: 20px;
    position: absolute;
    bottom: 100%;
    transform: rotate(-45deg) translateY(1rem);
    z-index: -5;
    border-radius: 0.2rem;
  }
  .omnisearch .omnisearch-suggestions .heading {
    color: #4a5568;
  }
  .omnisearch .omnisearch-suggestions .list-link span {
    font-weight: 600;
    color: #2d3748;
  }
  .omnisearch .omnisearch-suggestions .list-link:hover {
    color: #008aff;
  }
  .omnisearch .omnisearch-suggestions .list-link:hover span {
    color: #008aff;
  }
  .omnisearch.show .omnisearch-form,
  .omnisearch.show .omnisearch-suggestions {
    pointer-events: auto;
  }
  .omnisearch.show .omnisearch-suggestions {
    opacity: 1;
  }
  .omnisearch-open {
    overflow: hidden;
  }
  .progress-circle {
    width: 100px;
    height: 100px;
  }
  .progress-circle .progressbar-text {
    font-size: 1.5rem;
    font-weight: 600;
    color: #121212;
  }
  .progress-circle .h1,
  .progress-circle .h2,
  .progress-circle .h3,
  .progress-circle [class^="display"] {
    color: #121212;
  }
  .progress-circle svg path {
    strokelinecap: round;
  }
  .progress-circle.progress-sm {
    width: 60px;
    height: 60px;
  }
  .progress-circle.progress-sm .progressbar-text {
    font-size: 0.875rem;
  }
  .progress-circle.progress-lg {
    width: 140px;
    height: 140px;
  }
  .progress-circle.progress-lg .progressbar-text {
    font-size: 1.25rem;
  }
  section.slice {
    // background-color: #fff;
  }
  .slice {
    position: relative;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .slice-xl {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .slice-lg {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  @media screen and (max-width: 768px) {
    .slice-lg {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }
  .slice-sm {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .slice:not(.border-top):not(.border-bottom):not(.delimiter-bottom):not(
      [class*="bg-"]
    ):not(.section-rotate)
    + .slice:not(.border-top):not(.border-bottom):not(.delimiter-top):not(
      [class*="bg-"]
    ) {
    padding-top: 0;
  }
  .slice-video {
    min-height: 600px;
  }
  .section-half-rounded {
    padding-top: 7rem;
    padding-bottom: 7rem;
    background: 0 0;
    position: relative;
  }
  .section-half-rounded .section-inner {
    position: absolute;
    top: 0;
    height: 100%;
    z-index: -2;
  }
  @media (max-width: 991.98px) {
    .section-half-rounded .section-inner {
      width: 100%;
    }
  }
  .section-process {
    background: 0 0;
  }
  .section-process-step {
    position: relative;
    padding: 4.5rem 0;
  }
  .section-process-step:not(:last-child):before {
    content: "";
    display: block;
    width: 360px;
    height: 100px;
    background: url("data:image/svg+xml;charset=utf8,%3Csvg width='355px' height='103px' viewBox='0 0 355 103' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' strokeDasharray='6,12' strokeLinecap='round'%3E%3Cpath stroke='%23E2E8F0' stroke-width='3' transform='translate(173.245679, 51.548257) scale(-1, 1) translate(-173.245679, -51.548257)' d='M-6.75432109,1.54825684 C113.245679,110.326533 233.245679,130.359459 353.245679,61.647035'/%3E%3C/g%3E%3C/svg%3E")
      no-repeat center;
    background-size: 360px 100px;
    transform: rotate(40deg);
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: -80px;
  }
  @media (max-width: 991.98px) {
    .section-process-step:not(:last-child):before {
      transform: rotate(90deg) scale(0.7);
      bottom: 20px;
    }
  }
  .section-process-step:nth-child(even):before {
    background: url("data:image/svg+xml;charset=utf8,%3Csvg width='355px' height='103px' viewBox='0 0 355 103' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' strokeDasharray='6,12' strokeLinecap='round'%3E%3Cpath stroke='%23E2E8F0' stroke-width='3' d='M2.24567891,1.54825684 C122.245679,110.326533 242.245679,130.359459 362.245679,61.647035'/%3E%3C/g%3E%3C/svg%3E")
      no-repeat center;
    background-size: 360px 100px;
    transform: rotate(-40deg);
  }
  @media (max-width: 991.98px) {
    .section-process-step:nth-child(even):before {
      transform: rotate(-90deg) scale(0.7);
      bottom: 20px;
    }
  }
  .shape-container {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 2;
    transform: translateZ(0);
    overflow: hidden;
  }
  .shape-container svg {
    display: block;
    fill: #fff;
    pointer-events: none;
    vertical-align: baseline;
  }
  .shape-position-top {
    top: 0;
    margin-top: -1px;
  }
  .shape-position-bottom {
    bottom: 0;
    margin-bottom: -1px;
  }
  .shape-orientation-inverse {
    transform: rotate(180deg);
  }
  .shape-line {
    min-height: 19px;
  }
  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    .ie-shape-line {
      height: 100px;
    }
    .ie-shape-wave-1 {
      height: 504px;
    }
    .ie-shape-wave-3 {
      height: 231px;
    }
    .ie-shape-clouds {
      height: 400px;
    }
  }
  .spotlight {
    position: relative;
  }
  .spotlight .container {
    height: 100%;
  }
  .spotlight .animated {
    opacity: 0;
  }
  .spotlight .animated.animation-ended {
    opacity: 1;
  }
  @media (min-width: 768px) {
    .spotlight-overlay-img {
      position: relative;
    }
    .spotlight-overlay-img img {
      position: absolute;
      z-index: 10;
    }
  }
  .table-cards {
    border-collapse: separate;
    border-spacing: 0 1rem;
  }
  .table-cards tbody td,
  .table-cards tbody th,
  .table-cards thead td,
  .table-cards thead th {
    border: 0 !important;
    position: relative;
    background-color: transparent;
  }
  .table-cards tbody td,
  .table-cards thead td {
    padding: 1rem;
  }
  .table-cards tbody th,
  .table-cards thead th {
    padding: 0 1rem;
  }
  .table-cards tbody tr {
    border-radius: 0.75rem;
    box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.05);
  }
  .table-cards tbody tr th {
    border-radius: 0.75rem 0 0 0.75rem;
  }
  .table-cards tbody tr th:after {
    border-radius: 0.75rem 0 0 0.75rem;
    border-left: 1px solid #eaecf3;
  }
  .table-cards tbody tr td:last-child {
    border-radius: 0 0.75rem 0.75rem 0;
  }
  .table-cards tbody tr td:last-child:after {
    border-radius: 0 0.75rem 0.75rem 0;
    border-right: 1px solid #eaecf3;
  }
  .table-cards tbody tr td,
  .table-cards tbody tr th {
    background-color: #fff;
  }
  .table-cards.table-hover tbody > tr:hover td,
  .table-cards.table-hover tbody > tr:hover th {
    background-color: #fafbfe;
  }
  @media (min-width: 768px) {
    .table-cards.table-scale--hover tr:hover {
      transform: scale(1.02);
    }
  }
  .timeline {
    position: relative;
  }
  .timeline:before {
    content: "";
    position: absolute;
    top: 0;
    left: 1rem;
    height: 100%;
    border-right: 2px solid #e2e8f0;
  }
  [data-timeline-axis-style="dashed"]:before {
    border-right-style: dashed !important;
  }
  [data-timeline-axis-style="dotted"]:before {
    border-right-style: dotted !important;
  }
  .timeline-block {
    position: relative;
    margin: 2em 0;
  }
  .timeline-block:after {
    content: "";
    display: table;
    clear: both;
  }
  .timeline-block:first-child {
    margin-top: 0;
  }
  .timeline-block:last-child {
    margin-bottom: 0;
  }
  .timeline-step {
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    left: 0;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background: #fff;
    border: 2px solid #e2e8f0;
    text-align: center;
    font-size: 1rem;
    font-weight: 600;
    z-index: 1;
  }
  .timeline-step i,
  .timeline-step svg {
    line-height: 1.4;
  }
  .timeline-step-icon {
    background: #fff;
    border: 2px solid #e2e8f0;
  }
  .timeline-step-xs {
    width: 17px;
    height: 17px;
    font-size: 0.75rem;
  }
  .timeline-step-sm {
    width: 23px;
    height: 23px;
    font-size: 0.75rem;
  }
  .timeline-step-lg {
    width: 47px;
    height: 47px;
    font-size: 1.75rem;
  }
  .timeline-content {
    position: relative;
    margin-left: 60px;
    margin-right: 30px;
    position: relative;
    top: -6px;
  }
  .timeline-content:after {
    content: "";
    display: table;
    clear: both;
  }
  .timeline-body {
    padding: 1.5rem;
  }
  @media (min-width: 992px) {
    .timeline:before {
      left: 50%;
      margin-left: -2px;
    }
    .timeline-step {
      left: 50%;
      transform: translateX(-50%);
    }
    .timeline-content {
      width: 38%;
    }
    .timeline-body {
      padding: 1.5rem;
    }
    .timeline-block:nth-child(even) .timeline-content {
      float: right;
    }
    [data-timeline-axis-color="primary"]:before {
      border-color: #008aff;
    }
    [data-timeline-axis-color="secondary"]:before {
      border-color: #eaecf3;
    }
    [data-timeline-axis-color="success"]:before {
      border-color: #5cc9a7;
    }
    [data-timeline-axis-color="info"]:before {
      border-color: #50b5ff;
    }
    [data-timeline-axis-color="warning"]:before {
      border-color: #ffbe3d;
    }
    [data-timeline-axis-color="danger"]:before {
      border-color: #d33139;
    }
    [data-timeline-axis-color="light"]:before {
      border-color: #eaecf3;
    }
    [data-timeline-axis-color="dark"]:before {
      border-color: #171347;
    }
    [data-timeline-axis-color="neutral"]:before {
      border-color: #fff;
    }
    [data-timeline-axis-color="white"]:before {
      border-color: #fff;
    }
  }
  .timeline-one-side:before {
    left: 1rem;
  }
  .timeline-one-side .timeline-step {
    transform: translateX(-50%);
    left: 1rem;
  }
  .timeline-one-side .timeline-content {
    width: auto;
  }
  .timeline-one-side .timeline-block:nth-child(even) .timeline-content {
    float: none;
  }
  .tongue {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 1;
    transform: rotate(180deg) translateX(50%);
    width: 138px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    color: #4a5568;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='138' height='35' viewBox='0 0 138 35'%3E%3Cpath fill='%23FFF' d='M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z'/%3E%3C/svg%3E");
  }
  .tongue i {
    animation: floating-sm 2s ease infinite;
  }
  .tongue:hover i {
    animation-play-state: paused;
  }
  .tongue-primary {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='138' height='35' viewBox='0 0 138 35'%3E%3Cpath fill='%23008aff' d='M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z'/%3E%3C/svg%3E");
    color: #fff;
  }
  .tongue-primary:hover {
    color: #fff;
  }
  .tongue-secondary {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='138' height='35' viewBox='0 0 138 35'%3E%3Cpath fill='%23eaecf3' d='M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z'/%3E%3C/svg%3E");
    color: #273444;
  }
  .tongue-secondary:hover {
    color: #273444;
  }
  .tongue-success {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='138' height='35' viewBox='0 0 138 35'%3E%3Cpath fill='%235cc9a7' d='M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z'/%3E%3C/svg%3E");
    color: #fff;
  }
  .tongue-success:hover {
    color: #fff;
  }
  .tongue-info {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='138' height='35' viewBox='0 0 138 35'%3E%3Cpath fill='%2350b5ff' d='M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z'/%3E%3C/svg%3E");
    color: #fff;
  }
  .tongue-info:hover {
    color: #fff;
  }
  .tongue-warning {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='138' height='35' viewBox='0 0 138 35'%3E%3Cpath fill='%23FFBE3D' d='M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z'/%3E%3C/svg%3E");
    color: #fff;
  }
  .tongue-warning:hover {
    color: #fff;
  }
  .tongue-danger {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='138' height='35' viewBox='0 0 138 35'%3E%3Cpath fill='%23d33139' d='M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z'/%3E%3C/svg%3E");
    color: #fff;
  }
  .tongue-danger:hover {
    color: #fff;
  }
  .tongue-light {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='138' height='35' viewBox='0 0 138 35'%3E%3Cpath fill='%23eaecf3' d='M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z'/%3E%3C/svg%3E");
    color: #273444;
  }
  .tongue-light:hover {
    color: #273444;
  }
  .tongue-dark {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='138' height='35' viewBox='0 0 138 35'%3E%3Cpath fill='%23171347' d='M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z'/%3E%3C/svg%3E");
    color: #fff;
  }
  .tongue-dark:hover {
    color: #fff;
  }
  .tongue-neutral {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='138' height='35' viewBox='0 0 138 35'%3E%3Cpath fill='%23FFF' d='M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z'/%3E%3C/svg%3E");
    color: #273444;
  }
  .tongue-neutral:hover {
    color: #273444;
  }
  .tongue-white {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='138' height='35' viewBox='0 0 138 35'%3E%3Cpath fill='%23FFF' d='M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z'/%3E%3C/svg%3E");
    color: #273444;
  }
  .tongue-white:hover {
    color: #273444;
  }
  .tongue-section-primary {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='138' height='35' viewBox='0 0 138 35'%3E%3Cpath fill='%23FFF' d='M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z'/%3E%3C/svg%3E");
    color: #273444;
  }
  .tongue-section-primary:hover {
    color: #273444;
  }
  .tongue-section-secondary {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='138' height='35' viewBox='0 0 138 35'%3E%3Cpath fill='%23FAFBFE' d='M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z'/%3E%3C/svg%3E");
    color: #273444;
  }
  .tongue-section-secondary:hover {
    color: #273444;
  }
  .tongue-section-light {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='138' height='35' viewBox='0 0 138 35'%3E%3Cpath fill='%23CBD5E0' d='M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z'/%3E%3C/svg%3E");
    color: #273444;
  }
  .tongue-section-light:hover {
    color: #273444;
  }
  .tongue-section-dark {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='138' height='35' viewBox='0 0 138 35'%3E%3Cpath fill='%230e0b2b' d='M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z'/%3E%3C/svg%3E");
    color: #fff;
  }
  .tongue-section-dark:hover {
    color: #fff;
  }
  .tongue-top {
    top: -1px;
  }
  .tongue-bottom {
    top: auto;
    bottom: -1px;
    transform: translateX(-50%);
  }
  @keyframes floating-sm {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(5px);
    }
    100% {
      transform: translateY(0);
    }
  }
  .countdown-item {
    display: inline-block;
  }
  .countdown-label {
    font-size: 1.2rem;
    padding: 0 10px;
  }
  .countdown-sm .countdown-digit,
  .countdown-sm .countdown-label {
    font-size: 1.4rem;
  }
  .countdown-sm .countdown-label {
    font-size: 0.875rem;
    padding: 0 10px;
  }
  [data-countdown-label="hide"] .countdown-label:not(.countdown-days) {
    display: none;
  }
  [data-countdown-label="show"] .countdown-separator {
    display: none;
  }
  .countdown-hero .countdown-digit {
    display: block;
    padding: 0 1.5rem;
    font-size: 3rem;
    font-weight: 600;
    text-align: center;
    color: #121212;
    cursor: default;
  }
  .countdown-hero .countdown-label {
    display: block;
    margin-top: 5px;
    text-align: center;
    font-size: 0.875rem;
    text-transform: capitalize;
    font-weight: 600;
  }
  .countdown-hero .countdown-separator {
    display: none;
  }
  @media (max-width: 991.98px) {
    .countdown-hero .countdown-digit {
      font-size: 1.5rem;
    }
  }
  .countdown-hero-dark .countdown-digit,
  .countdown-hero-dark .countdown-label {
    color: #fff;
  }
  .countdown-blocks .countdown-item {
    margin-right: 10px;
  }
  .countdown-blocks .countdown-item:last-child {
    margin-right: 0;
  }
  .countdown-blocks .countdown-digit {
    display: block;
    width: 60px;
    height: 60px;
    line-height: 60px;
    background: rgba(31, 45, 61, 0.6);
    color: #fff;
    font-size: 1.5rem;
    font-weight: 400;
    text-align: center;
    cursor: default;
    border-radius: 0.5rem;
  }
  .countdown-blocks .countdown-label {
    display: block;
    margin-top: 5px;
    text-align: center;
    font-size: 0.875rem;
    text-transform: capitalize;
    font-weight: 600;
  }
  .countdown-blocks .countdown-separator {
    display: none;
  }
  .countdown-dark .countdown-label {
    color: rgba(255, 255, 255, 0.8);
  }
  .milestone-count-extra {
    display: none;
  }
  .counting-finished + .counter-extra {
    display: inline-block;
  }
  .flatpickr-calendar {
    border: 0;
    width: auto;
    margin-top: -2px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: #fff;
    box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.05);
    border-radius: 0.375rem;
  }
  .flatpickr-calendar:after,
  .flatpickr-calendar:before {
    display: none;
  }
  .flatpickr-months {
    background-color: #fff;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }
  .flatpickr-months .flatpickr-month {
    height: 60px;
  }
  .flatpickr-months .flatpickr-next-month,
  .flatpickr-months .flatpickr-prev-month {
    width: 35px;
    height: 35px;
    padding: 0;
    line-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #121212;
    top: 13px;
    transition: background-color 0.4s linear;
  }
  @media (prefers-reduced-motion: reduce) {
    .flatpickr-months .flatpickr-next-month,
    .flatpickr-months .flatpickr-prev-month {
      transition: none;
    }
  }
  .flatpickr-months .flatpickr-next-month svg,
  .flatpickr-months .flatpickr-prev-month svg {
    fill: rgba(21, 44, 91, 0.7);
  }
  .flatpickr-months .flatpickr-next-month:hover,
  .flatpickr-months .flatpickr-prev-month:hover {
    color: #121212;
  }
  .flatpickr-months .flatpickr-next-month:hover svg,
  .flatpickr-months .flatpickr-prev-month:hover svg {
    fill: #121212;
  }
  .flatpickr-months .flatpickr-prev-month {
    margin-left: 15px;
  }
  .flatpickr-months .flatpickr-next-month {
    margin-right: 15px;
  }
  .flatpickr-current-month {
    font-size: 1.125rem;
    color: #121212;
    padding-top: 18px;
  }
  .flatpickr-current-month span.cur-month:hover {
    background-color: transparent;
  }
  .flatpickr-current-month .numInputWrapper:hover {
    background-color: transparent;
  }
  .flatpickr-current-month .numInputWrapper span {
    border: 0;
    right: -5px;
    padding: 0;
  }
  .flatpickr-current-month .numInputWrapper span:after {
    left: 3px;
  }
  .flatpickr-current-month .numInputWrapper span.arrowUp:after {
    border-bottom-color: rgba(255, 255, 255, 0.7);
  }
  .flatpickr-current-month .numInputWrapper span.arrowUp:hover:after {
    border-bottom-color: #fff;
  }
  .flatpickr-current-month .numInputWrapper span.arrowDown:after {
    border-top-color: rgba(255, 255, 255, 0.7);
  }
  .flatpickr-current-month .numInputWrapper span.arrowDown:hover:after {
    border-top-color: #fff;
  }
  span.flatpickr-weekday {
    font-weight: 600;
    color: #4a5568;
  }
  .flatpickr-day {
    font-size: 0.875rem;
    border: 0;
    color: #4a5568;
    border-radius: 0.375rem;
  }
  .flatpickr-day.today {
    color: #008aff !important;
  }
  .flatpickr-day.today:hover {
    background-color: transparent;
    color: #008aff;
  }
  .flatpickr-day:hover {
    background-color: transparent;
    color: #008aff;
  }
  .flatpickr-day.selected {
    background-color: #008aff;
    color: #fff !important;
  }
  .flatpickr-day.selected:hover {
    background-color: #008aff;
    color: #fff;
  }
  .numInputWrapper span:hover {
    background-color: transparent;
  }
  .flatpickr-time {
    border-top: 1px solid #eaecf3;
  }
  .flatpickr-innerContainer {
    padding: 15px;
  }
  .fancybox-button--arrow_left,
  .fancybox-button--arrow_right {
    transform: scale(1.5);
  }
  .fancybox-button--close {
    width: 110px;
    display: inline-flex;
  }
  .fancybox-button--close svg {
    width: auto;
    margin-right: 5px;
  }
  .quick-view-container {
    background: rgba(10, 10, 10, 0.85);
  }
  .quick-view-content {
    bottom: 0;
    height: calc(100% - 40px);
    left: 0;
    margin: auto;
    max-height: 650px;
    max-width: 980px;
    position: absolute;
    right: 0;
    top: 0;
    width: calc(100% - 40px);
  }
  .quick-view-carousel {
    background: #fff;
    border: 1px solid #eaecf3;
    border-radius: 0.75rem;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 57%;
    z-index: 10;
    box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.05);
  }
  .quick-view-carousel .fancybox-stage {
    bottom: 30px;
  }
  .quick-view-aside {
    background: #fff;
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
    bottom: 2rem;
    color: #718096;
    left: auto;
    padding: 3rem 0 2rem 0;
    position: absolute;
    right: 0;
    top: 2rem;
    width: 43%;
  }
  .quick-view-aside > div {
    height: 100%;
    overflow: auto;
    padding: 0 2rem;
  }
  .quick-view-close {
    background: #f0f0f0;
    border: 0;
    border-top-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
    color: #222;
    cursor: pointer;
    font-family: Arial;
    font-size: 14px;
    height: 44px;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    text-indent: -99999px;
    top: 2rem;
    transition: all 0.2s;
    width: 2.75rem;
  }
  .quick-view-close:hover {
    background: #e4e4e4;
  }
  .quick-view-close:after,
  .quick-view-close:before {
    background-color: #222;
    content: "";
    height: 18px;
    left: 22px;
    position: absolute;
    top: 12px;
    width: 1px;
  }
  .quick-view-close:before {
    transform: rotate(-45deg);
  }
  .quick-view-close:after {
    transform: rotate(45deg);
  }
  .quick-view-bullets {
    bottom: 0;
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    -webkit-tap-highlight-color: transparent;
    text-align: center;
    width: 100%;
    z-index: 99999;
  }
  .quick-view-bullets li {
    display: inline-block;
    vertical-align: top;
  }
  .quick-view-bullets li a {
    display: block;
    height: 30px;
    position: relative;
    width: 20px;
  }
  .quick-view-bullets li a span {
    background: #a0aec0;
    border-radius: 99px;
    height: 10px;
    left: 50%;
    overflow: hidden;
    position: absolute;
    text-indent: -99999px;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
  }
  .quick-view-bullets li.active a span {
    background: #008aff;
  }
  @media all and (min-width: 600px) {
    .fancybox-custom-layout {
      width: 90%;
      height: 90%;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%) translateZ(0) !important;
      box-shadow: 0 20px 25px -5px rgba(31, 45, 61, 0.1),
        0 10px 10px -5px rgba(31, 45, 61, 0.04);
    }
    .fancybox-custom-layout .fancybox-bg {
      background: #fcfaf9;
    }
    .fancybox-custom-layout.fancybox-is-open .fancybox-bg {
      opacity: 1;
    }
    .fancybox-custom-layout .fancybox-caption {
      display: none;
      background: #f1ecec;
      bottom: 0;
      color: #6c6f73;
      left: auto;
      padding: 30px 20px;
      right: 44px;
      top: 0;
      width: 256px;
    }
    .fancybox-custom-layout .fancybox-caption h3 {
      color: #444;
      font-size: 21px;
      line-height: 1.3;
      margin-bottom: 24px;
    }
    .fancybox-custom-layout .fancybox-caption a {
      color: #444;
    }
    .fancybox-custom-layout .fancybox-caption::before {
      display: none;
    }
    .fancybox-custom-layout .fancybox-stage {
      right: 300px;
    }
    .fancybox-custom-layout .fancybox-toolbar {
      background: #3b3b45;
      bottom: 0;
      left: auto;
      right: 0;
      top: 0;
      width: 44px;
    }
    .fancybox-custom-layout .fancybox-button {
      background: 0 0;
    }
    .fancybox-custom-layout .fancybox-navigation .fancybox-button div {
      padding: 6px;
      background: #fcfaf9;
      border-radius: 50%;
      transition: opacity 0.2s;
      box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
        0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
      opacity: 0.7;
    }
    .fancybox-custom-layout
      .fancybox-navigation
      .fancybox-button:not([disabled]):hover
      div {
      opacity: 1;
    }
    .fancybox-custom-layout .fancybox-navigation .fancybox-button[disabled] {
      color: #999;
    }
    .fancybox-custom-layout
      .fancybox-navigation
      .fancybox-button:not([disabled]) {
      color: #333;
    }
    .fancybox-custom-layout .fancybox-button--arrow_right {
      right: 308px;
    }
  }
  .fc-header-toolbar {
    display: none;
  }
  .fc-scroller {
    height: auto !important;
  }
  .fc th {
    padding: 0.75rem 1rem;
    font-size: 0.75rem;
    font-weight: 600;
    color: #4a5568;
    text-transform: uppercase;
  }
  .fc div.fc-row {
    margin-right: 0;
    border: 0;
  }
  .fc button .fc-icon {
    top: -5px;
  }
  .fc-unthemed td.fc-today {
    background-color: transparent;
  }
  .fc-unthemed td.fc-today span {
    color: #ffbe3d;
  }
  .fc-event {
    padding: 0;
    font-size: 0.75rem;
    border-radius: 0.2rem;
    border: 0;
  }
  .fc-event .fc-title {
    padding: 0.4rem 0.5rem;
    display: block;
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 600;
  }
  .fc-event .fc-time {
    float: left;
    background: rgba(31, 45, 61, 0.2);
    padding: 2px 6px;
    margin: 0 0 0 -1px;
  }
  .fc-view,
  .fc-view > table {
    border: 0;
    overflow: hidden;
  }
  .fc-view > table > tbody > tr .ui-widget-content {
    border-top: 0;
  }
  .fc-body {
    border: 0;
  }
  .fc-icon {
    font-size: 1rem;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    line-height: 35px;
  }
  .fc-icon:hover {
    color: #008aff;
  }
  .fc-button {
    border: 0;
    background: 0 0;
    box-shadow: none;
  }
  .fc-button:active,
  .fc-button:focus {
    outline: 0;
  }
  .calendar {
    z-index: 0;
  }
  .calendar td,
  .calendar th {
    border-color: #f0f2f7;
  }
  .calendar .fc-toolbar {
    height: 250px;
    background-color: #fff;
    border-radius: 0.375rem 0.375rem 0 0;
    position: relative;
    margin-bottom: -2px;
    z-index: 2;
  }
  @media (max-width: 575.98px) {
    .calendar .fc-toolbar {
      height: 135px;
    }
  }
  .calendar .fc-day-number {
    padding: 0.5rem 1rem;
    width: 100%;
    box-sizing: border-box;
  }
  @media (min-width: 576px) {
    .calendar .fc-day-number {
      font-size: 0.875rem;
      font-weight: 600;
      color: #8e9aac;
    }
  }
  .calendar .fc-day-header {
    text-align: left;
  }
  .calendar .fc-day-grid-event {
    margin: 1px 9px;
  }
  .card-calendar .card-header {
    border-bottom: 0;
  }
  .card-calendar table {
    background: 0 0;
  }
  .card-calendar table tr > td:first-child {
    border-left-width: 0;
  }
  .card-calendar table tr > td:last-child {
    border-right-width: 0;
  }
  .widget-calendar {
    position: relative;
    z-index: 0;
  }
  .widget-calendar td,
  .widget-calendar th {
    border-color: transparent;
    text-align: center;
  }
  .widget-calendar .fc-toolbar {
    margin-top: 1.25rem;
  }
  .widget-calendar .fc-toolbar h2 {
    font-size: 1rem;
  }
  .widget-calendar .fc-day-number {
    text-align: center;
    width: 100%;
    padding: 0;
  }
  .widget-calendar .fc table {
    font-size: 0.875rem;
  }
  .widget-calendar .fc th {
    padding: 0.75rem 0.5rem;
    font-size: 0.75rem;
  }
  .highlight {
    background-color: #13103b;
    padding: 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.375rem;
  }
  .highlight pre {
    margin-bottom: 0;
    padding: 1.25rem;
  }
  .hljs {
    display: block;
    overflow-x: auto;
    padding: 0.5em;
    color: #dcdcdc;
  }
  .hljs pre code {
    color: #dcdcdc;
  }
  .hljs-keyword,
  .hljs-literal,
  .hljs-name,
  .hljs-symbol {
    color: #569cd6;
  }
  .hljs-link {
    color: #569cd6;
    text-decoration: underline;
  }
  .hljs-built_in,
  .hljs-type {
    color: #4ec9b0;
  }
  .hljs-class,
  .hljs-number {
    color: #b8d7a3;
  }
  .hljs-meta-string,
  .hljs-string {
    color: #d69d85;
  }
  .hljs-regexp,
  .hljs-template-tag {
    color: #9a5334;
  }
  .hljs-formula,
  .hljs-function,
  .hljs-params,
  .hljs-subst,
  .hljs-title {
    color: #dcdcdc;
  }
  .hljs-comment,
  .hljs-quote {
    color: #57a64a;
    font-style: italic;
  }
  .hljs-doctag {
    color: #608b4e;
  }
  .hljs-meta,
  .hljs-meta-keyword,
  .hljs-tag {
    color: #9b9b9b;
  }
  .hljs-template-variable,
  .hljs-variable {
    color: #bd63c5;
  }
  .hljs-attr,
  .hljs-attribute,
  .hljs-builtin-name {
    color: #9cdcfe;
  }
  .hljs-section {
    color: gold;
  }
  .hljs-emphasis {
    font-style: italic;
  }
  .hljs-strong {
    font-weight: 700;
  }
  .hljs-bullet,
  .hljs-selector-attr,
  .hljs-selector-class,
  .hljs-selector-id,
  .hljs-selector-pseudo,
  .hljs-selector-tag {
    color: #d7ba7d;
  }
  .hljs-addition {
    background-color: #144212;
    display: inline-block;
    width: 100%;
  }
  .hljs-deletion {
    background-color: #600;
    display: inline-block;
    width: 100%;
  }
  .masonry-filter {
    cursor: pointer;
  }
  .scroll-wrapper {
    overflow: hidden !important;
    padding: 0 !important;
    position: relative;
  }
  .scroll-wrapper > .scroll-content {
    border: none !important;
    box-sizing: content-box !important;
    height: auto;
    left: 0;
    margin: 0;
    max-height: none;
    max-width: none !important;
    overflow: scroll !important;
    padding: 0;
    position: relative !important;
    top: 0;
    width: auto !important;
  }
  .scroll-wrapper > .scroll-content::-webkit-scrollbar {
    height: 0;
    width: 0;
  }
  .scroll-wrapper.scroll--rtl {
    direction: rtl;
  }
  .scroll-element {
    box-sizing: content-box;
    display: none;
  }
  .scroll-element div {
    box-sizing: content-box;
  }
  .scroll-element .scroll-arrow,
  .scroll-element .scroll-bar {
    cursor: default;
  }
  .scroll-element.scroll-x.scroll-scrollx_visible,
  .scroll-element.scroll-y.scroll-scrolly_visible {
    display: block;
  }
  .scroll-textarea {
    border: 1px solid #ccc;
    border-top-color: #999;
  }
  .scroll-textarea > .scroll-content {
    overflow: hidden !important;
  }
  .scroll-textarea > .scroll-content > textarea {
    border: none !important;
    box-sizing: border-box;
    height: 100% !important;
    margin: 0;
    max-height: none !important;
    max-width: none !important;
    overflow: scroll !important;
    outline: 0;
    padding: 2px;
    position: relative !important;
    top: 0;
    width: 100% !important;
  }
  .scroll-textarea > .scroll-content > textarea::-webkit-scrollbar {
    height: 0;
    width: 0;
  }
  .scrollbar-inner > .scroll-element,
  .scrollbar-inner > .scroll-element div {
    border: none;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 10;
  }
  .scrollbar-inner > .scroll-element div {
    display: block;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
  }
  .scrollbar-inner > .scroll-element.scroll-x {
    bottom: 2px;
    height: 8px;
    left: 0;
    width: 100%;
  }
  .scrollbar-inner > .scroll-element.scroll-y {
    height: 100%;
    right: 2px;
    top: 0;
    width: 8px;
  }
  .scrollbar-inner > .scroll-element .scroll-element_outer {
    overflow: hidden;
  }
  .scrollbar-inner > .scroll-element .scroll-bar,
  .scrollbar-inner > .scroll-element .scroll-element_outer,
  .scrollbar-inner > .scroll-element .scroll-element_track {
    border-radius: 8px;
  }
  .scrollbar-inner > .scroll-element .scroll-bar,
  .scrollbar-inner > .scroll-element .scroll-element_track {
    opacity: 0.4;
  }
  .scrollbar-inner > .scroll-element .scroll-element_track {
    background-color: #e0e0e0;
  }
  .scrollbar-inner > .scroll-element .scroll-bar {
    background-color: #c2c2c2;
  }
  .scrollbar-inner > .scroll-element:hover .scroll-bar {
    background-color: #919191;
  }
  .scrollbar-inner > .scroll-element.scroll-draggable .scroll-bar {
    background-color: #919191;
  }
  .scrollbar-inner
    > .scroll-element.scroll-x.scroll-scrolly_visible
    .scroll-element_track {
    left: -12px;
  }
  .scrollbar-inner
    > .scroll-element.scroll-y.scroll-scrollx_visible
    .scroll-element_track {
    top: -12px;
  }
  .scrollbar-inner
    > .scroll-element.scroll-x.scroll-scrolly_visible
    .scroll-element_size {
    left: -12px;
  }
  .scrollbar-inner
    > .scroll-element.scroll-y.scroll-scrollx_visible
    .scroll-element_size {
    top: -12px;
  }
  .scrollbar-outer > .scroll-element,
  .scrollbar-outer > .scroll-element div {
    border: none;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 10;
  }
  .scrollbar-outer > .scroll-element {
    background-color: #fff;
  }
  .scrollbar-outer > .scroll-element div {
    display: block;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
  }
  .scrollbar-outer > .scroll-element.scroll-x {
    bottom: 0;
    height: 12px;
    left: 0;
    width: 100%;
  }
  .scrollbar-outer > .scroll-element.scroll-y {
    height: 100%;
    right: 0;
    top: 0;
    width: 12px;
  }
  .scrollbar-outer > .scroll-element.scroll-x .scroll-element_outer {
    height: 8px;
    top: 2px;
  }
  .scrollbar-outer > .scroll-element.scroll-y .scroll-element_outer {
    left: 2px;
    width: 8px;
  }
  .scrollbar-outer > .scroll-element .scroll-element_outer {
    overflow: hidden;
  }
  .scrollbar-outer > .scroll-element .scroll-element_track {
    background-color: #eee;
  }
  .scrollbar-outer > .scroll-element .scroll-bar,
  .scrollbar-outer > .scroll-element .scroll-element_outer,
  .scrollbar-outer > .scroll-element .scroll-element_track {
    border-radius: 8px;
  }
  .scrollbar-outer > .scroll-element .scroll-bar {
    background-color: #d9d9d9;
  }
  .scrollbar-outer > .scroll-element .scroll-bar:hover {
    background-color: #c2c2c2;
  }
  .scrollbar-outer > .scroll-element.scroll-draggable .scroll-bar {
    background-color: #919191;
  }
  .scrollbar-outer > .scroll-content.scroll-scrolly_visible {
    left: -12px;
    margin-left: 12px;
  }
  .scrollbar-outer > .scroll-content.scroll-scrollx_visible {
    top: -12px;
    margin-top: 12px;
  }
  .scrollbar-outer > .scroll-element.scroll-x .scroll-bar {
    min-width: 10px;
  }
  .scrollbar-outer > .scroll-element.scroll-y .scroll-bar {
    min-height: 10px;
  }
  .scrollbar-outer
    > .scroll-element.scroll-x.scroll-scrolly_visible
    .scroll-element_track {
    left: -14px;
  }
  .scrollbar-outer
    > .scroll-element.scroll-y.scroll-scrollx_visible
    .scroll-element_track {
    top: -14px;
  }
  .scrollbar-outer
    > .scroll-element.scroll-x.scroll-scrolly_visible
    .scroll-element_size {
    left: -14px;
  }
  .scrollbar-outer
    > .scroll-element.scroll-y.scroll-scrollx_visible
    .scroll-element_size {
    top: -14px;
  }
  .scrollbar-macosx > .scroll-element,
  .scrollbar-macosx > .scroll-element div {
    background: 0 0;
    border: none;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 10;
  }
  .scrollbar-macosx > .scroll-element div {
    display: block;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
  }
  .scrollbar-macosx > .scroll-element .scroll-element_track {
    display: none;
  }
  .scrollbar-macosx > .scroll-element .scroll-bar {
    background-color: #6c6e71;
    display: block;
    opacity: 0;
    border-radius: 7px;
    transition: opacity 0.2s linear;
  }
  .scrollbar-macosx:hover > .scroll-element .scroll-bar,
  .scrollbar-macosx > .scroll-element.scroll-draggable .scroll-bar {
    opacity: 0.7;
  }
  .scrollbar-macosx > .scroll-element.scroll-x {
    bottom: 0;
    height: 0;
    left: 0;
    min-width: 100%;
    overflow: visible;
    width: 100%;
  }
  .scrollbar-macosx > .scroll-element.scroll-y {
    height: 100%;
    min-height: 100%;
    right: 0;
    top: 0;
    width: 0;
  }
  .scrollbar-macosx > .scroll-element.scroll-x .scroll-bar {
    height: 7px;
    min-width: 10px;
    top: -9px;
  }
  .scrollbar-macosx > .scroll-element.scroll-y .scroll-bar {
    left: -9px;
    min-height: 10px;
    width: 7px;
  }
  .scrollbar-macosx > .scroll-element.scroll-x .scroll-element_outer {
    left: 2px;
  }
  .scrollbar-macosx > .scroll-element.scroll-x .scroll-element_size {
    left: -4px;
  }
  .scrollbar-macosx > .scroll-element.scroll-y .scroll-element_outer {
    top: 2px;
  }
  .scrollbar-macosx > .scroll-element.scroll-y .scroll-element_size {
    top: -4px;
  }
  .scrollbar-macosx
    > .scroll-element.scroll-x.scroll-scrolly_visible
    .scroll-element_size {
    left: -11px;
  }
  .scrollbar-macosx
    > .scroll-element.scroll-y.scroll-scrollx_visible
    .scroll-element_size {
    top: -11px;
  }
  .scrollbar-light > .scroll-element,
  .scrollbar-light > .scroll-element div {
    border: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    z-index: 10;
  }
  .scrollbar-light > .scroll-element {
    background-color: #fff;
  }
  .scrollbar-light > .scroll-element div {
    display: block;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
  }
  .scrollbar-light > .scroll-element .scroll-element_outer {
    border-radius: 10px;
  }
  .scrollbar-light > .scroll-element .scroll-element_size {
    background: #dbdbdb;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2RiZGJkYiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNlOGU4ZTgiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: linear-gradient(to right, #dbdbdb 0, #e8e8e8 100%);
    border-radius: 10px;
  }
  .scrollbar-light > .scroll-element.scroll-x {
    bottom: 0;
    height: 17px;
    left: 0;
    min-width: 100%;
    width: 100%;
  }
  .scrollbar-light > .scroll-element.scroll-y {
    height: 100%;
    min-height: 100%;
    right: 0;
    top: 0;
    width: 17px;
  }
  .scrollbar-light > .scroll-element .scroll-bar {
    background: #fefefe;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZlZmVmZSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmNWY1ZjUiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: linear-gradient(to right, #fefefe 0, #f5f5f5 100%);
    border: 1px solid #dbdbdb;
    border-radius: 10px;
  }
  .scrollbar-light > .scroll-content.scroll-scrolly_visible {
    left: -17px;
    margin-left: 17px;
  }
  .scrollbar-light > .scroll-content.scroll-scrollx_visible {
    top: -17px;
    margin-top: 17px;
  }
  .scrollbar-light > .scroll-element.scroll-x .scroll-bar {
    height: 10px;
    min-width: 10px;
    top: 0;
  }
  .scrollbar-light > .scroll-element.scroll-y .scroll-bar {
    left: 0;
    min-height: 10px;
    width: 10px;
  }
  .scrollbar-light > .scroll-element.scroll-x .scroll-element_outer {
    height: 12px;
    left: 2px;
    top: 2px;
  }
  .scrollbar-light > .scroll-element.scroll-x .scroll-element_size {
    left: -4px;
  }
  .scrollbar-light > .scroll-element.scroll-y .scroll-element_outer {
    left: 2px;
    top: 2px;
    width: 12px;
  }
  .scrollbar-light > .scroll-element.scroll-y .scroll-element_size {
    top: -4px;
  }
  .scrollbar-light
    > .scroll-element.scroll-x.scroll-scrolly_visible
    .scroll-element_size {
    left: -19px;
  }
  .scrollbar-light
    > .scroll-element.scroll-y.scroll-scrollx_visible
    .scroll-element_size {
    top: -19px;
  }
  .scrollbar-light
    > .scroll-element.scroll-x.scroll-scrolly_visible
    .scroll-element_track {
    left: -19px;
  }
  .scrollbar-light
    > .scroll-element.scroll-y.scroll-scrollx_visible
    .scroll-element_track {
    top: -19px;
  }
  .scrollbar-rail > .scroll-element,
  .scrollbar-rail > .scroll-element div {
    border: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    z-index: 10;
  }
  .scrollbar-rail > .scroll-element {
    background-color: #fff;
  }
  .scrollbar-rail > .scroll-element div {
    display: block;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
  }
  .scrollbar-rail > .scroll-element .scroll-element_size {
    background-color: #999;
    background-color: rgba(0, 0, 0, 0.3);
  }
  .scrollbar-rail
    > .scroll-element
    .scroll-element_outer:hover
    .scroll-element_size {
    background-color: #666;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .scrollbar-rail > .scroll-element.scroll-x {
    bottom: 0;
    height: 12px;
    left: 0;
    min-width: 100%;
    padding: 3px 0 2px;
    width: 100%;
  }
  .scrollbar-rail > .scroll-element.scroll-y {
    height: 100%;
    min-height: 100%;
    padding: 0 2px 0 3px;
    right: 0;
    top: 0;
    width: 12px;
  }
  .scrollbar-rail > .scroll-element .scroll-bar {
    background-color: #d0b9a0;
    border-radius: 2px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  }
  .scrollbar-rail > .scroll-element .scroll-element_outer:hover .scroll-bar {
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
  }
  .scrollbar-rail > .scroll-content.scroll-scrolly_visible {
    left: -17px;
    margin-left: 17px;
  }
  .scrollbar-rail > .scroll-content.scroll-scrollx_visible {
    margin-top: 17px;
    top: -17px;
  }
  .scrollbar-rail > .scroll-element.scroll-x .scroll-bar {
    height: 10px;
    min-width: 10px;
    top: 1px;
  }
  .scrollbar-rail > .scroll-element.scroll-y .scroll-bar {
    left: 1px;
    min-height: 10px;
    width: 10px;
  }
  .scrollbar-rail > .scroll-element.scroll-x .scroll-element_outer {
    height: 15px;
    left: 5px;
  }
  .scrollbar-rail > .scroll-element.scroll-x .scroll-element_size {
    height: 2px;
    left: -10px;
    top: 5px;
  }
  .scrollbar-rail > .scroll-element.scroll-y .scroll-element_outer {
    top: 5px;
    width: 15px;
  }
  .scrollbar-rail > .scroll-element.scroll-y .scroll-element_size {
    left: 5px;
    top: -10px;
    width: 2px;
  }
  .scrollbar-rail
    > .scroll-element.scroll-x.scroll-scrolly_visible
    .scroll-element_size {
    left: -25px;
  }
  .scrollbar-rail
    > .scroll-element.scroll-y.scroll-scrollx_visible
    .scroll-element_size {
    top: -25px;
  }
  .scrollbar-rail
    > .scroll-element.scroll-x.scroll-scrolly_visible
    .scroll-element_track {
    left: -25px;
  }
  .scrollbar-rail
    > .scroll-element.scroll-y.scroll-scrollx_visible
    .scroll-element_track {
    top: -25px;
  }
  .scrollbar-dynamic > .scroll-element,
  .scrollbar-dynamic > .scroll-element div {
    background: 0 0;
    border: none;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 10;
  }
  .scrollbar-dynamic > .scroll-element div {
    display: block;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
  }
  .scrollbar-dynamic > .scroll-element.scroll-x {
    bottom: 2px;
    height: 7px;
    left: 0;
    min-width: 100%;
    width: 100%;
  }
  .scrollbar-dynamic > .scroll-element.scroll-y {
    height: 100%;
    min-height: 100%;
    right: 2px;
    top: 0;
    width: 7px;
  }
  .scrollbar-dynamic > .scroll-element .scroll-element_outer {
    opacity: 0.3;
    border-radius: 12px;
  }
  .scrollbar-dynamic > .scroll-element .scroll-element_size {
    background-color: #ccc;
    opacity: 0;
    border-radius: 12px;
    transition: opacity 0.2s;
  }
  .scrollbar-dynamic > .scroll-element .scroll-bar {
    background-color: #6c6e71;
    border-radius: 7px;
  }
  .scrollbar-dynamic > .scroll-element.scroll-x .scroll-bar {
    bottom: 0;
    height: 7px;
    min-width: 24px;
    top: auto;
  }
  .scrollbar-dynamic > .scroll-element.scroll-y .scroll-bar {
    left: auto;
    min-height: 24px;
    right: 0;
    width: 7px;
  }
  .scrollbar-dynamic > .scroll-element.scroll-x .scroll-element_outer {
    bottom: 0;
    top: auto;
    left: 2px;
    transition: height 0.2s;
  }
  .scrollbar-dynamic > .scroll-element.scroll-y .scroll-element_outer {
    left: auto;
    right: 0;
    top: 2px;
    transition: width 0.2s;
  }
  .scrollbar-dynamic > .scroll-element.scroll-x .scroll-element_size {
    left: -4px;
  }
  .scrollbar-dynamic > .scroll-element.scroll-y .scroll-element_size {
    top: -4px;
  }
  .scrollbar-dynamic
    > .scroll-element.scroll-x.scroll-scrolly_visible
    .scroll-element_size {
    left: -11px;
  }
  .scrollbar-dynamic
    > .scroll-element.scroll-y.scroll-scrollx_visible
    .scroll-element_size {
    top: -11px;
  }
  .scrollbar-dynamic > .scroll-element.scroll-draggable .scroll-element_outer,
  .scrollbar-dynamic > .scroll-element:hover .scroll-element_outer {
    overflow: hidden;
    opacity: 0.7;
  }
  .scrollbar-dynamic
    > .scroll-element.scroll-draggable
    .scroll-element_outer
    .scroll-element_size,
  .scrollbar-dynamic
    > .scroll-element:hover
    .scroll-element_outer
    .scroll-element_size {
    opacity: 1;
  }
  .scrollbar-dynamic
    > .scroll-element.scroll-draggable
    .scroll-element_outer
    .scroll-bar,
  .scrollbar-dynamic > .scroll-element:hover .scroll-element_outer .scroll-bar {
    height: 100%;
    width: 100%;
    border-radius: 12px;
  }
  .scrollbar-dynamic
    > .scroll-element.scroll-x.scroll-draggable
    .scroll-element_outer,
  .scrollbar-dynamic > .scroll-element.scroll-x:hover .scroll-element_outer {
    height: 20px;
    min-height: 7px;
  }
  .scrollbar-dynamic
    > .scroll-element.scroll-y.scroll-draggable
    .scroll-element_outer,
  .scrollbar-dynamic > .scroll-element.scroll-y:hover .scroll-element_outer {
    min-width: 7px;
    width: 20px;
  }
  .scrollbar-chrome > .scroll-element,
  .scrollbar-chrome > .scroll-element div {
    border: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    z-index: 10;
  }
  .scrollbar-chrome > .scroll-element {
    background-color: #fff;
  }
  .scrollbar-chrome > .scroll-element div {
    display: block;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
  }
  .scrollbar-chrome > .scroll-element .scroll-element_track {
    background: #f1f1f1;
    border: 1px solid #dbdbdb;
  }
  .scrollbar-chrome > .scroll-element.scroll-x {
    bottom: 0;
    height: 16px;
    left: 0;
    min-width: 100%;
    width: 100%;
  }
  .scrollbar-chrome > .scroll-element.scroll-y {
    height: 100%;
    min-height: 100%;
    right: 0;
    top: 0;
    width: 16px;
  }
  .scrollbar-chrome > .scroll-element .scroll-bar {
    background-color: #d9d9d9;
    border: 1px solid #bdbdbd;
    cursor: default;
    border-radius: 2px;
  }
  .scrollbar-chrome > .scroll-element .scroll-bar:hover {
    background-color: #c2c2c2;
    border-color: #a9a9a9;
  }
  .scrollbar-chrome > .scroll-element.scroll-draggable .scroll-bar {
    background-color: #919191;
    border-color: #7e7e7e;
  }
  .scrollbar-chrome > .scroll-content.scroll-scrolly_visible {
    left: -16px;
    margin-left: 16px;
  }
  .scrollbar-chrome > .scroll-content.scroll-scrollx_visible {
    top: -16px;
    margin-top: 16px;
  }
  .scrollbar-chrome > .scroll-element.scroll-x .scroll-bar {
    height: 8px;
    min-width: 10px;
    top: 3px;
  }
  .scrollbar-chrome > .scroll-element.scroll-y .scroll-bar {
    left: 3px;
    min-height: 10px;
    width: 8px;
  }
  .scrollbar-chrome > .scroll-element.scroll-x .scroll-element_outer {
    border-left: 1px solid #dbdbdb;
  }
  .scrollbar-chrome > .scroll-element.scroll-x .scroll-element_track {
    height: 14px;
    left: -3px;
  }
  .scrollbar-chrome > .scroll-element.scroll-x .scroll-element_size {
    height: 14px;
    left: -4px;
  }
  .scrollbar-chrome > .scroll-element.scroll-y .scroll-element_outer {
    border-top: 1px solid #dbdbdb;
  }
  .scrollbar-chrome > .scroll-element.scroll-y .scroll-element_track {
    top: -3px;
    width: 14px;
  }
  .scrollbar-chrome > .scroll-element.scroll-y .scroll-element_size {
    top: -4px;
    width: 14px;
  }
  .scrollbar-chrome
    > .scroll-element.scroll-x.scroll-scrolly_visible
    .scroll-element_size {
    left: -19px;
  }
  .scrollbar-chrome
    > .scroll-element.scroll-y.scroll-scrollx_visible
    .scroll-element_size {
    top: -19px;
  }
  .scrollbar-chrome
    > .scroll-element.scroll-x.scroll-scrolly_visible
    .scroll-element_track {
    left: -19px;
  }
  .scrollbar-chrome
    > .scroll-element.scroll-y.scroll-scrollx_visible
    .scroll-element_track {
    top: -19px;
  }
  .scrollbar-inner {
    height: 100%;
  }
  .scrollbar-inner:not(:hover) .scroll-element {
    opacity: 0;
  }
  .scrollbar-inner .scroll-element {
    transition: opacity 0.3s;
    margin-right: 2px;
  }
  .scrollbar-inner .scroll-element .scroll-bar,
  .scrollbar-inner .scroll-element .scroll-element_track {
    transition: background-color 0.3s;
  }
  .scrollbar-inner .scroll-element .scroll-element_track {
    background-color: transparent;
  }
  .scrollbar-inner .scroll-element:hover {
    width: 4px;
  }
  .scrollbar-inner .scroll-element.scroll-y {
    width: 3px;
    right: 0;
  }
  .scrollbar-inner .scroll-element.scroll-x {
    height: 3px;
    bottom: 0;
  }
  .select2-selection__arrow {
    display: none;
  }
  .select2.select2-container {
    width: 100% !important;
  }
  .select2-container .select2-selection--single,
  .select2-container--default .select2-search--dropdown .select2-search__field,
  .select2-container--default .select2-selection--multiple,
  .select2-container--default.select2-container--focus
    .select2-selection--multiple {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.5rem + 2px);
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #4a5568;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e2e8f0;
    border-radius: 0.375rem;
    box-shadow: inset 0 1px 1px rgba(31, 45, 61, 0.075);
    transition: all 0.2s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .select2-container .select2-selection--single,
    .select2-container--default
      .select2-search--dropdown
      .select2-search__field,
    .select2-container--default .select2-selection--multiple,
    .select2-container--default.select2-container--focus
      .select2-selection--multiple {
      transition: none;
    }
  }
  .select2-container .select2-selection--single::-ms-expand,
  .select2-container--default
    .select2-search--dropdown
    .select2-search__field::-ms-expand,
  .select2-container--default .select2-selection--multiple::-ms-expand,
  .select2-container--default.select2-container--focus
    .select2-selection--multiple::-ms-expand {
    background-color: transparent;
    border: 0;
  }
  .select2-container .select2-selection--single:focus,
  .select2-container--default
    .select2-search--dropdown
    .select2-search__field:focus,
  .select2-container--default .select2-selection--multiple:focus,
  .select2-container--default.select2-container--focus
    .select2-selection--multiple:focus {
    color: #4a5568;
    background-color: #fff;
    border-color: rgba(0, 138, 255, 0.5);
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(31, 45, 61, 0.075),
      0 0 20px rgba(0, 138, 255, 0.1);
  }
  .select2-container .select2-selection--single:-ms-input-placeholder,
  .select2-container--default
    .select2-search--dropdown
    .select2-search__field:-ms-input-placeholder,
  .select2-container--default
    .select2-selection--multiple:-ms-input-placeholder,
  .select2-container--default.select2-container--focus
    .select2-selection--multiple:-ms-input-placeholder {
    color: #a0aec0;
    opacity: 1;
  }
  .select2-container .select2-selection--single::-ms-input-placeholder,
  .select2-container--default
    .select2-search--dropdown
    .select2-search__field::-ms-input-placeholder,
  .select2-container--default
    .select2-selection--multiple::-ms-input-placeholder,
  .select2-container--default.select2-container--focus
    .select2-selection--multiple::-ms-input-placeholder {
    color: #a0aec0;
    opacity: 1;
  }
  .select2-container .select2-selection--single::placeholder,
  .select2-container--default
    .select2-search--dropdown
    .select2-search__field::placeholder,
  .select2-container--default .select2-selection--multiple::placeholder,
  .select2-container--default.select2-container--focus
    .select2-selection--multiple::placeholder {
    color: #a0aec0;
    opacity: 1;
  }
  .select2-container .select2-selection--single:disabled,
  .select2-container .select2-selection--single[readonly],
  .select2-container--default
    .select2-search--dropdown
    .select2-search__field:disabled,
  .select2-container--default
    .select2-search--dropdown
    .select2-search__field[readonly],
  .select2-container--default .select2-selection--multiple:disabled,
  .select2-container--default .select2-selection--multiple[readonly],
  .select2-container--default.select2-container--focus
    .select2-selection--multiple:disabled,
  .select2-container--default.select2-container--focus
    .select2-selection--multiple[readonly] {
    background-color: #eaecf3;
    opacity: 1;
  }
  .select2-container .select2-selection--single .select2-selection__rendered {
    padding: 0;
    overflow: inherit;
    text-overflow: inherit;
    white-space: inherit;
  }
  .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    color: inherit;
    line-height: inherit;
  }
  .select2-dropdown {
    padding: 0.35rem 0;
    background-color: #fff;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
  }
  .select2-results__option {
    padding: 0.25rem 1.25rem;
    background-color: #fff;
    color: #4a5568;
    font-size: 1rem;
  }
  .select2-results__option:hover {
    color: #008aff;
  }
  .select2-container--default
    .select2-results__option--highlighted[aria-selected],
  .select2-container--default .select2-results__option[aria-selected="true"] {
    background-color: transparent;
    color: #008aff;
  }
  .select2-container--default .select2-results__option[aria-disabled="true"] {
    color: #718096;
  }
  .select2-container--default .select2-selection--multiple,
  .select2-container--default.select2-container--focus
    .select2-selection--multiple {
    height: auto;
    min-height: calc(1.5em + 1.5rem + 2px);
  }
  .select2-container--default
    .select2-selection--multiple
    .select2-selection__rendered {
    display: block;
    margin: 0 0 -0.25rem -0.25rem;
    padding: 0;
  }
  .select2-container--default
    .select2-selection--multiple
    .select2-selection__choice {
    display: inline-flex;
    padding: 0 0.5rem;
    margin: 0 0 0.25rem 0.25rem;
    background-color: #eaecf3;
    border: none;
    border-radius: 0.2rem;
    line-height: 1.5rem;
    font-size: 1rem;
    color: #4a5568;
  }
  .select2-container--default
    .select2-selection--multiple
    .select2-selection__choice__remove {
    order: 2;
    margin-left: 0.5rem;
    color: #718096;
  }
  .select2-container--default
    .select2-selection--multiple
    .select2-selection__choice__remove:hover {
    color: #718096;
  }
  .select2-container .select2-search--inline {
    display: none;
  }
  .select2-selection[aria-expanded="true"] {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .select2-search--dropdown {
    padding: 0.25rem 1.25rem;
  }
  .select2-container--default .select2-search--dropdown .select2-search__field {
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1.25rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
  .form-control-sm + .select2-container .select2-selection--single,
  .form-control-sm + .select2-container--default .select2-selection--multiple,
  .form-control-sm
    + .select2-container--default.select2-container--focus
    .select2-selection--multiple {
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1.25rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
  .form-control-sm + .select2-container--default .select2-selection--multiple,
  .form-control-sm
    + .select2-container--default.select2-container--focus
    .select2-selection--multiple {
    min-height: calc(1.5em + 1rem + 2px);
  }
  .form-control-sm
    + .select2-container--default
    .select2-selection--multiple
    .select2-selection__choice {
    line-height: 1.3125rem;
  }
  .form-control-lg + .select2-container .select2-selection--single,
  .form-control-lg + .select2-container--default .select2-selection--multiple,
  .form-control-lg
    + .select2-container--default.select2-container--focus
    .select2-selection--multiple {
    height: calc(1.5em + 2rem + 2px);
    padding: 1rem 1.875rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.5rem;
  }
  .form-control-lg + .select2-container--default .select2-selection--multiple,
  .form-control-lg
    + .select2-container--default.select2-container--focus
    .select2-selection--multiple {
    min-height: calc(1.5em + 2rem + 2px);
  }
  .form-control-lg
    + .select2-container--default
    .select2-selection--multiple
    .select2-selection__choice {
    line-height: 1.5rem;
  }
  .swal2-popup {
    padding: 1.5rem;
  }
  .swal2-popup .swal2-title {
    font-size: 1.5rem;
  }
  .swal2-popup .swal2-content {
    font-size: 0.875rem;
  }
  .swal2-popup .swal2-image {
    max-width: 200px;
  }
  .swiper-slide {
    opacity: 1;
    transition: opacity 0.3s;
  }
  @media (prefers-reduced-motion: reduce) {
    .swiper-slide {
      transition: none;
    }
  }
  .swiper-slide .card {
    margin: 0;
  }
  .swiper-fade .swiper-slide-next,
  .swiper-fade .swiper-slide-prev {
    opacity: 0;
  }
  .swiper-container-vertical {
    width: 100%;
    height: 100%;
  }
  .swiper-container-vertical .swiper-slide {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .swiper-pagination {
    left: 0;
  }
  .swiper-pagination-bullet {
    width: 0.4rem;
    height: 0.4rem;
    margin-right: 0.45rem;
    opacity: 0.5;
    background: #a0aec0;
    outline: 0;
    transition: all 0.2s ease;
  }
  .swiper-pagination-bullet:hover {
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    transform: scale(1.4);
    background: #008aff;
    opacity: 1;
  }
  .swiper-button {
    position: absolute;
    top: 50%;
    width: 44px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    margin-top: 0;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    z-index: 10;
    cursor: pointer;
    background-image: none;
    outline: 0;
    transition: all 0.2s ease;
    box-shadow: 0 0.125rem 0.25rem rgba(31, 45, 61, 0.08);
  }
  @media (prefers-reduced-motion: reduce) {
    .swiper-button {
      transition: none;
    }
  }
  .swiper-button:after {
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Free";
    font-weight: 700;
  }
  .swiper-button-sm {
    width: 34px;
    height: 34px;
    line-height: 34px;
  }
  .swiper-button-next {
    right: -44px;
  }
  .swiper-button-next.swiper-button-sm {
    right: -14px;
  }
  .swiper-button-next:after {
    content: "\f105";
  }
  @media (max-width: 767.98px) {
    .swiper-button-next {
      right: -20px;
    }
  }
  .swiper-button-prev {
    left: 0;
  }
  .swiper-button-prev:after {
    content: "\f104";
  }
  @media (max-width: 767.98px) {
    .swiper-button-prev {
      left: 24px;
    }
  }
  .swiper-button-disabled {
    opacity: 0 !important;
  }
  .bootstrap-tagsinput {
    display: block;
    max-width: 100%;
    color: #718096;
    vertical-align: middle;
    background-color: transparent;
    border: 0 solid transparent;
    border-radius: 0.375rem;
    cursor: default;
  }
  .bootstrap-tagsinput input {
    display: block;
    border: 0;
    color: #4a5568;
    box-shadow: none;
    outline: 0;
    background-color: transparent;
    padding: 0;
    margin: 0;
    width: auto;
    max-width: inherit;
  }
  .bootstrap-tagsinput input:-ms-input-placeholder {
    color: #a0aec0;
    opacity: 1;
  }
  .bootstrap-tagsinput input::-ms-input-placeholder {
    color: #a0aec0;
    opacity: 1;
  }
  .bootstrap-tagsinput input::placeholder {
    color: #a0aec0;
    opacity: 1;
  }
  .bootstrap-tagsinput input:focus {
    border: none;
    box-shadow: none;
  }
  .bootstrap-tagsinput [data-role="remove"] {
    margin-left: 10px;
    cursor: pointer;
    color: #fff;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    opacity: 0;
  }
  .bootstrap-tagsinput [data-role="remove"]:after {
    content: "×";
    font-size: 16px;
  }
  .bootstrap-tagsinput .badge {
    display: inline-block;
    position: relative;
    padding: 0.625rem 0.625rem 0.5rem;
    margin: 0.125rem;
    border-radius: 0.375rem;
    background: #008aff;
    color: #fff;
    line-height: 1.5;
    overflow: hidden;
    box-shadow: 0 1px 2px rgba(31, 45, 61, 0.25);
    transition: all 0.2s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .bootstrap-tagsinput .badge {
      transition: none;
    }
  }
  .bootstrap-tagsinput .badge:hover {
    padding-right: 1.675rem;
  }
  .bootstrap-tagsinput .badge:hover [data-role="remove"] {
    opacity: 1;
  }
  .fs-1 {
    font-size: 1rem !important;
  }
}
