.Swiper {
  box-shadow: rgb(0 0 0 / 10%) 0px 20px 20px 5px;
  overflow: hidden;
}

.Swiper .z-i-999 {
  z-index: 999;
}

.dragger {
  max-width: 50px;
  position: absolute !important;
  top: calc(80% - 25px);
  left: -20px;
}

.dark-image {
  border-radius: 0.6rem;
}

.space-t {
  margin-top: 5rem;
}