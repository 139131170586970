.PriceSlider .ui-slider__input-wrapper {
  width: 80%;
}

.PriceSlider .teacherInp {
  border: none;
  width: 150px;
  color: white;
  background: #6264a7;
  font-size: larger;
  text-align: center;
  font-weight: bold;
  outline: none;
  border-radius: 6px;
}

.PriceSlider.user-select-none {
  user-select: none;
}

.PriceSlider .ui-slider__track {
  border-radius: 50px;
  height: 0.425rem !important;
  /* transition: all 0.1s ease-out 0s; */
}

.PriceSlider .ui-slider__rail {
  border-radius: 50px;
  height: 0.425rem !important;
}

.PriceSlider .ui-slider__thumb:focus-visible {
  display: block;
}

.PriceSlider .ui-slider__input {
  /* outline: none; */
  z-index: 5000;
}

.PriceSlider .ui-slider__input:focus+.ui-slider__thumb {
  border: 5px solid rgb(98, 100, 167) !important;
}

.PriceSlider .ui-slider__thumb {
  width: 1.625rem !important;
  height: 1.625rem !important;
  margin-top: -0.2rem;
  /* transition: all 0.1s ease-out 0s; */
  border: 5px solid rgb(98, 100, 167);
  background: white !important;
}

span.ui-slider__thumb {
  margin-top: -0.2rem !important;
  margin-left: -0.8rem !important;
}

.more-form {
  padding: 1px;
}