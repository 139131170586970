/* box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px; */

.success-payement_container .succss-payment-header .navbar#navbar-main {
  position: unset;
}

.success-payment-wrapper {
  height: 500px;
  width: 80%;
  margin: auto;
  text-align: center;
}

.succuss-payment-main img {
  height: 60vh;
  padding: 1rem;
}

.succuss-payment-main p {
  font-size: 18px;
}

.succuss-payment-main h1 {
  font-size: 38px;
  color: #25a888;
}

.success-payement_container .succuss-payment-button {
  display: flex;
  justify-content: space-evenly;
  width: 49%;
  margin: auto;
}

.success-payement_container .succuss-payment-button button {
  padding: 14px;
  width: 224px;
  outline: none;
  border-radius: 6px;
  font-weight: 700;
  cursor: pointer;
}

.btn_invoice {
  background-color: #d33139;
  color: #fff;
  border: 1px solid #d33139;
}

.success-payement_container .succuss-payment-button {
  margin-top: 40px;
}

.succuss-payment-button .btn_go_to_acv {
  background-color: #d33139;
  color: #fff;
  border: 1px solid #d33139;
}

.cancel-payment {
  padding-top: 55px;
}