.brand-btn {
  /* width: 260px; */
  height: 58px;
  background-color: #d33139;
  border-color: #d33139;
  border-radius: 8px;
  color: white;
  font-weight: 500;
  font-size: 1.2rem;
  color: #f5f5f5;
  padding: 0 1.2rem;
}

/* .btn-danger.brand-btn {
  cursor: url('/public/assets/cursor-light.svg'), pointer !important;
} */