.form-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* min-height: 100vh; */
  }
  
  .form-holder .form-content {
    position: relative;
    text-align: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    /* padding: 60px; */
  }
  
  .form-content .form-items {
    border: 1px solid black;
    padding: 40px;
    display: inline-block;
    width: 100%;
    min-width: 540px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    text-align: left;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  
  .form-content h3 {
    color: black;
    text-align: left;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 40px;
  }
  
  .form-content h3.form-title {
    margin-bottom: 30px;
  }
  
  .form-content p {
    color: black;
    text-align: left;
    font-size: 17px;
    /* font-weight: 300; */
    line-height: 20px;
    margin-bottom: 30px;
  }
  
  .form-content label,
  .was-validated .form-check-input:invalid ~ .form-check-label,
  .was-validated .form-check-input:valid ~ .form-check-label {
    color: #fff;
  }
  
  .form-content input[type="text"],
  .form-content input[type="password"],
  .form-content input[type="email"],
  .form-content select {
    width: 100%;
    padding: 9px 20px;
    text-align: left;
    border: 0;
    outline: 0;
    border-radius: 6px;
    background-color: #fff;
    font-size: 15px;
    font-weight: 300;
    color: black;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    margin-top: 16px;
    border: 1px solid black !important;
    margin-bottom: 30px;
  }
  
  /* .btn-primary {
    background-color: #6c757d;
    outline: none;
    border: 0px;
    box-shadow: none;
  }
  
  .btn-primary:hover,
  .btn-primary:focus,
  .btn-primary:active {
    background-color: #495056;
    outline: none !important;
    border: none !important;
    box-shadow: none;
  } */
  
  .form-content textarea {
    position: static !important;
    width: 100%;
    padding: 8px 20px;
    border-radius: 6px;
    text-align: left;
    background-color: #fff;
    border: 0;
    font-size: 15px;
    font-weight: 300;
    color: #8d8d8d;
    outline: none;
    resize: none;
    height: 120px;
    -webkit-transition: none;
    transition: none;
    margin-bottom: 14px;
  }
  
  .form-content textarea:hover,
  .form-content textarea:focus {
    border: 0;
    background-color: #ebeff8;
    color: #8d8d8d;
  }
  
  .mv-up {
    margin-top: -9px !important;
    margin-bottom: 8px !important;
  }
  
  .invalid-feedback {
    color: #ff606e;
  }
  
  .valid-feedback {
    color: #2acc80;
  }
  
  .animation {
    background: linear-gradient(-45deg, #dddddd, #f0f0f0, #dddddd, #f0f0f0);
    background-size: 400% 400%;
    -webkit-animation: Gradient 2.25s ease infinite;
    -moz-animation: Gradient 2.25s ease infinite;
    animation: Gradient 2.25s ease infinite;
    /* background: rgb(224, 223, 225); */
    width: 20%;
    margin-bottom: 30px;
    border-radius: 5px;
  }
  