.tog-container {
  width: 100%;
  margin: 0 auto;
  box-shadow: rgb(0 0 0 / 10%) 0px 20px 20px 5px;
  overflow: hidden;
  border-radius: 20px !important;
  border: 1px solid;
}
.toggler-navs {
  height: 70px;
  width: 100%;
  background-color: #121212;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  padding-left: 1.8rem;
  padding-right: 1.8rem;
}
/* @media only screen and (min-width: 1370px) {
  .tog-container {
    width: 1216px;
  }
} */

.report-header {
  margin: auto 0 !important;
  color: #e6e6e6 !important;
}

.db-navs {
  /* text-decoration: none; */
  font-weight: 500;
}

.toggler-navs .text-primary {
  color: #64a1ff !important;
}
@media (max-width: 768px) {
  .report-header,
  .toggler-navs lable {
    white-space: nowrap;
  }
  .toggler-navs lable {
    font-size: 12px !important;
    margin-left: 0 !important;
  }
  .report-header {
    font-size: 14px !important;
    padding: 0 1rem 0 2rem !important;
  }
}
.toggler-img {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
