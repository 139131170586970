@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');

:root {
  --brand: #d33139;
  --bs-brand: #d33139;
  --bs-brand-rgb: 211, 49, 58;
  --bs-dark-rgb: 18, 18, 18;
}

html {
  cursor: url('assets/svg/cursor.svg'), pointer;
}

body {
  margin: 0;
  padding: 0;
  /* background-color: #f5f5f5; */
  font-family: 'Poppins', sans-serif;
  text-align: center;
  color: #121212;
  overflow-x: clip;
}

::selection {
  color: #f5f5f5;
  background-color: #30475e;
}

img {
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

p {
  font-size: 18px;
}